import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import React, { useCallback, useEffect, useState } from "react";

import {
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Slider,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import OutlinedInput from "@mui/material/OutlinedInput";
import InfiniteScroll from "react-infinite-scroll-component";

import { listLostFoundpets } from "../../redux/action-creators/lostFoundAction";

import LeftSidebar from "../../components/sidebars/LeftSidebar";

import { capitalize } from "../../utils/helper";
import { RoutingLinks, Categories } from "../../utils/constants";

import { IPetLostFoundUserList } from "../../interfaceModules/IPetsInteface";

import { CommonButton } from "../../stories/Button";
import defaultUserprofile from "../../images/defaultUserprofile.png";

import { UserCommonStyles } from "../../styles/CommonStyles";
import { ProductStyles } from "../products/ProductsStyle";

const PetLostFound = () => {
  const { t: translation } = useTranslation();
  const commonStyle = UserCommonStyles();
  const styleClasses = ProductStyles();
  const dispatch = useDispatch();

  // const [userList, setUserList] = useState<IPetLostFoundUserList[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const userId = localStorage.getItem("userId");
  const [option, setoption] = useState({ text: "" });
  const history = useHistory();
  const [types, setType] = useState({ searchType: "" });
  const [distance, setdistance] = useState({ distance: "50" });
  const [loader, setloader] = useState(true);

  const [state, setState] = useState({
    userList: [],
    offset: 0,
    prevOffset: 0,
    hasMore: true,
  });
  const [productCallTimeOut, setProductCallTimeOut] = useState<any>();



  /**
   * Function To Get The List of Lost and Found Pets
   * @param firstload
   * @param optionValue
   * @param typeValue
   * @param distanceValue
   */
  const getList = async (
    firstLoad: boolean,
    optionValue?: string,
    typeValue?: string,
    distanceValue?: string
  ) => {
    if (firstLoad) {
      setState((prevState) => ({
        ...prevState,
        prevOffset: 0,
        offset: 0,
      }));
    }
    setloader(true);
    let offset = firstLoad ? 0 : state.offset
    let checked = optionValue !== undefined ? optionValue : option.text;
    let typeSearch = typeValue !== undefined ? typeValue : types.searchType;
    let distanceField = distanceValue !== undefined ? distanceValue : distance.distance;

    if (state.offset !== state.prevOffset || firstLoad) {
      setLoading(true); 
      let result: any = await dispatch(
        listLostFoundpets(checked, typeSearch, distanceField, userId, offset)
      );
      setloader(false);
      if (result && result?.length > 0) {
        for (let i = 0; i < result.length; i++) {
          let data = result[i]?.image;
          data = JSON.parse(data);

          result[i].image = data[0];
        }
        setState((prevState) => ({
          ...prevState,
          prevOffset: firstLoad ? 0 : prevState.offset,
          offset: firstLoad ? 10 : prevState.offset + 10,
          userList: firstLoad
            ? result
            : prevState.userList.concat(result),
          hasMore: true,
        }));
      } else {
        if (result) {
          setState((prevState) => ({
            ...prevState,
            userList: firstLoad
              ? result
              : prevState.userList.concat(result),
            hasMore: false,
          }));
        }
      }
    }
    setLoading(false)
  }


  useEffect(() => {
    getList(true, "", "", distance.distance);
  }, []);

  /**,
   * search Based On Lost and Found categories
   * @param e
   */
  const onSiteChanged = (e: React.ChangeEvent) => {
    let target = e.target as HTMLInputElement;
    const optionValue = target.value;
    if (target.value) {
      setoption((prevState) => ({
        ...prevState,
        text: optionValue,
      }));
      setState((prevState) => ({
        ...prevState,
        prevOffset: 0,
        offset: 0,
      }));
      getList(true, optionValue, types.searchType, distance.distance);
    }
  };


  const commonListCall = (firstLoad: boolean,
    optionValue?: string,
    typeValue?: string,
    distanceValue?: string) => {

    if (productCallTimeOut) {
      clearTimeout(productCallTimeOut);
    }
    const timeoutValue = setTimeout(() => {
      getList(firstLoad, optionValue, typeValue, distanceValue);
    }, 1500);
    setProductCallTimeOut(timeoutValue);
  }

  /**
   * search Based on Pets categories
   * @param event
   */
  const handleChange = (event: SelectChangeEvent) => {
    const typetext = event.target.value;

    setType((prevState) => ({
      ...prevState,
      searchType: typetext,
    }));
    setState((prevState) => ({
      ...prevState,
      prevOffset: 0,
      offset: 0,
    }));
    //getList(true,"", typetext,"");
    getList(true, option.text, typetext, distance.distance);


  };

  /**
   * search Based on Distance
   * @param event
   */
  const handleChangeSlider = (event: Event) => {
    let target = event.target as HTMLInputElement;
    const distanceValue = target.value;
    if (target.value) {
      setdistance((prevState) => ({
        ...prevState,
        distance: distanceValue,
      }));
      setState((prevState) => ({
        ...prevState,
        prevOffset: 0,
        offset: 0,
      }));
      // getList( true,"", "", distanceValue);
      commonListCall(true, option.text, types.searchType, distanceValue)
    }
  };
  return (
    <Box>
      <Box className={commonStyle.windowColor}>
        <Grid container>
          <Grid item md={3}>
            <LeftSidebar />
          </Grid>

          <Grid item md={9}>
            <Box className={`${commonStyle.p20} ${commonStyle.mt20}`}>
              <Grid container className={commonStyle.mb20}>
                <Grid item md={2} className={commonStyle.flexCenter}>
                  <Typography
                    variant="h6"
                    className={`${commonStyle.grey} ${commonStyle.fontWeight600}`}
                  >
                    {translation("lost_found")}
                  </Typography>
                </Grid>

                <Grid item md={10}>
                  <Grid container spacing={2}>
                    <Grid
                      item
                      md={7}
                      className={`${commonStyle.justifyEnd} ${commonStyle.flexCenter}`}
                    >
                      <RadioGroup
                        row
                        aria-label={translation("gender")}
                        name="row-radio-buttons-group"
                      >
                        <FormControlLabel
                          value="lost"
                          control={<Radio />}
                          label={translation<string>("lost")}
                          onChange={onSiteChanged}
                        />
                        <FormControlLabel
                          value="found"
                          control={<Radio />}
                          label={translation<string>("found")}
                          onChange={onSiteChanged}
                        />
                      </RadioGroup>

                      <Box className={commonStyle.commonSlider}>
                        <Typography>
                          {translation("distance_preference")}
                          <span>
                            {distance.distance} {translation("miles")}
                          </span>
                        </Typography>
                        <Slider
                          defaultValue={50}
                          aria-label={translation("default")}
                          valueLabelDisplay="auto"
                          onChange={handleChangeSlider}
                        />
                      </Box>
                    </Grid>

                    <Grid item md={5} className={commonStyle.flexCenter}>
                      <FormControl
                        fullWidth
                        className={`${commonStyle.mr10} ${styleClasses.selectLostBg}`}
                      >
                        <Select
                          displayEmpty
                          value={types.searchType}
                          onChange={handleChange}
                          input={<OutlinedInput />}
                          renderValue={(selected) => {
                            if (selected.length === 0) {
                              return <>{translation("Type_of_Pet")}</>;
                            }
                            return translation(selected);
                          }}
                        >
                          <MenuItem disabled value="">
                            <>{translation("Type_of_Pet")}</>
                          </MenuItem>
                          {Categories.map((name) => (
                            <MenuItem key={name} value={(name)}>
                              {translation(name)}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>

                      <Box className={`${commonStyle.flexCenter}`}>
                        <Box className={`${commonStyle.orangeBtn}`}>
                          <CommonButton
                            label={translation("list_yours")}
                            onClick={() =>
                              history.push(`${RoutingLinks.lostFoundlist}`)
                            }
                          />
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Box className={`${commonStyle.p20} ${commonStyle.card}`}>
                <InfiniteScroll
                  dataLength={state?.userList?.length}
                  next={() => getList(false)}
                  hasMore={state?.hasMore}
                  loader={(() => {
                    if (loading) {
                      return <div className="loaing-area">Loading...</div>;
                    }
                  })()}
                  scrollThreshold="100px" >
                  <Box className={styleClasses.productCardBox}>
                    <Grid container spacing={2}>
                      {state.userList.length > 0
                        ? state.userList.map((item, index) => (
                          <Grid
                            item
                            md={3}
                            className={commonStyle.textCenter}
                            key={index}
                          >
                            <Box className={styleClasses.productCard}>
                              <img
                                src={
                                  item.image ? item.image : defaultUserprofile
                                }
                                alt={translation("image")}
                              />
                            </Box>
                            <Typography>{item.name}</Typography>

                            <span>{capitalize(item.description)}</span>
                            <Box
                              className={`${commonStyle.blueBtn} ${commonStyle.mt10}`}
                            >
                              <CommonButton
                                label={translation("view_details")}
                                onClick={() =>
                                  history.push(
                                    `${RoutingLinks.lostFoundDetail}${item.id}`
                                  )
                                }
                              />
                            </Box>
                          </Grid>
                        ))
                        : loader
                          ? translation("loading")
                          : translation("missing_record")}
                    </Grid>
                  </Box>
                </InfiniteScroll>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default PetLostFound;
