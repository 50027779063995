import { RootStateOrAny, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useEffect, useState, useCallback } from "react";

import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";

import { getBalanceListofUser } from "../../redux/action-creators/adAction";

import LeftSidebar from "../../components/sidebars/LeftSidebar";

import { UserCommonStyles } from "../../styles/CommonStyles";

import ApiResponse from "../../interfaceModules/IApiResponse";

import moment from "moment";
import { isJsonString } from "../../utils/helper";

const PaymentList: React.FC = (props: any) => {
  const commonStyle = UserCommonStyles();
  const { t: translation } = useTranslation();
  const [adList, setAdList] = useState<any>([]);
  const userInfo = useSelector((state: RootStateOrAny) => {
    let userData = state.authReducer.authData;
    if (isJsonString(state.authReducer.authData)) {
      userData = JSON.parse(state.authReducer.authData);
    }
    return userData;
  });
  interface Column {
    autoNumber: number;
    id: "date" | "type" | "amount" | "available_balance";

    label: string;
    minWidth?: number;
    align?: "right";
    format?: (value: number) => string;
  }

  const columns: readonly Column[] = [
    {
      autoNumber: 6,
      id: "date",
      label: "Date",
      minWidth: 170,
      align: "right",
    },

    {
      autoNumber: 3,
      id: "type",
      label: "Type",
      minWidth: 170,
      align: "right",
    },
    {
      autoNumber: 5,
      id: "amount",
      label: "Amount",
      minWidth: 170,
      align: "right",
      format: (value: number) => value.toFixed(2),
    },
    {
      autoNumber: 4,
      id: "available_balance",
      label: "Available Balance",
      minWidth: 170,
      align: "right",
      format: (value: number) => value.toLocaleString("en-US"),
    },
  ];

  /**
   * fetch all Balance list
   * @param id
   */
  const getBalanceList = useCallback(async () => {
    await getBalanceListofUser(userInfo.id).then((response: ApiResponse) => {
      const { data } = response;
      setAdList(data?.data[0]);
    });
  }, [userInfo.id]);

  useEffect(() => {
    getBalanceList();
  }, [getBalanceList]);

  return (
    <Box className={commonStyle.windowColor}>
      <Grid container>
        <Grid item md={3}>
          <LeftSidebar />
        </Grid>

        <Grid item md={9}>
          <Box className={`${commonStyle.p20} ${commonStyle.mt20}`}>
            <Typography
              variant="h6"
              className={`${commonStyle.grey} ${commonStyle.mb15} ${commonStyle.fontWeight600}`}
            >
              Balance History
            </Typography>

            <Typography
              variant="h6"
              className={`${commonStyle.grey} ${commonStyle.mb15} ${commonStyle.fontWeight600}`}
            >
              Available Balance: $
              {adList[0]?.available_balance ? adList[0].available_balance : "0"}
            </Typography>

            <TableContainer sx={{ maxHeight: 440 }}>
              <Table stickyHeader aria-label={translation("sticky_table")}>
                <TableHead>
                  <TableRow className={commonStyle.commonTableHead}>
                    {columns.map((column) => (
                      <TableCell
                        key={column.autoNumber}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {adList && adList.length > 0
                    ? adList.map((ad, index) => (
                        <TableRow hover tabIndex={-1} key={ad.id}>
                          <TableCell>
                            {moment(ad.created_ts).format("MM-DD-YYYY")}
                          </TableCell>
                          <TableCell>{ad.type}</TableCell>
                          <TableCell>${ad.amount}</TableCell>
                          <TableCell>${ad.available_balance}</TableCell>
                        </TableRow>
                      ))
                    : "No History Found"}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PaymentList;
