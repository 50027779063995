import { useEffect, useState, useCallback } from "react";
import { useDispatch } from "react-redux";

import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";

import { getpageDetail } from "../../redux/action-creators/profileAction";

import LeftSidebar from "../../components/sidebars/LeftSidebar";

import { UserCommonStyles } from "../../styles/CommonStyles";
import { ProfileStyle } from "./ProfileStyle";
import { useTranslation } from "react-i18next";

const AboutUs = () => {
  const dispatch = useDispatch();
  const commonStyle = UserCommonStyles();
  const { t: translation } = useTranslation();
  const styleClasses = ProfileStyle();
  const [pageDetail, setPageDetail] = useState<any>([]);
  const [showLoader, setShowLoader] = useState(true);

  /**
   * get all Page Details
   */
  const getPageDetail = useCallback(async () => {
    let result: any = await dispatch(getpageDetail(1));
    if (result) {
      setPageDetail(result);
    }
    setShowLoader(false);
  }, [dispatch]);

  useEffect(() => {
    getPageDetail();
  }, [getPageDetail]);

  return (
    <Box>
      <Box className={commonStyle.windowColor}>
        <Grid container>
          <Grid item md={3}>
            <LeftSidebar />
          </Grid>

          <Grid item md={9}>
            <Box className={`${commonStyle.p20} ${commonStyle.mt20}`}>
              {pageDetail && pageDetail?.title ? (
                <>
                  <Typography
                    variant="h6"
                    className={`${commonStyle.grey} ${commonStyle.mb15} ${commonStyle.fontWeight600}`}
                  >
                    {pageDetail.title}
                  </Typography>
                  <Box
                    className={`${styleClasses.helpAccordion} ${commonStyle.p20}`}
                  >
                    <Typography
                      variant="h6"
                      dangerouslySetInnerHTML={{
                        __html: pageDetail.content ? pageDetail.content : `-`,
                      }}
                    ></Typography>
                    <Typography className={commonStyle.textRight}>
                      © 2022 PETLOVERSCHAT. All Rights Reserved.
                    </Typography>
                  </Box>
                </>
              ) : (
                showLoader && translation("loading")
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default AboutUs;
