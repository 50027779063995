export const endpoint = {
    ad:{
      addlist:"/api/v1/ad/add",
      packageList:"/api/v1/ad/package_list",
      userAdList:"/api/v1/ad/ad_list/:userId",
      updatead : "/api/v1/ad/update-ad/:id",
      packageHistory : "/api/v1/ad/package-history",
      userBalanceList:"/api/v1/ad/balance_list/:userId",
      addAdActionViewAndClick:"/api/v1/ad/action",
      updateAdAction : "/api/v1/ad/ad_action/:ad_id"
    },
    report:{
        report:"/api/v1/report/:userId/:id",
        block:"/api/v1/report/block/:userId/:id",
        check_block:"/api/v1/report/block_check/:userId/:id",
        unblock_user:"/api/v1/report/unblock/:userId/:id",
        block_list:"/api/v1/report/unblock/:userId/:id",
        block_lists:"/api/v1/report/blockbyuserlist/:userId",
    }    
  }