import { Grid, Avatar, Typography } from "@mui/material";
import { Box } from "@mui/system";
import Swiper from "react-id-swiper";
import StoriesSidebar from "../../components/sidebars/StoriesSidebar";
import { UserCommonStyles } from "../../styles/CommonStyles";
import { ProfileStyle } from "./ProfileStyle";
import defaultUserprofile from "../../images/defaultUserprofile.png";
import minImage from "../../images/minImage.png";

import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";
import { getTimeDiff } from "../../utils/helper";
import CommonStoryScrollBar from "../../components/commonModal/CommonStoryScrollBar";
import {
  IAllUserStoryResponse,
  IStoriesSidebarProps,
  IStoryActiveUserSlideData,
} from "../../interfaceModules/IStoriesInterface";
import { ChevronLeft, ChevronRight, Today } from "@mui/icons-material";
import { RouteComponentProps } from "react-router-dom";
import moment from "moment";
import { NewLineRegex } from "../../utils/constants";
const Stories: React.FC<RouteComponentProps> = ({ location }) => {
  const shownStory: any = location.state;
  const { t: translation } = useTranslation();
  const commonStyle = UserCommonStyles();
  const styleClasses = ProfileStyle();
  const swiperRef = useRef(null);
  let innerSwiperRef = useRef([]);
  const [userStoryId, setUserStoryId] = useState(0);
  const [userActiveIndex, setUserActiveIndex] = useState(-1);
  const [
    activeUserDetails,
    setActiveUserDetails,
  ] = useState<IStoryActiveUserSlideData>();
  const [allUserStoriesData, setAllUserStoriesData] = useState<
    IAllUserStoryResponse[]
  >([]);
  const [showPreviousButton, setShowPreviousButton] = useState(true);
  const [showNextButton, setShowNextButton] = useState(false);
  const [previousInnerSwiperIndex, setPreviousInnerSwiperIndex] = useState(0);

  useEffect(() => {
    if (shownStory && shownStory.totalFriendsStories.length > 0) {
      userUploadingStory(
        shownStory.storyUserId,
        shownStory.index,
        shownStory.totalFriendsStories
      );
    }
  }, [shownStory]);

  const onInnerSlideChange = (event: any) => {
    setActiveUserSlideData(
      swiperRef.current.swiper.activeIndex,
      event.activeIndex,
      event.slides.length
    );
  };

  const onOuterSlideChange = () => {
    if (swiperRef && swiperRef?.current?.swiper) {
      updateSliderIndexAndButtons(
        swiperRef.current.swiper.activeIndex,
        allUserStoriesData
      );
    }
  };

  //Inner Swiper Params
  const innerSwiperParams = {
    slidesPerView: 1,
    spaceBetween: 10,
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },
    on: {
      slideChange: onInnerSlideChange,
    },
  };

  //Outer Swiper Params
  const outerSwiperParams = {
    slidesPerView: 1,
    grabCursor: true,
    loop: false,
    spaceBetween: 30,
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },
    on: {
      slideChange: onOuterSlideChange,
    },
  };

  /**
   * Update the index of silder by current active Silde and enable/disable the next or previous buttons
   * @param userActiveIndexNumber current active slide number
   * @param totalFriendsStories all user stories data
   */
  const updateSliderIndexAndButtons = (
    userActiveIndexNumber: number,
    totalFriendsStories: IAllUserStoryResponse[]
  ) => {
    swiperRef.current.swiper.activeIndex = userActiveIndexNumber;
    setUserActiveIndex(userActiveIndexNumber);
    setActiveUserSlideData(
      userActiveIndexNumber,
      innerSwiperRef.current[userActiveIndexNumber].swiper.activeIndex,
      JSON.parse(totalFriendsStories[userActiveIndexNumber].fileData).length
    );

    if (userActiveIndexNumber > 0) {
      setShowPreviousButton(false);
    } else {
      setShowPreviousButton(true);
    }
    if (userActiveIndexNumber === totalFriendsStories.length - 1) {
      setShowNextButton(true);
    } else {
      setShowNextButton(false);
    }
  };

  /**
   * Enable/disable the next or previous buttons based on current active slide and total userStories data, when click on next button
   */
  const goNext = () => {
    if (swiperRef.current !== null && swiperRef.current.swiper !== null) {
      swiperRef.current.swiper.slideNext();

      setUserActiveIndex(swiperRef.current.swiper.activeIndex);
      setActiveUserSlideData(
        swiperRef.current.swiper.activeIndex,
        innerSwiperRef.current[swiperRef.current.swiper.activeIndex].swiper
          .activeIndex,
        JSON.parse(
          allUserStoriesData[swiperRef.current.swiper.activeIndex].fileData
        ).length
      );

      if (swiperRef?.current?.swiper?.activeIndex > 0) {
        setShowPreviousButton(false);
      }
      if (
        swiperRef?.current?.swiper?.activeIndex ===
        allUserStoriesData.length - 1
      ) {
        setShowNextButton(true);
      }
    }
  };

  /**
   * Enable/disable the next or previous buttons based on current active slide and total userStories data,when click on previous button
   */
  const goPrev = () => {
    if (swiperRef.current !== null && swiperRef.current.swiper !== null) {
      swiperRef.current.swiper.slidePrev();

      setUserActiveIndex(swiperRef.current.swiper.activeIndex);
      setActiveUserSlideData(
        swiperRef.current.swiper.activeIndex,
        innerSwiperRef.current[swiperRef.current.swiper.activeIndex].swiper
          .activeIndex,
        JSON.parse(
          allUserStoriesData[swiperRef.current.swiper.activeIndex].fileData
        ).length
      );

      setShowNextButton(false);
      if (swiperRef?.current?.swiper?.activeIndex > 0) {
        setShowPreviousButton(false);
      } else {
        setShowPreviousButton(true);
      }
    }
  };

  /**
   * Set states which comes from props
   * @param storyUserId Current Story userId
   * @param userActiveIndexNumber Current slide index number
   * @param totalFriendsStories all user stories data
   */
  const userUploadingStory = (
    storyUserId: number,
    userActiveIndexNumber: number,
    totalFriendsStories: IAllUserStoryResponse[]
  ) => {
    setActiveUserSlideData(
      userActiveIndexNumber,
      0,
      JSON.parse(totalFriendsStories[userActiveIndexNumber].fileData).length
    );
    setUserStoryId(storyUserId);
    setUserActiveIndex(userActiveIndexNumber);
    setAllUserStoriesData(totalFriendsStories);
    if (swiperRef && swiperRef?.current?.swiper) {
      updateSliderIndexAndButtons(userActiveIndexNumber, totalFriendsStories);
    }
  };

  const setActiveUserSlideData = (
    userActiveIndexNumber: number,
    slideIndex: number,
    totalNumberOfSlides: number
  ) => {
    setActiveUserDetails({
      activeSlideIndex: userActiveIndexNumber,
      activeInnerSlideIndex: slideIndex,
      totalSlides: totalNumberOfSlides,
    });
    setPreviousInnerSwiperIndex(slideIndex);
  };

  return (
    <Box>
      <Box className={commonStyle.windowColor}>
        <Grid container>
          <Grid item md={3}>
            <StoriesSidebar
              showStory={(
                storyUserId: number,
                index: number,
                totalFriendsStories: IAllUserStoryResponse[]
              ) => userUploadingStory(storyUserId, index, totalFriendsStories)}
            />
          </Grid>

          <Grid item md={9}>
            <Box className={`${commonStyle.p20}`}>
              <Box className={`${commonStyle.card} ${commonStyle.p20}`}>
                <Box className={styleClasses.outerStrom}>
                  <Swiper
                    {...outerSwiperParams}
                    ref={swiperRef}
                    activeSlideKey={userActiveIndex.toString()}
                  >
                    {allUserStoriesData.length > 0 &&
                      allUserStoriesData.map((otherStory, index) => (
                        <Box className={styleClasses.sliderStories} key={index}>
                          {/* {console.log("activeUserDetails.activeSlideIndex--", activeUserDetails.activeSlideIndex, index, activeUserDetails.activeInnerSlideIndex, JSON.parse(JSON.stringify(JSON.parse(allUserStoriesData[activeUserDetails.activeSlideIndex].fileData)[activeUserDetails.activeInnerSlideIndex].path)))}                                    */}
                          {/* {activeUserDetails.activeSlideIndex === index ? (   
                             <> */}
                          <CommonStoryScrollBar
                            storyDetails={activeUserDetails}
                            userStoryData={allUserStoriesData}
                          />
                          <Box
                            className={`${commonStyle.pb20} ${commonStyle.wallUserInfo}`}
                          >
                            <Avatar
                              alt={translation("story_person")}
                              src={
                                otherStory.profile_image
                                  ? otherStory.profile_image
                                  : minImage
                              }
                            />
                            <Box className={commonStyle.ml10}>
                              <Typography variant="h2">
                                {otherStory.first_name} {otherStory.last_name}
                              </Typography>
                            </Box>
                          </Box>

                          <Swiper
                            {...innerSwiperParams}
                            ref={(el) => (innerSwiperRef.current[index] = el)}
                          >
                            {JSON.parse(otherStory.fileData).map(
                              (imageData, imageIndex) => {
                                const imageName =
                                  process.env.REACT_APP_IMAGE_URL +
                                  imageData.path;
                                return (
                                  <div key={imageIndex}>
                                    <Typography
                                      className={styleClasses.dateStoryShow}
                                    >
                                      <Today />
                                      {imageData?.timing
                                        ? getTimeDiff(
                                            moment.utc(imageData?.timing)
                                          )
                                        : ""}{" "}
                                      {translation("ago")}
                                    </Typography>
                                    {imageData.type === "video" ? (
                                      <video
                                        controls
                                        className={styleClasses.sliderImg}
                                        src={imageName + "#t=0.10"}
                                      />
                                    ) : (
                                      <img
                                        src={
                                          activeUserDetails.activeInnerSlideIndex ===
                                          imageIndex
                                            ? process.env.REACT_APP_IMAGE_URL +
                                              JSON.parse(
                                                allUserStoriesData[
                                                  activeUserDetails
                                                    .activeSlideIndex
                                                ].fileData
                                              )[
                                                activeUserDetails
                                                  .activeInnerSlideIndex
                                              ].path
                                            : imageName
                                        }
                                        alt={translation("wall_img")}
                                        className={styleClasses.sliderImg}
                                      />
                                    )}

                                    {/* {imageData.type==="image" ? (
                                      <img
                                      src={activeUserDetails.activeInnerSlideIndex === imageIndex ? JSON.parse(allUserStoriesData[activeUserDetails.activeSlideIndex].fileData)[activeUserDetails.activeInnerSlideIndex].path : imageData.path}
                                      alt={translation("slider")}
                                      className={styleClasses.sliderImg}
                                    /> 
                                  ):(
                                    <video controls className={styleClasses.sliderImg}>
                                    <source
                                      src={imageData.path}
                                      type="video/mp4"
                                    />                                    
                                  </video>
                                  )}                    */}

                                    <Typography
                                      dangerouslySetInnerHTML={{
                                        __html: `${imageData.caption.replace(
                                          NewLineRegex,
                                          "<br />"
                                        )}`,
                                      }}
                                    ></Typography>
                                  </div>
                                );
                              }
                            )}

                            {/* {activeUserDetails.activeSlideIndex===index && (
                                <div key={JSON.parse(allUserStoriesData[activeUserDetails.activeSlideIndex].fileData)}>
                                <img
                                  src={JSON.parse(allUserStoriesData[activeUserDetails.activeSlideIndex].fileData).path}
                                  alt={translation("slider")}
                                  className={styleClasses.sliderImg}
                                />
                              </div>
                            )
                            } */}
                          </Swiper>
                          {/* </>
                           ):null} */}
                        </Box>
                      ))}
                  </Swiper>
                </Box>
                {allUserStoriesData.length > 0 && (
                  <Box className={styleClasses.ButtonSliderSet}>
                    <button
                      onClick={goPrev}
                      disabled={showPreviousButton}
                      className={
                        showPreviousButton && styleClasses.sliderButtonDisable
                      }
                    >
                      <ChevronLeft />
                    </button>
                    <button
                      onClick={goNext}
                      disabled={showNextButton}
                      className={
                        showNextButton && styleClasses.sliderButtonDisable
                      }
                    >
                      <ChevronRight />
                    </button>
                  </Box>
                )}
                {/* <Box
                  className={`${commonStyle.flexCenter} ${commonStyle.justifyCenter} ${styleClasses.sliderMsg}`}
                >
                  <TextField
                    multiline
                    placeholder={translation("reply")}
                    variant="outlined"
                    size="small"
                    className={styleClasses.sliderInput}
                    fullWidth
                  />
                  <ul className={commonStyle.flexCenter}>
                    <li>
                      <span>&#128077;</span>
                    </li>
                    <li>
                      <span>&#128513;</span>
                    </li>
                    <li>
                      <span>&#128521;</span>
                    </li>
                    <li>
                      <span>&#128525;</span>
                    </li>
                  </ul>
                </Box> */}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Stories;
