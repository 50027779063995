import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState, useCallback } from "react";
import {
  useParams,
  RouteComponentProps,
  useHistory,
  useLocation,
} from "react-router-dom";

import { Button, Grid, Tab, Tabs, Typography, IconButton } from "@mui/material";
import { MoreHoriz } from "@mui/icons-material";
import { Box } from "@mui/system";

import {
  postListsbyUserId,
  getAllCommentsLists,
} from "../../redux/action-creators/postAction";
import {
  RequestType,
  changeRequestType,
  removeFriendbyId,
} from "../../redux/action-creators/requestAction";
import {
  blockUser,
  getBlocklList,
} from "../../redux/action-creators/reportAction";
import { sendNotification } from "../../redux/action-creators/petPlayAction";
import { getPetOfthedayofuser, getProfile } from "../../redux/action-creators/profileAction";

import CommonPopover from "../../components/commonModal/CommonPopover";

import ApiResponse from "../../interfaceModules/IApiResponse";

import LeftSidebar from "../../components/sidebars/LeftSidebar";
import CommonCard from "../../components/commonCard/CommonCard";

import defaultUserprofile from "../../images/defaultUserprofile.png";
import defaultUsercover from "../../images/defaultUsercover.png";

import { NewLineRegex, RoutingLinks } from "../../utils/constants";
import { toast } from "../../utils/toastsMessage";

import { UserCommonStyles } from "../../styles/CommonStyles";
import { ProfileStyle } from "./ProfileStyle";
import { capitalize } from "../../utils/helper";
import moment from "moment";
import CommonReactionModal from "../../components/commonModal/CommonReactionModal";


function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Profile = (props: RouteComponentProps) => {
  const { t: translation } = useTranslation();
  const commonStyle = UserCommonStyles();
  const styleClasses = ProfileStyle();
  const userId: number = Number(localStorage.getItem("userId"));
  const { id }: any = useParams();

  const dispatch = useDispatch();
  const history = useHistory();

  const [userData, setUserData] = useState([]);
  const [requestType, setRequestType] = useState([]);
  const [allPosts, setAllPosts] = React.useState([]);
  const [value, setValue] = React.useState(0);
  const [disablerequest, setDisablerequest] = useState(false);
  const [allComments, setAllComments] = useState([]);
  const [reportUserId, setreportUserId] = useState<number>();
  const [block_data, setblockdata] = useState<any[]>([]);
  const [disableBlockButton,setDisableBlockButton]=useState(false);
  const [petDay,setPetDay]=useState<any>([])
  const [openLike, setLikeOpen] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const open = Boolean(anchorEl);

  const [modalopen, setOpen] = React.useState(false);
  const modalhandleClose = () => setOpen(false);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if(newValue){
      setValue(newValue);
    }
  };

  /**
   * get all posts
   * @param profileId
   */
  const getAllPosts = useCallback(
    async (profileId: number) => {
      const response: any = await dispatch(
        postListsbyUserId(profileId, userId)
      );
      if(response){
        setAllPosts(
          response.map((i: any) => ({
            ...i,
            isEdit: false,
          }))
        );      
      }
    },
    [dispatch, userId]
  );

  /**
   * get user data by getting profile
   * @param user
   */
  const getUserData = useCallback(async (user: number) => {
    let result: any = await dispatch(getProfile(user,userId));
    if (result && result?.length > 0) {
      setUserData(result);
    }
  }, [dispatch]);

  /**
   * change request type
   * @returns
   */
  const changeRequest = async () => {
    setDisablerequest(true);

    const result: any = await dispatch(changeRequestType(id, userId));

    if ((result && result?.isRegistered) || result.affectedRows ) {
      getRequest();
      const data = { type: "Friend Request", id: userId };
      if (id !== data.id) {
        await dispatch(sendNotification(id, data));
        return toast.success(translation("friend_request_sent"));
      }
    } else {
      return toast.success(translation("error"));
    }
  };

  /**
   * get request type
   */
  const getRequest = useCallback(async () => {
    let request: any = await dispatch(RequestType(id, userId));
    if (request && request?.length > 0) {
      setDisablerequest(false);
      setRequestType(request);
    } else {
      setRequestType([]);
    }
  }, [dispatch, id, userId]);

  /**
   * Get all the posts comments
   * @param type
   */
  const getAllPostsComments = useCallback(async () => {
    const response: any = await dispatch(getAllCommentsLists("all"));
    if (response && response?.length > 0) {
      let data = [...response];
      setAllComments(data);
    }
  }, [dispatch]);

  const BlockList = useCallback(async (id: number) => {
    await getBlocklList(id, userId).then((response: ApiResponse) => {
      const { data, error } = response;
      setblockdata(data.data);
    });
  }, []);


   /**
   * Get the last time pet of the day of user
   * @param type
   */
  const getPetOftheday = useCallback(async (userId: number) => {
    const response: any = await dispatch(getPetOfthedayofuser(userId));
  
    

    if(response.length>0)
    {
    
      setPetDay(response)
    }

  }, []);


  /**
   * When user clicks on any
   */
   const handleLikeOpen = () => {
    setLikeOpen(true);
    // getAllLikeReactionsOnPost();
  };

  /**
   * when like popup closes
   */
   const handleLikeClose = () => {
    setLikeOpen(false);
  };

  useEffect(() => {
    if (id) {
      getUserData(id);
      getRequest();
      getAllPosts(id);
      BlockList(id);
     getPetOftheday(id)

    } else {
      getUserData(userId);
      getAllPosts(userId);
      getPetOftheday(userId)

      
    }
    getAllPostsComments();
  }, [
    userId,
    id,
    getAllPostsComments,
    getUserData,
    getRequest,
    getAllPosts,
    BlockList,
  ]);

  const blockUsersbyid = async (id: number) => {
    setDisableBlockButton(true);  
    await blockUser(id, userId).then((response: ApiResponse) => {
      if (response) {
        getReportedId(id);
        BlockList(id);
        modalhandleClose();
        dispatch(removeFriendbyId(userId, id));
        setDisableBlockButton(false);
      }
    });
  };

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    Id: number
  ) => {
    setAnchorEl(event.currentTarget);
    setreportUserId(Id);
  };
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getReportedId = (id: number) => {
    userData[0]["reportUserId"] = userId;
    handleClose();
  };
  



//  .toDate())
  return (
    <Box>
      <Grid container>
        <Grid item md={3}>
          <LeftSidebar />
        </Grid>
        {userData.length > 0 && (
          <Grid item md={9}>
            <Box className={`${commonStyle.mt20} ${commonStyle.p20}`}>
              <Grid container spacing={2}>
                <Grid item md={6}>
                  <img
                    src={
                      userData[0]?.cover_image
                        ? userData[0].cover_image
                        : defaultUsercover
                    }
                    alt={translation("profile_banner")}
                    className={`${styleClasses.imageBannerSize} ${commonStyle.w100}`}
                  />
                </Grid>

                <Grid item md={6}>
                  <Grid container spacing={3}>
                    <Grid item md={4}>
                      <img
                        src={
                          userData[0]?.profile_image
                            ? userData[0].profile_image
                            : defaultUserprofile
                        }
                        alt={translation("profile")}
                        className={styleClasses.profileShow}
                      />
                    </Grid>               
                    <Grid item md={8}>
                      <Box className={styleClasses.threeFlexAlign}>
                        <Box className={styleClasses.userDescProfile}>
                          <Typography variant="h6" className={commonStyle.m0}>
                            {capitalize(userData[0].first_name) +
                              " " +
                              capitalize(userData[0].last_name)}
                          </Typography>

                          {userData[0].description && (
                            <Typography
                            dangerouslySetInnerHTML={{
                              __html: `${userData[0].description.replace(
                                NewLineRegex,
                                "<br />"
                              )}`,
                            }}
                          ></Typography>
                          )} 
                             {openLike && petDay.length>0 && ( 
                              
          <CommonReactionModal
            name="reaction_of_friends"
            open={openLike}
            onClose={handleLikeClose}
            id={petDay[0].id}
          />
        )}
                          {petDay.length>0?    
                          <Button onClick={()=> handleLikeOpen()}>                         
                          <Typography className={commonStyle.cursorA}><strong>
                            Pet of The Day on {moment(petDay[0]?.date.split('T')[0]).format("MM-DD-YYYY")}</strong></Typography></Button> :null}
                          <Box
                            className={`${commonStyle.blueBtn} ${commonStyle.mt10}`}
                          >
                            {requestType.length > 0 &&
                            block_data.length === 0 ? (
                              requestType[0].request_type ===
                              "pending" ? null : requestType[0].request_type === // </Button> //   {requestType[0]?.request_type} // <Button disabled>
                                  "accepted" && block_data.length === 0 ? (
                                <Button
                                  onClick={() =>
                                    history.push({
                                      pathname: RoutingLinks.chat,
                                      state: {
                                        id: userData[0]?.userId,
                                        first_name: userData[0]?.first_name,
                                        last_name: userData[0]?.last_name,
                                        profile_image:
                                          userData[0]?.profile_image,
                                        channel: "",
                                      },
                                    })
                                  }
                                  fullWidth
                                >
                                  {translation("message")}
                                </Button>
                              ) : (
                                block_data.length === 0 &&
                                userData[0].reportedId !== userId &&
                                userId != id &&
                                id && (
                                  <Button
                                    disabled={disablerequest}
                                    onClick={() => changeRequest()}
                                  >
                                    {translation("add")}
                                  </Button>
                                )
                              )
                            ) : (
                              block_data.length === 0 &&
                              userData[0].reportedId !== userId &&
                              userId != id &&
                              id && (
                                <Button
                                  disabled={disablerequest}
                                  onClick={() => changeRequest()}
                                >
                                  {translation("add")}
                                </Button>
                              )
                            )}
                          </Box>
                        </Box>

                        {userData[0].reportUserId !== userId &&
                        userData[0].reported_id !== userData[0].userId &&
                        userData[0]?.userId !== userId &&
                        block_data.length === 0 ? (
                          <Box className={commonStyle.iconBtn}>
                            <IconButton
                              onClick={(e) =>
                                handleClick(e, userData[0]?.userId)
                              }
                            >
                              <MoreHoriz />
                            </IconButton>
                            <CommonPopover
                              open={open}
                              anchorEl={anchorEl}
                              handleClose={handleClose}
                              handleOpen={handleOpen}
                              modalopen={modalopen}
                              modalhandleClose={modalhandleClose}
                              reportUserId={reportUserId}
                              getReportedId={getReportedId}
                              blockUsersbyid={blockUsersbyid}
                              blockButtonDisable={disableBlockButton}
                            />
                          </Box>
                        ) : null}
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            <Box className={styleClasses.tabination}>
              <Box className={styleClasses.tabsBorder}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label={translation("basic_tabs")}
                >
                  <Tab label={translation("chronicle")} {...a11yProps(0)} />
                  {id ? (
                    <Link
                      className={commonStyle.commonLink}
                      to={{ pathname: `${RoutingLinks.friendsList}${id}` }}
                    >
                      <Tab label={translation("Friends")} />
                    </Link>
                  ) : (
                    <Link
                      className={commonStyle.commonLink}
                      to={RoutingLinks.friends}
                    >
                      <Tab label={translation("Friends")} />
                    </Link>
                  )}

                  {id ? null : (
                    <Link
                      className={commonStyle.commonLink}
                      to={{ pathname: `${RoutingLinks.editProfile}${userId}` }}
                    >
                      <Tab label={translation("edit_profile")} />
                    </Link>
                  )}
                </Tabs>
              </Box>
            </Box>
            {block_data.length === 0 && (
              <CommonCard
                allPost={allPosts}
                allPostComments={allComments}
                postType={"post"}
                props={props}
              />
            )}
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default Profile;
