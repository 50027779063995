import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useEffect, useState, useCallback } from "react";

import { Button, Grid, Typography, Modal } from "@mui/material";
import { Box } from "@mui/system";

import { addPackageHistory, getAdPackagesList } from "../../redux/action-creators/adAction"

import LeftSidebar from "../../components/sidebars/LeftSidebar";

import { UserCommonStyles } from "../../styles/CommonStyles";
import { ProfileStyle } from "../profile/ProfileStyle";
import { RoutingLinks } from "../../utils/constants";
import { IAdPackageList } from "../../interfaceModules/IAdInterface";
import ApiResponse from "../../interfaceModules/IApiResponse";
import { toast } from "../../utils/toastsMessage";

const AdCenter: React.FC = (props: any) => {
  const commonStyle = UserCommonStyles();
  const { t: translation } = useTranslation();
  const [adPackageDetail, setAdPackageDetails] = useState<IAdPackageList[]>([]);
  const styleClasses = ProfileStyle();
  const [modalopen, setOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [packageData,setData]=useState({
    packageAmount:0,
    name: "",
    views: 0,
    clicks: 0,
    days: 0
  })
  const dispatch = useDispatch();
  const [disable, setdisable] = useState(false);
  const modalhandleClose = () => setOpen(false);
  const data = props?.location?.state;
  const adid = data.adid

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "#fff",
    border: "2px solid #000",
    p: 4,
  };

  useEffect(() => {
    if (adPackageDetail.length === 0) {
      getAllPackagesList();
    }

  }, [adPackageDetail]);

  /**
 * fetch all ad packages list
 * @param id
 */
  const getAllPackagesList = useCallback(async () => {
    await getAdPackagesList().then((response: ApiResponse) => {
      const { data, error } = response;
      setAdPackageDetails(data?.data[0]);
    });
  }, [dispatch]);



  const packageConfirm = (
    packageAmount: number,
    name: string,
    views: number,
    clicks: number,
    days: number
  ) => {
    setShowModal(true)
    setData(
      {
        packageAmount:packageAmount,
    name:name,
    views:views,
    clicks: clicks,
    days:days
      }
    )
    // addPackageHistory({
    //   packageAmount,
    //   name,
    //   adid,
    //   views,
    //   clicks,
    //   days,
    // }).then((response: any) => {
    //   if (response)
    //     if (response.data?.isRegistered) {
    //       setdisable(false);
    //       // props.history.goBack({
    //       //   pathname: RoutingLinks.ad,
    //       //   state: data,
    //       // });
    //       props.history.push("/ad-list");
    //       return toast.success(translation("adsubmitmsg"));
    //     } else {
    //       setdisable(false);
    //       return toast.error(translation(response.data.data.message));
    //     }
    // });
  };

  /**
* Check ad is approved by admin or not
* @param packageId
*/
const buyPackage = (packageAmount: number, name : string, views : number ,clicks : number, days : number) => {
  setdisable(true);
  addPackageHistory({
    packageAmount,
    name,
    adid,
    views,
    clicks,
    days
  }).then((response) => {
if (response.data.isRegistered) {
  setdisable(false);
  props.history.push("/adlist")
  return toast.success(translation("adsubmitmsg"));
} else {
  setdisable(false);
  return toast.error(translation(response.data.data.message));
}
}
)
}

  return (
    <Box className={commonStyle.windowColor}>
      <Grid container>
        <Grid item md={3}>
          <LeftSidebar />
        </Grid>      

        <Grid item md={9}>
          <Box className={`${commonStyle.p20} ${commonStyle.mt20}`}>
            <Typography
              variant="h6"
              className={`${commonStyle.grey} ${commonStyle.mb15} ${commonStyle.fontWeight600}`}
            >
              {translation("ad")}
            </Typography>
            <Box className={`${commonStyle.p20} ${commonStyle.card}`}>
              <Box
                className={`${commonStyle.flexCenter} ${commonStyle.justifySpaceBetween}`}
              >
                <Typography>
                  <strong>{translation("company_name")}:</strong>
                  {props?.location && props.location.state.adDetails.company_name}
                </Typography>
              </Box>

              <Typography variant="h6">{translation("package")}</Typography>

              <ul className={styleClasses.subscriptionListSlider}>
               
                {adPackageDetail && adPackageDetail.length > 0 && adPackageDetail.map((packageItem, index) => (
                  <li key={index}>
                    <Box className={styleClasses.subscriptionCard}>
                      <Typography variant="h5">${packageItem.amount}</Typography>
                      <Box
                        className={`${commonStyle.w100} ${commonStyle.orangeBtn} ${commonStyle.my10}`}
                      >
                        <Button 
                    onClick={() =>
                      packageConfirm(
                        packageItem.amount,
                        packageItem.package,
                        packageItem.views,
                        packageItem.clicks,
                        packageItem.days
                      )
                    }                        disabled={disable}>{translation("buy_now")}</Button>
                      </Box>
                      <Typography>
                        <strong className={commonStyle.orange}>{packageItem.views}</strong> {translation("views")}
                      </Typography>
                      {packageItem.clicks > 0 && (
                        <Typography>
                          <strong className={commonStyle.orange}>{packageItem.clicks}</strong> {translation("clicks")}
                        </Typography>
                      )}
                      {packageItem.days > 0 && (
                        <Typography>
                          <strong className={commonStyle.orange}>{packageItem.days}</strong> {translation("days")}
                        </Typography>
                      )}
                    </Box>
                  </li>
                ))}

                <Modal
                  open={modalopen}
                  onClose={modalhandleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                  className={styleClasses.modalShadow}
                >
                  <Box sx={style}>
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                    >
                      {translation("insufficient_bal_msg")}

                    </Typography>
                    <Box
                      id="modal-modal-description"
                      sx={{ mt: 2 }}
                      className={commonStyle.flexCenter}
                    >
                      <Box
                        className={`${commonStyle.blueBtn} ${commonStyle.pt20} ${commonStyle.mr10}  ${commonStyle.anchorDecoration}`}
                      >
                
                      </Box>

                      <Box
                        className={`${commonStyle.blueBtn} ${commonStyle.pt20}`}
                      >
                        <Button onClick={modalhandleClose}>
                          {translation("cancel")}
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Modal>

                <Modal
            open={showModal}
            // onClose={set}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className={styleClasses.modalShadow}
          >
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                {translation("buy_package")}
              </Typography>
              <Box
                id="modal-modal-description"
                sx={{ mt: 2 }}
                className={commonStyle.flexCenter}
              >
                <Box
                  className={`${commonStyle.blueBtn} ${commonStyle.pt20} ${commonStyle.mr10}`}
                >
                 
                    <Button
                      disabled={disable}
                      onClick={() =>
                        buyPackage(packageData.packageAmount,packageData.name,packageData.views,packageData.clicks,packageData.days)
                      }
                      
                    >
                      {" "}
                      {translation("yes")}
                    </Button>
                </Box>

                <Box className={`${commonStyle.blueBtn} ${commonStyle.pt20}`}>
                  <Button onClick={()=>setShowModal(false)}>
                    {translation("no")}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Modal>
              </ul>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AdCenter;
