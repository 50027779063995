import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Avatar, Grid, Modal, Typography } from "@mui/material";
import { HighlightOff } from "@mui/icons-material";
import { Box } from "@mui/system";


import { capitalize } from "../../utils/helper";
import { RoutingLinks } from "../../utils/constants";

import { CommonUserDetailModalProps } from "../../interfaceModules/ICommonInteface";

import defaultUserprofile from "../../images/defaultUserprofile.png";
import minImage from "../../images/minImage.png";

import { UserCommonStyles } from "../../styles/CommonStyles";
import { HomeStyles } from "../../styles/HomeStyles";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "#fff",
  boxShadow: "0px 1px 4px #0000001A",
  borderRadius: "13px",
  padding: "10px 20px 20px",
};

const CommonUserDetailModal: React.FC<CommonUserDetailModalProps> = (
  props: CommonUserDetailModalProps
) => {
  const commonStyle = UserCommonStyles();
  const styleClasses = HomeStyles();
  const { t: translation } = useTranslation();

  return (
    <Modal
      hideBackdrop
      open={props?.open}
      onClose={props?.onClose}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <Box sx={style}>
        <Box className={styleClasses.modalHeader}>
          <Typography id="modal-modal-title" variant="h6">
            {translation(props.name)}
          </Typography>
          <HighlightOff onClick={props?.onClose} />
        </Box>

        <Box
          id="modal-modal-description"
          sx={{ mt: 2 }}
          className={styleClasses.modalDescTag}
        >
          <Grid container>
            {props.userDetails.length > 0 &&
              props.userDetails.map((showUser, index) => (
                <Grid
                  item
                  md={12}
                  key={index}
                  className={styleClasses.likeShowYou}
                >
                  <Box
                    className={`${commonStyle.pb20} ${commonStyle.wallUserInfo} ${commonStyle.cursorA}`}
                  >
                    <Avatar
                      alt={translation("story_person")}
                      src={
                        showUser.profile_image
                          ? showUser.profile_image
                          : minImage

                      }
                    />
                    <Box className={commonStyle.ml10}>
                      <Link
                        key={index}
                        to={{
                          pathname: `${RoutingLinks.profiles}${showUser.userId}`,
                        }}
                        className={commonStyle.anchorDecoration}
                      >
                        {capitalize(showUser.first_name)} {capitalize(showUser.last_name)}
                      </Link>
                      <Typography>{translation(showUser.type)}</Typography>
                    </Box>
                  </Box>
                  <Box>{showUser?.reaction}</Box>
                </Grid>
              ))}
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
};

export default CommonUserDetailModal;
