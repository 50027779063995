import { Dispatch } from "redux";
import axios from "../../services/axiosService";
import { ActionType } from "../action-types/index";
import { IUnreadMessageParams } from "../../utils/interfaceModel";
const URL = process.env.REACT_APP_API_URL;

export const getChatChannel = (data: {
  sender_id: string;
  receiver_id: number;
}) => async (dispatch: Dispatch) => {
  try {
    const res: any = await axios.get(
      `${URL}/api/v1/chat/getChatChannel/${data.sender_id}/${data.receiver_id}`
    );
    return res.data;
  } catch (error) {
    dispatch({
      type: ActionType.FAILURE,
    });
  }
};

export const getAllChats = (sender_id: string) => async (
  dispatch: Dispatch
) => {
  try {
    const res: any = await axios.get(
      `${URL}/api/v1/chat/getAllChats/${sender_id}`
    );
    console.log("res>>>>>>>>>>>>>>>>",res.data)
    return res.data;
  } catch (error) {
    dispatch({
      type: ActionType.FAILURE,
    });
  }
};

export const setUnreadMessages = (data: IUnreadMessageParams) => async (
  dispatch: Dispatch
) => {
  console.log("dtaaaaaaaaaaaaaaaaaa",data)
  try {
    const res: any = await axios.put(
      `${URL}/api/v1/chat/setUnreadMessages`,
      data
    );
    return res.data;
  } catch (error) {
    dispatch({
      type: ActionType.FAILURE,
    });
  }
};

export const uploadFile = ({ files, sender_id }) => async (dispatch) => {
  try {
    const res: any = await axios.post(
      `${URL}/api/v1/chat/upload-file/${sender_id}`,
      { files }
    );
    return res.data;
  } catch (error) {
    dispatch({
      type: ActionType.FAILURE,
    });
  }
};
