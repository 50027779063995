import Dropzone from "react-dropzone";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import ClipLoader from "react-spinners/ClipLoader";
import imageCompression from "browser-image-compression";

import { Controller, useForm } from "react-hook-form";
import { RouteComponentProps, useParams, useLocation } from "react-router-dom";
import React, { useEffect, useState, useRef, useCallback } from "react";
import Calendar from "react-calendar";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";

import {
  Grid,
  TextField,
  Typography,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import { Box } from "@mui/system";
import { HighlightOff } from "@mui/icons-material";

import {
  createAd,
  getAdDetailsById,
  updateAd,
} from "../../redux/action-creators/adAction";
import { GooglePlacesDetail } from "../../redux/action-creators/profileAction";

import { isEmpty } from "../../utils/helper";
import { IAdList } from "../../interfaceModules/IUserInterface";
import { toast } from "../../utils/toastsMessage";
import { AdListSchema } from "../../utils/validationSchema";
import {
  ImgTypeArray,
  Region,
  locationPrediction,
  Reason,
} from "../../utils/constants";

import LeftSidebar from "../../components/sidebars/LeftSidebar";

import { CommonButton } from "../../stories/Button";
import { UserCommonStyles } from "../../styles/CommonStyles";
import "react-calendar/dist/Calendar.css";

const AdCenter = (props: RouteComponentProps) => {
  const { t: translation } = useTranslation();
  const commonStyle = UserCommonStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const id = Number(useParams<{ id: string }>().id);
  const userId = localStorage.getItem("userId");
  const [adData, setAdData] = useState<any[]>([]);
  const [imageError, setimageError] = useState("");
  const [files, setFile] = useState([]);
  const [disable, setDisable] = React.useState(false);
  const [locationPredictions, setLocationPredictions] = useState({
    predictions: locationPrediction.predictions,
    location: locationPrediction.location,
    latitude: locationPrediction.latitude,
    longitude: locationPrediction.longitude,
  });
  const [timeOutState, setTimeOutState] = useState(null);
  const [adStartDate, setAdStartDate]: any = useState("");
  const [startDateError, setStartDateError] = useState("");
  const [loactionerr, setlocationerr] = useState(false);

  const [adDetails, setAddetails] = useState({
    company_name: "",
    company_website_url: "",
    email: "",
    phone_number: "",
    region: "",
    reason: "",
    location: "",
  });

  const getadData = useCallback(
    async (id: number) => {
      let result: any = await dispatch(getAdDetailsById(id));
      if (result && result?.length > 0 && result[0]?.image) {
        let data = result[0].image;
        data = JSON.parse(data);
        setFile(data);
      }

      if (result && result?.length > 0 && result[0]?.start_date) {
        const localOffsetInHours = -(moment().utcOffset() / 60);
        setAdStartDate(
          moment(result[0].start_date)
            .clone()
            .add(localOffsetInHours, "hours")
            .toDate()
        );
        // let date = moment(result[0].start_date).format("yyyy-MM-DD");
        // setAdStartDate(date);
      }

      setAdData(result);
      if (result && result?.length > 0) {
        setLocationPredictions((prevState) => ({
          ...prevState,
          location: result[0].address,
          latitude: result[0].latitude,
          longitude: result[0].longitude,
        }));
        setAddetails((prevState) => ({
          ...prevState,
          company_name: result.company_name,
          company_website_url: result.company_website_url,
          email: result.email,
          phone_number: result.phone_number,
          region: result.region,
          reason: result.reason,
        }));
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (location.pathname === "/edit-ad/" + id) {
      getadData(id);
    }
  }, [getadData, id, location.pathname]);

  const {
    handleSubmit,
    reset,
    register,
    control,
    formState: { errors },
  } = useForm<IAdList>({
    resolver: yupResolver(AdListSchema(translation)),
  });
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setLocationPredictions((prevState) => ({
            ...prevState,
            predictions: [],
          }));
        }
      }

      /**
       * Bind the event listener
       */
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        /**
         * Unbind the event listener on clean up
         */
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  /**
   * To Remove selected images from Drag & Drop list
   * @param index
   */
  const removeImage = (index: number) => {
    const remove = [...files];
    remove.splice(index, 1);

    setFile(remove);
  };

  /**
   * To handle selcted files
   * @param file
   */
  const handleChange = async (file) => {
    if (file.length > 0) {
      for (let i = 0; i < file.length; i++) {
        if (file[i].type !== undefined) {
          const imgType = file[i].type.split("/");
          const MimeTypeArray = ImgTypeArray;
          if (imgType[1] !== undefined) {
            if (MimeTypeArray.includes(imgType[1])) {
              if (file && file[i]) {
                const img = file[i];
                console.log("IMAGEEEEE",img)
                try {
                  const options = {
                    maxSizeMB: 1,
                    maxWidthOrHeight: 720,
                    useWebWorker: true,
                  };
                  const compressImg = await imageCompression(img, options);
                  console.log("compressImg",compressImg)
                  readFile(compressImg);
                } catch (e) {
                  console.error("Error while compressing image : ", e);
                }
                // readFile(img);
              }
            }
          }
        }
      }
    }
  };

  /**
   * To Read Selected file in base 64
   * @param file
   */
  const readFile = async (file: Blob) => {
    const fileReader = new FileReader();

    fileReader.onloadend = function () {
      if (files.length > 0) {
        setFile((prevState: string[]) => [...prevState, fileReader.result]);
      } else {
        setFile((prevState: string[]) => [...prevState, fileReader.result]);
      }
    };

    fileReader.readAsDataURL(file);
  };

  /**
   * Autocomplete search
   * @param text
   * @returns
   */
  const googleAutocomplete = async (text: string) => {
    if (isEmpty(text)) {
      setLocationPredictions((prevState) => ({
        ...prevState,
        predictions: [],
      }));
      return null;
    }
    if (!text) {
      return translation("valid_text");
    }
    if (typeof window === "undefined") {
      return translation("valid_window");
    }

    try {
      let result;
      let autocompleteService = await new google.maps.places.AutocompleteService();
      result = await autocompleteService.getPlacePredictions(
        { input: text },
        result
      );
      return result;
    } catch (e) {
      return e;
    }
  };

  /**
   * Select location
   * @param event
   */
  const selectLocation = async (event?: React.SyntheticEvent | Event) => {
    const target = event.target as HTMLInputElement;
    setLocationPredictions((prevState) => ({
      ...prevState,
      location: target.value,
    }));
    if (timeOutState) {
      clearInterval(timeOutState);
    }

    let result = setTimeout(async () => {
      let results = await googleAutocomplete(target.value);
      if (results) {
        setLocationPredictions((prevState) => ({
          ...prevState,
          latitude: 0,
          longitude: 0,
          predictions: results?.predictions,
        }));
      }
    }, 800);

    setTimeOutState(result);
  };

  /**
   * on Place selected by placeId
   * @param placeId
   * @param placeDescription
   * @param event
   * @returns
   */
  const onPlaceSelected = async (
    placeId: string,
    placeDescription: string,
    event: React.SyntheticEvent
  ) => {
    setlocationerr(false);

    event.preventDefault();
    const response: any = await dispatch(GooglePlacesDetail(placeId));
    if (!response || !response.result) {
      return;
    }
    setLocationPredictions({
      predictions: [],
      location: placeDescription,
      latitude: response.result?.geometry?.location?.lat,
      longitude: response.result?.geometry?.location?.lng,
    });
  };

  const cancelProduct = () => {
    setFile([]);
    reset({});
    setAddetails((prevState) => ({
      ...prevState,
      region: "",
      reason: "",
    }));
    setLocationPredictions({
      predictions: locationPrediction.predictions,
      location: locationPrediction.location,
      latitude: locationPrediction.latitude,
      longitude: locationPrediction.longitude,
    });
  };

  /**
   * get value from input fields
   * @param event
   * @param name
   */
  const handleChangetextfield = (event: any, name: string) => {
    let target = event.target as HTMLInputElement;
    setAddetails((prevState) => ({
      ...prevState,
      [name]: target.value,
    }));
  };

  const handleDateChange = (e, key) => {
    const value = moment(e).format("yyyy-MM-DD");
    setAdStartDate(value);
  };

  const validateDateOfBirth = () => {
    let isFormValid = true;
    setStartDateError("");

    if (adStartDate.trim().length === 0) {
      isFormValid = false;
      setStartDateError(translation("date_req"));
      setDisable(false);
    }
    return isFormValid;
  };

  /**
   * To submit data
   * @param data
   * @returns
   */
  const onSubmit = async (data: IAdList) => {
    setDisable(true);
    if (files.length === 0) {
      setimageError(translation("img_req"));
      setDisable(false);
    }

    if (locationPredictions.latitude === 0) {
      setlocationerr(true);
      setDisable(false);
    }

    if (files.length > 0 && locationPredictions.latitude !== 0) {
      if (!id) {
        if (validateDateOfBirth()) {
          createAd({
            data,
            adStartDate,
            locationPredictions,
            files,
            userId,
          }).then((response) => {
            if (response.data.isRegistered) {
              props?.history?.push({
                pathname: "/adpackages",
                state: {
                  adDetails: data,
                  adid: response?.data?.id,
                },
              });
            } else {
              return toast.error(translation(response.data.data.message));
            }
          });
        }
      } else {
        const adDate = moment(adStartDate).format("yyyy-MM-DD");
        updateAd(
          {
            data,
            adStartDate: adDate,
            locationPredictions,
            files,
            userId,
          },
          id
        ).then((response) => {
          if (response.data.isRegistered) {
            props?.history?.push({
              pathname: "/adlist",
              state: data,
            });
            return toast.success(translation("update_Ad"));
          } else {
            return toast.error(translation(response.data.data.message));
          }
        });
      }
    }
  };

  return (
    <Box>
      <Box className={`${commonStyle.windowColor}`}>
        <Grid container>
          <Grid item md={3}>
            <LeftSidebar />
          </Grid>

          <Grid item md={9}>
            <Box className={`${commonStyle.p20} ${commonStyle.mt20}`}>
              <Typography
                variant="h6"
                className={`${commonStyle.grey} ${commonStyle.mb15} ${commonStyle.fontWeight600}`}
              >
                {id ? "Edit Ad" : "Ad Center"}
              </Typography>
              {id ? (
                adData.length > 0 &&
                adData.map((item, index) => (
                  <Box className={`${commonStyle.p20} ${commonStyle.card}`}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <Grid container spacing={2}>
                        <Grid item md={6}>
                          <Box className={commonStyle.commonInput}>
                            <Typography
                              className={commonStyle.labelCommonInput}
                            >
                              {translation("company_name")}
                            </Typography>
                            <TextField
                              {...register("company_name")}
                              // onChange={(e) => handleChangetextfield(e, "company_name")}
                              defaultValue={item.company_name}
                              className={commonStyle.w100}
                            />
                            {/* {errors && errors?.company_name && ( */}
                            <span className={commonStyle.errorMsg}>
                              {errors?.company_name?.message}
                            </span>
                          </Box>
                        </Grid>

                        <Grid item md={6}>
                          <Box className={commonStyle.commonInput}>
                            <Typography
                              className={commonStyle.labelCommonInput}
                            >
                              {translation("company_url")}
                            </Typography>
                            <TextField
                              {...register("company_website_url")}
                              variant="outlined"
                              defaultValue={item.company_website_url}
                              className={commonStyle.w100}
                            />
                            {errors && errors?.company_website_url && (
                              <span className={commonStyle.errorMsg}>
                                {errors?.company_website_url?.message}
                              </span>
                            )}
                          </Box>
                        </Grid>
                        <Grid item md={6}>
                          <Box className={commonStyle.commonInput}>
                            <Typography
                              className={commonStyle.labelCommonInput}
                            >
                              {translation("email")}
                            </Typography>
                            {/* <fieldset disabled> */}
                            <TextField
                              {...register("email")}
                              // inputProps={{ readOnly: true }}
                              variant="outlined"
                              // onChange={(e) => handleChangetextfield(e, "email")}
                              defaultValue={item.email}
                              className={commonStyle.w100}
                            />
                            {/* </fieldset> */}
                            {errors && errors.email && (
                              <span className={commonStyle.errorMsg}>
                                {errors?.email?.message}
                              </span>
                            )}
                          </Box>
                        </Grid>

                        <Grid item md={6}>
                          <Box className={commonStyle.commonInput}>
                            <Typography
                              className={commonStyle.labelCommonInput}
                            >
                              {translation("contact")}
                            </Typography>
                            <TextField
                              {...register("phone_number")}
                              variant="outlined"
                              // onChange={(e) => handleChangetextfield(e, "phone_number")}
                              defaultValue={item.phone_number}
                              className={commonStyle.w100}
                              inputProps={{ maxLength: 10 }}
                            />
                            {errors && errors.phone_number && (
                              <span className={commonStyle.errorMsg}>
                                {errors?.phone_number?.message}
                              </span>
                            )}
                          </Box>
                        </Grid>
                        <Grid item md={12}>
                          <Box className={commonStyle.commonInput}>
                            <Typography
                              className={commonStyle.labelCommonInput}
                            >
                              {translation("start_date")}
                            </Typography>
                            <Calendar
                              style={{ height: 500 }}
                              onChange={(event) =>
                                handleDateChange(event, "date")
                              }
                              value={
                                adStartDate !== "" &&
                                moment(adStartDate).toDate()
                              }
                              minDate={new Date()}
                            ></Calendar>

                            {startDateError && startDateError.length > 0 && (
                              <span className={commonStyle.errorMsg}>
                                {startDateError}
                              </span>
                            )}
                          </Box>
                        </Grid>
                        <Grid item md={12}>
                          <Box className={commonStyle.commonInput}>
                            <Typography
                              className={commonStyle.labelCommonInput}
                            >
                              {translation("region")}
                            </Typography>
                            <Controller
                              name="region"
                              control={control}
                              defaultValue={item.region}
                              render={({ field }) => {
                                return (
                                  <Select
                                    value={field.value}
                                    onChange={(e) => {
                                      field.onChange(e);
                                    }}
                                    {...register("region")}
                                    className={commonStyle.w100}
                                  >
                                    {Region.length > 0 &&
                                      Region?.map((region, index) => {
                                        return (
                                          <MenuItem
                                            key={index}
                                            value={translation(region)}
                                          >
                                            {translation(region)}
                                          </MenuItem>
                                        );
                                      })}
                                  </Select>
                                );
                              }}
                            />
                            {errors && errors.region && (
                              <span className={commonStyle.errorMsg}>
                                {errors?.region?.message}
                              </span>
                            )}
                          </Box>
                        </Grid>

                        <Grid item md={12}>
                          <Box className={commonStyle.commonInput}>
                            <Typography
                              className={commonStyle.labelCommonInput}
                            >
                              {translation("ad_reason")}{" "}
                            </Typography>
                            <Controller
                              name="reason"
                              control={control}
                              defaultValue={item.Reason}
                              render={({ field }) => {
                                return (
                                  <Select
                                    value={field.value}
                                    onChange={(e) => {
                                      field.onChange(e);
                                    }}
                                    {...register("reason")}
                                    className={commonStyle.w100}
                                  >
                                    {Reason.length > 0 &&
                                      Reason?.map((reason, index) => {
                                        return (
                                          <MenuItem
                                            key={index}
                                            value={translation(reason)}
                                          >
                                            {translation(reason)}
                                          </MenuItem>
                                        );
                                      })}
                                  </Select>
                                );
                              }}
                            />
                            {errors && errors.reason && (
                              <span className={commonStyle.errorMsg}>
                                {errors?.reason?.message}
                              </span>
                            )}
                          </Box>
                        </Grid>

                        <Grid item md={12}>
                          <Box className={commonStyle.commonInput}>
                            <Typography
                              className={commonStyle.labelCommonInput}
                            >
                              {translation("location")}
                            </Typography>
                            <TextField
                              {...register("location")}
                              variant="outlined"
                              className={commonStyle.w100}
                              onChange={selectLocation}
                              value={locationPredictions.location}
                            />

                            {locationPredictions.predictions.length !== 0 && (
                              <div
                                className="search-drop-list"
                                ref={wrapperRef}
                              >
                                <ul className={commonStyle.searchCommonList}>
                                  {locationPredictions.predictions.map(
                                    (prediction) => (
                                      <li
                                        key={prediction?.place_id}
                                        onClick={(e) =>
                                          onPlaceSelected(
                                            prediction?.place_id,
                                            prediction?.description,
                                            e
                                          )
                                        }
                                      >
                                        {prediction?.description ||
                                          translation("not_found")}
                                      </li>
                                    )
                                  )}
                                </ul>
                              </div>
                            )}
                            {!locationPredictions.location ? (
                              <span className={commonStyle.errorMsg}>
                                {errors?.location?.message}
                              </span>
                            ) : loactionerr ? (
                              <span className={commonStyle.errorMsg}>
                                {"Please select a valid location"}
                              </span>
                            ) : (
                              ""
                            )}

                            {/* {!locationPredictions.location && (
                              <span className={commonStyle.errorMsg}>
                                {errors?.location?.message}
                              </span>
                            )} */}
                          </Box>
                        </Grid>
                        <Grid item md={12}>
                          <Box className={commonStyle.commonInput}>
                            <Typography
                              className={commonStyle.labelCommonInput}
                            >
                              {translation("upload_image")}
                            </Typography>
                            <Box className={`${commonStyle.commonUploadBox}`}>
                              <Dropzone
                                onDrop={(files) => handleChange(files)}
                                maxFiles={1}
                                accept="image/*"
                              >
                                {({ getRootProps, getInputProps }) => (
                                  <div className="container">
                                    <div
                                      {...getRootProps({
                                        className: "dropzone",
                                      })}
                                    >
                                      <p>{translation("drop_box_content")}</p>
                                      <input {...getInputProps()} />
                                    </div>

                                    {files.length > 0 && (
                                      <ul
                                        className={`${commonStyle.flexCenter} ${commonStyle.removeListStyle} ${commonStyle.oneImageDargList}`}
                                      >
                                        {files.length > 0 &&
                                          files.map((item, index) => (
                                            <li key={index}>
                                              <img
                                                src={item}
                                                alt="null"
                                                className={
                                                  commonStyle.imageDragDrop
                                                }
                                              />
                                              <HighlightOff
                                                onClick={() =>
                                                  removeImage(index)
                                                }
                                              />
                                            </li>
                                          ))}
                                      </ul>
                                    )}
                                  </div>
                                )}
                              </Dropzone>
                            </Box>
                            {files.length === 0 && (
                              <span className={commonStyle.errorMsg}>
                                {imageError}
                              </span>
                            )}
                          </Box>
                        </Grid>
                      </Grid>

                      <Box className={commonStyle.flexCenter}>
                        <Box
                          className={`${commonStyle.mr10} ${commonStyle.blueBtn}`}
                        >
                          <Button
                            disabled={disable}
                            onClick={handleSubmit(onSubmit)}
                          >
                            {translation("save&continue")}{" "}
                            {disable ? <ClipLoader /> : null}
                          </Button>
                        </Box>
                        <Box
                          className={`${commonStyle.mr10} ${commonStyle.greyBtn}`}
                        >
                          <CommonButton
                            onClick={cancelProduct}
                            label={translation("cancel")}
                          />
                        </Box>
                      </Box>
                    </form>
                  </Box>
                ))
              ) : (
                <Box className={`${commonStyle.p20} ${commonStyle.card}`}>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2}>
                      <Grid item md={6}>
                        <Box className={commonStyle.commonInput}>
                          <Typography className={commonStyle.labelCommonInput}>
                            {translation("company_name")}
                          </Typography>
                          <TextField
                            {...register("company_name")}
                            // onChange={(e) => handleChangetextfield(e, "company_name")}
                            // defaultValue={item.company_name}
                            className={commonStyle.w100}
                          />
                          {/* {errors && errors?.company_name && ( */}
                          <span className={commonStyle.errorMsg}>
                            {errors?.company_name?.message}
                          </span>
                        </Box>
                      </Grid>

                      <Grid item md={6}>
                        <Box className={commonStyle.commonInput}>
                          <Typography className={commonStyle.labelCommonInput}>
                            {translation("company_url")}
                          </Typography>
                          <TextField
                            {...register("company_website_url")}
                            variant="outlined"
                            // defaultValue={item.company_website_url}
                            className={commonStyle.w100}
                          />
                          {errors && errors?.company_website_url && (
                            <span className={commonStyle.errorMsg}>
                              {errors?.company_website_url?.message}
                            </span>
                          )}
                        </Box>
                      </Grid>
                      <Grid item md={6}>
                        <Box className={commonStyle.commonInput}>
                          <Typography className={commonStyle.labelCommonInput}>
                            {translation("email")}
                          </Typography>
                          {/* <fieldset disabled> */}
                          <TextField
                            {...register("email")}
                            // inputProps={{ readOnly: true }}
                            variant="outlined"
                            // onChange={(e) => handleChangetextfield(e, "email")}
                            // defaultValue={item.email}
                            className={commonStyle.w100}
                          />
                          {/* </fieldset> */}
                          {errors && errors.email && (
                            <span className={commonStyle.errorMsg}>
                              {errors?.email?.message}
                            </span>
                          )}
                        </Box>
                      </Grid>

                      <Grid item md={6}>
                        <Box className={commonStyle.commonInput}>
                          <Typography className={commonStyle.labelCommonInput}>
                            {translation("contact")}
                          </Typography>
                          <TextField
                            {...register("phone_number")}
                            variant="outlined"
                            // onChange={(e) => handleChangetextfield(e, "phone_number")}
                            // defaultValue={item.phone_number}
                            className={commonStyle.w100}
                          />
                          {errors && errors.phone_number && (
                            <span className={commonStyle.errorMsg}>
                              {errors?.phone_number?.message}
                            </span>
                          )}
                        </Box>
                      </Grid>
                      <Grid item md={12}>
                        <Box className={commonStyle.commonInput}>
                          <Typography className={commonStyle.labelCommonInput}>
                            {translation("start_date")}
                          </Typography>
                          <Calendar
                            style={{ height: 500 }}
                            onChange={(event) =>
                              handleDateChange(event, "date")
                            }
                            value={
                              adStartDate !== "" && moment(adStartDate).toDate()
                            }
                            minDate={new Date()}
                          ></Calendar>

                          {startDateError && startDateError.length > 0 && (
                            <span className={commonStyle.errorMsg}>
                              {startDateError}
                            </span>
                          )}
                          {/* <TextField
                            {...register("start_date")}
                            variant="outlined"
                            className={commonStyle.w100}
                            type="date"
                            // onChange={(e) => handleChangetextfield(e, "start_date")}
                            // defaultValue={moment(item.start_date).format("YYYY-MM-DD")}

                            // defaultValue={moment(item.start_date).format("YYYY-MM-DD")}
                          />
                          {errors && errors.start_date && (
                            <span className={commonStyle.errorMsg}>
                              {errors?.start_date?.message}
                            </span>
                          )} */}
                        </Box>
                      </Grid>
                      <Grid item md={12}>
                        <Box className={commonStyle.commonInput}>
                          <Typography className={commonStyle.labelCommonInput}>
                            {translation("region")}
                          </Typography>
                          <Controller
                            name="region"
                            control={control}
                            defaultValue=""
                            render={({ field }) => {
                              return (
                                <Select
                                  value={field.value}
                                  onChange={(e) => {
                                    field.onChange(e);
                                  }}
                                  {...register("region")}
                                  className={commonStyle.w100}
                                >
                                  {Region.length > 0 &&
                                    Region?.map((region, index) => {
                                      return (
                                        <MenuItem
                                          key={index}
                                          value={translation(region)}
                                        >
                                          {translation(region)}
                                        </MenuItem>
                                      );
                                    })}
                                </Select>
                              );
                            }}
                          />
                          {errors && errors.region && (
                            <span className={commonStyle.errorMsg}>
                              {errors?.region?.message}
                            </span>
                          )}
                        </Box>
                      </Grid>
                      <Grid item md={12}>
                        <Box className={commonStyle.commonInput}>
                          <Typography className={commonStyle.labelCommonInput}>
                            {translation("ad_reason")}{" "}
                          </Typography>
                          <Controller
                            name="reason"
                            control={control}
                            defaultValue=""
                            render={({ field }) => {
                              return (
                                <Select
                                  value={field.value}
                                  onChange={(e) => {
                                    field.onChange(e);
                                  }}
                                  {...register("reason")}
                                  className={commonStyle.w100}
                                >
                                  {Reason.length > 0 &&
                                    Reason?.map((reason, index) => {
                                      return (
                                        <MenuItem
                                          key={index}
                                          value={translation(reason)}
                                        >
                                          {translation(reason)}
                                        </MenuItem>
                                      );
                                    })}
                                </Select>
                              );
                            }}
                          />

                          {errors && errors.reason && (
                            <span className={commonStyle.errorMsg}>
                              {errors?.reason?.message}
                            </span>
                          )}
                        </Box>
                      </Grid>

                      <Grid item md={12}>
                        <Box className={commonStyle.commonInput}>
                          <Typography className={commonStyle.labelCommonInput}>
                            {translation("location")}
                          </Typography>
                          <TextField
                            {...register("location")}
                            variant="outlined"
                            className={commonStyle.w100}
                            autoComplete="off"
                            onChange={selectLocation}
                            value={locationPredictions.location}
                          />

                          {locationPredictions.predictions.length !== 0 && (
                            <div className="search-drop-list" ref={wrapperRef}>
                              <ul className={commonStyle.searchCommonList}>
                                {locationPredictions.predictions.map(
                                  (prediction) => (
                                    <li
                                      key={prediction?.place_id}
                                      onClick={(e) =>
                                        onPlaceSelected(
                                          prediction?.place_id,
                                          prediction?.description,
                                          e
                                        )
                                      }
                                    >
                                      {prediction?.description ||
                                        translation("not_found")}
                                    </li>
                                  )
                                )}
                              </ul>
                            </div>
                          )}
                          {!locationPredictions.location ? (
                            <span className={commonStyle.errorMsg}>
                              {errors?.location?.message}
                            </span>
                          ) : loactionerr ? (
                            <span className={commonStyle.errorMsg}>
                              {"Please select a valid location"}
                            </span>
                          ) : (
                            ""
                          )}

                          {/* {!locationPredictions.location && (
                            <span className={commonStyle.errorMsg}>
                              {errors?.location?.message}
                            </span>
                          )} */}
                        </Box>
                      </Grid>
                      <Grid item md={12}>
                        <Box className={commonStyle.commonInput}>
                          <Typography className={commonStyle.labelCommonInput}>
                            {translation("upload_image")}
                          </Typography>
                          <Box className={`${commonStyle.commonUploadBox}`}>
                            <Dropzone
                              onDrop={(files) => handleChange(files)}
                              maxFiles={1}
                              accept="image/*"
                            >
                              {({ getRootProps, getInputProps }) => (
                                <div className="container">
                                  <div
                                    {...getRootProps({ className: "dropzone" })}
                                  >
                                    <p>{translation("drop_box_content")}</p>
                                    <input {...getInputProps()} />
                                  </div>

                                  {files.length > 0 && (
                                    <ul
                                      className={`${commonStyle.flexCenter} ${commonStyle.removeListStyle} ${commonStyle.dragList}`}
                                    >
                                      {files.length > 0 &&
                                        files.map((item, index) => (
                                          <li key={index}>
                                            <img
                                              src={item}
                                              alt="null"
                                              className={
                                                commonStyle.imageDragDrop
                                              }
                                            />
                                            <HighlightOff
                                              onClick={() => removeImage(index)}
                                            />
                                          </li>
                                        ))}
                                    </ul>
                                  )}
                                </div>
                              )}
                            </Dropzone>
                          </Box>
                          {files.length === 0 && (
                            <span className={commonStyle.errorMsg}>
                              {imageError}
                            </span>
                          )}
                        </Box>
                      </Grid>
                    </Grid>

                    <Box className={commonStyle.flexCenter}>
                      <Box
                        className={`${commonStyle.mr10} ${commonStyle.blueBtn}`}
                      >
                        <Button
                          disabled={disable}
                          onClick={handleSubmit(onSubmit)}
                        >
                          {translation("save&continue")}{" "}
                          {disable ? <ClipLoader /> : null}

                          
                        </Button>
                      </Box>
                      <Box
                        className={`${commonStyle.mr10} ${commonStyle.greyBtn}`}
                      >
                        <CommonButton
                          onClick={cancelProduct}
                          label={translation("cancel")}
                        />
                      </Box>
                    </Box>
                  </form>
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default AdCenter;
