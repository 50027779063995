import {
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";

import LeftSidebar from "../../components/sidebars/LeftSidebar";
import { UserCommonStyles } from "../../styles/CommonStyles";

const DashboardAdvertisment: React.FC = () => {
  const commonStyle = UserCommonStyles();

  return (
    <Box>
      <Grid container>
        <Grid item md={3}>
          <LeftSidebar />
        </Grid>

        <Grid item md={9}>
          <Box className={`${commonStyle.mb20} ${commonStyle.p20}`}>
            <Typography
              variant="h6"
              className={`${commonStyle.grey} ${commonStyle.mb15} ${commonStyle.fontWeight600}`}
            >
              Dashboard Advertisment
            </Typography>
            <TableContainer>
              <Table stickyHeader>
                <TableHead>
                  <TableRow className={commonStyle.commonTableHead}>
                    <TableCell>Package</TableCell>
                    <TableCell>Start Date</TableCell>
                    <TableCell>End Date</TableCell>
                    <TableCell>No. of Clicks</TableCell>
                    <TableCell>No. of Views</TableCell>
                    <TableCell>&nbsp;</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow hover tabIndex={-1}>
                    <TableCell>$101</TableCell>
                    <TableCell>11-12-1996</TableCell>
                    <TableCell>12-12-1996</TableCell>
                    <TableCell>23</TableCell>
                    <TableCell>2</TableCell>
                    <TableCell>
                      <Box className={`${commonStyle.blueBtn}`}>
                        <Button>Pause Campaign</Button>
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>

            <Box className={`${commonStyle.orangeBtn}`}>
              <Button>Pause Campaign</Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DashboardAdvertisment;
