import axios from "../../services/axiosService";
import { Dispatch } from "redux";
import { ActionType } from "../action-types/index";
const URL = process.env.REACT_APP_API_URL;

/**
 * create order
 * @param data
 * @returns
 */
export const createOrder = (data: object) => async (dispatch: Dispatch) => {
  try {
    const response = await axios.post(`${URL}/api/v1/order/add`, data);
    return response.data;
  } catch (err) {
    dispatch({
      type: ActionType.FAILURE,
    });
  }
};

/**
 * get all users order
 * @param userId
 * @param searchText
 * @returns
 */
export const getAllUserOrders = (userId: number, searchText: string) => async (
  dispatch: Dispatch
) => {
  try {
    const response = await axios.get(
      `${URL}/api/v1/order/list/${userId}?searchText=${searchText}`
    );
    return response.data;
  } catch (err) {
    dispatch({
      type: ActionType.FAILURE,
    });
  }
};

/**
 * get all users client orders
 * @param sellerId
 * @param searchText
 * @returns
 */
export const getAllUserClientOrders = (
  sellerId: number,
  searchText: string
) => async (dispatch: Dispatch) => {
  try {
    const response = await axios.get(
      `${URL}/api/v1/order/client_order_list/${sellerId}?searchText=${searchText}`
    );
    return response.data;
  } catch (err) {
    dispatch({
      type: ActionType.FAILURE,
    });
  }
};

/**
 * update client order status
 * @param data
 * @param orderId
 * @returns
 */
export const updateClientOrderStatus = (
  data: object,
  orderId: number
) => async (dispatch: Dispatch) => {
  try {
    const response = await axios.patch(
      `${URL}/api/v1/order/update/${orderId}`,
      data
    );
    return response.data;
  } catch (error) {
    dispatch({
      type: ActionType.FAILURE,
    });
  }
};
