import axios from "../../services/axiosService";
import { Dispatch } from "redux";
import { ActionType } from "../action-types/index";
import { Action } from "../actions/AuthAction";
const URL = process.env.REACT_APP_API_URL;

/**
 * get list of user
 * @param id
 * @param type
 * @param gender
 * @param distance
 * @returns
 */
export const getListUser = (
  id: number,
  type: string,
  gender: string,
  distance: string,
  surgical_procedure : string
) => async (dispatch: Dispatch<Action>) => {
  try {
    const response = await axios.get(
      `${URL}/api/v1/pet-play/userList/${id}/?type=${type}&gender=${gender}&distance=${distance}&surgical_procedure=${surgical_procedure}`
    );
    return response.data.data.data;
  } catch (err) {
    dispatch({
      type: ActionType.FAILURE,
    });
  }
};

/**
 * active pet play
 * @param id
 * @returns
 */
export const petPlayon = (id: number) => async (dispatch: Dispatch<Action>) => {
  try {
    const response = await axios.post(`${URL}/api/v1/pet-play/active/${id}`);

    return response.data.data;
  } catch (err) {
    dispatch({
      type: ActionType.FAILURE,
    });
  }
};

/**
 * get status of user
 * @param id
 * @returns
 */
export const getUserStatus = (id: number) => async (
  dispatch: Dispatch<Action>
) => {
  try {
    const response = await axios.get(`${URL}/api/v1/pet-play/userStatus/${id}`);

    return response.data.data;
  } catch (err) {
    dispatch({
      type: ActionType.FAILURE,
    });
  }
};

/**
 * change status of petplay
 * @param id
 * @param data
 * @returns
 */
export const changeStatus = (id: number, data: object) => async (
  dispatch: Dispatch<Action>
) => {
  try {
    const response = await axios.post(
      `${URL}/api/v1/pet-play/changeStatus/${id}`,
      data
    );

    return response.data.data.data;
  } catch (err) {
    dispatch({
      type: ActionType.FAILURE,
    });
  }
};

/**
 * add to the favourite list
 * @param id
 * @param userId
 * @returns
 */
export const userAddFavourite = (id: number, userId: number) => async (
  dispatch: Dispatch<Action>
) => {
  try {
    const response = await axios.post(
      `${URL}/api/v1/pet-play/add-favourite/${id}/${userId}`
    );
    return response.data.data;
  } catch (err) {
    dispatch({
      type: ActionType.FAILURE,
    });
  }
};

/**
 * get list of favourite
 * @param userId
 * @returns
 */
export const getListOfFavourite = (userId: number) => async (
  dispatch: Dispatch<Action>
) => {
  try {
    const response = await axios.get(
      `${URL}/api/v1/pet-play/list-favourite/${userId}`
    );

    return response.data.data.data;
  } catch (err) {
    dispatch({
      type: ActionType.FAILURE,
    });
  }
};

/**
 * send notification
 * @param userId
 * @param data
 * @returns
 */
export const sendNotification = (userId: any, data: object) => async (
  dispatch: Dispatch<Action>
) => {
  try {
    const response = await axios.post(
      `${URL}/api/v1/pet-play/send-notification/${userId}`,
      data
    );
    return response.data.data.data;
  } catch (err) {
    dispatch({
      type: ActionType.FAILURE,
    });
  }
};
