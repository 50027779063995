import { ActionType } from "../action-types/index";

export const initialState = {
  user: localStorage.getItem("userData")
    ? localStorage.getItem("userData")
    : { id: 0 },
  loginType: "",
  isLoggedIn: false,
  isFound: "",
  authData: localStorage.getItem("userData")
    ? JSON.parse(localStorage.getItem("userData") as string)
    : undefined,
};

/**
 *  user information stored in global state of redux
 * @param state
 * @param action
 * @returns
 */
export const authReducer = (state: object = initialState, action: any) => {
  switch (action.type) {
    case ActionType.LOGIN:
      return {
        ...state,
        isLoggedIn: true,
        user: action.payload,
        authData: action.payload,
      };
    case ActionType.UPDATEPROFILE:
      return {
        ...state,
        isLoggedIn: true,
        authData: action.payload,
      };
    case ActionType.LOGOUTUSER:
      localStorage.clear();
      return {
        ...state,
        loginType: "",
        isLoggedIn: false,
        isFound: "",
        user: {},
      };
    case ActionType.SOCIALLOGIN:
      return {
        ...state,
        isLoggedIn: action.payload?.data.isFound ? true : false,
        isFound: action.payload?.data.isFound,
        loginType: action.payload?.data.type,
        authData: action.payload?.data.user,
      };
    case ActionType.FAILURE:
      return {
        ...state,
        user: action.type,
      };

    default:
      return state;
  }
};
