import { useDispatch } from "react-redux";
import { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";

import { Avatar, Button, Modal, Typography } from "@mui/material";
import { Box } from "@mui/system";
import ClipLoader from "react-spinners/ClipLoader";


import { getProductDetailsById } from "../../redux/action-creators/productAction";
import { getdatabyId } from "../../redux/action-creators/adoptionAction";
import { createOrder } from "../../redux/action-creators/orderAction";

import { SidebarStyles } from "./Sidebar";

import { toast } from "../../utils/toastsMessage";
import { capitalize } from "../../utils/helper";
import { RoutingLinks, NewLineRegex, Type } from "../../utils/constants";

import minImage from "../../images/minImage.png";


import { UserCommonStyles } from "../../styles/CommonStyles";
import { sendNotification } from "../../redux/action-creators/petPlayAction";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#fff",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ProductSidebar = (props: { id: string; type: string }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t: translation } = useTranslation();
  const commonStyle = UserCommonStyles();
  const styleClasses = SidebarStyles();
  const [userList, setUserList] = useState([]);
  const [showConfirmButton, setShowConfirmButton] = useState(false);

  let userData: any = localStorage.getItem("userData");
  userData = userData ? JSON.parse(userData) : {};

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { id, type } = props;

  /**
   * fetch user details
   * @param id
   */
  const userDetails = useCallback(
    async (id: string) => {
      if (type === Type.product) {
        const response: any = await dispatch(getProductDetailsById(id));

        if (userData && Object.keys(userData).length > 0 && userData?.address && response && response?.length>0) {
          response[0].address = userData?.address;
        }
        setUserList(response);
      } else {
        const response: any = await dispatch(getdatabyId(id, type));       
        setUserList(response);
      }
    },
    [dispatch, type, userData]
  );
  useEffect(() => {
    userDetails(id);
  }, [id]);

  /**
   * order confirmed
   * @returns
   */
  const OrderConfirmed = async () => {
    setShowConfirmButton(true);
    let sellerId = userList[0].userId;
    userList[0].userId = userData.id;
    const response: any = await dispatch(createOrder(userList[0]));
    if (response && response?.data?.isRegistered) {
      setShowConfirmButton(false);
      handleClose();
      userList[0].userId = sellerId;
      const data = { type: "New order", id: userData.id };
      await dispatch(sendNotification(userList[0].userId, data));
      return toast.success(translation(response.message));
    }
    else
    {
      setShowConfirmButton(false);
      handleClose();
      return toast.error(translation(response.message));
    }
  };

  return (
    <Box className={`${styleClasses.SidebarMain}`}>
      <Box>
        <Typography variant="h6" className={commonStyle.m0}>
          {userList[0]?.name ? userList[0].name : null}
        </Typography>

        {type === Type.product && (
          <Typography className={styleClasses.smallPrice}>
            $ {userList[0]?.price ? userList[0].price : null}
          </Typography>
        )}

        <Box
          className={`${commonStyle.borderTop} ${commonStyle.blueBtn} ${commonStyle.py15}`}
        >
          <Button
            onClick={() =>
              history.push({
                pathname: RoutingLinks.chat,
                state: {
                  id: userList[0]?.userId,
                  first_name: userList[0]?.first_name,
                  last_name: userList[0]?.last_name,
                  profile_image: userList[0]?.profile_image,
                  channel: "",
                },
              })
            }
            fullWidth
          >
            {translation("message")}
          </Button>
        </Box>

        {type === Type.product && (
          <Box className={`${commonStyle.py15} ${commonStyle.borderTop}`}>
            <Typography variant="h6" className={commonStyle.m0}>
              {translation("details")}
            </Typography>
            {userList[0]?.description ? (
              <Typography
                className={styleClasses.smallPrice}
                dangerouslySetInnerHTML={{
                  __html: `${userList[0]?.description.replace(
                    NewLineRegex,
                    "<br />"
                  )}`,
                }}
              ></Typography>
            ) : (
              ""
            )}
          </Box>
        )}

        {(type === Type.adoption || type === Type["lost&found"]) && (
          <>
            <Box className={styleClasses.petDetails}>
              <Typography className={styleClasses.petProductLabel}>
                {translation("Type_of_Pet")}:
              </Typography>
              <Typography className={styleClasses.petProductDesc}>
                {userList[0]?.type ? translation(userList[0].type) : null}
              </Typography>
            </Box>
            {userList[0]?.type === "Dog" || userList[0]?.type === "Cat" ? (
              <Box className={styleClasses.petDetails}>
                <Typography className={styleClasses.petProductLabel}>
                  {translation("surgical")}:
                </Typography>
                <Typography className={styleClasses.petProductDesc}>
                  {userList[0]?.surgical_procedure
                    ? capitalize("Spayed/Neutered")
                    : null}
                </Typography>
              </Box>
            ) : null}
            <Box className={styleClasses.petDetails}>
              <Typography className={styleClasses.petProductLabel}>
                {translation("breed")}:
              </Typography>
              <Typography className={styleClasses.petProductDesc}>
                {userList[0]?.breed ? userList[0].breed : null}
              </Typography>
            </Box>

            <Box className={styleClasses.petDetails}>
              <Typography className={styleClasses.petProductLabel}>
                {translation("description")}:
              </Typography>
              <Typography className={styleClasses.petProductDesc}>
                {userList[0]?.description ? (
                  <p
                    className={commonStyle.m0}
                    dangerouslySetInnerHTML={{
                      __html: `${userList[0]?.description.replace(
                        NewLineRegex,
                        "<br />"
                      )}`,
                    }}
                  ></p>
                ) : null}
              </Typography>
            </Box>

            <Box className={styleClasses.petDetails}>
              <Typography className={styleClasses.petProductLabel}>
                {translation("color")}:
              </Typography>
              <Typography className={styleClasses.petProductDesc}>
                {userList[0]?.color ? capitalize(userList[0].color) : null}
              </Typography>
            </Box>

            <Box className={styleClasses.petDetails}>
              <Typography className={styleClasses.petProductLabel}>
                {translation("age")}:
              </Typography>
              <Typography className={styleClasses.petProductDesc}>
                {userList[0]?.age ? userList[0].age : null}
              </Typography>
            </Box>

            <Box className={styleClasses.petDetails}>
              <Typography className={styleClasses.petProductLabel}>
                {translation("location")}:
              </Typography>
              <Typography className={styleClasses.petProductDesc}>
                {userList[0]?.address ? userList[0].address : null}
              </Typography>
            </Box>
          </>
        )}

        <Box className={`${commonStyle.py15} ${commonStyle.borderTop}`}>
          <Typography variant="h6" className={commonStyle.m0}>
            {type === Type.product
              ? translation("seller_info")
              : translation("Owner_info")}
          </Typography>

          <Box className={`${commonStyle.py15} ${commonStyle.wallUserInfo}`}>
            <Avatar
              alt={translation("story_person")}
              src={
                userList[0]?.profile_image
                  ? userList[0].profile_image
                  : minImage
              }
            />
            <Box className={commonStyle.ml10}>
              <Typography variant="h2" className={commonStyle.white}>
                <Link
                  to={{
                    pathname: `${RoutingLinks.profiles}${userList[0]?.userId}`,
                  }}
                  className={`${commonStyle.white} ${commonStyle.anchorDecoration}`}
                >
                  {userList[0]?.first_name
                    ? capitalize(userList[0].first_name)
                    : null}{" "}
                  {+" " + userList[0]?.last_name
                    ? capitalize(userList[0].last_name)
                    : null}
                </Link>
              </Typography>
              <Typography>
                {userList[0]?.type ? translation(userList[0].type) : null}
              </Typography>
            </Box>
          </Box>

          {type === Type.product && (
            <Box className={` ${commonStyle.blueBtn} ${commonStyle.py15}`}>
              <Button fullWidth onClick={handleOpen}>
                {translation("order_now")}
              </Button>

              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Typography
                    id="modal-modal-title"
                    variant="h4"
                    className={`${commonStyle.borderBottom}`}
                  >
                    {translation("confirmation")}
                  </Typography>
                  <Box className={commonStyle.py15}>
                    <Box>
                      <Typography variant="h6">
                        {translation("product_name")}:
                      </Typography>
                      <Typography>
                        {userList[0]?.name ? userList[0].name : ""}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="h6">
                        {translation("price")} :
                      </Typography>
                      <Typography>
                        {userList[0]?.price ? userList[0].price : ""} $
                      </Typography>
                    </Box>

                    <Box>
                      <Typography variant="h6">
                        {translation("location")}:
                      </Typography>
                      <Typography>
                        {userList[0]?.address ? userList[0].address : ""}
                      </Typography>
                    </Box>

                    <Box
                      className={` ${commonStyle.blueBtn} ${commonStyle.py15}`}
                    >
                      <Button
                        disabled={showConfirmButton}
                        fullWidth
                        onClick={OrderConfirmed}
                      >
                        {translation("confirm")}
                        {showConfirmButton?<ClipLoader/>:null}
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Modal>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ProductSidebar;
