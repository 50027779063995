import React, { useState, useEffect } from "react";
import ClipLoader from "react-spinners/ClipLoader";

import {
  Grid,
  Typography,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  Radio,
  Button,
  OutlinedInput,
  TextField,
  sliderUnstyledClasses,
} from "@mui/material";
import { Box } from "@mui/system";
import { loadStripe } from "@stripe/stripe-js";
import { toast } from "../../utils/toastsMessage";

import LeftSidebar from "../../components/sidebars/LeftSidebar";
import { UserCommonStyles } from "../../styles/CommonStyles";
import { UserAuthStyles } from "../userAuth/UserAuth";

import { useTranslation } from "react-i18next";

import { Elements, useStripe } from "@stripe/react-stripe-js";

import { useDispatch, RootStateOrAny, useSelector } from "react-redux";
import AddPaymentMethodModal from "./addPaymentMethod";

import {
  getSavedCardsList,
  paymentBySelectedCard,
} from "../../redux/action-creators/stripe";

import PaymentMethod from "./paymentMethod";
import { CheckCircle } from "@mui/icons-material";
import { isJsonString } from "../../utils/helper";

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || ""
);

if (!process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY) {
  console.error("**Stripe publishable key environment variable not set**");
  console.error(
    "**Add an environemnt variable REACT_APP_STRIPE_PUBLISHABLE_KEY**"
  );
  console.error("**Replace .env.example with .env and **");
}

const Cards = (props: any) => {
  const stripe = useStripe();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const userInfo = useSelector((state: RootStateOrAny) => {
    let userData = state.authReducer.authData;
    if (isJsonString(state.authReducer.authData)) {
      userData = JSON.parse(state.authReducer.authData);
    }
    return userData;
  });
  const commonStyle = UserCommonStyles();
  const styleClasses = UserAuthStyles();
  const { t: translation } = useTranslation();

  const [cardList, setCardList] = useState([]);
  const [paysucess, setsucess] = useState(false);
  const [disable, setdisable] = useState(true);
  const [loading, setloading] = useState(false);

  const [stripeCardDetails, setStripeCardDetails] = useState({
    isPaymentButtonEnable: false,
    paymentType: "",
    isCardDropdownEnable: false,
    savedCardList: [],
    selectedCardId: null,
    paymentTypeSelectErrorMsg: "",
    paymentResponse: null,
    incompletePaymentMessage: "",
    isPaymentInitialized: false,
    paymentDescription: "",
    cartSubtotal: 0,
    stripeResponse: {},
    handleClickKey: false,
    handleRestForm: false,
    isFormSubmitted: false,
    paypalDescriptionString: "",
    cartItems: [],
    chkboxnewcard: false,
    newCard: false,
  });

  const [selectCard, setSelectCard] = useState();

  const getListofSaveCard = async () => {
    let result: any = await dispatch(getSavedCardsList(userInfo.id));

    if (result && result?.length > 0) {
      setCardList(result);
    }
  };

  const [values, setValues] = React.useState({
    amount: "",
  });

  const handleChange = (prop) => (event) => {
    if (event.target.value > 0) {
      setdisable(false);
      setValues({ ...values, [prop]: event.target.value });
    } else {
      setdisable(true);
      setValues({ ...values, [prop]: "0" });
    }
  };

  useEffect(() => {
    getListofSaveCard();
  }, []);

  const onSelectCard = (id) => {
    if (values.amount) {
      setdisable(false);
    }
    setSelectCard(id);
  };

  const onSubmit = async () => {
    setdisable(true);
    if (selectCard) {
      setloading(true);
      const res: any = await dispatch(
        paymentBySelectedCard(
          { paymentMethodId: selectCard, amount: values.amount },
          userInfo.id
        )
      );

      if (res.isSuccess) {
        setloading(false);
        setsucess(true);
        setdisable(false);
        return toast.success(translation(res.message));
      } else {
        setloading(false);
        return toast.error(translation(res.message));
      }
    } else {
      setdisable(true);
      return toast.error(translation("select_card"));
    }
  };

  return (
    <Box>
      <Grid container>
        <Grid item md={3}>
          <LeftSidebar />
        </Grid>

        <Grid item md={9}>
          <Box className={`${commonStyle.mb20} ${commonStyle.p20}`}>
            {paysucess ? (
              <Box className={styleClasses.confirmAmountModal}>
                <CheckCircle />
                <Typography>Payment Successful</Typography>
                <Box className={commonStyle.orangeBtn}>
                  <Button
                    onClick={() =>
                      props.history.push({ pathname: "/view-balance" })
                    }
                  >
                    View Balance
                  </Button>
                </Box>
              </Box>
            ) : null}
            <Typography
              variant="h6"
              className={`${commonStyle.grey} ${commonStyle.mb15} ${commonStyle.fontWeight600}`}
            >
              Card Details
            </Typography>

            <Box className={`${commonStyle.p20} ${commonStyle.card}`}>
              <ul className={styleClasses.cardSaveList}>
                {cardList.map((item, index) => {
                  return (
                    <PaymentMethod
                      key={index}
                      data={item}
                      selectPayment={onSelectCard}
                      selectedIndex={selectCard}
                    />
                  );
                })}

                <li>
                  <FormControlLabel
                    control={<Radio />}
                    checked={stripeCardDetails.chkboxnewcard}
                    disabled={disable}
                    onChange={() => setOpen(true)}
                    label="Add New Card"
                  />
                </li>
              </ul>
            </Box>
            <AddPaymentMethodModal
              toggleModal={open}
              amount={values.amount}
              success={() => setsucess(true)}
              handleClose={() => setOpen(false)}
            />

            <TextField
              fullWidth
              type="number"
              label="Amount"
              id="simple-start-adornmhent"
              className={commonStyle.mt20}
              onChange={handleChange("amount")}
              InputProps={{ inputProps: { min: 1 } }}
            />

            <Box className={`${commonStyle.mt20} ${commonStyle.textCenter}`}>
              <Box className={commonStyle.orangeBtn}>
                <Button disabled={disable} onClick={() => onSubmit()}>
                  Pay ${values?.amount}
                  {loading ? <ClipLoader /> : null}
                </Button>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

const PaymentForm = (props: any) => (
  <Elements stripe={stripePromise}>
    <Cards {...props} />
  </Elements>
);

export default PaymentForm;
