import { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { ExpandMore } from "@mui/icons-material";

import { getFaq } from "../../redux/action-creators/profileAction";

import LeftSidebar from "../../components/sidebars/LeftSidebar";

import { NewLineRegex } from "../../utils/constants";

import { UserCommonStyles } from "../../styles/CommonStyles";
import { ProfileStyle } from "./ProfileStyle";

const HelpCenter = () => {
  const { t: translation } = useTranslation();
  const dispatch = useDispatch();
  const commonStyle = UserCommonStyles();
  const styleClasses = ProfileStyle();
  // const [FaqList, setFaqList] = useState([]);

  const [loading, setLoading] = useState<boolean>(false);
  const [state, setState] = useState({
    FaqList: [],
    offset: 0,
    // prevOffset: 0,
    // hasMore: true,
  });

  /**
   * get all faqs
   */
  const getAllFaq = async (firstLoad: boolean) => {
    //if (state.offset !== state.prevOffset || firstLoad) {
    setLoading(true);
    let result: any = await dispatch(getFaq(state.offset));
    if (result && result?.length > 0) {
      setState((prevState) => ({
        ...prevState,
        //  prevOffset: firstLoad ? 0 : prevState.offset,
        //  offset: firstLoad ? 20 : prevState.offset + 20,
        FaqList: firstLoad ? result : prevState.FaqList.concat(result),
        // hasMore: true,
      }));
    } else {
      if (result) {
        setState((prevState) => ({
          ...prevState,
          FaqList: firstLoad ? result : prevState.FaqList.concat(result),
          // hasMore: false,
        }));
      }
    }
    //}
    setLoading(false);
  };

  useEffect(() => {
    getAllFaq(true);
  }, []);

  return (
    <Box>
      <Box className={commonStyle.windowColor}>
        <Grid container>
          <Grid item md={3}>
            <LeftSidebar />
          </Grid>
          <Grid item md={9}>
            <Box className={`${commonStyle.p20} ${commonStyle.mt20}`}>
              <Typography
                variant="h6"
                className={`${commonStyle.grey} ${commonStyle.mb15} ${commonStyle.fontWeight600}`}
              >
                {translation("help_center_head")}
              </Typography>
              {/* <InfiniteScroll
                dataLength={state?.FaqList?.length}
                next={() => getAllFaq(true)}
                hasMore={state?.hasMore}
                loader={(() => {
                  if (loading) {
                    return <div className="loaing-area">Loading...</div>;
                  }
                })()}
                scrollThreshold="100px"
              > */}
              <Box
                className={`${styleClasses.helpAccordion} ${commonStyle.p20}`}
              >
                {state.FaqList.length > 0
                  ? state.FaqList.map((i) => (
                      <Accordion className={styleClasses.accordionShadow}>
                        <AccordionSummary
                          expandIcon={<ExpandMore />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography className={`${commonStyle.textUpper}`}>
                            <strong>{i.name}</strong>
                          </Typography>
                        </AccordionSummary>

                        <AccordionDetails>
                          <Typography
                            className={`${commonStyle.grey}`}
                            dangerouslySetInnerHTML={{
                              __html: `${i.description.replace(
                                { NewLineRegex },
                                "<br />"
                              )}`,
                            }}
                          ></Typography>
                        </AccordionDetails>
                      </Accordion>
                    ))
                  : loading
                  ? translation("loading")
                  : translation("missing_record")}
              </Box>
              {/* </InfiniteScroll> */}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default HelpCenter;
