import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  useRef,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";

import { Box } from "@mui/system";
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import Badge, { BadgeProps } from "@mui/material/Badge";
import { styled } from "@mui/material/styles";
import { FiberManualRecord, Groups } from "@mui/icons-material";

import {
  getAllChats,
  setUnreadMessages,
} from "../../redux/action-creators/chatActions";

import { capitalize } from "../../utils/helper";
import {
  IChatSidebarProps,
  IReceiverdata,
  INewMessageResponse,
  IUnreadMessageParams,
} from "../../utils/interfaceModel";

import PubNub from "pubnub";
import { isEmpty, map, orderBy } from "lodash";

import { UserCommonStyles } from "../../styles/CommonStyles";
import { SidebarStyles } from "./Sidebar";
import ChatFriendList from "../commonModal/FriendListChatModal";
import Status from "../../screens/chat/status";

const pubnubPublishKey = process.env.REACT_APP_PUBNUB_PUBLISH_KEY;
const pubnubSubscribeKey = process.env.REACT_APP_PUBNUB_SUBSCRIBE_KEY;

const ChatSidebar: React.FC<IChatSidebarProps> = (props) => {
   console.log("props",props)

  const { t: translation } = useTranslation();
  const commonStyle = UserCommonStyles();
  const styleClasses = SidebarStyles();
  const dispatch = useDispatch();
  const authData = useSelector(
    (state: RootStateOrAny) => state.authReducer.authData
  );
  const [allReceivers1, setAllReceivers1] = useState<IReceiverdata[]>([]);
  const [allReceivers, setAllReceivers] = useState<IReceiverdata[]>([]);
  const [warning,setwarning]=useState("")
  const [filteredReceivers, setFilteredReceivers] = useState<IReceiverdata[]>(
    []
  );
  const [unreadMessage, setUnreadMessage] = useState<any>();
  const [selectedUser, setSelectedUser] = useState<number>(0);
  const [getReceiversTime,setGetReceiversTime]=useState<any>();
  const [loading,setLoading]=useState(false)
  const selectedUserRef = useRef<number>();
  const userRef=useRef<number>()
  const [online,setonline]=useState<any>([])
  const [render,setRender]=useState(false)



  const [showModalShare, setShowShareModal] = useState(false);
  selectedUserRef.current = selectedUser;
  const [update, setUpdate] = useState<number>(0);
  const updateRef = useRef<number>();
  updateRef.current = update;

  const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
    "& .MuiBadge-badge": {
      border: `2px solid ${theme.palette.background.paper}`,
      padding: "0 4px",
      backgroundColor: "#44b700",
      width: "12px",
      height: "12px",
      borderRadius: "100px",
    },
  }));

  /**
   * Configure Pubnub with the keys
   * Used useMemo hook because the const is in the dependancy array of the useEffect and putting this outside the useMemo can make unnecessory renders
   */
  const pubnub = useMemo(() => {
    const pubnub_config = {
      publishKey: pubnubPublishKey,
      subscribeKey: pubnubSubscribeKey,
      uuid: authData?.email,
      logVerbosity: false,
      authKey: authData?.token,
      ssl: true,
    };
    return new PubNub(pubnub_config);
  }, [authData?.email, authData?.token]);

  /**
   * Get all receivers
   */
  const getReceivers = useCallback(async () => {

    setLoading(true)
    const response: any = await dispatch(getAllChats(authData?.id));
    
    if (response && response?.isFound) {
      console.log("responseDATAAAAAAAAAAA",response?.data)
      await setAllReceivers1(response?.data);
      
      setLoading(false)

    }
    else
    {
      setwarning("No Record Found")
      setLoading(false)

    }
  }, [authData?.id, dispatch]);
  
  
  const getAllonlineUser= () => {
    console.log("CHATAPPPPPP")
    if(render)
    {
      setRender(false)

    }
    else
    {
      setRender(true)
    }
    // setLoading(false)
  // setAllReceivers(reciever)
  }


 
 


  useEffect(() => {
    // updateSetTime()  
    console.log("CASE1")
    getReceivers();    
   
  }, [getReceivers]);




  useEffect(() => {
  
    (async () => {
      console.log("CASE2")

      // Getting all channels
      const channels: string[] = map(allReceivers1, "channel");
      let reciever = [...allReceivers1];
     
  
      // Subscribe pubnub service for all channels
      await pubnub.subscribe({
        channels,
          withPresence: true,

        
      });
   
      // event listener defination
      const listener = {
        presence: function(event) {
          console.log("ONLINEEEEEE")
          getAllonlineUser()
        },
        message: async function (response: INewMessageResponse) {
       
          // if (
          //   response?.message?.id !== authData?.id 
          // ) {
            
            // Setting latest message to a state variable for showing unread message dot for the user locally
            setUnreadMessage(response);
      
          // }
        },
      };
      // Real time event listener
      await pubnub.addListener(listener);
    })();
  },[allReceivers1]);


   
      

useEffect(()=>{
  console.log("CASE3")

  if(allReceivers.length<=0){
    setAllReceivers(allReceivers1)

  }
},[allReceivers1])  



  useEffect(() => {
    


    (async () => {
     

      let result=[] as any;
      try {
          result = await pubnub.objects.getMemberships(
           {
         
            include: {
          customFields: true
        },
       });
       console.log("resu",result)
       
    } catch (status) {
        console.log("operation failed w/ error:", status);
    }
      
      // Fetching message history for all channels
      let temp = [...allReceivers1];
      if (!isEmpty(allReceivers1)) {
        for (const receiver of temp) {
          if(result?.data?.length>0){

            let userIndex: number = result?.data.findIndex(
              (user: any) => user?.channel.id ===receiver?.channel
            );
            pubnub.messageCounts({
              channels: [receiver?.channel as string],
              channelTimetokens: [result?.data[userIndex]?.custom?.lastReadTimetoken]
            }).then((response) => {
              console.log("response",response)
              
                const channelName:any=receiver?.channel
                
                receiver["unread_messages"] = response.channels[channelName] as any
               
                
              console.log("messageCounts",response.channels[channelName])
              }).catch((error) => {
               console.log("ERROR",error)
            }
          );
          }
        
         
          // Fetching message history for individual channel
          await pubnub.history({
            channel: receiver?.channel || "",
            count: 1,
            stringifiedTimeToken: true,
            includeMeta: true,
          }).then((res) => {
              
            if (res.messages[0]) {
              let { type, text, id } = res.messages[0]?.entry;
              receiver["lastMessage"] = `${id === authData.id ? "You:" : ""} ${
                type === "text" ? text : "File"
              }`;
              receiver["lastTime"] = res.messages[0]?.timetoken || "";
            } else {
              receiver["lastTime"] = "0";
            }
           
            
          }).catch((error) => {
           console.log("ERROR",error)
        }
      );

    
        }

        // Sorting the list by last message time
        temp = orderBy(temp, ["lastTime"], ["desc"]);

        // Appending the user in the list if not exist
        if (props?.user?.id) {
          let userIndex: number = temp.findIndex(
            (user: IReceiverdata) => user.id === props?.user?.id
          );
          if (userIndex === -1) {
            temp = [props?.user, ...temp];
          }
        }
        // Setting all receivers list to the state
        userRef.current=selectedUser

        await setAllReceivers([...temp]);
        setFilteredReceivers([...temp]);
      }
    })();
  }, [
   
    allReceivers1,
    unreadMessage,
    selectedUser
  ]);

  /**
   * Change receivers on search
   * @param e text field event
   */
  const onSearch = async (e: React.ChangeEvent) => {
    let target = e.target as HTMLInputElement;
    const text = target.value;
    // setSearchText(text);
    const filteredUsers: any = filteredReceivers.filter((user: any) => {
      const first_name = user?.first_name;
      if (first_name && first_name.toLowerCase().includes(text.toLowerCase()))
        return true;
      else return false;
    });
    setAllReceivers(filteredUsers);
  };

  /**
   * Setting state values for currently selected user, sending data to parent component and updating unread messages to current state
   * @param receiver selected receiver data
   */
  const onSelectUser = useCallback((receiver) => {
    console.log("case5")
    setSelectedUser(receiver.id);
    console.log("reciever",receiver)

    props.callback({
      ...receiver,
    });
    // let unread = { [receiver?.id]: 0, [authData?.id]: 0 };
    // receiver["unread_messages"] = JSON.stringify(unread);
  },[]);

  const onSharePost = async () => {
    setShowShareModal(true);
  };
  const onSharePostClose = () => {
    setShowShareModal(false);
  };

  

  return (
    <Box
      className={`${styleClasses.sidebarScroll} ${styleClasses.SidebarMain}`}
    >
          {console.log("RENDERRR",render)}

      <Box className={`${commonStyle.flexCenter} ${commonStyle.justifySpaceBetween}`}>
      <Typography variant="h6">
        {translation("chats")}
      </Typography>
      <Box className={`${commonStyle.flexCenter} ${commonStyle.cursorA}`} onClick={() => onSharePost()}>
      
      <Groups  />
      <Typography variant="h6" className={`${commonStyle.ml4} `}>
         {translation("Friends")}
      </Typography>
      </Box>
      </Box>
   
      

      <TextField
        fullWidth
        placeholder={translation("search_message")}
        variant="outlined"
        className={styleClasses.chatSearchbar}
        size="small"
        onChange={(e) => onSearch(e)}
      />

      <Box>
        <List className={`${styleClasses.hoverList} ${styleClasses.rightList}`}>
          {allReceivers.length
            ? allReceivers.map((receiver: IReceiverdata, index: number) => {
             
                const unreadMessages=typeof(receiver.unread_messages)==="number"?receiver.unread_messages:null
                 
                return (
                  <ListItem
                    className={`${styleClasses.listAvatarSide} ${
                      commonStyle.p0
                    } ${commonStyle.cursorA} ${commonStyle.px10} ${
                      receiver.id === selectedUser ? styleClasses.active : ""
                    }`}
                    key={index}
                    onClick={() => onSelectUser(receiver)}
                  >
                    <Status
                    channel={receiver?.channel}
                    Image={receiver?.profile_image}
                    render={render}

                    />
                
                    <ListItemText
                      primary={`${capitalize(
                        receiver?.first_name
                      )} ${capitalize(receiver?.last_name)}`}
                      secondary={receiver?.lastMessage}
                      className={styleClasses.avatarText}
                    />
                    {unreadMessages && userRef.current!==receiver.id &&  props?.user?.channel!==receiver.channel && (receiver?.lastMessage)?.split(":")[0]!=="You"? (
                      <p                         className={styleClasses.messageNotifyNew}
                      >{receiver.unread_messages}</p>
                    
                    ) : null}
                  </ListItem>
                );
              })
            :loading
            ?translation('loading'):warning}
        </List>
      </Box>
      {showModalShare ? (
        <ChatFriendList
          showModal={showModalShare}
          sending={props}
          close={onSharePostClose}
        />
      ) : null}
    </Box>
    
  );
};

export default ChatSidebar;
