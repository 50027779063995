import {
  useEffect,
  useRef,
  useState,
  useMemo,
  useCallback,
  ChangeEvent,
} from "react";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { useDispatch, RootStateOrAny, useSelector } from "react-redux";
import Picker, { SKIN_TONE_MEDIUM_DARK } from "emoji-picker-react";
import ClipLoader from "react-spinners/ClipLoader";

import {
  Avatar,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import Popover from "@mui/material/Popover";
import { Box } from "@mui/system";
import {
  AttachFile,
  Audiotrack,
  CancelRounded,
  HighlightOff,
  InsertDriveFile,
  InsertPhoto,
  Send,
  SentimentSatisfiedOutlined,
  VideoLibrary,
} from "@mui/icons-material";

import {
  getChatChannel,
  uploadFile,
  setUnreadMessages,
} from "../../redux/action-creators/chatActions";

import BasicModal from "../../components/commonModal/CommonModal";
import ChatSidebar from "../../components/sidebars/ChatSidebar";
import MessageBox from "../../components/chatTools/messageBox";

import fileImage from "../../images/file.png";

import { toast } from "../../utils/toastsMessage";
import {
  IReceiverdata,
  INewMessageResponse,
  IMessageHistoryCallbackStatus,
  IUnreadMessageParams,
  IMessage,
  IMessageActionCallbackResponse,
  IMessageHistoryResponse,
} from "../../utils/interfaceModel";
import { capitalize, FileToBase64, isEmpty } from "../../utils/helper";
import {
  VideoTypes,
  AudioTypes,
  FileTypes,
  PdfTypes,
  ImageExtentions,
  VideoExtentions,
  AudioExtentions,
  DocumentExtentions,
  RoutingLinks,
} from "../../utils/constants";

import { omit } from "lodash";
import PubNub from "pubnub";

import { UserCommonStyles } from "../../styles/CommonStyles";
import { ProfileStyle } from "../profile/ProfileStyle";
import { uploadChatFile } from "../../utils/fileUpload";
import { sendNotification } from "../../redux/action-creators/petPlayAction";

const pubnubPublishKey = process.env.REACT_APP_PUBNUB_PUBLISH_KEY;
const pubnubSubscribeKey = process.env.REACT_APP_PUBNUB_SUBSCRIBE_KEY;

const Chat: React.FC<RouteComponentProps> = (props) => {
  const { t: translation } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const commonStyle = UserCommonStyles();
  const styleClasses = ProfileStyle();
  const textFieldRef = useRef(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [update, setUpdate] = useState<number>(0);
  const updateRef = useRef<number>();
  updateRef.current = update;
  const [channel, setChannel] = useState<string>("");
  const [messages, setMessages] = useState([]);
  const [loadingMessages, setLoadingMessages] = useState(false);
  const [message, setMessage] = useState<string>("");
  const [currentMessage, setCurrentMessage] = useState<IMessage>();
  const [chatImages, setChatImages] = useState<File[]>([]);
  const [chatVideos, setChatVideos] = useState<File[]>([]);
  const [chatOtherFiles, setChatOtherFiles] = useState<File[]>([]);
  const [showEmojies, setShowEmojies] = useState<boolean>(false);
  const [last_seen,setlast_seen]=useState({
    channel:"",
    last_seen:""
  });

  const emojiRef = useRef(null);
  useOutsideAlerter(emojiRef);

  const [userData, setUserData] = useState<IReceiverdata>();
  const [replyMessage, setReplyMessage] = useState<IMessage | null>();

  const [openImageModal, setOpenImageModal] = useState<{
    show: boolean;
    images: string[];
  }>({
    show: false,
    images: [],
  });
  const authData = useSelector(
    (state: RootStateOrAny) => state.authReducer.authData
  );
  const [showPreview, setShowPreview] = useState<boolean>(false);
  /** Media picker popup state handling */
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  /**
   * Configure Pubnub with the keys
   * Used useMemo hook because the pubnub is in the dependancy array of the useEffect and putting this outside the useMemo can make unnecessory renders
   */
  const pubnub = useMemo(() => {
    const pubnub_config = {
      publishKey: pubnubPublishKey,
      subscribeKey: pubnubSubscribeKey,
      uuid: authData?.email,
      logVerbosity: false,
      authKey: authData?.token,
      ssl: true,
    };
    return new PubNub(pubnub_config);
  }, [authData?.email, authData?.token]);

  /**
   * Update the unread messages count for receiver
   * @param data { channel, sender_id, receiver_id, type }
   */
  const updateUnreadMessages = useCallback(
    async (data: IUnreadMessageParams) => {
      console.log("dataaaaaaaaaaaaa",data)
      let result: any = await dispatch(setUnreadMessages(data));
      if (result && result?.isUpdated) {
        setUpdate(updateRef.current + 1);
      }
    },
    [dispatch]
  );



  /**
   * Gets the receiver info from ChatSidebar component and set to state
   * @param userData
   */
  const handleSelectedReceiver = useCallback((userData: IReceiverdata) => {
    setChannel(userData?.channel);
    setUserData(userData);
    setMessages([]);
    setMessage("");
    setLoading(false);
    setShowPreview(false);
    setChatImages([]);
    setChatVideos([]);
    setChatOtherFiles([]);
  }, []);


    /**
   * Update unread messages for the receiver
   */
     useEffect(()=>{
      if(last_seen?.last_seen!=="" && last_seen?.channel!=="")
      {
        (async () => {
          console.log("case8",last_seen,channel)
          try{
            const res=await pubnub.objects.setMemberships({
              channels: [{
                id: last_seen?.channel,
                custom: {
                  lastReadTimetoken: last_seen?.last_seen
                },}]
              });
              console.log("UPDATE",res)
          }catch (status) {
            console.log("operation failed w/ error:", status);
        }
        
          
      
            // await updateUnreadMessages({
            //   channel,
            //   sender_id: authData?.id,
            //   receiver_id: userData?.id,
            //   type: "remove",
            //   last_seen:last_seen?.last_seen
            // });
        })();
      }

     
    
    },[last_seen?.last_seen])

    useEffect(()=>{
     ( async()=>{
        await updateUnreadMessages({
          channel,
          sender_id: authData?.id,
          receiver_id: userData?.id,
          type: "remove",
          last_seen:last_seen?.last_seen
        });
      })();
      
    },[handleSelectedReceiver])

  /** Getting chat channel */
  useEffect(() => {
    // console.log("helllllllllllllllllllllllllllllllllll")
   
    if (props?.location?.state && props?.location?.state["id"]) {
      (async () => {
        let { id, first_name, last_name, profile_image } = props?.location
          ?.state as IReceiverdata;
        const response: any = await dispatch(
          getChatChannel({
            sender_id: authData?.id,
            receiver_id: id,
          })
        );
        if (response?.channelFound) {
          setUserData({
            id,
            first_name,
            last_name,
            profile_image,
            channel: response?.channel,
            lastMessage: "",
            lastTime: "",
            unread_messages: "",
          });
          setChannel(response.channel);
        } else {
          console.error("Chat Channel couldn't be established");
        }
      })();
    }
    // else if(user)
    // {
    //   (async () => {
    //     let { id, first_name, last_name, profile_image } =user
    //       ?.state as IReceiverdata;
    //     const response: any = await dispatch(
    //       getChatChannel({
    //         sender_id: authData?.id,
    //         receiver_id: id,
    //       })
    //     );
    //     if (response?.channelFound) {
    //       setUserData({
    //         id,
    //         first_name,
    //         last_name,
    //         profile_image,
    //         channel: response?.channel,
    //         lastMessage: "",
    //         lastTime: "",
    //         unread_messages: "",
    //       });
    //       setChannel(response.channel);
    //     } else {
    //       console.error("Chat Channel couldn't be established");
    //     }
    //   })();
    // }
  }, [authData?.id, dispatch, props?.location?.state]);

  /**
   *Alert if clicked on outside of element
   */
  function useOutsideAlerter(ref: React.MutableRefObject<any>) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowEmojies(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  /**
   * Subscribing the channel
   */
  useEffect(() => {
  
    let listener;
    (async () => {
      listener = {
        message: function (m: INewMessageResponse) {
          setCurrentMessage({
            timetoken: m.timetoken,
            entry: m.message,
            channel: m?.channel,
          });
        },
      };
      // console.log("current",currentMessage)
      // setlast_seen(currentMessage?.timetoken)
      // Real time input message listener
      await pubnub.addListener(listener);

      //Subscribes to the channel in our state
      await pubnub.subscribe({
        channels: [channel],
      });
    })();
    return function cleanup() {
      pubnub.removeListener(listener);
    };
  }, [channel, pubnub]);

  /** Subscribing chat channel and fetching chat history */
  useEffect(() => {
   
   
    (async () => {
      setLoadingMessages(true);
      let allMessages = [];
      if (channel !== "") {
        // Fetching all messages of the channel
        await pubnub.history(
          {
            channel: channel,
            count: 1000, // 100 is the default
            stringifiedTimeToken: true,
            includeMeta: true, // false is the default
          },
          async function (
            status: IMessageHistoryCallbackStatus,
            response: IMessageHistoryResponse
          ) {
            allMessages = [...response?.messages];
            // console.log("timetokenlastseen",allMessages[allMessages.length-1].timetoken,allMessages)
            setlast_seen({last_seen:allMessages[allMessages.length-1]?.timetoken,channel:channel})
            


            // Receives message actions such as reactions and replies
            await pubnub.getMessageActions(
              {
                channel: channel,
                limit: 100,
              },
              function (
                status: IMessageHistoryCallbackStatus,
                response: IMessageActionCallbackResponse
              ) {
                if (response?.data?.length) {
                  for (const message of allMessages) {
                    response?.data?.map((item) => {
                      if (message?.timetoken === item?.messageTimetoken) {
                        message[item.type] = item.value;
                      }
                      return item;
                    });
                  }
                } else {
                  setLoadingMessages(false);
                }
                setMessages(allMessages);
              }
            );
          }
        );
      }
    })();
  }, [channel, pubnub, update]);

  /** Append the latest message at the end of list */
  useEffect(() => {
   
    (async () => {
      console.log("APPEND")
      if (Object.keys(omit(currentMessage, "channel")).length) {
        if (channel === currentMessage?.channel) {
          console.log("currentMessage",currentMessage)
          setlast_seen({channel:channel,last_seen:currentMessage.timetoken})
          setMessages([...messages, currentMessage]);
        }
      }
    })();
  }, [channel, currentMessage]);

  /**
   * Handling image and other file type previews before sending them
   * @param e // file change event
   */
  const handlePreviewImages = async (
    { currentTarget: { files } }: ChangeEvent<HTMLInputElement>,
    fileType: string
  ) => {
    if (files && files.length !== 0) {
      let filesArray = Array.from(files);
      if (filesArray.length > 10) {
        toast.error(translation("file_size_err"));
        files = null;
      } else {
        handleClose();
        if (fileType === "image") {
          
          setChatImages(filesArray);
        } else if (fileType === "video") {
          setChatVideos(filesArray);
        } else if (fileType === "audio" || fileType === "document") {
          setChatOtherFiles(filesArray);
        }
        setShowPreview(true);
      }
    }
  };

  /**
   * Remove files from preview before sending
   * @param index // array index
   * @param type // file type
   */
  const handleRemoveFileFromPreview = (index: number, type: string) => {
    let files: File[] = [];
    if (type === "chatImages") {
      files = [...chatImages];
      files.splice(index, 1);
      setChatImages([...files]);
    } else if (type === "chatVideos") {
      files = [...chatVideos];
      files.splice(index, 1);
      setChatVideos([...files]);
    } else if (type === "chatOtherFiles") {
      files = [...chatOtherFiles];
      files.splice(index, 1);
      setChatOtherFiles([...files]);
    }
  };

  /**
   * Send text & media messages based on the selection
   * Reply on specific message
   */
  const handleSendMessage = () => {

    let files = chatImages.length || chatVideos.length || chatOtherFiles.length;
    
    // setShowPreview(false);
    if (!files && message) {
       setLoading(true);
      sendMessage(message.trim(), "text", "");
      // setMessage("");
    } else if (files) {
      setLoading(true);
      handleUploadFiles();
    }
   
  };

  /**
   * Convert javascript file object into a base64 string
   * @param file javascript file object
   * @returns base64 string
   */
  const getBase64String = async (file: File) => {
    const result = await FileToBase64(file).catch((e) => Error(e));
    if (result instanceof Error) {
      return;
    }
    return result;
  };

  /**
   * Handling file uploading options by file types and send for upload on s3
   */
  const handleUploadFiles = async () => {
    let files: string[] = [];
    if (chatImages.length) {
      for (let i = 0; i < chatImages.length; i++) {
        const result = (await getBase64String(chatImages[i])) as string;
        files[i] = result;
      }
      uploadToS3AndSendFile(files, "image", message);
    }
    if (chatVideos.length) {
      for (let i = 0; i < chatVideos.length; i++) {
        let ext = chatVideos[i].name.split(".").pop();
        if (VideoTypes.includes(ext)) {
          if (chatVideos[i].size > 41943040) {
            toast.error(translation("file_size_err20"));
          } else {
            const result = (await getBase64String(chatVideos[i])) as string;
            files[i] = result;
          }
        }
      }
      uploadToS3AndSendFile(files, "video", !chatImages.length ? message : "");
    }
    if (chatOtherFiles.length) {
      for (let i = 0; i < chatOtherFiles.length; i++) {
        let ext = chatOtherFiles[i].name.split(".").pop();
        if (AudioTypes.includes(ext)) {
          const result = (await getBase64String(chatOtherFiles[i])) as string;
          files[i] = result;
          uploadToS3AndSendFile(files, "audio", "");
        } else if (FileTypes.includes(ext)) {
          if (chatOtherFiles[i].size > 5120000) {
            toast.error(translation("file_size_err2"));
          } else {
            const result = (await getBase64String(chatOtherFiles[i])) as string;
            files[i] = result;
            uploadToS3AndSendFile(files, "other_document", "");
          }
        } else if (PdfTypes.includes(ext)) {
          if (chatOtherFiles[i].size > 5120000) {
            toast.error(translation("file_size_err2"));
          } else {
            const result = (await getBase64String(chatOtherFiles[i])) as string;
            files[i] = result;
            uploadToS3AndSendFile(files, "pdf", "");
          }
        } else {
          toast.error(translation("file_not_support"));
        }
      }
    }
  };

  /**
   * Method to uploading files to s3 and then send as message
   * @param fd Formdata object
   * @param type File type
   * @param caption Caption with file
   */
  const uploadToS3AndSendFile = async (
    fd: string[],
    type: string,
    caption: string
  ) => {
    //const response:any=await dispatch(uploadFile({files:fd,sender_id:authData?.id}));
    const response: any = await uploadChatFile(fd, authData?.id);
    if (response) {
      //  if(response && response?.isFound){
      sendMessage(JSON.stringify(response), type, caption);
      //sendMessage(JSON.stringify(response?.data), type, caption);
    } else {
      console.error(response?.message);
    }
  };

  /**
   * Handling emoji selection and setting it as message
   * @param event
   * @param emojiObject
   */
  const onEmojiClick = (event, emojiObject) => {
    setMessage(message + " " + emojiObject.emoji);
  };

  /**
   * Method to send message
   * @param text message text or file link
   * @param type message type (text/image/video/audio/pdf/other-documents)
   * @param caption caption with images
   */
  const sendMessage = async (text: string, type: string, caption: string) => {
    if (!isEmpty(text)) {
      await pubnub.publish(
        {
          channel: channel,
          message: {
            text,
            type,
            caption,
            id: authData?.id,
            repliedMessage: replyMessage ? replyMessage : null,
          },
        },
        async function (status, response) {
          if (!status.error) {
            const data = {
              type: "Message",
              id: authData.id,
              content:text.substring(0,20),
              name:authData?.first_name
            
            };
            dispatch(sendNotification(userData.id, data))
            setMessage("");
            setShowEmojies(false);
            setReplyMessage(null);
            setLoading(false);
    setShowPreview(false);
    setChatImages([]);
    setChatVideos([]);
    setChatOtherFiles([]);
            
          }
        }
      );
    } else {
      setMessage("");
      setShowPreview(false);
      setChatImages([]);
      setChatVideos([]);
      setChatOtherFiles([]);
    } // remove space

    // setLoading(false);
    // setShowPreview(false);
    // setChatImages([]);
    // setChatVideos([]);
    // setChatOtherFiles([]);
  };

  /**
   * Handling reply on specific message functionality
   * @param timetoken // Message timetoken
   * @param type // reply type reaction or text reply
   * @param replyMessage // reply text
   */
  const replyOnMessage = async (
    timetoken: string,
    type: string,
    replyMessage: string
  ) => {
    await pubnub.addMessageAction(
      {
        channel: channel,
        messageTimetoken: timetoken,
        action: {
          type,
          value: replyMessage,
        },
      },
      function (status, response) {
        setUpdate(updateRef.current + 1);
        setMessage("");
        setReplyMessage(null);
      }
    );
  };

  /**
   * Method to set the choosen message timetoken and focus the message text box
   * @param message message
   */
  const handleReplyOnMessage = (message: IMessage) => {
    setReplyMessage(message);
    textFieldRef.current.focus();
  };

  /**
   * Gets the receiver info from ChatSidebar component and set to state
   * @param userData
   */
   const handleChatFriendList = useCallback((userData: IReceiverdata) => {
    if(userData)
    {
      (async () => {
        let { id, first_name, last_name, profile_image } =userData

        const response: any = await dispatch(
          getChatChannel({
            sender_id: authData?.id,
            receiver_id: id,
          })
        );
        if (response?.channelFound) {
          setUserData({
            id,
            first_name,
            last_name,
            profile_image,
            channel: response?.channel,
            lastMessage: "",
            lastTime: "",
            unread_messages: "",
          });
          setChannel(response.channel);
        } else {
          console.error("Chat Channel couldn't be established");
        }
      })();
    }
  }, []);

  return (
    <Box>
      <Box className={commonStyle.windowColor}>
        <Grid container>
          <Grid item md={3}>
            <ChatSidebar
              user={{ ...userData,last_seen }}
              callback={handleSelectedReceiver}
              updateList={update}
              FriendList={handleChatFriendList}
              
              // last_seen={last_seen}
            />
          </Grid>

          {userData?.id ? (
            <Grid item md={9}>
              <Box className={`${commonStyle.p20} ${commonStyle.mt20}`}>
                <Box className={`${commonStyle.p20} ${commonStyle.card}`}>
                  <Box
                    className={`${commonStyle.pb8} ${commonStyle.borderBottom}`}
                  >
                    <Box className={commonStyle.wallUserInfo}>
                      <Avatar
                        alt={translation("story_person")}
                        src={userData?.profile_image}
                      />
                      <Box className={commonStyle.ml10}>
                        <Typography
                          variant="h2"
                          className={`${commonStyle.cursorA} ${commonStyle.flexCenter}`}
                          onClick={() =>
                            history.push(
                              `${RoutingLinks.profiles}${userData?.id}`
                            )
                          }
                        >
                          {`${capitalize(userData?.first_name)} ${capitalize(
                            userData?.last_name
                          )}`}
                        </Typography>
                        {/* <Typography>{translation("active_10min")}</Typography> */}
                      </Box>
                    </Box>
                  </Box>
                  {/** Message box component start */}
                  {!isEmpty(messages) ? (
                    <MessageBox
                      messages={messages}
                      userData={userData}
                      handleReplyOnMessage={handleReplyOnMessage}
                      sendEmojiMessage={replyOnMessage}
                      setOpenImageModal={setOpenImageModal}
                    />
                  ) : (
                    <Box className={styleClasses.placeholderBoxMessage}>
                      <Typography>
                        {loadingMessages ? "Loading" : "No message yet"}.....
                      </Typography>
                    </Box>
                  )}

                  {/** Message box component end */}
                  <Box
                    className={`${commonStyle.flexCenter} ${commonStyle.w100}`}
                  >
                    <Box
                      className={`${styleClasses.chatMsgSend} ${commonStyle.w100}`}
                    >
                      {replyMessage ? (
                        <Box className={styleClasses.quottedMessage}>
                          <CancelRounded
                            className={styleClasses.quottedClose}
                            onClick={() => setReplyMessage(null)}
                          />
                          <p>
                            <span>"</span> {replyMessage.entry.text}
                          </p>
                        </Box>
                      ) : null}
                      {showPreview ? (
                        <ul className={styleClasses.showImageBox}>
                          {/** Videos preview start */}
                          <>
                            {chatVideos.length
                              ? chatVideos.map((item: File, index: number) => (
                                  <li
                                    key={index}
                                    className={
                                      loading ? commonStyle.disabled : ""
                                    }
                                  >
                                    <video controls key={index}>
                                      <source src={URL.createObjectURL(item)} />
                                    </video>
                                    <HighlightOff
                                      onClick={() =>
                                        handleRemoveFileFromPreview(
                                          index,
                                          "chatVideos"
                                        )
                                      }
                                    />
                                  </li>
                                ))
                              : null}
                            {/** Videos preview end */}

                            {/** Images preview start */}
                            {chatImages.length
                              ? chatImages.map((item: File, index: number) => (
                                  <li
                                    key={index}
                                    className={
                                      loading ? commonStyle.disabled : ""
                                    }
                                  >
                                    <img
                                      src={URL.createObjectURL(item)}
                                      alt={translation("image_show")}
                                    />
                                    <HighlightOff
                                      onClick={() =>
                                        handleRemoveFileFromPreview(
                                          index,
                                          "chatImages"
                                        )
                                      }
                                    />
                                  </li>
                                ))
                              : null}
                            {/** Images preview end */}

                            {/** Other files preview start */}
                            {chatOtherFiles.length
                              ? chatOtherFiles.map(
                                  (item: File, index: number) => (
                                    <li
                                      key={index}
                                      className={styleClasses.lastFile}
                                    >
                                      <img
                                        src={fileImage}
                                        alt={translation("image_show")}
                                      />
                                      <HighlightOff
                                        onClick={() =>
                                          handleRemoveFileFromPreview(
                                            index,
                                            "chatOtherFiles"
                                          )
                                        }
                                      />
                                    </li>
                                  )
                                )
                              : null}
                          </>

                          {/** Other files preview start */}
                          {/*  
                      <li>
                        <img src={defaultUserprofile} alt="imageShow" />
                        <p>+10</p>
                      </li> */}
                        </ul>
                      ) : null}

                      <TextField
                        inputRef={textFieldRef}
                        placeholder={translation("write_message")}
                        size="small"
                        fullWidth
                        multiline
                        disabled={loading}
                        value={message}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <SentimentSatisfiedOutlined
                                onClick={() =>
                                  setShowEmojies(showEmojies ? false : true)
                                }
                                className={commonStyle.cursorA}
                              />

                              {/** Emoji Message Popup Start */}
                              {showEmojies ? (
                                <div ref={emojiRef}>
                                  <Picker
                                    onEmojiClick={onEmojiClick}
                                    // skinTone={SKIN_TONE_MEDIUM_DARK}
                                    // pickerStyle={{ width: "100%" }}
                                  />
                                </div>
                              ) : null}
                              {/** Emoji Message Popup Start */}

                              <IconButton
                                className={styleClasses.chatSendMsg}
                                onClick={handleClick}
                              >
                                <AttachFile />
                                {/* <input
                                  type="file"
                                  onChange={(e) => handlePreviewImages(e)}
                                  name="chat_image"
                                  multiple
                                  accept={AcceptedExtensionsInChat}
                                /> */}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        onChange={(
                          event: ChangeEvent<
                            HTMLInputElement | HTMLTextAreaElement
                          >
                        ) => setMessage(event.target.value)}
                        // onKeyUp={(e: React.KeyboardEvent<object>) => {
                        //   e.keyCode === 13 && handleSendMessage();
                        // }}
                      />
                    </Box>
                    <IconButton
                      disabled={loading}
                      className={commonStyle.ml10}
                      onClick={() => handleSendMessage()}
                    >
                      {loading ? <ClipLoader /> : <Send />}
                    </IconButton>
                  </Box>
                </Box>
              </Box>

              {/** Media file selection popover starts */}
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <ul className={styleClasses.mediaIconPopup}>
                  <li>
                    <InsertPhoto />
                    Image
                    <input
                      type="file"
                      onChange={(e) => handlePreviewImages(e, "image")}
                      name="chat_image"
                      multiple
                      accept={ImageExtentions}
                    />
                  </li>
                  <li>
                    <VideoLibrary />
                    Video
                    <input
                      type="file"
                      onChange={(e) => handlePreviewImages(e, "video")}
                      name="chat_image"
                      multiple
                      accept={VideoExtentions}
                    />
                  </li>
                  <li>
                    <Audiotrack />
                    Audio
                    <input
                      type="file"
                      onChange={(e) => handlePreviewImages(e, "audio")}
                      name="chat_image"
                      multiple
                      accept={AudioExtentions}
                    />
                  </li>
                  <li>
                    <InsertDriveFile />
                    Document
                    <input
                      type="file"
                      onChange={(e) => handlePreviewImages(e, "document")}
                      name="chat_image"
                      multiple
                      accept={DocumentExtentions}
                    />
                  </li>
                </ul>
              </Popover>
              {/** Media file selection popover ends */}

              {/** Image view modal start */}
              {openImageModal?.show ? (
                <BasicModal
                  open={openImageModal?.show}
                  onClose={() => setOpenImageModal({ show: false, images: [] })}
                  images={openImageModal?.images}
                />
              ) : null}
              {/** Image view modal start */}
            </Grid>
          ) : null}
        </Grid>
      </Box>
    </Box>
  );
};

export default Chat;
