import { makeStyles } from "@mui/styles";

export const ProductStyles = makeStyles((theme) => {
  return {
    selectProductBg: {
      borderRadius: "100px",
      backgroundColor: "#01B4BC",
      "& label": {
        color: "#fff",
      },

      "& div": {
        marginTop: "0 !important",
        minHeight: "52px",
        color: "#fff",
        paddingLeft: "10px",
        "&::before": {
          display: "none",
        },
        "&::after": {
          display: "none",
        },
        "& svg": {
          color: "#fff",
        },
        "&:focus": {
          backgroundColor: "transparent",
          borderColor: "transparent",
        },
        "& fieldset": {
          display: "none",
        },
      },
    },

    selectLostBg: {
      borderRadius: "10px",
      backgroundColor: "#D95557",
      "& label": {
        color: "#fff",
      },
      "& div": {
        marginTop: "0 !important",
        minHeight: "45px",
        color: "#fff",
        paddingLeft: "10px",
        "&::before": {
          display: "none",
        },
        "&::after": {
          display: "none",
        },
        "& svg": {
          color: "#fff",
        },
        "&:focus": {
          backgroundColor: "transparent",
        },
      },
    },

    productCardBox: {
      border: "1px solid #979797",
      padding: "30px",
      boxShadow: "0px 1px 4px #0000001A",
      borderRadius: "10px",
      margin: "30px 0",
      "& p": {
        marginTop: "10px",
        fontWeight: 500,
        fontSize: "16px",
      },
      "& span": {
        fontSize: "14px",
        opacity: 0.9,
      },
    },

    productCard: {
      // boxShadow: "0px 1px 4px #0000001A",
      borderRadius: "13px",
      overflow: "hidden",
      display: "flex",
      // padding: "20px",
      margin: "10px",
      "& img": {
        width: "100%",
        height: "230px",
        objectFit: "cover",
        objectPosition: "center",
      },
    },

    //product detail card style ------------
    productDetailCard: {
      border: "1px solid #979797",
      borderRadius: "10px",
      "& img": {
        width: "100%",
        height: "200px",
        objectFit: "cover",
        objectPosition: "center",
      },
    },

    productCardImg: {
      height: "450px !important",
    },

    combineBox: {
      boxShadow: "0px 1px 4px #0000001A",
      borderRadius: "10px",
      padding: "20px",
    },
  };
});
