import { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";

import { Search } from "@mui/icons-material";
import {
  FormControl,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import OutlinedInput from "@mui/material/OutlinedInput";

import { listAdoption } from "../../redux/action-creators/adoptionAction";

import LeftSidebar from "../../components/sidebars/LeftSidebar";

import { RoutingLinks, Categories } from "../../utils/constants";
import { capitalize } from "../../utils/helper";

import defaultUserprofile from "../../images/defaultUserprofile.png";
import { CommonButton } from "../../stories/Button";

import { ProductStyles } from "../products/ProductsStyle";
import { UserCommonStyles } from "../../styles/CommonStyles";

const Adoption = () => {
  const { t: translation } = useTranslation();
  const commonStyle = UserCommonStyles();
  const styleClasses = ProductStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  
  
  const [loading, setLoading] = useState<boolean>(false);
  // const [userList, setUserList] = useState([]);
  const [search, setsearch] = useState({ searchtext: "" });
  const [breed, setbreed] = useState({ searchbreed: "" });
  const [types, setType] = useState({ searchType: "" });
  const [loader, setloader] = useState(true);

  const [state, setState] = useState({
    userList: [],
    offset: 0,
    prevOffset: 0,
    hasMore: true,
  });

  const [productCallTimeOut, setProductCallTimeOut] = useState<any>();

  /**
   * To get the list of Adoption
   * @param firstload
   * @param searchValue
   * @param typeValue
   * @param breedValue
   */
  const getList = 
    async (firstLoad:boolean,searchValue?: string, typeValue?: string, breedValue?: string) => {
  
      setloader(true);
      let offset =  firstLoad ?  0 :  state.offset
      let searchItem = searchValue!==undefined ? searchValue : search.searchtext;
      let breedSearch = breedValue!==undefined ? breedValue : breed.searchbreed;
      let typeSearch = typeValue!==undefined ? typeValue : types.searchType;

      if (state.offset !== state.prevOffset || firstLoad) {
        setLoading(true);
      let result: any = await dispatch(
        listAdoption(searchItem, typeSearch, breedSearch,offset)
      );
  
      setloader(false);
      if (result && result.length > 0) {
        for (let i = 0; i < result.length; i++) {
          let data = result[i]?.image;
          data = JSON.parse(data);
          result[i].image = data[0];
        }
       setState((prevState) => ({
      ...prevState,
      prevOffset: firstLoad ? 0 : prevState.offset,
      offset: firstLoad ? 10 : prevState.offset + 10,
      userList: firstLoad
        ? result
        : prevState.userList.concat(result),
      hasMore: true,
    }));
      } else {
        if(result){
          setState((prevState) => ({
            ...prevState,
            userList: firstLoad
              ? result
              : prevState.userList.concat(result),
            hasMore: false,
          }));
        }
        }        
    }
    setLoading(false)
  }
  

  useEffect(() => {
    getList(true,"");
  }, []);

const commonListCall=(firstLoad:boolean,searchValue?: string, typeValue?: string, breedValue?: string)=>
{
  console.log(searchValue,'searchValue')
  if (productCallTimeOut) {
    clearTimeout(productCallTimeOut);
  }
  const timeoutValue = setTimeout(() => {
    console.log(searchValue,'searchValue1')
  getList(firstLoad,searchValue, typeValue, breedValue);
}, 1500);
setProductCallTimeOut(timeoutValue);
}

  /**
   * Search Based On the categories of Pets
   * @param event
   */
  const handleChange = (event: SelectChangeEvent) => {
    const typetext = event.target.value;
    if(event.target.value) { 
    setType((prevState) => ({
      ...prevState,
      searchType: typetext,
    }));
    setState((prevState) => ({
      ...prevState,
      prevOffset: 0,
      offset: 0,
    }));
    
    getList(true,search.searchtext, typetext, breed.searchbreed);
  }
  };

  /**
   * Search Based on Name
   * @param event
   */
  const handleSearchChange = (event: React.ChangeEvent) => {
    let target = event.target as HTMLInputElement;
    const searchtext = target.value;
     
     setsearch((prevState) => ({
      ...prevState,
      searchtext: searchtext,
    }));
    setState((prevState) => ({
      ...prevState,
      prevOffset: 0,
      offset: 0,
    }));

    commonListCall(true,searchtext, types.searchType, breed.searchbreed)
      
  

  };

  /**
   * search Based On the Breed of Pets
   * @param event
   */
  const handlebreedChange = (event: React.ChangeEvent) => {
    let target = event.target as HTMLInputElement;

    const breedtype = target.value;
  
    setbreed((prevState) => ({
      ...prevState,
      searchbreed: breedtype,
    }));
    setState((prevState) => ({
      ...prevState,
      prevOffset: 0,
      offset: 0,
    }));
    

    commonListCall(true,search.searchtext, types.searchType, breedtype)
  
  };

  return (
    <Box>
      <Box className={commonStyle.windowColor}>
        <Grid container>
          <Grid item md={3}>
            <LeftSidebar />
          </Grid>

          <Grid item md={9}>
            <Box className={commonStyle.p20}>
              <Box
                className={`${commonStyle.flexCenter} ${commonStyle.justifySpaceBetween} ${commonStyle.mb15} ${commonStyle.mt20}`}
              >
                <Typography
                  variant="h6"
                  className={`${commonStyle.grey} ${commonStyle.fontWeight600}`}
                >
                  {translation("adoption")}
                </Typography>

                <Box className={`${commonStyle.flexCenter}`}>
                  <Box className={`${commonStyle.orangeBtn}`}>
                    <CommonButton
                      label={translation("list_yours")}
                      onClick={() =>
                        history.push(`${RoutingLinks.adoptionList}`)
                      }
                    />
                  </Box>
                </Box>
              </Box>
              <Box className={`${commonStyle.p20} ${commonStyle.card}`}>
                <Grid container spacing={2}>
                  <Grid item md={6}>
                    <Box className={`${commonStyle.tagMain} ${commonStyle.m0}`}>
                      <Box className={`${commonStyle.searchTag}`}>
                        <TextField
                          placeholder={translation("search")}
                          variant="standard"
                          onChange={(e) => handleSearchChange(e)}
                        />
                        <Search />
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item md={3}>
                    <FormControl
                      fullWidth
                      className={styleClasses.selectProductBg}
                    >
                      <Select
                        displayEmpty
                        value={types.searchType}
                        onChange={handleChange}
                        input={<OutlinedInput />}
                        renderValue={(selected) => {
                          if (selected.length === 0) {
                            return <>{translation("Type_of_Pet")}</>;
                          }
                          return translation(selected);
                        }}
                      >
                        <MenuItem disabled value="">
                          <>{translation("Type_of_Pet")}</>
                        </MenuItem>
                        {Categories.map((name) => (
                          <MenuItem key={name} value={(name)}>
                            {translation(name)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item md={3}>
                    <TextField
                      onChange={(e) => handlebreedChange(e)}
                      placeholder={translation("breed")}
                      fullWidth
                      className={commonStyle.blueInput}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
                <InfiniteScroll
                dataLength={state?.userList?.length}
                next={() => getList(false)}
                hasMore={state?.hasMore}
                loader={(() => {
                  if (loading) {
                    return <div className="loaing-area">Loading...</div>;
                  }
                })()}
                scrollThreshold="100px" 
                >
                <Box className={styleClasses.productCardBox}>
                  <Grid container spacing={2}>
                    {state.userList.length > 0
                      ? state.userList.map((item, index) => (
                          <Grid
                            key={index}
                            item
                            md={3}
                            className={commonStyle.textCenter}
                          >
                            <Box className={styleClasses.productCard}>
                              <img
                                src={
                                  item.image ? item.image : defaultUserprofile
                                }
                                alt={translation("image")}
                              />
                            </Box>
                            <Typography>{capitalize(item.name)}</Typography>
                            <Box
                              className={`${commonStyle.blueBtn} ${commonStyle.mt10}`}
                            >
                              <CommonButton
                                label={translation("view_details")}
                                onClick={() =>
                                  history.push(
                                    `${RoutingLinks.adoptionDetail}${item?.id}`
                                  )
                                }
                              />
                            </Box>
                          </Grid>
                        ))
                      : loader
                      ? translation("loading")
                      : translation("missing_record")}
                  </Grid>
                </Box>
              </InfiniteScroll>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Adoption;
