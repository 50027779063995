import Dropzone from "react-dropzone";
import { Controller, useForm } from "react-hook-form";
import ClipLoader from "react-spinners/ClipLoader";

import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";

import { Grid, MenuItem, Select, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Button } from "@mui/material";
import { HighlightOff, AttachMoneyOutlined } from "@mui/icons-material";

import {
  getAllProductCategories,
  getAllProductCategoriesByCategoryId,
  getAllProductCategoriesBySubCategoryId,
  getProductInfoById,
  updateProduct,
} from "../../redux/action-creators/productAction";

import LeftSidebar from "../../components/sidebars/LeftSidebar";

import { toast } from "../../utils/toastsMessage";
import { IAddProduct } from "../../interfaceModules/IPetsInteface";
import { createProductValidationSchema } from "../../utils/validationSchema";
import { Availability, ImgTypeArray, Region, RoutingLinks } from "../../utils/constants";

import { CommonButton } from "../../stories/Button";

import { UserCommonStyles } from "../../styles/CommonStyles";

import { yupResolver } from "@hookform/resolvers/yup";
import imageCompression from "browser-image-compression";
import { setDefaultNamespace } from "i18next";
import { setGlobalRender } from "@storybook/client-api";

const EditProduct = (props: any) => {
  // const Data = props.location.state.product
  const id = Number(useParams<{ id: string }>().id);
  const [Data,setData]=useState<any>()




  const { t: translation } = useTranslation();
  const [imageError, setimageError] = useState("");
  const commonStyle = UserCommonStyles();
  const dispatch = useDispatch();
  const [imageFiles, setImageFiles] = useState([]);
  const [loader,setLoader]=useState(false)


  const productCategoriesList = useSelector(
    (state: RootStateOrAny) => state.productReducer.productCategoryList
  );
  const [productCategories, setProductCategories] = useState([
    ...productCategoriesList,
  ]);
  const [productCategoryId, setProductCatgoryId] = useState({
    categoryId: 0,
    subCategoryId: 0,
    subProductCategoryId: 0
  });
  const [productSubCategories, setProductSubCategories] = useState([]);
  const [productSubSubCategories, setProductSubSubCategories] = useState([]);
  const [disableSubmitButton, setDisableSubmitButton] = React.useState(false);
  let userData = localStorage.getItem("userData");
  userData = userData ? JSON.parse(userData) : {};
  const [userInfo] = useState(JSON.parse(JSON.stringify(userData)));
  const {
    handleSubmit,
    register,
    control,
    reset,
    formState: { errors },
  } = useForm<IAddProduct>({
    resolver: yupResolver(createProductValidationSchema(translation)),
  });



  /**
   * get list of product categories
   */
  const getProductCategoriesList = useCallback(async () => {
    const response: any = await dispatch(getAllProductCategories());
    if (response && !response?.data?.isError) {
      setProductCategories(response.data);
    }
  }, [dispatch]);


  /**
   * get list of product categories
   */
  const selectCategory = useCallback(async (id: number) => {
    console.log("Caetgory Id---", id);
    const response: any = await dispatch(getAllProductCategoriesByCategoryId(id));

    if (response && !response?.data?.isError) {
      setProductSubCategories(response.data);
      setProductCatgoryId((prevState) => ({
        ...prevState,
        categoryId: id,
      }));
    }
  }, [dispatch]);

  /**
     * get list of product categories
     */
  const selectSubCategory = useCallback(async (id: number) => {
    console.log("Caetgory Id---", id);
    const response: any = await dispatch(getAllProductCategoriesBySubCategoryId(id));

    if (response && !response?.data?.isError) {
      setProductSubSubCategories(response.data);
      setProductCatgoryId((prevState) => ({
        ...prevState,
        subCategoryId: id
      }));
    }
  }, [dispatch]);



  /**
   * set list of product category id
   */
  const selectSubSubProductCategory = (id: number) => {
    setProductCatgoryId((prevState) => ({
      ...prevState,
      subProductCategoryId: id
    }));

  };
  /**
   * remove image
   * @param index
   */
  const removeImage = (index: number) => {
    const remove = [...imageFiles];
    remove.splice(index, 1);

    setImageFiles(remove);
  };

  /**
   * handle change
   * @param file
   */
  const handleChange = async (file) => {
    if (file.length > 0) {
      for (let i = 0; i < file.length; i++) {
        if (file[i].type !== undefined) {
          const imgType = file[i].type.split("/");
          const MimeTypeArray = ImgTypeArray;
          if (imgType[1] !== undefined) {
            if (MimeTypeArray.includes(imgType[1])) {
              if (file && file[i]) {
                const img = file[i];
                const options = {
                  maxSizeMB: 1,
                  maxWidthOrHeight: 720,
                  useWebWorker: true,
                };
                try {
                  const compressImg = await imageCompression(
                    img,
                    options
                  );
                  readFile(compressImg);
                } catch (e) {
                  console.error("Error while compressing image : ", e);
                }

              }
            }
            else {
              toast.error(translation("file_not_support_img"));
            }
          }
        }
      }
    }
  };

  /**
   * read file
   * @param file
   */
  const readFile = async (file: Blob) => {
    const fileReader = new FileReader();

    fileReader.onloadend = function () {
      if (imageFiles.length > 0) {
        setImageFiles((prevState) => [...prevState, fileReader?.result]);
      } else {
        setImageFiles((prevState) => [...prevState, fileReader?.result]);
      }
    };

    fileReader.readAsDataURL(file);
  };

  /**
   * cancel products
   */
  const cancelProduct = () => {
    setImageFiles([]);
    reset({});

  };

  /**
   * submit data
   * @param data
   * @returns
   */
  const onSubmit = async (data: IAddProduct) => {
    setDisableSubmitButton(true);
    if (imageFiles.length === 0) {
      setimageError(translation("img_req"));
      setDisableSubmitButton(false);
    }

    if (imageFiles.length > 0) {
      const response: any = await dispatch(
        updateProduct({ data, imageFiles, productCategoryId }, id, userInfo.seller_id, userInfo.id)
      );
      if (response && response?.data?.isUpdated) {
        setDisableSubmitButton(false);
        props.history.push({pathname:RoutingLinks.MyProduct + `${userInfo.seller_id}`
  
        });
        return toast.success(translation(response.message));
      }
      else {
        setDisableSubmitButton(false);
        return toast.success(translation(response.message));


      }
    }
  };

  useEffect(() => {
    if(Data && Data.id){
      if (Data && Data?.gallery_images !== "") {
        setImageFiles([])
  
        let data = Data?.gallery_images;
        const result = JSON.parse(data);
  
  
        for (let i = 0; i < result.length; i++) {
  
  
  
          try {
            setImageFiles((prevState) => ([
              ...prevState,
              result[i]
            ]))
  
  
          } catch (error) {
            console.log("error", error)
          }
  
        }
  
  
      }
      if ((Data?.categoryNm.split((",")).length >= 3) && (Data?.categoryNm.split((","))[2] !== "")) {
  
        getProductCategoriesList();
        selectCategory(Data?.categoryNm.split((","))[2].split("#")[1])
        selectSubCategory((Data?.categoryNm.split(","))[1].split("#")[1])
      }
      else if (Data?.categoryNm.split((","))[1] !== "") {
        getProductCategoriesList();
        selectCategory((Data?.categoryNm.split(","))[1].split("#")[1])
        selectSubCategory((Data?.categoryNm.split(","))[0].split("#")[1])
      }
      else {
        getProductCategoriesList();
        selectCategory((Data?.categoryNm.split(","))[0].split("#")[1])
  
      }
    }

 


  }, [Data?.id]);

 const getProductData=async ()=>{
  
  setLoader(true)
  let result: any = await dispatch(getProductInfoById(id,userInfo.seller_id));
 
  if(result && result.length>0)
  {
    setData(result[0])
    setLoader(false)

  }
  else{
    setLoader(false)
    return toast.error("No Record Found")
 
  }




 } 


  useEffect(() => {
    getProductData();
  }, [id]);

  return (
    <Box>
      <Grid container>
        <Grid item md={3}>
          <LeftSidebar />
        </Grid>

        <Grid item md={9}>
          <Box className={commonStyle.p20}>
            <Typography
              variant="h6"
              className={`${commonStyle.grey} ${commonStyle.mb15} ${commonStyle.fontWeight600}`}
            >
              {translation("edit_product")}
            </Typography>
            {Data && Data?.id?
            <Box className={`${commonStyle.p20} ${commonStyle.card}`}>
              <form onSubmit={handleSubmit(onSubmit)} noValidate>
                <Grid container spacing={2}>
                  <Grid item md={6}>
                    <Box className={commonStyle.commonInput}>
                      <Typography className={commonStyle.labelCommonInput}>
                        {translation("product_title")}
                      </Typography>
                      <TextField
                        {...register("name")}
                        defaultValue={Data?.name}
                        variant="outlined"
                        className={commonStyle.w100}
                      />
                      {errors && errors.name && (
                        <span className={commonStyle.errorMsg}>
                          {errors?.name?.message}
                        </span>
                      )}
                    </Box>
                  </Grid>

                  <Grid item md={6}>
                    <Box className={commonStyle.commonInput}>

                      <Typography className={commonStyle.labelCommonInput}>
                        {translation("Type_of_Pet")}
                      </Typography>
                      <Select
                        {...register("category")}
                        defaultValue={Data?.categoryNm.split((",")).length >= 3 && Data?.categoryNm.split((","))[2] !== "" ? Data?.categoryNm.split((","))[2].split("#")[0] : Data?.categoryNm.split((","))[1] !== "" ? (Data?.categoryNm.split(","))[1].split("#")[0] : (Data?.categoryNm.split(","))[0].split("#")[0]}
                        className={commonStyle.w100}
                      >
                        {productCategories.length > 0 &&
                          productCategories?.map((productCategory, index) => {
                            return (
                              <MenuItem
                                key={index}
                                value={productCategory.name}
                                onClick={() => selectCategory(productCategory.id)}
                              >
                                {productCategory.name}
                              </MenuItem>
                            );
                          })}
                      </Select>
                      {errors && errors.category && (
                        <span className={commonStyle.errorMsg}>
                          {errors?.category?.message}
                        </span>
                      )}
                    </Box>
                  </Grid>

                  <Grid item md={6}>
                    <Box className={commonStyle.commonInput}>
                      <Typography className={commonStyle.labelCommonInput}>
                        {translation("category")}
                      </Typography>
                      <Select
                        className={commonStyle.w100}
                        defaultValue={Data?.categoryNm.split((","))[2] !== "" ? Data?.categoryNm.split((","))[1].split("#")[0] : Data?.categoryNm.split((","))[1] !== "" ? (Data?.categoryNm.split(","))[0].split("#")[0] : ""}

                      >
                        {productSubCategories.length > 0 &&
                          productSubCategories?.map((productCategory, index) => {
                            return (
                              <MenuItem
                                key={index}
                                value={productCategory.name}
                                onClick={() => selectSubCategory(productCategory.id)}
                              >
                                {productCategory.name}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </Box>
                  </Grid>

                  {productSubSubCategories.length > 0 && (
                    <Grid item md={6}>
                      <Box className={commonStyle.commonInput}>
                        <Typography className={commonStyle.labelCommonInput}>
                          Sub Category
                        </Typography>
                        <Select
                          className={commonStyle.w100}
                          defaultValue={Data?.categoryNm.split((","))[2] !== "" ? Data?.categoryNm.split((","))[0].split("#")[0] : Data?.categoryNm.split((","))[1] !== "" ? (Data?.categoryNm.split(","))[0].split("#")[0] : ""}
                          disabled={productSubSubCategories.length === 0}
                        >
                          {productSubSubCategories.length > 0 &&
                            productSubSubCategories?.map((productCategory, index) => {
                              return (
                                <MenuItem
                                  key={index}
                                  value={productCategory.name}
                                  onClick={() => selectSubSubProductCategory(productCategory.id)}
                                >
                                  {productCategory.name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </Box>
                    </Grid>
                  )}

                  < Grid item md={12}>
                    <Box className={commonStyle.commonInput}>
                      <Typography className={commonStyle.labelCommonInput}>
                        {translation("product_des")}
                      </Typography>
                      <TextField
                        {...register("description")}
                        defaultValue={Data?.description}
                        multiline
                        variant="outlined"
                        className={commonStyle.w100}
                        rows={5}
                        placeholder={translation("write_something")}
                      />
                      {errors && errors.description && (
                        <span className={commonStyle.errorMsg}>
                          {errors?.description?.message}
                        </span>
                      )}
                    </Box>
                  </Grid>

                  <Grid item md={6}>
                    <Box className={commonStyle.commonInput}>
                      <Typography className={commonStyle.labelCommonInput}>
                        {translation("price")}
                      </Typography>
                      <Box className={commonStyle.dollarIconSet}>
                        {/* <MonetizationOn/> */}
                        <AttachMoneyOutlined />
                        <TextField
                          {...register("price")}
                          variant="outlined"
                          type="number"
                          onWheel={(e:any) => e.target.blur()}
                          defaultValue={Data?.price}
                          inputProps={{ min: 1 }}

                          className={commonStyle.w100}
                        />
                      </Box>
                      {errors && errors.price && (
                        <span className={commonStyle.errorMsg}>
                          {errors?.price?.message}
                        </span>
                      )}
                    </Box>
                  </Grid>


                  <Grid item md={12}>
                    <Box className={commonStyle.commonInput}>
                      <Typography
                        className={commonStyle.labelCommonInput}
                      >
                        {translation("availability")}
                      </Typography>
                      <Controller
                        name="availability"
                        control={control}
                        defaultValue={Data?.availability}
                        render={({ field }) => {
                          return (
                            <Select
                              // value={field.value}

                              {...register("availability")}
                              onChange={(e) => {
                                field.onChange(e);
                              }}
                              value={field.value}

                              className={commonStyle.w100}
                            >
                              {Availability.length > 0 &&
                                Availability?.map((region, index) => {
                                  return (
                                    <MenuItem
                                      key={index}

                                      defaultValue={Data?.availability}
                                      value={translation(region)}
                                    >
                                      {translation(region)}
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                          );
                        }}
                      />
                      {errors && errors.availability && (
                        <span className={commonStyle.errorMsg}>
                          {errors?.availability?.message}
                        </span>
                      )}
                    </Box>
                  </Grid>

                  <Grid item md={12}>
                    <Box className={commonStyle.commonInput}>
                      <Typography className={commonStyle.labelCommonInput}>
                        {translation("upload_image")}
                      </Typography>
                      <Box className={`${commonStyle.commonUploadBox}`}>
                        {/* <UploadFile className={commonStyle.mb15} />
                        <Typography className={commonStyle.mb15}>
                          Drag & Drop an Image to import it
                        </Typography>
                        <Box className={commonStyle.blueBtn}>
                          <CommonButton label="Import Form" />
                        </Box>
                      </Box>
                      <span className={commonStyle.errorMsg}>
                        Please fill this field.
                      </span>  */}
                        {/* <UploadFile className={commonStyle.mb15} />
                           <Typography className={commonStyle.mb15}>
                           Drag & Drop an Image to import it
                           
                          </Typography>  */}
                        <Dropzone onDrop={(files) => handleChange(files)}>
                          {({ getRootProps, getInputProps }) => (
                            <div className="container">
                              <div {...getRootProps({ className: "dropzone" })}>
                                <p> {translation("drop_box_content")}</p>
                                <input {...getInputProps()} accept="image/*" />
                              </div>
                              {imageFiles.length > 0 && (
                                <ul
                                  className={`${commonStyle.flexCenter} ${commonStyle.removeListStyle} ${commonStyle.dragList}`}
                                >
                                  {imageFiles.length > 0 &&
                                    imageFiles.map((item, index) => (
                                      <li key={index}>
                                        <img
                                          src={item}
                                          className={commonStyle.imageDragDrop}
                                          alt={translation("altDragimg")} />
                                        <HighlightOff
                                          onClick={() => removeImage(index)}
                                        />
                                      </li>
                                    ))}
                                </ul>
                              )}
                            </div>
                          )}
                        </Dropzone>
                      </Box>
                      {imageFiles.length === 0 && (
                        <span className={commonStyle.errorMsg}>
                          {imageError}
                        </span>
                      )}
                    </Box>
                  </Grid>
                </Grid>

                <Box className={commonStyle.flexCenter}>
                  <Box className={`${commonStyle.mr10} ${commonStyle.blueBtn}`}>
                    {/* <CommonButton label="Upload Product" /> */}
                    <Button
                      disabled={disableSubmitButton}
                      onClick={handleSubmit(onSubmit)}
                    >
                      {translation("upload_product")}
                      {disableSubmitButton ? <ClipLoader /> : null}

                    </Button>
                  </Box>
                  <Box className={`${commonStyle.mr10} ${commonStyle.greyBtn}`}>
                    <CommonButton
                      onClick={cancelProduct}
                      label={translation("cancel")}
                    />
                  </Box>
                </Box>
              </form>
            </Box>
            :loader?translation("loading"):null}
          </Box>
        </Grid>
      </Grid>
    </Box >
  );
};

export default EditProduct;
