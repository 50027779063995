import { ActionType } from "../action-types/index";
import axios from "../../services/axiosService";
const URL = process.env.REACT_APP_API_URL;


export const getSavedCardsList = (userId) => async (dispatch) => {
    try {
      dispatch({ type: "LOADING_STARTED" });
      const res = await axios({
        method: "get",
        url: `${URL}/api/v1/card/get-saved-card/${userId}`,
         
      });
      dispatch({ type: "LOADING_COMPLETED" });
      console.log("res from get saved cards list", res.data.data);
      return res.data.data;
    }
    catch (error) {
        dispatch({
          type: ActionType.FAILURE,
        });
      }
  }


  export const SaveCards = (data,userId) => async (dispatch) => {
    try {
      dispatch({ type: "LOADING_STARTED" });
      const res = await axios({
        method: "post",
        url: `${URL}/api/v1/card/add/${userId}`,data,
         
      });
      dispatch({ type: "LOADING_COMPLETED" });
      // console.log("res from get saved cards list", res);
      return res.data;
    }
    catch (error) {
        dispatch({
          type: ActionType.FAILURE,
        });
      }
  }

  export const SaveCardsList = (data,userId,id) => async (dispatch) => {
    try {
      dispatch({ type: "LOADING_STARTED" });
      const res = await axios({
        method: "get",
        url: `${URL}/api/v1/card/saveCardList/${userId}`
         
      });
      dispatch({ type: "LOADING_COMPLETED" });
      // console.log("res from get saved cards list", res);
      return res;
    }
    catch (error) {
        dispatch({
          type: ActionType.FAILURE,
        });
      }
  }


  export const makeStripePayment = (data,userId) => async (dispatch) => {

    try {
      dispatch({ type: "LOADING_STARTED" });
      const res = await axios({
        method: "post",
        url: `${URL}/api/v1/card/payment/${userId}`,data,
         
      });
      dispatch({ type: "LOADING_COMPLETED" });
      return res.data;
    }
    catch (error) {
        dispatch({
          type: ActionType.FAILURE,
        });
      }


  }


  export const paymentBySelectedCard = (data,userId) => async (dispatch) => {

    try {
      dispatch({ type: "LOADING_STARTED" });
      const res = await axios({
        method: "post",
        url: `${URL}/api/v1/card/select-card/${userId}`,data,
         
      });
      dispatch({ type: "LOADING_COMPLETED" });
      // console.log("res from get saved cards list", res);
      return res.data;
    }
    catch (error) {
        dispatch({
          type: ActionType.FAILURE,
        });
      }


  }