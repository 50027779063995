
import { useCallback, useEffect, useMemo, useState } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import PubNub, { ListenerParameters } from "pubnub";
import {  ListItemAvatar,Avatar } from "@mui/material";
import Badge, { BadgeProps } from "@mui/material/Badge";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";




const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
    "& .MuiBadge-badge": {
      border: `2px solid ${theme.palette.background.paper}`,
      padding: "0 4px",
      backgroundColor: "#44b700",
      width: "12px",
      height: "12px",
      borderRadius: "100px",
    },
  }));

const pubnubPublishKey = process.env.REACT_APP_PUBNUB_PUBLISH_KEY;
const pubnubSubscribeKey = process.env.REACT_APP_PUBNUB_SUBSCRIBE_KEY;

const Status = (props:any) => {
    const authData = useSelector(
        (state: RootStateOrAny) => state.authReducer.authData
      );
      const [online, SetOnline] = useState(false);
      const { t: translation } = useTranslation();

    const pubnub = useMemo(() => {
        const pubnub_config = {
          publishKey: pubnubPublishKey,
          subscribeKey: pubnubSubscribeKey as string,
          heartbeatInterval:20,
          
          setPresenceTimeout:30,
          uuid: authData.email,
          logVerbosity: true,
          authKey: authData.token,
          ssl: true,
        };
        return new PubNub(pubnub_config);
      }, [authData.email, authData.token]);
    useEffect(() => {
        pubnub.hereNow({
       
            channels: [props.channel as string],
            includeUUIDs: true,
        }).then((response) => {
            console.log("pubnub.hereNow",response)
            if(response.totalOccupancy>1){
                SetOnline(true)
            }
            else
            {
              SetOnline(false)
            }
           
            
    
        }).catch((error) => {
            console.log("errrrrrrrrrrrrrrrrr",error)
        });
      }, [props]);
  return (
   <div>
  {online? <ListItemAvatar>
                      <StyledBadge
                        overlap="circular"
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        variant="dot"
                      >
                        <Avatar
                          alt={translation("Remy_Sharp")}
                          src={props?.Image}
                        />
                      </StyledBadge>
                    </ListItemAvatar>:<ListItemAvatar>
                      <StyledBadge
                        // overlap="circular"
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        // variant="dot"
                      >
                        <Avatar
                          alt={translation("Remy_Sharp")}
                          src={props?.Image}
                        />
                      </StyledBadge>
                    </ListItemAvatar>} 

   </div>
  );
};

export default Status;