import { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import ClipLoader from "react-spinners/ClipLoader";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";

import { Modal, TextField, Typography } from "@mui/material";
import { AddCircleOutline, HighlightOff } from "@mui/icons-material";
import { Box } from "@mui/system";
import { Popover } from "@mui/material";
import { InsertPhoto, VideoLibrary } from "@mui/icons-material";

import { createStory } from "../../redux/action-creators/storyAction";

import { UserCommonStyles } from "../../styles/CommonStyles";
import { HomeStyles } from "../../styles/HomeStyles";

import { CommonButton } from "../../stories/Button";

import {
  ICreateOwnStoryInterface,
  ICreateOwnStoryProps,
} from "../../utils/interfaceModel";
import { toast } from "../../utils/toastsMessage";
import {
  VideoExtentions,
  ImageExtentions,
  Type,
  VideoTypes,
  ImageTypes,
} from "../../utils/constants";
import { uploadImage, uploadVideo } from "../../utils/fileUpload";
import Button from "@mui/material/Button";
import { isJsonString } from "../../utils/helper";
import imageCompression from "browser-image-compression";
let postImagesBucketName = process.env.REACT_APP_BUCKET_NAME;

/**
 * @method to get random string
 * @param length
 * @returns
 */
const getRandomString = (length: number) => {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "#fff",
  boxShadow: "0px 1px 4px #0000001A",
  borderRadius: "13px",
  padding: "10px 20px 20px",
};

const CreateStoryModal: React.FC<ICreateOwnStoryProps> = (
  props: ICreateOwnStoryProps,
  any
) => {
  const dispatch = useDispatch();
  const commonStyle = UserCommonStyles();
  const styleClasses = HomeStyles();
  const { t: translation } = useTranslation();
  const userInfo = useSelector((state: RootStateOrAny) => {
    let userData = state.authReducer.authData;
    if (isJsonString(state.authReducer.authData)) {
      userData = JSON.parse(state.authReducer.authData);
    }
    return userData;
  });
  const [newStory, setNewStory] = useState<ICreateOwnStoryInterface[]>([]);
  const [showNewStoryButton, setShowNewStoryButton] = useState(false);
  const [storyloader, setStoryloader] = useState(false);

  const [disableimg, setdisableimg] = useState(false);
  const [disablevideo, setdisablevideo] = useState(false);

  /**
   * Select the file which user selects
   */
  const fileSelectHandler = async (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    type: any,
    fileType:string

  ) => {
    let target = e.target as HTMLInputElement;
    if (target.files[0] !== undefined)
      if (target.files.length > 10) {
        toast.error(translation("file_size_err"));
        target.files = null;
      } else {
        for (let i = 0; i < target.files.length; i++) {
          if (target.files[i].type !== undefined) {
            const imgType = target.files[i].name.split(".").pop();
            if (VideoTypes.includes(imgType.toLowerCase())) {
              setdisableimg(true);
            }
            if (ImageExtentions.includes(imgType.toLowerCase())) {
              setdisablevideo(true);
            }
            const MimeTypeArray = type;
            if (imgType.toLowerCase() !== undefined) {
              if (MimeTypeArray.includes(imgType.toLowerCase())) {
                if (target.files[i].size > 10360000) {
                  toast.error(translation("file_error_ms10"));
                } 
                else {
                  if(fileType==="image")
                  {
                    const img = target.files[i];
                    const options = {
                      maxSizeMB: 1,
                      maxWidthOrHeight: 720,
                      useWebWorker: true,
                    };
                    try {
                      const compressImg = await imageCompression(img, options);
                      readFile(compressImg);
                    } catch (e) {
                      console.error("Error while compressing image : ", e);
                    }

                  }
                  else
                  {
                    const img = target.files[i];

                    readFile(img);

                  }
                }
              } else {
                if (!VideoExtentions.includes(imgType.toLowerCase())) {
                  toast.error(translation("file_not_support_video"));
                }
                if (!ImageExtentions.includes(imgType.toLowerCase())) {
                  toast.error(translation("file_not_support_img"));
                }
              }
            }
          }
        }
      }
  };

  /**
   * Read the file data which user selects
   * @param file
   */
  const readFile = async (file: Blob) => {
    /**
     * Create an instance of FileReader
     */
    const fileReader = new FileReader();
    /**
     * Implement onLoad function
     * @param event
     * @returns
     */
    fileReader.onloadend = () =>
      setNewStory((prevState: any) => [
        ...prevState,
        {
          data: fileReader?.result,
          text: "",
          type: "",
        },
      ]);
    handleClosepost();
    /**
     * Start reading the file
     */
    fileReader.readAsDataURL(file);
  };

  /**
   * add caption for Image
   * @param event
   * @param index
   */
  const addTextForImage = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    index: number
  ) => {
    let target = event?.target as HTMLInputElement;
    let storyData = [...newStory];
    storyData[index].text = target.value;
    setNewStory(storyData);
  };

  /**
   * Create story
   * @returns
   */
  const addStory = async () => {
    setStoryloader(true);
    setShowNewStoryButton(true);
    let uploadedImages = [];
    let filetype = "";
    if (newStory.length > 0) {
      filetype = newStory[0].data.split("/")[0].split(":")[1];
      if (filetype === "image") {
        const imagePath = await uploadImage(
          newStory[0].data,
          postImagesBucketName,
          `${userInfo.id}/posts/${getRandomString(6)}`
        );
        uploadedImages.push(imagePath);
      } else {
        const imagePath = await uploadVideo(
          newStory[0].data,
          postImagesBucketName,
          `${userInfo.id}/posts/${getRandomString(6)}`
        );
        uploadedImages.push(imagePath);
      }
    }

    const response: any = await dispatch(
      createStory(
        { data: uploadedImages, type: filetype, text: newStory[0].text },
        userInfo.id
      )
    );
    if (response && response?.isSuccess) {
      setStoryloader(false);
      setShowNewStoryButton(false);
      setNewStory([]);
      props?.onClose();
      return toast.success(translation(response.message));
    } else {
      setStoryloader(false);
    }
  };
  const [anchorE2, setAnchorE2] = useState(null);
  const openE = Boolean(anchorE2);
  const id = openE ? "simple-popover" : undefined;
  const handleClick = (event) => {
    setAnchorE2(event.currentTarget);
  };
  const handleClosepost = () => {
    setAnchorE2(null);
  };

  return (
    <Modal
      open={props?.open}
      onClose={props?.onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box className={styleClasses.modalHeader}>
          <Typography id="modal-modal-title" variant="h6">
            {translation("upload_story")}
          </Typography>
          <HighlightOff onClick={props?.onClose} />
        </Box>
        <Box
          className={`${commonStyle.bgSkyBlue} ${styleClasses.heightBoxUpload} ${commonStyle.mt20}`}
        >
          {newStory.length === 0 && <AddCircleOutline onClick={handleClick} />}
          <Popover
            id={id}
            open={openE}
            anchorEl={anchorE2}
            onClose={handleClosepost}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <ul className={styleClasses.mediaIconPopup}>
              <li>
                <InsertPhoto />
                {translation("image")}
                <input
                  disabled={disableimg}
                  type="file"
                  onChange={(e) => fileSelectHandler(e, ImageTypes,"image")}
                  name="story_image"
                  // multiple
                  accept={ImageExtentions}
                />
              </li>
              <li>
                <VideoLibrary />
                {translation("video")}
                <input
                  disabled={disablevideo}
                  type="file"
                  onChange={(e) => fileSelectHandler(e, VideoTypes,"video")}
                  name="story_video"
                  //multiple
                  accept={VideoExtentions}
                />
              </li>
            </ul>
          </Popover>

          {newStory.length > 0 && (
            <ul className={styleClasses.uploadImgList}>
              {newStory.length > 0
                ? newStory.map((story: any, index: number) => (
                    <li key={index}>
                      {story?.data.split("/")[0].split(":")[1] ===
                      Type.image ? (
                        <>
                          <img src={story.data} alt="" />
                          <Box className={commonStyle.commonInput}>
                            <TextField
                              multiline
                              placeholder={translation("add_cap")}
                              onChange={(
                                e: ChangeEvent<
                                  HTMLTextAreaElement | HTMLInputElement
                                >
                              ) => addTextForImage(e, index)}
                              variant="standard"
                              className={commonStyle.w100}
                              minRows={4}
                            ></TextField>
                          </Box>
                        </>
                      ) : (
                        <>
                          <video src={story.data} controls={true} />
                          <Box className={commonStyle.commonInput}>
                            <TextField
                              multiline
                              placeholder={translation("add_cap")}
                              onChange={(
                                e: ChangeEvent<
                                  HTMLTextAreaElement | HTMLInputElement
                                >
                              ) => addTextForImage(e, index)}
                              variant="standard"
                              className={commonStyle.w100}
                              minRows={4}
                            ></TextField>
                          </Box>
                        </>
                      )}
                    </li>
                  ))
                : null}
            </ul>
          )}

          {newStory.length === 0 && (
            <Typography className={commonStyle.mb10}>
              {translation("upload_file")}
            </Typography>
          )}

          {newStory.length > 0 && (
            <Box className={`${commonStyle.orangeBtn}`}>
              <Button
                className={`${commonStyle.w100}`}
                onClick={addStory}
                disabled={showNewStoryButton}
              >
                {translation("add_story")}
                {storyloader ? <ClipLoader /> : null}
              </Button>
              {/* <Button
                disabled={showNewStoryButton}
                onClick={addStory}
                >
                {translation("add_story")}
                {storyloader ? <ClipLoader/> : null}
                </Button> */}
            </Box>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default CreateStoryModal;
