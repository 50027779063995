import axios from "../../services/axiosService";
import { Dispatch } from "redux";
import { ActionType } from "../action-types/index";
import { Action } from "../actions/AuthAction";
const URL = process.env.REACT_APP_API_URL;

/**
 * get all product categories
 * @returns
 */
export const getAllProductCategories = () => async (
  dispatch: Dispatch<Action>
) => {
  try {
    const response = await axios.get(
      `${URL}/api/v1/product/product-category/list`
    );
    if (!response.data.isError) {
      dispatch({
        type: ActionType.PRODUCTCATEGORYLIST,
        payload: response.data.data,
      });
    }
    return response.data;
  } catch (err) {
    dispatch({
      type: ActionType.FAILURE,
    });
  }
};

/**
 * get all product categories
 * @returns
 */
 export const getAllProductCategoriesByCategoryId = (categoryId:number) => async (
  dispatch: Dispatch<Action>
) => {
  try {
    const response = await axios.get(
      `${URL}/api/v1/product/product-category/${categoryId}`
    );  
    return response.data;
  } catch (err) {
    dispatch({
      type: ActionType.FAILURE,
    });
  }
};

/**
 * get all product categories
 * @returns
 */
 export const getAllProductCategoriesBySubCategoryId = (sub_categoryId:number) => async (
  dispatch: Dispatch<Action>
) => {
  try {
    const response = await axios.get(
      `${URL}/api/v1/product/product-sub-category/${sub_categoryId}`
    );   
    return response.data;
  } catch (err) {
    dispatch({
      type: ActionType.FAILURE,
    });
  }
};

/**
 * create product
 * @param data
 * @param sellerId
 * @param userId
 * @returns
 */
export const createProduct = (
  data: any,
  sellerId: number,
  userId: number
) => async (dispatch: Dispatch) => {
  try {
    const response = await axios.post(
      `${URL}/api/v1/product/add/${userId}/${sellerId}`,
      data
    );

    return response.data;
  } catch (err) {
    dispatch({
      type: ActionType.FAILURE,
    });
  }
};

/**
 * get all products
 * @param searchCategory
 * @param searchProduct
 * @param distance
 * @param data
 * @returns
 */
export const getAllProducts = (
  searchCategory: number,
  searchProduct: string,
  distance: string,
  data: any
) => async (dispatch: Dispatch) => {
  const { latitude, longitude ,offset} = data;
  try {
    const response = await axios.get(
      `${URL}/api/v1/product/list/?searchCategory=${searchCategory}&searchProduct=${searchProduct}&distance=${distance}&latitude=${latitude}&longitude=${longitude}&offset=${offset}`
    );
    return response.data;
  } catch (err) {
    dispatch({
      type: ActionType.FAILURE,
    });
  }
};

/**
 * get product details by id
 * @param productId
 * @returns
 */
export const getProductDetailsById = (productId: string) => async (
  dispatch: Dispatch
) => {
  try {
    const response = await axios.get(`${URL}/api/v1/product/${productId}`);
    return response.data.data.data;
  } catch (err) {
    dispatch({
      type: ActionType.FAILURE,
    });
  }
};


/**
 * update product
 */
 export const updateProduct = (data: object, id: number,userId:number,sellerId:number) => async (
  dispatch: Dispatch
) => {
  try {
    const response = await axios.patch(
      `${URL}/api/v1/product/edit-product/${id}/${userId}/${sellerId}`,
      data
    );
   return response.data;
  } catch (error) {
    dispatch({
      type: ActionType.FAILURE,
    });
  }
};

/**
 * update product
 */
 export const updateProductAction = (status:Number, id: number) => async (
  dispatch: Dispatch
) => {
  try {
    const response = await axios.patch(
      `${URL}/api/v1/product/product-action/${id}/${status}`,
      
    );
   return response.data;
  } catch (error) {
    dispatch({
      type: ActionType.FAILURE,
    });
  }
};


/**
 * get product details by id
 * @param productId
 * @returns
 */
 export const getProductInfoById = (productId: number,seller_id:number) => async (
  dispatch: Dispatch
) => {
  try {
    const response = await axios.get(`${URL}/api/v1/product/my-product-details/${seller_id}/${productId}`);
    return response.data.data.data;
  } catch (err) {
    dispatch({
      type: ActionType.FAILURE,
    });
  }
};