import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Avatar, Grid, Modal, Typography } from "@mui/material";
import { HighlightOff } from "@mui/icons-material";
import { Box } from "@mui/system";


import { capitalize } from "../../utils/helper";
import { RoutingLinks } from "../../utils/constants";

import { CommonReactionModalProps, CommonUserDetailModalProps } from "../../interfaceModules/ICommonInteface";

import defaultUserprofile from "../../images/defaultUserprofile.png";
import minImage from "../../images/minImage.png";


import { UserCommonStyles } from "../../styles/CommonStyles";
import { HomeStyles } from "../../styles/HomeStyles";
import { allReaction } from "../../redux/action-creators/petofdayAction";
import InfiniteScroll from "react-infinite-scroll-component";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "#fff",
  boxShadow: "0px 1px 4px #0000001A",
  borderRadius: "13px",
  padding: "10px 20px 20px",
};

const CommonReactionModal: React.FC<CommonReactionModalProps> = (
  props: CommonReactionModalProps
) => {
  const commonStyle = UserCommonStyles();
  const styleClasses = HomeStyles();
  const { t: translation } = useTranslation();
  const [allFriendListLoader, setAllFriendListLoader] = useState(false);


  const dispatch = useDispatch();

  const [state, setLikedPeopleUserDetails] = useState({
    userList: [],
    offset: 0,
    prevOffset: 0,
    hasMore: true,
  });



/**
   * reactions on pet_of_the_day
   */
 const getAllLikeReactions = async (firstLoad?: boolean) => {
       console.log("props>>>>>>>>>>>>>>>",props.id)
    if (state.offset !== state.prevOffset || firstLoad) {
        setAllFriendListLoader(true);
        let offset = firstLoad ? 0 : state.offset;
        

        

        let result: any = await dispatch(allReaction(props.id,offset));
     
  
        if (result)
          if (result.length > 0) {
            setLikedPeopleUserDetails((prevState) => ({
              ...prevState,
              prevOffset: firstLoad ? 0 : prevState.offset,
              offset: firstLoad ? 10 : prevState.offset + 10,
              userList: firstLoad ? result : prevState.userList.concat(result),
              hasMore: true,
            }));
          } else {
              setLikedPeopleUserDetails((prevState) => ({
              ...prevState,
              userList: firstLoad ? result : prevState.userList.concat(result),
              hasMore: false,
            }));
          }
      }
      setAllFriendListLoader(false);
   
  };

   /**
       * Fetching all reactions List
       */
    useEffect(() => {
        getAllLikeReactions(true);
    }, [props.id]); 

  return (
    <Modal
      hideBackdrop
      open={props?.open}
      onClose={props?.onClose}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <Box sx={style}>
        <Box className={styleClasses.modalHeader}>
          <Typography id="modal-modal-title" variant="h6">
            {translation(props.name)}
          </Typography>
          <HighlightOff onClick={props?.onClose} />
        </Box>

        <Box
          id="modal-modal-description"
          sx={{ mt: 2 }}
          className={styleClasses.modalDescTag}
        >
             <InfiniteScroll
                dataLength={state?.userList?.length}
                next={() => getAllLikeReactions(false)}
                hasMore={state?.hasMore}
                 height={100}
                loader={(() => {
                  if (allFriendListLoader) {
                    return <div className={commonStyle.textFlexCenter}>Loading...</div>;
             
                  }
                })()}
                >
          <Grid container>
         
            {state.userList.length > 0?
              state.userList.map((showUser, index) => (
                <Grid
                  item
                  md={12}
                  key={index}
                  className={styleClasses.likeShowYou}
                >
                    
                  <Box
                    className={`${commonStyle.pb20} ${commonStyle.wallUserInfo} ${commonStyle.cursorA}`}
                  >
                    <Avatar
                      alt={translation("story_person")}
                      src={
                        showUser.profile_image
                          ? showUser.profile_image
                          : minImage
                      }
                    />
                    <Box className={commonStyle.ml10}>
                      <Link
                        key={index}
                        to={{
                          pathname: `${RoutingLinks.profiles}${showUser.userId}`,
                        }}
                        className={commonStyle.anchorDecoration}
                      >
                        {capitalize(showUser.first_name)} {capitalize(showUser.last_name)}
                      </Link>
                      <Typography>{translation(showUser.type)}</Typography>
                    </Box>
                  </Box>
                  <Box>{showUser?.reaction}</Box>
                </Grid>
              )):allFriendListLoader?"":<Box className={commonStyle.textFlexCenter}>{translation("no_user")}</Box>}
     
              
          </Grid>
          </InfiniteScroll>
        </Box>
      </Box>
    </Modal>
  );
};

export default CommonReactionModal;
