import { Dispatch } from "redux";
import axios from "../../services/axiosService";
import { ICreateOwnStoryInterface } from "../../utils/interfaceModel";
import { ActionType } from "../action-types/index";
const URL = process.env.REACT_APP_API_URL;

/**
 * For Create the New User Story
 * @param data
 * @param userId
 * @returns
 */
export const createStory = (
  data: any,
  userId: number
) => async (dispatch: Dispatch) => {
  console.log("action data",data)
  try {
    const response: any = await axios.post(
      `${URL}/api/v1/story/create/${userId}`,
      data
    );
    return response.data;
  } catch (error) {
    dispatch({
      type: ActionType.FAILURE,
    });
  }
};

/**
 * Get all stories of friends of userId
 * @param userId
 * @returns
 */
export const getStoryListsbyUserId = (userId: number) => async (
  dispatch: Dispatch
) => {
  try {
    const response: any = await axios.get(`${URL}/api/v1/story/${userId}`);
    return response.data.data.data;
  } catch (err) {
    dispatch({
      type: ActionType.FAILURE,
    });
  }
};

/**
 * Get all stories of a user
 * @param userId
 * @returns
 */
export const getStoryDetailsByUserId = (userId: number) => async (
  dispatch: Dispatch
) => {
  try {
    const response: any = await axios.get(
      `${URL}/api/v1/story/details/${userId}`
    );
    return response.data.data.data;
  } catch (err) {
    dispatch({
      type: ActionType.FAILURE,
    });
  }
};
