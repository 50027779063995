import React, { useEffect, useState, useCallback } from "react";
import { RouteComponentProps } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import ClipLoader from "react-spinners/ClipLoader";

import {
  Checkbox,
  Avatar,
  Button,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Slider,
  Switch,
  Typography,
} from "@mui/material";
import { Star } from "@mui/icons-material";
import { Box } from "@mui/system";

import { useDispatch } from "react-redux";
import {
  getListUser,
  petPlayon,
  getUserStatus,
  changeStatus,
  userAddFavourite,
  sendNotification,
} from "../../redux/action-creators/petPlayAction";
import { changeRequestType } from "../../redux/action-creators/requestAction";

import RightSidebar from "../../components/sidebars/RightSidebar";
import LeftSidebar from "../../components/sidebars/LeftSidebar";

import { toast } from "../../utils/toastsMessage";
import { capitalize } from "../../utils/helper";
import {
  Categories,
  NewLineRegex,
  RoutingLinks,
  empStr,
  Type,
} from "../../utils/constants";

import {
  IPetPlayUserList,
  IPetPlayStatus,
} from "../../interfaceModules/IPetsInteface";
import * as Iswiper from "swiper";

import { CommonButton } from "../../stories/Button";
import defaultUserprofile from "../../images/defaultUserprofile.png";
import minImage from "../../images/minImage.png";


import { UserCommonStyles } from "../../styles/CommonStyles";
import { ProfileStyle } from "./ProfileStyle";
import "swiper/swiper-bundle.css";

const PetPlay = (props: RouteComponentProps) => {
  const { t: translation } = useTranslation();
  const userId = Number(localStorage.getItem("userId"));
  const dispatch = useDispatch();
  const [userList, setuserList] = useState<IPetPlayUserList[]>([]);
  const [checked, setchecked] = useState(false);
  const [userStatus, setUserStatus] = useState<IPetPlayStatus>();
  const [disable, setdisable] = useState(false);

  const [loader, setloader] = useState(true);
  const commonStyle = UserCommonStyles();
  const styleClasses = ProfileStyle();
  const [category, setcategory] = useState({ searchType: empStr });

  const [surgicalProcedure, setsurgicalProcedure] = useState({
    searchType: empStr,
  });
  const [gender, setgender] = useState({ genderType: empStr });
  const [slidevalue, setslidevalue] = useState(0);
  const [distance, setdistance] = useState({ distance: "50" });
  const [disableFavourite, setDisableFavourite] = useState(false);
  const [timeOutSliderState, setTimeOutSliderState] = React.useState(null);

  /**
   * get all list
   * @param typeValue
   * @param genderValue
   * @param distanceValue
   */
  const getList = async (
    typeValue?: string,
    genderValue?: string,
    distanceValue?: string,
    surgicalProcedureValue?: string
  ) => {
    if (
      !(category.searchType === Type.cat || category.searchType === Type.dog)
    ) {
      setsurgicalProcedure((prevState) => ({
        ...prevState,
        searchType: " ",
      }));
    }
    let categoryType = typeValue ? typeValue : category.searchType;
    let genderSearch = genderValue ? genderValue : gender.genderType;
    let distanceField = distanceValue ? distanceValue : distance.distance;
    let surgical_procedure = surgicalProcedureValue
      ? surgicalProcedureValue
      : surgicalProcedure.searchType;

    let result: any = await dispatch(
      getListUser(
        userId,
        categoryType,
        genderSearch,
        distanceField,
        surgical_procedure
      )
    );
    if (result && result.length > 0) {
      setDisableFavourite(false);
      setuserList(result);
    } else {
      setuserList([]);
    }
    setloader(false);
  };

  /**
   * get user status
   * @param id
   */
  const getStatus = useCallback(
    async (id: number) => {
      let status: any = await dispatch(getUserStatus(id));

      if (status && status?.isFound && status?.data?.status === 1) {
        setUserStatus(status.data);
        setchecked(true);
      } else if (status && status?.isFound && status?.data?.status === 0) {
        setUserStatus(status.data);
        setchecked(false);
      }
    },
    [dispatch]
  );

  useEffect(() => {
    getStatus(userId);
    getList();
  }, []);

  /**
   * handle change
   * @param event
   * @returns
   */
  const handleChange = async (event: SelectChangeEvent) => {
    if (userStatus) {
      if (userStatus?.status === 1) {
        await dispatch(changeStatus(userId, { status: false }));
        getStatus(userId);
        setchecked(false);

        // return toast.success(translation("succ_update"));
      } else if (userStatus?.status === 0) {
        await dispatch(changeStatus(userId, { status: true }));
        getStatus(userId);
        setchecked(true);

        // return toast.success(translation("succ_update"));
      }
    } else {
      const result: any = await dispatch(petPlayon(userId));

      if (result && result.isRegistered) {
        getStatus(userId);
        return toast.success(translation("succ_insert"));
      }
      setchecked(true);
    }
  };

  /**
   * type change on handle
   * @param event
   */
  const handleTypeChange = (event: SelectChangeEvent) => {
    const typetext = event.target.value;
    setcategory((prevState) => ({
      ...prevState,
      searchType: typetext,
    }));
    getList(typetext, "");
  };

  /**
   * search change on handle
   * @param event
   */
  const handleSearchChange = (event: SelectChangeEvent) => {
    const searchtext = event.target.value;

    setgender((prevState) => ({
      ...prevState,
      genderType: searchtext,
    }));
    getList("", searchtext);
  };
  /**
   * search change on handle status
   * @param event
   */
  const handleStatusChange = (event: React.SyntheticEvent<Element, Event>) => {
    let target = event.target as HTMLInputElement;
    const searchtext = target.checked ? target.value : " ";

    setsurgicalProcedure({
      searchType: searchtext,
    });

    getList("", "", "", searchtext);
  };

  /**
   * change slides
   * @param e
   */
  const slideChanges = (e: Iswiper.Swiper) => {
    e.previousIndex = slidevalue;
    setslidevalue(e.realIndex);
  };

  /**
   * handle click
   * @param event
   */
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setslidevalue(slidevalue - 1);
  };

  /**
   * increase 1 counter to handle click
   * @param event
   */
  const handleClickpositive = (event: React.MouseEvent<HTMLButtonElement>) => {
    setslidevalue(slidevalue + 1);
  };

  /**
   * add to favourite
   * @param id
   * @returns
   */

  const addFavorite = async (id: number) => {
    setDisableFavourite(true);
    let result: any = await dispatch(userAddFavourite(id, userId));

    if (result && result.isRegistered) {
      await getList();
      setDisableFavourite(false);
      return toast.success(translation("add_fav"));
    }
  };

  /**
   * change request type
   * @param id
   * @returns
   */
  const changeRequest = async (id: number) => {
    setdisable(true);
    const result: any = await dispatch(changeRequestType(id, userId));
    if ((result && result?.isRegistered) || result.affectedRows) {
      await getList();

      const data = { type: "Friend Request", id: userId };
      setdisable(false);
      if (id !== data.id) {
        await dispatch(sendNotification(id, data));
        return toast.success(translation("friend_request_sent"));
      }
    } else {
      setdisable(false);
      return toast.success(translation("error"));
    }
  };

  /**
   * slider change on handler
   * @param event
   */
  const handleChangeSlider = (event: React.SyntheticEvent | Event) => {
    const target = event.target as HTMLInputElement;
    const distanceValue = target.value;
    setdistance((prevState) => ({
      ...prevState,
      distance: distanceValue,
    }));

    if (timeOutSliderState) {
      clearInterval(timeOutSliderState);
    }
    let result: any = setTimeout(async () => {
      await getList("", "", distanceValue);
    }, 1500);
    setTimeOutSliderState(result);
  };

  return (
    <Box>
      <Box className={commonStyle.windowColor}>
        <Grid container>
          <Grid item md={3}>
            <LeftSidebar />
          </Grid>

          <Grid item md={6}>
            <Box className={`${commonStyle.p20}`}>
              <Box
                className={`${commonStyle.flexCenter} ${commonStyle.justifySpaceBetween} ${commonStyle.mb15} ${commonStyle.mt20}`}
              >
                <Typography
                  variant="h6"
                  className={`${commonStyle.grey} ${commonStyle.fontWeight600}`}
                >
                  {translation("play_date", { pet: translation("pet") })}
                </Typography>

                <Box className={`${commonStyle.flexCenter}`}>
                  <Box className={commonStyle.commonSlider}>
                    <Typography>
                      {translation("distance_preference")}{" "}
                      <span>
                        {distance.distance}
                        {translation("miles")}
                      </span>
                    </Typography>
                    <Slider
                      defaultValue={50}
                      aria-label={translation("default")}
                      valueLabelDisplay="auto"
                      onChange={handleChangeSlider}
                    />
                  </Box>
                  <Box
                    className={`${commonStyle.orangeBtn} ${commonStyle.mr10}`}
                  >
                    <CommonButton
                      onClick={() =>
                        props.history.push(`${RoutingLinks.favouritesList}`)
                      }
                      label={translation("your_favourites")}
                    />
                  </Box>
                </Box>
              </Box>

              <Box
                className={`${commonStyle.p20} ${commonStyle.card} ${commonStyle.mb20}`}
              >
                <Box
                  className={`${commonStyle.flexCenter} ${commonStyle.mb10}`}
                >
                  <Typography className={commonStyle.lightGrey}>
                    {translation("open_for_petdate")}
                  </Typography>
                  {userStatus?.status === 1 ? (
                    <Switch
                      checked={checked}
                      onChange={handleChange}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  ) : (
                    <Switch
                      checked={checked}
                      onChange={handleChange}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  )}
                </Box>

                <Grid container spacing={2}>
                  <Grid item md={4}>
                    <Box className={commonStyle.commonInput}>
                      <Typography className={commonStyle.labelCommonInput}>
                        {translation("pet_category")}
                      </Typography>
                      <Select
                        className={commonStyle.w100}
                        onChange={handleTypeChange}
                        value={category.searchType}
                      >
                        <MenuItem disabled value=""></MenuItem>
                        {Categories.map((name) => (
                          <MenuItem key={name} value={name}>
                            {translation(name)}
                          </MenuItem>
                        ))}
                      </Select>
                    </Box>
                  </Grid>

                  <Grid item md={4}>
                    <Box className={commonStyle.commonInput}>
                      <Typography className={commonStyle.labelCommonInput}>
                        {translation("look_for")}
                      </Typography>
                      <Select
                        className={commonStyle.w100}
                        onChange={handleSearchChange}
                        value={gender.genderType}

                        // value={userInfo?.country ? userInfo.country : ""}
                      >
                        <MenuItem value={"Male"}>
                          {translation("male")}
                        </MenuItem>
                        <MenuItem value={"Female"}>
                          {translation("female")}
                        </MenuItem>
                        <MenuItem value={"Any"}>{translation("any")}</MenuItem>
                      </Select>
                    </Box>
                  </Grid>
                  {category.searchType === Type.cat ||
                  category.searchType === Type.dog ? (
                    <Grid item md={4}>
                      <FormControlLabel
                        value="Spayed"
                        control={<Checkbox />}
                        label={translation<string>("spayed")}
                        onChange={handleStatusChange}
                      />
                    </Grid>
                  ) : null}
                </Grid>
              </Box>

              <Box className={`${commonStyle.card}`}>
                {userList?.length > 0 ? (
                  <Swiper
                    spaceBetween={10}
                    slidesPerView={1}
                    onSlideChange={(e) => slideChanges(e)}
                  >
                    {userList?.length > 0 &&
                      userList.map((item, index) => (
                        <SwiperSlide key={index}>
                          <Box
                            className={`${commonStyle.flexCenter} ${commonStyle.justifySpaceBetween} ${commonStyle.p20}`}
                          >
                            <Box className={commonStyle.wallUserInfo}>
                              <Avatar
                                alt={translation("story_person")}
                                // src={item.profile_image?item.profile_image:defaultUserprofile}
                                src={
                                  userList[slidevalue]?.profile_image
                                    ? userList[slidevalue]?.profile_image
                                    : minImage
                                }
                              />
                              <Box className={commonStyle.ml10}>
                                <Typography
                                  variant="h2"
                                  className={commonStyle.flexCenter}
                                >
                                  {capitalize(
                                    userList[slidevalue]?.first_name
                                  ) +
                                    " " +
                                    capitalize(userList[slidevalue]?.last_name)}
                                </Typography>
                                <Typography>
                                  {translation(userList[slidevalue]?.type)}
                                </Typography>
                              </Box>
                            </Box>

                            <Box className={commonStyle.flexCenter}>
                              {userList[slidevalue]?.profile_id ? (
                                <Button disabled>
                                  <Star
                                    className={`${commonStyle.yellow} ${commonStyle.mr10} ${commonStyle.cursorA}`}
                                  />
                                </Button>
                              ) : (
                                <Button
                                  disabled={disableFavourite}
                                  onClick={() => {
                                    addFavorite(userList[slidevalue]?.id);
                                  }}
                                >
                                  {disableFavourite ? (
                                    <Star
                                    className={`${commonStyle.yellow} ${commonStyle.mr10} ${commonStyle.cursorA}`}
                                    />
                                  ) : (
                                    <Star
                                    className={`${commonStyle.lightGrey} ${commonStyle.mr10} ${commonStyle.cursorA}`}
                                    />
                                  )}
                                </Button>
                              )}

                              <Box className={commonStyle.blueBtn}>
                                {userList[slidevalue]?.request_type ==="accepted"? (
                                  <Button  onClick={() => props.history.push({
                                    pathname: RoutingLinks.chat,
                                    state: {
                                      id: item.id,
                                      first_name: item.first_name,
                                      last_name: item.last_name,
                                      profile_image: item.profile_image,
                                      channel: "",
                                    },
                                  })}>
                                    {translation(
                                      "message"
                                    )}{" "}
                                  </Button>
                                ): userList[slidevalue]?.request_type?(
                                      <Button disabled>
                                    {translation(
                                      userList[slidevalue]?.request_type
                                    )}{" "}
                                  </Button>
                                  
                                )
                                
                                : (
                                  <Button
                                    disabled={disable}
                                    onClick={() => {
                                      changeRequest(userList[slidevalue].id);
                                    }}
                                  >
                                    {translation("send_request")}{" "}
                                    {disable ? <ClipLoader /> : null}
                                  </Button>
                                )}
                              </Box>
                            </Box>
                          </Box>

                          <img
                            src={
                              userList[slidevalue]?.profile_image
                                ? userList[slidevalue]?.profile_image
                                : defaultUserprofile
                            }
                            alt={translation("animal_img")}
                            className={styleClasses.petPlaySlider}
                          />
                          <Box className={commonStyle.p20}>
                            <Typography
                              className={`${commonStyle.lightGrey} ${commonStyle.font14} ${commonStyle.commonLineClamp}`}
                              dangerouslySetInnerHTML={{
                                __html: `${userList[
                                  slidevalue
                                ]?.description.replace(
                                  NewLineRegex,
                                  "<br />"
                                )}`,
                              }}
                            ></Typography>
                          </Box>
                        </SwiperSlide>
                      ))}
                  </Swiper>
                ) : (
                  <Box
                    className={`${commonStyle.flexCenter} ${commonStyle.justifySpaceBetween} ${commonStyle.p20}`}
                  >
                    {loader
                      ? translation("loading")
                      : translation("missing_record")}
                  </Box>
                )}

                <Box
                  className={`${commonStyle.flexCenter} ${commonStyle.justifySpaceBetween} ${commonStyle.p20}`}
                >
                  <Box className={commonStyle.orangeBtn}>
                    {slidevalue > 0 ? (
                      <Button onClick={handleClick}>
                        {translation("previous")}
                      </Button>
                    ) : (
                      <Button disabled onClick={handleClick}>
                        {translation("previous")}
                      </Button>
                    )}
                  </Box>

                  <Box className={commonStyle.orangeBtn}>
                    {slidevalue < userList.length - 1 ? (
                      <Button onClick={handleClickpositive}>
                        {translation("next")}
                      </Button>
                    ) : (
                      <Button disabled onClick={handleClickpositive}>
                        {translation("next")}
                      </Button>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>

          <Grid item md={3}>
            <RightSidebar />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default PetPlay;
