import { useDispatch,useSelector,RootStateOrAny } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect, useState, useCallback } from "react";
import {
  Avatar,
  Button,
  Grid,
  IconButton,
  Popover,
  Tooltip,
  Typography,
  TextField,
} from "@mui/material";
import {
 
  ThumbUpAlt,
} from "@mui/icons-material";

import { Box } from "@mui/system";

import { getProfile } from "../../redux/action-creators/profileAction";
import {
  RequestType,
  changeRequestType,
} from "../../redux/action-creators/requestAction";
import { sendNotification } from "../../redux/action-creators/petPlayAction";

import LeftSidebar from "../../components/sidebars/LeftSidebar";
import RightSidebar from "../../components/sidebars/RightSidebar";

import { toast } from "../../utils/toastsMessage";
import { capitalize, isJsonString } from "../../utils/helper";

import { IPetOfTheDay } from "../../interfaceModules/IPetsInteface";
import { IUserData } from "../../interfaceModules/ICommonInteface";

import defaultUserprofile from "../../images/defaultUserprofile.png";
import minImage from "../../images/minImage.png";


import { UserCommonStyles } from "../../styles/CommonStyles";
import { ProfileStyle } from "./ProfileStyle";
import { RoutingLinks, Type } from "../../utils/constants";
import { HomeStyles } from "../../styles/HomeStyles";
import likelcon from "../../images/likeIcon.jpg";
import { addpetofdayReaction, allReaction, likeReactionByUser } from "../../redux/action-creators/petofdayAction";
import CommonUserDetailModal from "../../components/commonModal/CommonUserDetailModal";
import CommonReactionModal from "../../components/commonModal/CommonReactionModal";

const PetOfTheDay = (props) => {
  const dispatch = useDispatch();
  const { t: translation } = useTranslation();
  const styleClass = HomeStyles();

  const commonStyle = UserCommonStyles();
  const styleClasses = ProfileStyle();

  const id = Number(useParams<{ id: string }>().id);
  const userId = Number(localStorage.getItem("userId"));

  const userInfo = useSelector((state: RootStateOrAny) => {
    let userData = state.authReducer.authData;
    if (isJsonString(state.authReducer.authData)) {
      userData = JSON.parse(state.authReducer.authData);
    }
    return userData;
  });
  let stateData = props.location.state.data[0]

  const [requestType, setRequestType] = useState<IPetOfTheDay[]>([]);
  const [userData, setUserData] = useState<IUserData[]>([]);
  const [disable, setDisable] = useState(false);
  const [disabled, setdisable] = useState(false);
  const [userReaction,setUserReaction]=useState<any>({
    totalReaction:0,
      reaction:"0",

  })
  const [openLike, setLikeOpen] = useState(false);

  const [
    anchorExpression,
    setAnchorExpression,
  ] = useState<HTMLButtonElement | null>(null);
  const openExpression = Boolean(anchorExpression);
  /**
   * To get UserInfo
   */
  const getUserData = useCallback(async () => {
    let result: any = await dispatch(getProfile(id));
    if (result && result.length > 0) {
      setUserData(result);
    }
  }, [dispatch, id]);

  /**
   * To Get The Request Type
   */
  const getRequest = useCallback(async () => {
    let request: any = await dispatch(RequestType(id, userId));
    if (request.length > 0) {
      setRequestType(request);
    } else {
      setRequestType([]);
    }
  }, [dispatch, id, userId]);

const getUserReaction=useCallback(async()=>{
  let data: any = await dispatch(likeReactionByUser(stateData.id,userInfo.id));
  console.log("dataaaaaaaa",data)

  if (data.length > 0) {
    console.log("Result",data)
    setUserReaction({
      totalReaction:data[0].totalReaction,
      reaction:data[0].reaction?data[0].reaction:"0"

    });
  } else {
    console.log("Result",data)
    setUserReaction({
      totalReaction:0,
      reaction:"0",

    });
  }
},[dispatch, id, userId])


  useEffect(() => {
    getUserData();
    getRequest();
    getUserReaction();
  }, [getRequest, getUserData, id]);
  /**
   * To send Friend request
   * @returns
   */
  const changeRequest = async () => {
    setDisable(true);
    const result: any = await dispatch(changeRequestType(id, userId));
    if ((result && result?.isRegistered) || result.affectedRows) {
      getRequest();
      const data = { type: Type.friendrequest, id: userId };
      if (id !== data.id) {
        await dispatch(sendNotification(id, data));
        return toast.success(translation("friend_request_sent"));
      }
    } else {
      setDisable(false);
      return toast.success(translation("error"));
    }
  };


  /**
   * handle click of expression closes
   */
   const handleExpressionClose = () => {
    setAnchorExpression(null);
  }; 


 /**
   * reactions on pet_of_the_day
   */
  const getAllLikeReactionsOnPost = async () => {
    const response: any = await dispatch(allReaction(stateData.id,1));
    if (response && response?.length > 0) {
      console.log("response",response)
    }
  };



  /**
   * When user clicks on any
   */
     const handleLikeOpen = () => {
      setLikeOpen(true);
      // getAllLikeReactionsOnPost();
    };

  const handleAddEmoji = async (emojiCode: string, type: string) => {
    handleExpressionClose();
    setdisable(true)
  
    let crudOperation = "insert";
 

  if (String.fromCodePoint(Number(emojiCode)) === userReaction.reaction) {
          crudOperation = "delete";
          userReaction.totalReaction =
          userReaction.totalReaction - 1;
          userReaction.reaction = "0";
   } else if (userReaction.reaction !== "0") {
        crudOperation = "update";
        userReaction.reaction =String.fromCodePoint(
         Number(emojiCode)
   );
   } else {
     console.log("insert")
        crudOperation = "insert";
        userReaction.totalReaction  =
        userReaction.totalReaction + 1;
        userReaction.reaction = String.fromCodePoint(
   Number(emojiCode)
);
  }

  //  setUserReaction(allReaction);
    handleExpressionClose();
    await dispatch(
      addpetofdayReaction(
       
        userInfo.id,
        {
          pet_of_the_day_id: stateData.id,
          reaction: String.fromCodePoint(Number(emojiCode)),
        },
        crudOperation
      )
    );
     setdisable(false);
  
  };
/**
   * handle click of expression opens
   */
 const handleExpressionClick = (
  event: React.MouseEvent<HTMLButtonElement>,

) => {
  
  setAnchorExpression(event.currentTarget);
 
};


  /**
   * when like popup closes
   */
   const handleLikeClose = () => {
    setLikeOpen(false);
  };

  return (
    <Box>
      <Grid container>
        <Grid item md={3}>
          <LeftSidebar />
        </Grid>

        <Grid item md={6}>
          <Box className={commonStyle.p20}>
            <Typography
              variant="h6"
              className={`${commonStyle.grey} ${commonStyle.mb15} ${commonStyle.fontWeight600}`}
            >
              {translation("pet_of_the_day")}
            </Typography>
          
            <Box className={commonStyle.card}>
              <Box>
                <Grid container className={commonStyle.p20}>
                  <Grid item md={6}>
                    <Box className={commonStyle.wallUserInfo}>
                      <Avatar
                        alt={translation("story_person")}
                        src={
                          userData.length > 0 && userData[0].profile_image
                            ? userData[0].profile_image
                            : minImage
                        }
                      />
                      <Box className={commonStyle.ml10}>
                        <Typography
                          variant="h2"
                          className={commonStyle.flexCenter}
                        >
                          {userData.length > 0 && (
                            <Link
                              to={{
                                pathname: `${RoutingLinks.profiles}${userData[0].id}`,
                              }}
                            >                          
                              {capitalize(userData[0].first_name) +
                              " " +
                              capitalize(userData[0].last_name)}                             
                            </Link>
                          )
                          }

                        </Typography>
                        <Typography>
                          {userData.length > 0 ? userData[0].category : null}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item md={6} className={commonStyle.textRight}>
                    <Box className={commonStyle.blueBtn}>
                      {requestType.length > 0 ? (
                        //
                        <Button disabled>{requestType[0].request_type}</Button>
                      ) : (
                        userId !== id &&
                        id && (
                          <Button
                            disabled={disable}
                            onClick={() => changeRequest()}
                          >
                            {translation("add_friend")}
                          </Button>
                        )
                      )}
                    </Box>
                  </Grid>
                </Grid>

                <img
                  src={
                    userData.length > 0 && userData[0].profile_image
                      ? userData[0].profile_image
                      : defaultUserprofile
                  }
                  alt={translation("images")}
                  className={styleClasses.petDayImg}
                />

                <Box className={commonStyle.p20}>
                <Typography className={`${commonStyle.commonLineClamp}`}>
                  {userData.length > 0 ? userData[0].description : null}
                </Typography>
                </Box>
              </Box>
              <Box className={`${styleClass.commentBtns} ${styleClass.paddingLike}`}>


{userReaction.totalReaction > 0 && (
          <Box className={`${styleClass.actionBtns} ${commonStyle.dflex}`}>
            <Button variant="text" className={commonStyle.blue}>
              <img src={likelcon} alt={translation("altlike")} />
            </Button>
            {/* <Button variant="text" className={commonStyle.red}>
            <Favorite />
          </Button> */}
            <Button
              variant="text"
              onClick={() => handleLikeOpen()}
            >
              {userReaction.totalReaction} {translation("people")}
            </Button>
          </Box>
        )}
             {openLike && userReaction.totalReaction > 0 && ( 
          <CommonReactionModal
            name="reaction_of_friends"
            open={openLike}
            onClose={handleLikeClose}
            id={stateData.id}
          />
        )}

          <Button
            variant="text"
            onClick={(e) =>
              handleExpressionClick(
                e,
                
              )
            }
          >
             {userReaction.reaction!== "0"? (
              userReaction.reaction
            ) :  
            
              
                <ThumbUpAlt />
              }{" "}
            {translation("like")}
          </Button>
          <Popover
            open={openExpression}
            anchorEl={anchorExpression}
            onClose={handleExpressionClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            className={`${styleClass.emojiShadow}`}
          >
            <ul
              className={`${commonStyle.flexCenter} ${styleClass.emojiList}`}
            >
              <li>
                <Tooltip
                  title={translation("like")}
                  disableTouchListener={true}
                >
                  <span
                    style={disabled ? { pointerEvents: "none" } : {}}
                    onClick={() => handleAddEmoji("0x1F44D", "post")}
                  >
                    &#128077;
                  </span>
                </Tooltip>
              </li>
              <li>
                <Tooltip
                  title={translation("heart")}
                  disableTouchListener={true}
                >
                  <span
                    style={disabled ? { pointerEvents: "none" } : {}}
                    onClick={() => handleAddEmoji("0x1F497", "post")}
                  >
                    &#128151;
                  </span>
                </Tooltip>
              </li>
              <li>
                <Tooltip
                  title={translation("laugh")}
                  disableTouchListener={true}
                >
                  <span
                    style={disabled ? { pointerEvents: "none" } : {}}
                    onClick={() => handleAddEmoji("0x1F603", "post")}
                  >
                    &#128515;
                  </span>
                </Tooltip>
              </li>
              <li>
                <Tooltip
                  title={translation("angry")}
                  disableTouchListener={true}
                >
                  <span
                    style={disabled ? { pointerEvents: "none" } : {}}
                    onClick={() => handleAddEmoji("0x1F621", "post")}
                  >
                    &#128545;
                  </span>
                </Tooltip>
              </li>
            </ul>
          </Popover>
          
        </Box>
            </Box>
          </Box>
        
        </Grid>

        <Grid item md={3}>
          <RightSidebar />
        </Grid>
      </Grid>
    </Box>
  );
};

export default PetOfTheDay;
