import { postImagesBucketName } from "../utils/constants";
import awsconfig from "./awsConfig";
const AWS = require("aws-sdk");

/**
 * Upload base64 image files to s3
 * @param imageBuffer base64 file data
 * @param bucketName targeted s3 bucket name
 * @param filePath file path in the bucket
 * @returns s3 bucket url of the uploaded image
 */
export const uploadImage = async (
  imageBuffer: any,
  bucketName: string,
  filePath: string
) => {
  return new Promise((resolve: any, reject: any) => {
    let extension = undefined;
    let lowerCase = imageBuffer.toLowerCase();

    if (lowerCase.indexOf("png") !== -1) extension = "png";
    else if (
      lowerCase.indexOf("jpg") !== -1 ||
      lowerCase.indexOf("jpeg") !== -1
    )
      extension = "jpg";
    else extension = "tiff";

    let buf = Buffer.from(
      imageBuffer.replace(/^data:image\/\w+;base64,/, ""),
      "base64"
    );

    filePath = filePath + "." + extension;

    AWS.config.update(awsconfig);
    var options = { partSize: 10 * 1024 * 1024, queueSize: 1 };
    const bucket = new AWS.S3({
      params: { Bucket: bucketName },
      useAccelerateEndpoint: true,
    });
    const params = {
      Body: buf,
      ContentEncoding: "base64",
      ContentType: `image/${extension}`,
      ACL: "public-read",
      Key: filePath,
    };

    try {
      bucket.upload(params, options, function (s3Err: any, data: any) {
        if (s3Err) {
          throw s3Err;
        }
        resolve(data.Location);
      });
    } catch (err) {
      console.log("Error - ", err);
    }
  });
};

/**
 * Upload base64 video files to s3
 * @param vidweoBuffer base64 file data
 * @param bucketName targeted s3 bucket name
 * @param filePath file path in the bucket
 * @returns s3 bucket url of the uploaded image
 */
export const uploadVideo = async (
  videoBuffer: any,
  bucketName: string,
  filePath: string
) => {
  return new Promise((resolve: any, reject: any) => {
    let extension = undefined;
    let lowerCase = videoBuffer.toLowerCase();

    if (lowerCase.indexOf("mov") !== -1) extension = "mov";
    else if (lowerCase.indexOf("mp4") !== -1 || lowerCase.indexOf("mov") !== -1)
      extension = "mp4";
    else extension = "mpeg";

    let buf = Buffer.from(
      videoBuffer.replace(/^data:video\/\w+;base64,/, ""),
      "base64"
    );
    var options = { partSize: 10 * 1024 * 1024, queueSize: 1 };
    filePath = filePath + "." + extension;
    AWS.config.update(awsconfig);
    const bucket = new AWS.S3({
      params: { Bucket: bucketName },
      useAccelerateEndpoint: true,
    });
    const params = {
      Body: buf,
      ContentType: `video/${extension}`,
      ACL: "public-read",
      ContentEncoding: "base64",
      Key: filePath,
    };

    try {
      bucket.upload(params, options, function (s3Err: any, data: any) {
        if (s3Err) {
          console.log("Error---------", s3Err);
          throw s3Err;
        }
        resolve(data.Location);
      });
    } catch (err) {
      console.log("Error - ", err);
    }
  });
};

/**
 * Upload any file to s3 bucket
 * @param file base64 data of the file
 * @param bucketName targeted bucket name
 * @param filePath file path in the bucket
 * @returns s3 bucket url of the uploaded file
 */
export const uploadFiles = async (
  file: any,
  bucketName: string,
  filePath: string
) => {
  let extension = file.match(/[^:/]\w+(?=;|,)/)[0];
  let mimeType = file.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0];

  return new Promise((resolve: any, reject: any) => {
    let buf = Buffer.from(file.split(",")[1], "base64");

    AWS.config.update(awsconfig);
    const bucket = new AWS.S3({
      params: { Bucket: bucketName },
      useAccelerateEndpoint: true,
    });
    const params = {
      Body: buf,
      ContentEncoding: "base64",
      ContentType: mimeType,
      ACL: "public-read",
      Key: `${filePath}.${extension}`,
    };

    try {
      bucket.upload(params, function (s3Err: any, data: any) {
        if (s3Err) {
          throw s3Err;
        }
        resolve(data.Location);
      });
    } catch (err) {
      console.log("Error - ", err);
    }
  });
};

/**
 * Uploading files to s3
 * @param req Http Request Object
 * @param res Http Response Object
 */
export const uploadChatFile = async (files: any, sender_id: any) => {
  try {
    let uploadedFiles: string[] = [],
      url: any = "";
    if (files && files.length) {
      for (let i = 0; i < files.length; i++) {
        url = await uploadFiles(
          files[i],
          postImagesBucketName,
          `chat-media/sender-${sender_id}/${Date.now().toString()}`
        );
        uploadedFiles[i] = url;
      }
    }
    return uploadedFiles;
  } catch (error) {
    return error;
  }
};
