import { makeStyles } from "@mui/styles";

export const SidebarStyles = makeStyles(() => {
  return {
    SidebarMain: {
      backgroundColor: "#D95557",
      padding: "25px",
      color: "#fff",
      position: "sticky",
      top: "84px",
      height: "70vh",
      overflowY: "scroll",
    },

    sidebarScroll: {
      height: "calc(100vh - 120px)",
      overflow: "auto",
    },

    linkPoint: {
      margin: "20px 0",
      "& a": {
        color: "#fff",
        textDecoration: "none",
      },
      "& img": {
        width: "32px",
        marginRight: "20px",
      },
    },

    rightList: {
      "& li": {
        padding: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "5px",
        position: "relative",
      },
    },

    messageNotifyNew: {
      position: "absolute",
      top: "14px",
right: "4px",
width: "14px !important",
backgroundColor: "#000",
height: "20",
borderRadius: "100px",
color: "white",
margin: "0",
display: "flex",
alignItem: "center",
justifyContent: "center",
fontSize: "12px",
    },

    hoverList: {
      "& li": {
        "&:hover": {
          backgroundColor: "#01B4BC",
        },
      },
    },

    petOfDay: {
      marginBottom: "20px",
      "& h6": {
        fontFamily: "Showcard Gothic",
      },
    },

    petDayImg: {
      border: "6px solid #fff",
      borderRadius: "9px",
      overflow: "hidden",
      marginTop: "10px",
      position: "relative",
      "& img": {
        width: "100%",
        height: "150px",
        objectFit: "cover",
        objectPosition: "center",
      },
    },

    dayDetails: {
      position: "absolute",
      backgroundColor: "rgba(0,0,0, 0.3)",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "flex-end",
      justifyContent: "space-between",
      "& p": {
        padding: "10px",
      },
      "& a": {
        color: "#c3c3c3",
        textDecoration: "none",
        fontWeight: 400,
        fontSize: "14px",
      },
    },

    requestsFriend: {
      borderTop: "1px solid #fff",
    },

    requestAvatar: {
      "& img": {
        width: "100%",
        margin: 0,
        height: "100%",
        objectFit: "cover",
        objectPosition: "center",
      },
    },

    avatarText: {
      "& span": {
        fontSize: "15px",
      },
      "& p": {
        color: "#ffffff96",
        fontSize: "12px",
        WebkitLineClamp: 1,
        display: "-webkit-box",
        WebkitBoxOrient: "vertical",
        overflow: "hidden",
        textOverflow: "ellipsis",
        wordBreak: "break-all",
      },
    },

    requestHeader: {
      margin: "10px 0",
      "& h6": {
        fontSize: "18px",
      },
      "& p": {
        fontWeight: 200,
      },
    },

    listAvatarSide: {
      "& .css-518kzi-MuiListItemSecondaryAction-root": {
        right: 0,
      },
    },

    active: {
      "& .css-518kzi-MuiListItemSecondaryAction-root": {
        right: 0,
      },
      backgroundColor: "#01B4BC",
    },

    storiesList: {
      listStyle: "none",
      padding: 0,
      margin: 0,
      "& li": {
        border: "2px solid #01B4BC",
        borderRadius: "100px",
        width: "55px",
        minWidth: "55px",
        height: "55px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
        cursor: "pointer",
        "&:last-child": {
          margin: "0 !important",
        },
        "& img": {
          width: "100%",
        },
      },
    },

    storyAvatarBg: {
      backgroundColor: "rgba(255, 255, 255, 0.4) !important",
      color: "#D95557 !important",
    },

    smallPrice: {
      opacity: 0.4,
      fontSize: "12px !important",
      paddingBottom: "12px",
    },

    petDetails: {
      display: "flex",
      marginBottom: "5px",
    },

    petProductLabel: {
      minWidth: "100px",
      fontSize: "14px !important",
    },

    petProductDesc: {
      opacity: 0.4,
      fontSize: "14px !important",
    },

    chatSearchbar: {
      backgroundColor: "#fff",
      color: "#000",
      borderRadius: "100px",
      "& div": {
        "& input": {
          fontSize: "14px",
        },
        "& fieldset": {
          display: "none",
        },
      },
    },

    sidebarActive: {
      backgroundColor: "#01B4BC",
    },
  };
});
