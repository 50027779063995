import React from "react";
import { useTranslation } from "react-i18next";
import { ICommonPopover } from "../../interfaceModules/ICommonInteface";
import {
  Button,
  Modal,
  Popover,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import { Box } from "@mui/system";

import { createReport } from "../../redux/action-creators/reportAction";

import { CommonButton } from "../../stories/Button";
import ApiResponse from "../../interfaceModules/IApiResponse";

import { ProfileStyle } from "../../screens/profile/ProfileStyle";
import { UserCommonStyles } from "../../styles/CommonStyles";
import useState from "react";
import { toast } from "../../utils/toastsMessage";

const CommonPopover: React.FC<ICommonPopover> = (props: ICommonPopover) => {
  const { t: translation } = useTranslation();

  const styleClasses = ProfileStyle();
  const commonStyle = UserCommonStyles();
  const userId = Number(localStorage.getItem("userId"));

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "#fff",
    boxShadow: "0px 1px 4px #0000001A",
    borderRadius: "13px",
    padding: "10px 20px 20px",
  };

  const [report_content, setreport_content] = React.useState("");
  const [disable, setdisable] = React.useState(false);

  const handleChange = (event: SelectChangeEvent) => {
    setreport_content(event.target.value as string);
  };

  //To create report for user and post
  const createReports = (id: number) => {
    setdisable(true);
    const data = {
      type: props?.reportPostId ? "post" : "user",
      description: report_content,
    };

    createReport(id, userId, data).then((response: ApiResponse) => {
      if (response) {
        props?.getReportedId(id);
        handleCloseReport();
        props?.modalhandleClose();
        setdisable(false);
        return toast.success(translation("Reported successfully"));
      }
    });
  };
  const [openReport, setOpenReport] = React.useState(false);

  const handleOpenReport = () => setOpenReport(true);
  const handleCloseReport = () => setOpenReport(false);

  return (
    <>
      <Popover
        id={props?.idEdit}
        open={props?.open}
        anchorEl={props?.anchorEl}
        onClose={props?.handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        className={styleClasses.popoverShadow}
      >
        <ul className={styleClasses.postPopup}>
          {props.setEditPost ? (
            <>
              <li onClick={props?.setEditPost}>{translation("edit_post")}</li>
              <li onClick={props?.handleOpen}> {translation("delete_post")}</li>
            </>
          ) : props.reportUserId ? (
            <>
              <li onClick={handleOpenReport}>{translation("report_user")}</li>
              <li onClick={props?.handleOpen}>{translation("block_user")}</li>
            </>
          ) : (
            <>
              <li onClick={handleOpenReport}>{translation("report_post")}</li>
            </>
          )}
          </ul>
           </Popover>

          <Modal
            open={openReport}
            onClose={handleCloseReport}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                className={commonStyle.mb20}
              >
                {props.reportPostId ? "Report a Post" : "Report a User"}
              </Typography>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  {translation("select_problem")}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={report_content}
                  label={translation("select_problem")}
                  onChange={handleChange}
                >
                  <MenuItem value={"False Information"}>
                    {translation("False Information")}
                  </MenuItem>
                  <MenuItem value={"Voilence"}>
                    {translation("Voilence")}
                  </MenuItem>
                  <MenuItem value={"Spam"}>{translation("Spam")}</MenuItem>
                </Select>
              </FormControl>

              <Box
                className={`${commonStyle.blueBtn} ${commonStyle.pt20} ${commonStyle.textCenter}`}
              >
                <Button
                  disabled={disable}
                  onClick={() =>
                    createReports(
                      props?.reportPostId
                        ? props?.reportPostId
                        : props?.reportUserId
                    )
                  }
                >
                  {translation("report")}
                </Button>
              </Box>
            </Box>
          </Modal>

          <Modal
            open={props?.modalopen}
            onClose={props?.modalhandleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className={styleClasses.modalShadow}
          >
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                {props?.deletePost
                  ? translation("delete_ms")
                  : translation("statement_block")}
              </Typography>
              <Box
                id="modal-modal-description"
                sx={{ mt: 2 }}
                className={commonStyle.flexCenter}
              >
                <Box
                  className={`${commonStyle.blueBtn} ${commonStyle.pt20} ${commonStyle.mr10}`}
                >
                  {props.deletePost ? (
                    <Button
                      disabled={props?.deletePostButtonDisable}
                      onClick={props?.deletePost}
                    >
                      {" "}
                      {translation("yes")}
                    </Button>
                  ) : (
                    <Button
                      disabled={props?.blockButtonDisable}
                      onClick={() =>
                        props?.reportUserId
                          ? props?.blockUsersbyid(props.reportUserId)
                          : null
                      }
                    >
                      {" "}
                      {translation("yes")}
                    </Button>
                  )}
                </Box>

                <Box className={`${commonStyle.blueBtn} ${commonStyle.pt20}`}>
                  <Button onClick={props.modalhandleClose}>
                    {translation("no")}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Modal>
     
    </>
  );
};
export default CommonPopover;
