import { useTranslation } from "react-i18next";

import { Popover, Tooltip } from "@mui/material";

import { IEmojiPopoverProps } from "../../utils/interfaceModel";

import { UserCommonStyles } from "../../styles/CommonStyles";
import { HomeStyles } from "../../styles/HomeStyles";

const EmojiPopover: React.FC<IEmojiPopoverProps> = (props) => {
  const { t: translation } = useTranslation();
  const commonStyle = UserCommonStyles();
  const homeStyleClasses = HomeStyles();
  return (
    <>
      <Popover
        open={props?.open}
        anchorEl={props?.anchorEl}
        onClose={() => props?.onClose()}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        className={`${homeStyleClasses.emojiShadow}`}
      >
        <ul
          className={`${commonStyle.flexCenter} ${homeStyleClasses.emojiList}`}
        >
          <li onClick={() => props?.onClick(translation("like"))}>
            <Tooltip title={translation("like")}>
              <span>&#128077;</span>
            </Tooltip>
          </li>
          <li onClick={() => props?.onClick(translation("heart"))}>
            <Tooltip title={translation("heart")}>
              <span>&#128151;</span>
            </Tooltip>
          </li>
          <li onClick={() => props?.onClick(translation("laugh"))}>
            <Tooltip title={translation("laugh")}>
              <span>&#128515;</span>
            </Tooltip>
          </li>
          <li onClick={() => props?.onClick(translation("angry"))}>
            <Tooltip title={translation("angry")}>
              <span>&#128545;</span>
            </Tooltip>
          </li>
        </ul>
      </Popover>
    </>
  );
};

export default EmojiPopover;
