import axios from "../../services/axiosService";
import { Dispatch } from "redux";
import { ActionType } from "../action-types/index";
import { Action } from "../actions/AuthAction";
import { IAdoption } from "../../interfaceModules/IPetsInteface";
const URL = process.env.REACT_APP_API_URL;
export const createAdoption = (
  data: IAdoption,
  others: {
    locationPredictions: any;
    files: string[];
    userId: string;
  }
) => async (dispatch: Dispatch<Action>) => {
  try {
    const response = await axios.post(`${URL}/api/v1/pet/add`, {
      data,
      ...others,
    });
   
    return response.data.data;
  } catch (err) {
    dispatch({
      type: ActionType.FAILURE,
    });
  }
};

/**
 * list of adoption
 * @param searchtext
 * @param type
 * @param breed
 * @returns
 */
export const listAdoption = (
  searchtext: string,
  type: string,
  breed: string,
  offset : number
) => async (dispatch: Dispatch<Action>) => {
  try {
    const response = await axios.get(
      `${URL}/api/v1/pet/lists/?searchText=${searchtext}&type=${type}&breed=${breed}&offset=${offset}`
    );
    return response.data.data.data;
  } catch (err) {
    dispatch({
      type: ActionType.FAILURE,
    });
  }
};

/**
 * fetch data by id
 * @param id
 * @param type
 * @returns
 */
export const getdatabyId = (id: string, type: string) => async (
  dispatch: Dispatch<Action>
) => {
  try {
    const response = await axios.get(`${URL}/api/v1/pet/details/${id}/${type}`);
    return response.data.data.data;
  } catch (err) {
    dispatch({
      type: ActionType.FAILURE,
    });
  }
};
