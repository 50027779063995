import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  getAllChats,
  getChatChannel,
} from "../../redux/action-creators/chatActions";
import PubNub from "pubnub";
import { HomeStyles } from "../../styles/HomeStyles";
import { Avatar, Box, Modal, Typography } from "@mui/material";
import defaultUserprofile from "../../images/defaultUserprofile.png";
import minImage from "../../images/minImage.png";

import { UserCommonStyles } from "../../styles/CommonStyles";
import { toast } from "../../utils/toastsMessage";
import ShortcutIcon from "@mui/icons-material/Shortcut";
import {
  ChatBubbleOutline,
  HighlightOff,
  HighlightOffOutlined,
} from "@mui/icons-material";
import { IReceiverdata } from "../../utils/interfaceModel";
const SharePopover: React.FC<any> = (props: any) => {
  const authData = useSelector(
    (state: RootStateOrAny) => state.authReducer.authData
  );

  const [channel, setChannel] = useState<string>("");
  const [open, setopen] = useState(false);
  const handleClose = () => setopen(false);
  const [disable, setdisable] = useState(false);

  const styleClasses = HomeStyles();
  const commonStyle = UserCommonStyles();
  const { t: translation } = useTranslation();

  useEffect(() => {
    setopen(true);
  }, []);

  const pubnubPublishKey = process.env.REACT_APP_PUBNUB_PUBLISH_KEY;
  const pubnubSubscribeKey = process.env.REACT_APP_PUBNUB_SUBSCRIBE_KEY;

  const pubnub = useMemo(() => {
    const pubnub_config = {
      publishKey: pubnubPublishKey,
      subscribeKey: pubnubSubscribeKey,
      uuid: authData?.email,
      logVerbosity: true,
      authKey: authData?.token,
      ssl: true,
    };
    return new PubNub(pubnub_config);
  }, [authData?.email, authData?.token]);

  const post = props?.post;
  const dispatch = useDispatch();
  const [allFriendListLoader, setAllFriendListLoader] = useState(false);
  const [allReceivers, setAllReceivers] = useState<any>([]);

  const getReceivers = useCallback(async () => {
    setAllFriendListLoader(true);
    const response: any = await dispatch(getAllChats(authData?.id));
    if (response && response?.isFound) {
      setAllReceivers(response?.data);
    }
    setAllFriendListLoader(false);
  }, [authData?.id, dispatch]);

  useEffect(() => {
    getReceivers();
  }, [getReceivers]);

  const onSend = async (user: any, i: number) => {
    setdisable(true);

    const response: any = await dispatch(
      getChatChannel({
        sender_id: authData?.id,
        receiver_id: user?.id,
      })
    );

    if (response && response?.channel) {
      setChannel(response.channel);
    }

    let postType = "";
    if (props?.post) {
      let image = JSON.parse(props?.post?.gallery_images);

      if (image && (
        image[0]?.split(".")?.pop() === "mov" ||
        image[0]?.split(".")?.pop() === "mp4" ||
        image[0]?.split(".")?.pop() === "mpeg")
      ) {
        postType = "video";
      }

      await pubnub.publish({
        channel: response.channel,
        message: {
          type:
            postType !== ""
              ? postType
              : post.gallery_images !== null
                ? "image"
                : "text",
          text:
            postType !== ""
              ? post.gallery_images
              : post.gallery_images !== null
                ? post.gallery_images
                : translation("post_shared") + "..." + post.description,
          caption:
            post.gallery_images !== null
              ? translation("post_shared") + "..." + post.description
              : "",
          id: authData?.id,
        },
      });
      setdisable(false);
      props.close();
      return toast.success(translation("post_shared"));
    }
  };

  return (
    <>
      <Modal
        open={props.showModal}
        onClose={props.showModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={`${commonStyle.commonModalStyle} ${commonStyle.p20}`}>
          <Box className={`${commonStyle.cursorA} ${commonStyle.textRight}`}>
            <HighlightOff onClick={props.close} />
          </Box>
          <ul className={styleClasses.modalDescTagAlt}>
            {allReceivers.length
              ? allReceivers.map((user: IReceiverdata, i: number) => {
                return (
                  <li key={i} className={styleClasses.shareListDone}>
                    <Box
                      className={`${commonStyle.wallUserInfo} ${commonStyle.cursorA}`}
                    >
                      <Avatar
                        alt="story_person"
                        src={
                          user.profile_image
                            ? user.profile_image
                            : minImage
                        }
                      />
                      <Box className={commonStyle.ml10}>
                        <Typography>{user.first_name}</Typography>
                      </Box>
                    </Box>
                    <Box className={commonStyle.orangeBtn}>
                      <button
                        disabled={disable}
                        onClick={() => onSend(user, i)}
                      >
                        Send
                      </button>
                    </Box>
                  </li>
                );
              })
              : !allFriendListLoader ? (
                <Box className={commonStyle.textFlexCenter}>
                  {translation("no_friend")}
                </Box>) : (<Box className={commonStyle.textFlexCenter}>
                  Loading...
                </Box>)
            }
          </ul>
        </Box>
      </Modal>
    </>
  );
};

export default SharePopover;
