import axios from "../../services/axiosService";
import { Dispatch } from "redux";
import { ActionType } from "../action-types/index";
import { Action } from "../actions/AuthAction";
const URL = process.env.REACT_APP_API_URL;

/**
 * get all users notification
 * @param userId
 * @param status
 * @returns
 */
export const getAllUsersNotification = (
  userId: number,
) => async (dispatch: Dispatch<Action>) => {
  try {
    const response = await axios.get(`${URL}/api/v1/notification/${userId}`);
    return response.data.data;
  } catch (err) {
    dispatch({
      type: ActionType.FAILURE,
    });
  }
};

/**
 * update notification
 * @param userId
 * @returns
 */
export const updateNotification = (userId: number) => async (
  dispatch: Dispatch
) => {
  try {
    const response = await axios.patch(`${URL}/api/v1/notification/${userId}`);
    return response.data;
  } catch (error) {
    dispatch({
      type: ActionType.FAILURE,
    });
  }
};

/**
 * get read notification of all users
 * @param userId
 * @param status
 * @returns
 */
export const getAllUsersReadNotification = (
  userId: number,
  status?: true
) => async (dispatch: Dispatch<Action>) => {
  try {
    console.log("userId",userId)
    const response = await axios.get(
      `${URL}/api/v1/notification/${userId}/${status}`
    );
    if(response.data.message==="authentication_error")
    {
      return response.data.message
    }
    else
    {
      dispatch({
        type: ActionType.Notification,
        payload: response.data.data,
      });
      return response.data.data
    } 
  
  } catch (err) {
    dispatch({
      type: ActionType.FAILURE,
    });
  }
};
