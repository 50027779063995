import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect, useState, useCallback } from "react";

import {
  Button,
  Grid,
  IconButton,
  Modal,
  Popover,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { MoreVert } from "@mui/icons-material";

import { getFriendsList } from "../../redux/action-creators/profileAction";
import { removeFriendbyId } from "../../redux/action-creators/requestAction";

import LeftSidebar from "../../components/sidebars/LeftSidebar";

import { capitalize } from "../../utils/helper";
import { toast } from "../../utils/toastsMessage";
import { IFriendsList } from "../../interfaceModules/IUserInterface";
import { RoutingLinks } from "../../utils/constants";

import { UserCommonStyles } from "../../styles/CommonStyles";
import { ProfileStyle } from "./ProfileStyle";
import defaultUserprofile from "../../images/defaultUserprofile.png";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#fff",
  border: "2px solid #000",
  p: 4,
};
const Friends = () => {
  const { t: translation } = useTranslation();
  const dispatch = useDispatch();
  const commonStyle = UserCommonStyles();
  const styleClasses = ProfileStyle();

  const [friendList, setfriendList] = useState<IFriendsList[]>([]);
  const [allFriendListLoader, setAllFriendListLoader] = useState(false);
  const [disable, setdisable] = useState(false);
  const [modalopen, setOpen] = useState(false);
  const [friendId, setfriendId] = useState(0);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  let id = Number(useParams<{ id: string }>().id);
  let userId;

  if (!id) {
    userId = localStorage.getItem("userId");
  }

  /**
   * Get The List of friends
   */
  const getAllfriend = useCallback(async () => {
    let result: any = await dispatch(getFriendsList(id ? id : userId));
    setAllFriendListLoader(false);
    setfriendList(result);
  }, [dispatch, userId, id]);

  useEffect(() => {
    setAllFriendListLoader(true);
    getAllfriend();
  }, [getAllfriend, id]);

  const handleOpen = () => setOpen(true);
  const modalhandleClose = () => setOpen(false);

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const idEdit = open ? translation("simple_pop") : undefined;

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    profile_id: number
  ) => {
    setAnchorEl(event.currentTarget);
    setfriendId(profile_id);
  };

  /**
   * To Remove friend from list
   * @returns
   */
  const removeFriend = async () => {
    setdisable(true);
    const response: any = await dispatch(removeFriendbyId(userId, friendId));

    if (response?.isDeleted) {
      setdisable(false);
      modalhandleClose();
      handleClose();
      getAllfriend();
      return toast.success(translation(response.message));
    }
  };

  return (
    <Box>
      <Box className={commonStyle.windowColor}>
        <Grid container>
          <Grid item md={3}>
            <LeftSidebar />
          </Grid>

          <Grid item md={9}>
            <Box
              className={`${commonStyle.mt20} ${commonStyle.p20}`}
            >
              <Grid container spacing={2} className={commonStyle.h100}>
                {friendList.length > 0
                  ? friendList.map((item, index) => (
                      <Grid item key={index} md={4}>
                        <Box
                          className={`${commonStyle.flexCenter} ${commonStyle.card} ${commonStyle.justifySpaceBetween} `}
                        >
                          <Link
                            to={{
                              pathname: `${RoutingLinks.profiles}${
                                item?.id ? item?.id : userId
                              }`,
                            }}
                            className={`${commonStyle.white} ${commonStyle.anchorDecoration}`}
                          >
                            <Box
                              className={`${commonStyle.flexCenter} ${styleClasses.friendCardImg}`}
                            >
                              <img
                                src={
                                  item?.profile_image
                                    ? item.profile_image
                                    : defaultUserprofile
                                }
                                alt={translation("image")}
                              />
                              <Box>
                                <Typography variant="h6">
                                  {capitalize(item.first_name)}{" "}
                                  {capitalize(item.last_name)}
                                </Typography>
                                {/* <Typography>Works at SpaceX</Typography> */}
                              </Box>
                            </Box>
                          </Link>

                          {id ? null : (
                            <IconButton
                              onClick={(e) => handleClick(e, item.id)}
                              className={`${commonStyle.iconBtn} ${commonStyle.mr10}`}
                            >
                              <MoreVert className={`${commonStyle.font30}`} />
                            </IconButton>
                          )}
                          <Popover
                            id={idEdit}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "left",
                            }}
                            className={styleClasses.popoverShadow}
                          >
                            <ul className={styleClasses.postPopup}>
                              <li onClick={handleOpen}>
                                {" "}
                                {translation("remove")}
                              </li>
                            
                            </ul>
                          </Popover>
                          
                        </Box>
                      </Grid>
                    ))
                  : !allFriendListLoader && (
                      <Box className={commonStyle.textFlexCenter}>
                        {translation("no_friend")}
                      </Box>
                    )}
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Modal
                                open={modalopen}
                                onClose={modalhandleClose}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                                className={styleClasses.modalShadow}
                              >
                                <Box sx={style}>
                                  <Typography
                                    id="modal-modal-title"
                                    variant="h6"
                                    component="h2"
                                  >
                                    {translation("remove_ms")}
                                  </Typography>
                                  <Box
                                    id="modal-modal-description"
                                    sx={{ mt: 2 }}
                                    className={commonStyle.flexCenter}
                                  >
                                    <Box
                                      className={`${commonStyle.blueBtn} ${commonStyle.pt20} ${commonStyle.mr10}`}
                                    >
                                      <Button
                                        disabled={disable}
                                        onClick={removeFriend}
                                      >
                                        {" "}
                                        {translation("yes")}
                                      </Button>
                                    </Box>

                                    <Box
                                      className={`${commonStyle.blueBtn} ${commonStyle.pt20}`}
                                    >
                                      <Button onClick={modalhandleClose}>
                                        {translation("no")}
                                      </Button>
                                    </Box>
                                  </Box>
                                </Box>
                              </Modal>
    </Box>
    
  );
};

export default Friends;
