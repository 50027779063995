import ApiResponse from "../../interfaceModules/IApiResponse"
import * as  http from "./http";
import { endpoint } from "../../utils/endPoints";

import axios from "../../services/axiosService";
import { Dispatch } from "redux";
import { ActionType } from "../action-types/index";
import { Action } from "../actions/AuthAction";
const URL = process.env.REACT_APP_API_URL;

export const createAd = (data): Promise<ApiResponse> => {
  return http.post(`${endpoint.ad.addlist}`, data)
}
export const addPackageHistory = (data): Promise<ApiResponse> => {
  return http.post(`${endpoint.ad.packageHistory}`, data)
}
export const updateAd = (data,id): Promise<ApiResponse> => { 
  return http.patch((`${endpoint.ad.updatead}`.replace(':id', `${id}`)),data)
}
/**
* get ad list of user
* @param name
* @returns
*/
export const getBalanceList = async (userId:number): Promise<ApiResponse> => {
  return http.get(`${endpoint.ad.userAdList}`.replace(':userId', `${userId}`));
}

export const getBalanceListofUser = async (userId:number): Promise<ApiResponse> => {
  return http.get(`${endpoint.ad.userBalanceList}`.replace(':userId', `${userId}`));
}

// export const createAd = (data: any) => async (
//   dispatch: Dispatch<Action>
// ) => {

//   try {
//     const response = await axios.post(`${URL}/api/v1/ad/add`,data);
//     return response.data.data;
//   } catch (err) {
//     dispatch({
//       type: ActionType.FAILURE,
//     });
//   }
// };

/**
 * get product details by id
 * @param adid
 * @returns
 */

export const getAdDetailsById = (adid: number) => async (
  dispatch: Dispatch
) => {
  try {
    const response = await axios.get(`${URL}/api/v1/ad/${adid}`);
    return response?.data?.data;
  } catch (err) {
    dispatch({
      type: ActionType.FAILURE,
    });
  }
};

/**
* get ad packages list
* @returns
*/
export const getAdPackagesList = async (): Promise<ApiResponse> => {
  return http.get(`${endpoint.ad.packageList}`);
}

/**
* get ad list of user
* @param userId
* @returns
*/
export const getAdListofUser = async (userId:number): Promise<ApiResponse> => {
  return http.get(`${endpoint.ad.userAdList}`.replace(':userId', `${userId}`));
}

/**
* add user action of ad
* @param name
* @returns
*/
export const addAdActionViewAndClick = (data): Promise<ApiResponse> => {
  return http.post(`${endpoint.ad.addAdActionViewAndClick}`,data);
}


export const updateAdAction = (status:number,id:number,userId:number): Promise<ApiResponse> => { 
  console.log("Update Ad action--",id,status)
  return http.patch((`${endpoint.ad.updateAdAction}`.replace(':ad_id', `${id}`)),{"status":status,"userId":userId});
}
