import React, { useState, useCallback, ChangeEvent } from "react";
import { Link, useLocation, RouteComponentProps } from "react-router-dom";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import ClipLoader from "react-spinners/ClipLoader";

import { Box } from "@mui/system";
import {
  Avatar,
  Button,
  Checkbox,
  Grid,
  IconButton,
  Modal,
  Popover,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  Add,
  HighlightOff,
  Search,
  Send,
  InsertPhoto,
  VideoLibrary,
  SentimentSatisfiedAlt,
} from "@mui/icons-material";
import { styled } from "@mui/material/styles";

import {
  getFriendsList,
  SearchFriendProfileById,
} from "../../redux/action-creators/profileAction";
import {
  getAllPostLists,
  getAllCommentsLists,
  CreatePost,
} from "../../redux/action-creators/postAction";
import { sendNotification } from "../../redux/action-creators/petPlayAction";
import { getStoryListsbyUserId } from "../../redux/action-creators/storyAction";

import LeftSidebar from "../../components/sidebars/LeftSidebar";
import RightSidebar from "../../components/sidebars/RightSidebar";
import CommonCard from "../../components/commonCard/CommonCard";
import CreateStoryModal from "../../components/commonModal/CreateStoryModal";
import imageCompression from "browser-image-compression";


import { ICreatePostState } from "../../interfaceModules/IPetsInteface";
import { toast } from "../../utils/toastsMessage";
import { isEmpty, capitalize, FileToBase64 } from "../../utils/helper";
import {
  RoutingLinks,
  empStr,
  VideoTypes,
  ImageExtentions,
  VideoExtentions,
  ImgTypeArray,
  ImageTypes,
  Type,
} from "../../utils/constants";

import SelectLanguage from "../../utils/lang";

import StoryPerson from "../../images/story-person.png";
import minImage from "../../images/minImage.png";

import Gallery from "../../images/gallery.png";
import FeelingActivity from "../../images/feeling-activity.png";
import TagFriends from "../../images/tag-friends.png";
import defaultUserprofile from "../../images/defaultUserprofile.png";

import { UserCommonStyles } from "../../styles/CommonStyles";
import { HomeStyles } from "../../styles/HomeStyles";

import { debounce } from "lodash";
import {
  IAllUserStoryResponse,
  IStoriesSidebarProps,
} from "../../interfaceModules/IStoriesInterface";

import { uploadImage, uploadVideo } from "../../utils/fileUpload";
let postImagesBucketName = process.env.REACT_APP_BUCKET_NAME;

/**
 * @method to get random string
 * @param length
 * @returns
 */
const getRandomString = (length: number) => {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "#fff",
  boxShadow: "0px 1px 4px #0000001A",
  borderRadius: "13px",
  padding: "10px 20px 20px",
};

let taggedFriendsIDs: string[] = [];

function ChildModal() {
  const { t: translation } = useTranslation();
  const dispatch = useDispatch();
  const commonStyle = UserCommonStyles();
  const styleClasses = HomeStyles();
  const [open, setOpen] = React.useState(false);
  const [friendsList, setFreindsList] = React.useState([]);
  const [searchItem, setSearchItem] = React.useState({
    searchText: empStr,
    showLoader: false,
  });
  const [loading, setloading] = useState(false);
  const [timeOutState, setTimeOutState] = React.useState(null);
  const [addedFriendsIDs, setAddedFriendsIDs] = React.useState([]);
  const userData = useSelector(
    (state: RootStateOrAny) => state.authReducer.authData
  );

  const getAllFriends = useCallback(async () => {
    const response: any = await dispatch(getFriendsList(userData.id));
    if (response && response?.length > 0) {
      setFreindsList(response);
    }
  }, [dispatch, userData.id]);

  /**
   * Sets the friendsList state
   */
  React.useEffect(() => {
    if (Object.keys(friendsList).length === 0) {
      getAllFriends();
    }
  }, [friendsList, getAllFriends]);

  /**
   * Open the Tagged User modal
   */
  const handleOpen = () => {
    setOpen(true);
  };

  /**
   * Close the Tagged User modal
   */
  const handleClose = () => {
    setOpen(false);
  };

  /**
   * Get Friends List
   */

  /**
   * Seach Friend Details
   */
  const getSearchResult = debounce(async (searchTerm: string) => {
    if (isEmpty(searchTerm) || isEmpty(searchItem.searchText)) {
      setFreindsList([]);
      return null;
    }

    const response: any = await dispatch(
      SearchFriendProfileById(userData.id, searchTerm)
    );

    if (response && response.length > 0) {
      setFreindsList(response);
    }else{
      setFreindsList([]);
    }
  }, 1000);

  /**
   * Select friend from Freinds List
   * @param event
   */
  const handleSearchChange = async (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    event.preventDefault();
    const searchKeyword = event.target.value.trim();

    if (timeOutState) {
      clearInterval(timeOutState);
    }

    setSearchItem({
      searchText: searchKeyword,
      showLoader: true,
    });

    if (searchKeyword.length > 0) {
      let result = setTimeout(async () => {
        await getSearchResult(searchKeyword);
      }, 800);
      setTimeOutState(result);
    } else {
      setSearchItem((prevState) => ({
        ...prevState,
        showLoader: false,
      }));
    }

    if (searchKeyword.length === 0) {
      await getAllFriends();
    }
  };

  /**
   * Add new friends
   * @param id
   */
  const handleAddNewFriends = (id: number) => {
    let addedFriendsIDLists = [...addedFriendsIDs];

    const index = addedFriendsIDLists.indexOf(id);

    if (index > -1) {
      addedFriendsIDLists.splice(index, 1);
    } else {
      addedFriendsIDLists.push(id);
    }
    setAddedFriendsIDs(addedFriendsIDLists);
  };

  /**
   * When submit the friends list,then close the modal
   */
  const tagFreinds = () => {
    taggedFriendsIDs = [...addedFriendsIDs];
    handleClose();
  };

  return (
    <>
      <img src={TagFriends} alt="" onClick={handleOpen} />

      <Modal
        hideBackdrop
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={style}>
          <Box className={styleClasses.modalHeader}>
            <Typography id="modal-modal-title" variant="h6">
              {translation("tag_friends")}
            </Typography>
            <HighlightOff onClick={handleClose} />
          </Box>

          <Box className={`${commonStyle.tagMain} ${commonStyle.flexCenter}`}>
            <Box
              className={`${commonStyle.mr20} ${commonStyle.searchTag} ${commonStyle.w100}`}
            >
              <TextField
                onChange={(e) => handleSearchChange(e)}
                placeholder={translation("find_friend")}
                variant="standard"
              />
              <Search />
            </Box>
            <Box className={`${commonStyle.blueBtn}`}>
              <Button onClick={tagFreinds} className={`${commonStyle.w100}`}>
                {translation("done")}
              </Button>
            </Box>
          </Box>

          <Typography
            variant="h6"
            className={`${commonStyle.grey} ${commonStyle.font16} ${commonStyle.fontWeight400}`}
          >
            {translation("suggest")}
          </Typography>
          <Box
            id="modal-modal-description"
            sx={{ mt: 2 }}
            className={styleClasses.modalDescTag}
          >
            {friendsList &&
              friendsList.length > 0 &&
              friendsList.map((friendDetails, index) => (
                <Grid container key={index}>
                  <Grid item md={11}>
                    <Box
                      className={`${commonStyle.pb20} ${commonStyle.wallUserInfo}`}
                    >
                      <Avatar
                        alt={translation("story_person")}
                        src={
                          friendDetails?.profile_image
                            ? friendDetails.profile_image
                            : defaultUserprofile
                        }
                      />
                      <Box className={commonStyle.ml10}>
                        <Typography variant="h2">
                          {`${capitalize(
                            friendDetails.first_name
                          )} ${capitalize(friendDetails.last_name)}`}
                        </Typography>
                        {/* <Typography>Lucasfilm</Typography> */}
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item md={1}>
                    <Checkbox
                      checked={addedFriendsIDs.includes(friendDetails.id)}
                      onChange={() => handleAddNewFriends(friendDetails.id)}
                    />
                  </Grid>
                </Grid>
              ))}
          </Box>
        </Box>
      </Modal>
    </>
  );
}

const Home = (props: RouteComponentProps) => {
  const { t: translation } = useTranslation();

  //url retreive
  let location = useLocation();
  const commonStyle = UserCommonStyles();
  const styleClasses = HomeStyles();
  const dispatch = useDispatch();
  const Input = styled("input")({
    display: "none",
  });
  const userData = useSelector(
    (state: RootStateOrAny) => state.authReducer.authData
  );

  //type set
  const [postType, setPostType] = useState("");
  const [allPosts, setAllPosts] = useState([]);
  const [allComments, setAllComments] = useState([]);

  const [postImages, setPostImages] = useState<File[]>([]);
  const [postVideos, setPostVideos] = useState<File[]>([]);

  const [showPreview, setShowPreview] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [postLoading, setPostLoading] = useState<boolean>(false);
  const [adData, setAdData] = useState<any[]>([]);

  const [disableimg, setdisableimg] = useState(false);
  const [disablevideo, setdisablevideo] = useState(false);

  const [open, setOpen] = useState(false);
  const userInfo = userData;
  const [userStories, setUserStories] = useState<IAllUserStoryResponse[]>([]);
  const [postDescription, setPostDescription] = useState("");
  const [showCreatePostButton, setShowCreatePostButton] = useState(false);
  const [newPostGalleryImages, setNewPostGalleryImages] = useState([]);
  const [newPostGalleryImagesurl, setNewPostGalleryImagesurl] = useState<any>();

  const [openStoryModal, setOpenStoryModal] = React.useState(false);
  const [state, setState] = useState({
    allPosts: [],
    offset: 0,
    prevOffset: 0,
    hasMore: true,
  });

  const handleOpenStory = () => setOpenStoryModal(true);
  const handleCloseStory = () => {
    getStoryLists();
    setOpenStoryModal(false);
  };

  //Add comment,aatch file and react on posts states
  const [anchorEl, setAnchorEl] = useState(null);

  const openEl = Boolean(anchorEl);

  const [anchorE2, setAnchorE2] = useState(null);
  const openE = Boolean(anchorE2);
  const id = openE ? "simple-popover" : undefined;
  const handleClick = (event) => {
    setAnchorE2(event.currentTarget);
  };
  const handleClosepost = () => {
    setAnchorE2(null);
  };

  //Get all the posts
  const getAllPosts = async (
    type: string,
    firstLoad: boolean,
    startOffset?: boolean
  ) => {
    let offset = startOffset ? 0 : state.offset;

    if (state.offset !== state.prevOffset || firstLoad) {
      setLoading(true);
      let result: any = await dispatch(
        getAllPostLists(type, userInfo.id, offset)
      );
        
      if ((result?.data?.length > 0) || result!==undefined )  {    
        let resultData:any = [];
        if(result.length){
          resultData = (result?.data?.length)? result.data:result
        }else if(result.data.length) resultData = result?.data;
     
        setState((prevState) => ({
          ...prevState,
          prevOffset: firstLoad ? 0 : offset,
          offset: firstLoad ? 10 : offset + 10,
          allPosts: firstLoad
            ? resultData
            : prevState.allPosts.concat(resultData),
          hasMore: true,
        }));

        if (result && result?.adData?.data.length > 0) {
          setAdData(
            result.adData.data.map((i: { id: number }) => ({
              ...i,
              type: "ad",
            }))
          );
        }
      } else {
        if (result?.data) {
          setState((prevState) => ({
            ...prevState,
            allPosts: firstLoad
              ? result?.data
              : prevState.allPosts.concat(result?.data),
            hasMore: false,
          }));
        }
      }
      setLoading(false);
    }
  };

  //Get all the posts comments
  const getAllPostsComments = useCallback(
    async (type: string) => {
      const response: any = await dispatch(getAllCommentsLists(type));
      if (response && response.length > 0) {
        let data = [...response];
        setAllComments(data);
      }
    },
    [dispatch]
  );

  //Sets the post lists
  React.useEffect(() => {
    if (location.pathname === RoutingLinks.home) {
      setPostType("post");
      getAllPosts("post", true);
      getAllPostsComments("post");
    } else {
      const abc: string = location.pathname;
      let postLocation = abc.split("/")[1];
      setPostType(postLocation);
      getAllPosts(postLocation, true);
      getAllPostsComments(postLocation);
    }
    if (userStories.length === 0) {
      getStoryLists();
    }
  }, []);

  /**
   * get story list
   */
  const getStoryLists = async () => {
    let result: any = await dispatch(getStoryListsbyUserId(userData.id));
    if (result && result.length > 0) {
      let myStory: any[] = [],
        otherStories: any[] = [];
      result.map((story: any) => {
        if (story.user_id === userInfo.id) {
          myStory = [story];
        } else {
          otherStories = [...otherStories, story];
        }
      });
      let allStories = myStory.concat(otherStories);
      setUserStories(allStories);
    }
  };

  /**
   * show story of users
   * @param userId
   * @param index
   */
  const showStoryOfUser = async (userId: number, index: number) => {
    let userStoryList = [...userStories];
    //userStoryList.splice(index,1);

    props.history.push({
      pathname: "/stories",
      state: {
        storyUserId: userId,
        index: index,
        totalFriendsStories: userStoryList,
      },
    });
    // props.location.push("showStory":(userId, index, userStoryList));
  };

  //Open the create post modal
  const handleOpen = () => setOpen(true);

  //Close the create post modal
  const handleClose = () => {
    setOpen(false);
    setPostDescription("");
    setNewPostGalleryImages([]);
    setdisableimg(false);
    setdisablevideo(false);
  };

  //Update the post description
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {

    const str:string=event.target.value

    setPostDescription(str);
    if ((str.trim()).length > 0) {
      setShowCreatePostButton(true);
    } else {
      setShowCreatePostButton(false);
    }
  };

  //Add emoji on description while creating post
  const handleAddEmoji = (emojiCode: string) => {
    setShowCreatePostButton(true);
    let description = postDescription + String.fromCodePoint(Number(emojiCode));
    setPostDescription(description);
  };

  //Remove image after selection
  const handleRemoveImage = (index: number) => {
    let galleryImages = [...newPostGalleryImages];
    galleryImages.splice(index, 1);
    setNewPostGalleryImages(galleryImages);
    if (galleryImages.length === 0) {
      setShowCreatePostButton(false);
      setdisablevideo(false);
      setdisableimg(false);
    }
  };

  //Open the emoji's option
  const handleEmojiClick = (event: React.MouseEvent) => {
    setAnchorEl(event.currentTarget);
  };

  //Close the emoji's option
  const handleEmojiClose = () => {
    setAnchorEl(null);
  };

  //Select the file which user selects
  const fileSelectHandler = async (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    type: any,
    fileType:string
  ) => {

    let target = e.target as HTMLInputElement;
    if (target.files[0] !== undefined)
      if (target.files.length > 10) {
        toast.error(translation("file_size_err"));
        target.files = null;
      } else {
        for (let i = 0; i < target.files.length; i++) {
          if (target.files[i].type !== undefined) {
            const imgType = target.files[i].name.split(".").pop();
            // if (VideoExtentions.includes(imgType.toLowerCase())) {
            //   setdisableimg(true);
            // }
            // if (ImageExtentions.includes(imgType.toLowerCase())) {
            //   setdisablevideo(true);
            // }
            const MimeTypeArray = type;
            console.log("type",type)

            if (imgType.toLowerCase() !== undefined) {
              if (MimeTypeArray.includes(imgType.toLowerCase())) {
                if (target.files[i].size > 41943040) {
                  toast.error(translation("file_size_err20"));
                } else {
                  if(fileType==="image")
                  {
                    const img = target.files[i];
                    const options = {
                      maxSizeMB: 1,
                      maxWidthOrHeight: 720,
                      useWebWorker: true,
                    };
                    try {
                      const compressImg = await imageCompression(img, options);
                      readFile(compressImg);
                    } catch (e) {
                      console.error("Error while compressing image : ", e);
                    }

                  }
                  else
                  {
                    const img = target.files[i];

                    readFile(img);

                  }
                }
              } else {
                if (!VideoExtentions.includes(imgType.toLowerCase())) {
                  toast.error(translation("file_not_support_video"));
                }
                if (!ImageExtentions.includes(imgType.toLowerCase())) {
                  toast.error(translation("file_not_support_img"));
                }
              }
            }
          }
        }
      }
  };

  //Read the file data which user selects
  const readFile = async (file: Blob) => {
    //Create an instance of FileReader
    const fileReader = new FileReader();
    //Implement onLoad function
    fileReader.onloadend = (event) =>
      setNewPostGalleryImages((prevState) => [...prevState, fileReader.result]);
    handleClosepost();
    //Start reading the file
    fileReader.readAsDataURL(file);
    setShowCreatePostButton(true);
  };

  //Create the post
  const onSubmit = async () => {
    setPostLoading(true);
    setState((prevState) => ({
      ...prevState,
      // allPosts: [],
      prevOffset: 0,
      offset: 0,
      hasMore: true,
    }));

    setShowCreatePostButton(false);

    const data: ICreatePostState = {
      userId: userInfo.id,
      description: postDescription,
      gallery_images: newPostGalleryImages,
      taggedFriendsIDs: taggedFriendsIDs,
    };

    const { gallery_images, userId } = data;

    let uploadedImages = [];
    if (gallery_images.length > 0) {
      for (let i = 0; i < gallery_images.length; i++) {
        let filetype = gallery_images[i].split("/")[0].split(":")[1];

        if (filetype === "image") {
          const imagePath = await uploadImage(
            gallery_images[i],
            postImagesBucketName,
            `${userId}/posts/${getRandomString(6)}`
          );
          uploadedImages.push(imagePath);
        } else {
          const imagePath = await uploadVideo(
            gallery_images[i],
            postImagesBucketName,
            `${userId}/posts/${getRandomString(6)}`
          );
          uploadedImages.push(imagePath);
        }
      }
      const images: any = JSON.stringify(uploadedImages);
      setNewPostGalleryImagesurl(images);
    }
    const updateddata: ICreatePostState = {
      userId: userInfo.id,
      description: postDescription,
      gallery_images: uploadedImages,
      taggedFriendsIDs: taggedFriendsIDs,
    };

    const response: any = await dispatch(CreatePost(updateddata, postType));
    setShowCreatePostButton(true);

    if (response?.data?.data?.isRegistered) {
      setPostLoading(false);
      if (data.taggedFriendsIDs.length > 0) {
        data.taggedFriendsIDs.map(async (item, index) => {
          const data = {
            type: "Tagged Friend",
            id: userInfo.id,
            post_id: response.data.data.data.insertId,
          };
          if (id !== data.id) await dispatch(sendNotification(item, data));
        });
      }
      taggedFriendsIDs = [];
      handleClose();
      getAllPosts(postType, true, true);
      return toast.success(translation(response.data.message));
    } else {
      setPostLoading(false);
    }
  };
  /**
   * Handling image and other file type previews before sending them
   * @param e // file change event
   */
  const handlePreviewImages = async (
    { currentTarget: { files } }: ChangeEvent<HTMLInputElement>,
    fileType: string
  ) => {
    if (files && files.length !== 0) {
      let filesArray = Array.from(files);
      if (filesArray.length > 10) {
        toast.error(translation("file_size_err"));
        files = null;
      } else {
        handleClose();
        if (fileType === "image") {
          setPostImages(filesArray);
        } else if (fileType === "video") {
          setPostVideos(filesArray);
        }
        setShowPreview(true);
      }
    }
  };

  /**
   * Remove files from preview before sending
   * @param index // array index
   * @param type // file type
   */
  const handleRemoveFileFromPreview = (index: number, type: string) => {
    let files: File[] = [];
    if (type === "postImages") {
      files = [...postImages];
      files.splice(index, 1);
      setPostImages([...files]);
    } else if (type === "postVideos") {
      files = [...postVideos];
      files.splice(index, 1);
      setPostVideos([...files]);
    }
  };

  /**
   * Convert javascript file object into a base64 string
   * @param file javascript file object
   * @returns base64 string
   */
  const getBase64String = async (file: File) => {
    const result = await FileToBase64(file).catch((e) => Error(e));
    if (result instanceof Error) {
      return;
    }
    return result;
  };

  /**
   * Handling file uploading options by file types and send for upload on s3
   */
  const handleUploadFiles = async () => {
    setLoading(true);
    let files: string[] = [];
    if (postImages.length) {
      for (let i = 0; i < postImages.length; i++) {
        const result = (await getBase64String(postImages[i])) as string;
        files[i] = result;
      }
    }
    if (postVideos.length) {
      for (let i = 0; i < postVideos.length; i++) {
        let ext = postVideos[i].name.split(".").pop();
        if (VideoTypes.includes(ext)) {
          if (postVideos[i].size > 10360000) {
            toast.error(translation("file_size_err1"));
          } else {
            const result = (await getBase64String(postVideos[i])) as string;
            files[i] = result;
          }
        }
      }
    }
    setLoading(false);
    setShowPreview(false);
    setPostImages([]);
    setPostVideos([]);
  };

  return (
    <Box>
      <Grid container className={commonStyle.windowColor}>
        {/* home part ------------ */}
        <Grid item md={3}>
          <LeftSidebar />
        </Grid>
        <Grid item md={6}>
          {/* Stories section start */}
          <Box className={`${styleClasses.homeMain} ${commonStyle.mt20}`}>
            <Box className={commonStyle.mb15}>
              {/* yhase  */}

              {postType === "tricks" ? (
                <Box>
                  <Typography variant="h6" className={`${commonStyle.mb10}`}>
                    <strong>{translation("pet_tricks")}</strong>
                  </Typography>
                  <Typography className={`${commonStyle.lightGrey}`}>
                    {translation("pet_tricks_content")}
                  </Typography>
                </Box>
              ) : postType === Type.chatRoom ? (
                <Box>
                  <Typography variant="h6" className={`${commonStyle.mb10}`}>
                    <strong>{translation("chat_room")}</strong>
                  </Typography>

                  <Typography className={`${commonStyle.lightGrey}`}>
                    {translation("chat_room_content")}
                  </Typography>
                </Box>
              ) : postType === "tails" ? (
                <Box>
                  <Typography variant="h6" className={`${commonStyle.mb10}`}>
                    <strong>{translation("pet_tails")}</strong>
                  </Typography>

                  <Typography className={`${commonStyle.lightGrey}`}>
                    {translation("pet_tails_content")}
                  </Typography>
                </Box>
              ) : (
                <div>
                  <Grid container className={commonStyle.mb15}>
                    <Grid item md={6}>
                      <Box className={commonStyle.commonHeading}>
                        <Typography variant="h2">
                          {" "}
                          {translation("stories")}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item md={6}>
                      <Box className={commonStyle.textRight}>
                        <Link
                          className={commonStyle.commonLink}
                          to={RoutingLinks.stories}
                        >
                          {translation("see_all")}
                        </Link>
                      </Box>
                    </Grid>
                  </Grid>

                  <ul
                    className={`${commonStyle.flexCenter} ${commonStyle.removeListStyle} ${commonStyle.overflowAuto}`}
                  >
                    <li
                      className={`${commonStyle.cursorA} ${commonStyle.mr10}`}
                    >
                      <Box className={styleClasses.storyCard}>
                        <img
                          className={styleClasses.storyBgImg}
                          src={
                            userInfo?.profile_image
                              ? userInfo.profile_image
                              : defaultUserprofile
                          }
                          alt={translation("story_bg")}
                        />
                        <Box className={styleClasses.storyPerson}>
                          <IconButton
                            className={commonStyle.iconBtn}
                            onClick={handleOpenStory}
                          >
                            <Add className={commonStyle.white} />
                          </IconButton>
                        </Box>

                        {/* modal own story ----------- */}
                        {openStoryModal ? (
                          <CreateStoryModal
                            open={openStoryModal}
                            onClose={handleCloseStory}
                          />
                        ) : null}

                        {/* modal own story end ----------- */}

                        <Box className={styleClasses.storyName}>
                          <Typography variant="h5">
                            {translation("add_story")}
                          </Typography>
                        </Box>
                      </Box>
                    </li>

                    {userStories.length > 0 &&
                      userStories.map((userStory, index) => (
                        <li className={commonStyle.mr10} key={index}>
                          <Box className={styleClasses.storyCard}>
                            <img
                              className={styleClasses.storyBgImg}
                              src={
                                userStory.profile_image
                                  ? userStory.profile_image
                                  : defaultUserprofile
                              }
                              alt={translation("story_bg")}
                              onClick={() =>
                                showStoryOfUser(userStory.user_id, index)
                              }
                            />
                            <Box className={styleClasses.storyPerson}>
                              <Avatar
                                alt={translation("story_person")}
                                src={
                                  userStory.profile_image
                                    ? userStory.profile_image
                                    : defaultUserprofile
                                }
                              />
                            </Box>
                            <Box className={styleClasses.storyName}>
                              <Typography variant="h5">
                                {userStory.first_name}
                              </Typography>
                            </Box>
                          </Box>
                        </li>
                      ))}
                  </ul>
                </div>
              )}
              {/* yhatk  */}
            </Box>
            {/* Stories section end */}

            {/* Post Card Start  */}
            <Box className={`${commonStyle.card} ${commonStyle.mb15}`}>
              <Box className={commonStyle.cardBody}>
                <Grid container spacing={1}>
                  <Grid item md={1} className={`${commonStyle.flexCenter}`}>
                    <Box>
                      <Avatar
                        alt={translation("story_person")}
                        src={
                          userInfo?.profile_image
                            ? userInfo.profile_image
                            : minImage
                        }
                      />
                    </Box>
                  </Grid>
                  <Grid item md={10}>
                    <Box>
                      <label htmlFor="contained-button-file">
                        <Box className={commonStyle.blueBtn}>
                          <Button
                            onClick={handleOpen}
                            fullWidth
                            className={commonStyle.m0}
                          >
                            {translation("add_post")}
                          </Button>
                        </Box>

                        <Modal
                          open={open}
                          onClose={handleClose}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                        >
                          <Box sx={style}>
                            <Box className={styleClasses.modalHeader}>
                              <Typography id="modal-modal-title" variant="h6">
                                {translation("create_post")}
                              </Typography>
                              <HighlightOff onClick={handleClose} />
                            </Box>
                            <Box id="modal-modal-description" sx={{ mt: 2 }}>
                              <Box
                                className={`${commonStyle.pb20} ${commonStyle.wallUserInfo}`}
                              >
                                <Avatar
                                  alt={translation("story_person")}
                                  src={
                                    userInfo?.profile_image
                                      ? userInfo.profile_image
                                      : defaultUserprofile
                                  }
                                />
                                <Box className={commonStyle.ml10}>
                                  <Typography variant="h2">
                                    {`${
                                      userInfo?.first_name
                                        ? capitalize(userInfo.first_name)
                                        : ""
                                    } ${
                                      userInfo?.last_name
                                        ? capitalize(userInfo.last_name)
                                        : ""
                                    }`}
                                  </Typography>
                                </Box>
                              </Box>

                              <Typography
                                className={`${commonStyle.w100} ${commonStyle.modalTextarea}`}
                              >
                                {translation("what_is_in_your_mind")}
                              </Typography>

                              <ul className={styleClasses.modalImageList}>
                                {newPostGalleryImages &&
                                  newPostGalleryImages.length > 0 &&
                                  newPostGalleryImages.map((image, index) => (
                                    <li key={index}>
                                      {image.split("/")[0].split(":")[1] ===
                                        "image" && (
                                        <img
                                          src={image}
                                          alt={translation("gallery")}
                                        />
                                      )}
                                      {image.split("/")[0].split(":")[1] ===
                                        "video" && (
                                        <video controls src={image} />
                                      )}
                                      <Box
                                        className={styleClasses.closePostImg}
                                      >
                                        <HighlightOff
                                          onClick={() =>
                                            handleRemoveImage(index)
                                          }
                                        />
                                      </Box>
                                    </li>
                                  ))}
                              </ul>
                              <Box className={styleClasses.addPostInput}>
                                <TextField
                                  variant="outlined"
                                  placeholder={translation("add_to_post")}
                                  onChange={handleChange}
                                  value={postDescription}
                                  name="description"
                                  multiline
                                  inputProps={{ maxLength: 750 }}
                                  className={`${commonStyle.w100}`}
                                />

                                <Box className={styleClasses.addPostIcon}>
                                  <Box className={styleClasses.customFileModal}>
                                    <Button onClick={handleClick}>
                                      <img src={Gallery} alt="" />
                                    </Button>
                                  </Box>

                                  <Button onClick={handleEmojiClick}>
                                    <SentimentSatisfiedAlt />
                                  </Button>
                                  <Popover
                                    id={id}
                                    open={openE}
                                    anchorEl={anchorE2}
                                    onClose={handleClosepost}
                                    anchorOrigin={{
                                      vertical: "top",
                                      horizontal: "left",
                                    }}
                                    transformOrigin={{
                                      vertical: "bottom",
                                      horizontal: "left",
                                    }}
                                  >
                                    <ul className={styleClasses.mediaIconPopup}>
                                      <li>
                                        <InsertPhoto />
                                        Image
                                        <input
                                          disabled={disableimg}
                                          type="file"
                                          onChange={(e) =>
                                            fileSelectHandler(e, ImageTypes,"image")
                                          }
                                          name="chat_image"
                                          multiple
                                          accept={ImageExtentions}
                                        />
                                      </li>
                                      <li>
                                        <VideoLibrary />
                                        Video
                                        <input
                                          disabled={disablevideo}
                                          type="file"
                                          accept={VideoExtentions}
                                          onChange={(e) =>
                                            fileSelectHandler(e, VideoTypes,"video")
                                          }
                                          name="chat_image"
                                          multiple
                                        />
                                      </li>
                                    </ul>
                                  </Popover>

                                  <Popover
                                    open={openEl}
                                    anchorEl={anchorEl}
                                    onClose={handleEmojiClose}
                                    anchorOrigin={{
                                      vertical: "bottom",
                                      horizontal: "left",
                                    }}
                                  >
                                    <ul
                                      className={`${commonStyle.flexCenter} ${styleClasses.emojiList}`}
                                    >
                                      <li>
                                        <Tooltip title={translation("smile")}>
                                          <span
                                            onClick={() =>
                                              handleAddEmoji("0x1F642")
                                            }
                                          >
                                            &#128578;
                                          </span>
                                        </Tooltip>
                                      </li>
                                      <li>
                                        <Tooltip title={translation("sad")}>
                                          <span
                                            onClick={() =>
                                              handleAddEmoji("0x1F61F")
                                            }
                                          >
                                            &#128577;
                                          </span>
                                        </Tooltip>
                                      </li>
                                      <li>
                                        <Tooltip
                                          title={translation("surprise")}
                                        >
                                          <span
                                            onClick={() =>
                                              handleAddEmoji("0x1F62E")
                                            }
                                          >
                                            &#128558;
                                          </span>
                                        </Tooltip>
                                      </li>
                                    </ul>
                                  </Popover>
                                  <ChildModal />
                                </Box>
                              </Box>

                              <Box
                                className={`${commonStyle.blueBtn} ${commonStyle.pt20}`}
                              >
                                <Button
                                  className={`${commonStyle.w100}`}
                                  onClick={onSubmit}
                                  disabled={!showCreatePostButton}
                                >
                                  {translation("post")}
                                  {postLoading ? <ClipLoader /> : null}
                                </Button>
                              </Box>
                            </Box>
                          </Box>
                        </Modal>
                      </label>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    md={1}
                    className={`${commonStyle.iconBtn} ${commonStyle.flexCenter}`}
                  >
                    <IconButton>
                      <Send />
                    </IconButton>
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid item md={1}></Grid>
                  <Grid item md={10}>
                    <Box
                      className={`${commonStyle.py15} ${styleClasses.postLinks}`}
                    >
                      <a href="javascript:void(0);" onClick={handleOpen}>
                        <img src={Gallery} alt="" />
                        {translation("gallery")}
                      </a>
                      <a href="javascript:void(0);" onClick={handleOpen}>
                        <img src={FeelingActivity} alt="" />
                        {translation("feeling_activity")}
                      </a>
                      <a href="javascript:void(0);" onClick={handleOpen}>
                        <img src={TagFriends} alt="" />
                        {translation("tag_friends")}
                      </a>
                    </Box>
                  </Grid>
                  <Grid item md={1}></Grid>
                </Grid>
              </Box>
            </Box>
            {/* Post Card End */}

            <InfiniteScroll
              dataLength={state?.allPosts?.length}
              next={() => getAllPosts(postType, false)}
              hasMore={state?.hasMore}
              loader={(() => {
                if (loading) {
                  return <div className="loaing-area">Loading...</div>;
                }
              })()}
              scrollThreshold="500px"
            >
              <CommonCard
                allPost={state.allPosts}
                allPostComments={allComments}
                postType={postType}
                props={props}
                state={state}
                allAdsData={adData}
                // getAllpost={getAllPosts("post",false)}
                // loading={loading}
              />
            </InfiniteScroll>
          </Box>
        </Grid>
        <Grid item md={3}>
          <RightSidebar />
        </Grid>
        {/* home part ------------ */}
      </Grid>
      <SelectLanguage />
    </Box>
  );
};

export default Home;
