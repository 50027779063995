import { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";

import { Box } from "@mui/system";
import {
  Avatar,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import { Check, Clear } from "@mui/icons-material";

import { getpetofdayList } from "../../redux/action-creators/petofdayAction";
import { sendNotification } from "../../redux/action-creators/petPlayAction";
import {
  fetchfriendRequest,
  requestapproval,
  getFriendsOfFriendsList,
  changeRequestType,
} from "../../redux/action-creators/requestAction";

import { SidebarStyles } from "./Sidebar";
import moment from "moment";

import { toast } from "../../utils/toastsMessage";
import { acceptFriend, RoutingLinks } from "../../utils/constants";
import { capitalize } from "../../utils/helper";

import { UserCommonStyles } from "../../styles/CommonStyles";
import defaultUserprofile from "../../images/defaultUserprofile.png";
import minImage from "../../images/minImage.png";


const RightSidebar = () => {
  const { t: translation } = useTranslation();
  const commonStyle = UserCommonStyles();
  const styleClasses = SidebarStyles();
  const dispatch = useDispatch();
  const [friendList, setFriendList] = useState([]);
  const [friendsOfFriends, setFriendsOfFriends] = useState([]);
  const [petday, setpetDay] = useState([]);
  const [disable, setdisable] = useState(false);  

  let request: boolean = true;
  const userId: number = Number(localStorage.getItem("userId"));

  /**
   * fetch friend request
   */
  const friendRequest = useCallback(async () => {
    const result: any = await dispatch(fetchfriendRequest(userId));
    if(result){
      setFriendList(result);
    }    
  }, [dispatch, userId]);

  /**
   * get friend of friend's data
   */
  const getFriendsOfFriendsData = useCallback(async () => {
    const response: any = await dispatch(getFriendsOfFriendsList(userId));
    if (response && response.length > 0) {
      let data = [...response];
      setFriendsOfFriends(
        data.map((i: { id: number }) => ({
          ...i,
          disable: false,
        }))
      );      
    }
  }, [dispatch, userId]);

  /**
   * get pet of day
   */
  const getPetofday = useCallback(async () => {
    var today = new Date();
    let date=moment(today).format("YYYY-MM-DD");
    const results: any = await dispatch(getpetofdayList(date));
    if (results && results.length > 0) {
      setpetDay(results);
    }
  }, [dispatch]);

  useEffect(() => {
    friendRequest();
    getFriendsOfFriendsData();
    getPetofday();
  }, [friendRequest, getFriendsOfFriendsData, getPetofday, userId]);

  /**
   * accept request
   * @param id
   * @returns
   */
  const accept = async (id: number) => {
    request = true;
    setdisable(true);
    const result: any = await dispatch(requestapproval(userId, id, request));
    if (result && result?.status === 200) {
      friendRequest();
      const data = { type: acceptFriend, id: userId };
      if (id !== data.id) await dispatch(sendNotification(id, data));
      setdisable(false);
      return toast.success(translation("succ_addFriend"));
    } else {
      setdisable(false);

      return toast.error(translation("error"));
    }
  };

  /**
   * remove request
   * @param id
   */
  const remove = async (id: number) => {
    request = false;
    await dispatch(requestapproval(userId, id, request));
    friendRequest();
  };

  /**
   * send request
   * @param profile_id
   * @param freindIndex
   */
  const sendRequest = async (profile_id: number, freindIndex: number) => {    
    let friendsOfFriendsList = [...friendsOfFriends];
    friendsOfFriendsList[freindIndex].disable=true;
    setFriendsOfFriends(friendsOfFriendsList);       
    await dispatch(changeRequestType(profile_id, userId));

    let friendsOfFriendsLists = [...friendsOfFriends];
    friendsOfFriendsLists.splice(freindIndex, 1);
    setFriendsOfFriends(friendsOfFriendsLists);        
    return toast.success(translation("friend_request_sent"));
  };

  return (
    <Box className={styleClasses.SidebarMain}>
      <Box className={styleClasses.petOfDay}>
        <Typography variant="h6" className={commonStyle.textCenter}>
          {translation("pet_of_the_day")}
        </Typography>
        <Box className={`${commonStyle.flexCenter} ${styleClasses.petDayImg}`}>
          <img
            src={
              petday.length > 0  && petday[0].profile_image ? petday[0].profile_image : defaultUserprofile
            }
            alt={translation("altpetprofile")}
          />
          <Box className={styleClasses.dayDetails}>
            <Typography>
              {petday.length > 0
                ? capitalize(petday[0].first_name) +
                  " " +
                  capitalize(petday[0].last_name)
                : null}
            </Typography>
            <Typography>
              <Link
                to={{
                  pathname: `${RoutingLinks.petoftheDay}${petday[0]?.userId}`,
                  state: { 
                    data:petday, 
                  
                }
                }}
              >
                {petday[0]?.userId ? translation("see_details") : null}
              </Link>
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box className={styleClasses.requestsFriend}>
        <List className={styleClasses.rightList}>
          <ListItem className={styleClasses.requestHeader}>
            <Typography variant="h6">
              {translation("friend_requests")}
            </Typography>
            <Typography>{translation("all")}</Typography>
          </ListItem>
          {friendList.length > 0 &&
            friendList.map((item, index) => (
              <>
                <ListItem
                  className={`${styleClasses.listAvatarSide} ${commonStyle.p0}`}
                  key={index}
                  secondaryAction={
                    <>
                      <IconButton
                        disabled={disable}
                        edge="end"
                        aria-label={translation("comment")}
                      >
                        <Check
                          onClick={() => {
                            accept(item.id);
                          }}
                          className={commonStyle.white}
                        />
                      </IconButton>

                      <IconButton
                        edge="end"
                        aria-label={translation("comment")}
                      >
                        <Clear
                          onClick={() => {
                            remove(item.id);
                          }}
                          className={commonStyle.white}
                        />
                      </IconButton>
                    </>
                  }
                >
                  <Link
                    to={{
                      pathname: `${RoutingLinks.profiles}${
                        item?.id ? item?.id : userId
                      }`,
                    }}
                    className={`${commonStyle.white} ${commonStyle.anchorDecoration}`}
                  >
                    <li>
                      <ListItemAvatar>
                        <Avatar className={styleClasses.requestAvatar}>
                          <img
                            src={
                              item?.profile_image
                                ? item.profile_image
                                : minImage
                            }
                            alt={translation("req_avt")}
                          />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          capitalize(item.first_name) +
                          " " +
                          capitalize(item.last_name)
                        }
                        className={styleClasses.avatarText}
                      />
                    </li>
                  </Link>
                </ListItem>
              </>
            ))}
        </List>
      </Box>

      <Box
        className={`${commonStyle.borderBottom} ${styleClasses.requestsFriend}`}
      >
        <List className={styleClasses.rightList}>
          <ListItem className={styleClasses.requestHeader}>
            <Typography variant="h6">
              {translation("people_may_know")}
            </Typography>
            <Typography>{translation("all")}</Typography>
          </ListItem>
          {friendsOfFriends &&
            friendsOfFriends.length > 0 &&
            friendsOfFriends.map((friend, index) => (
              <ListItem
                key={index}
                className={`${styleClasses.listAvatarSide} ${commonStyle.p0}`}
                secondaryAction={
                  <Box className={commonStyle.whiteBtnSm}>
                    <Button
                       disabled={friend.disable}
                      variant="contained"
                      disableElevation
                      onClick={() => sendRequest(friend.id, index)}
                    >
                      {translation("add")}
                    </Button>
                  </Box>
                }
              >
                {" "}
                <Link
                  to={{
                    pathname: `${RoutingLinks.profiles}${
                      friend?.id ? friend?.id : userId
                    }`,
                  }}
                  className={`${commonStyle.anchorDecoration} ${commonStyle.white}`}
                >
                  <li>
                    <ListItemAvatar>
                      <Avatar className={styleClasses.requestAvatar}>
                        <img
                          src={
                            friend?.profile_image
                              ? friend.profile_image
                              : minImage
                          }
                          alt={translation("req_avt")}
                        />
                      </Avatar>
                    </ListItemAvatar>

                    <ListItemText
                      primary={capitalize(friend.first_name)}
                      className={styleClasses.avatarText}
                    />
                  </li>
                </Link>
              </ListItem>
            ))}
        </List>
      </Box>
    </Box>
  );
};

export default RightSidebar;
