import axios from "../../services/axiosService";
import { Dispatch } from "redux";
import { ActionType } from "../action-types/index";
const URL = process.env.REACT_APP_API_URL;

/**
 * get list of pet of day
 * @param data
 * @returns
 */
export const getpetofdayList = (data: any) => async (dispatch: Dispatch) => {
  try {
    const response = await axios.get(`${URL}/api/v1/pet/${data}`);
    return response.data.data.data;
  } catch (err) {
    dispatch({
      type: ActionType.FAILURE,
    });
  }
};


/**
 * get reaction of pet of day
 * @param data
 * @returns
 */
 export const addpetofdayReaction = ( userId: number,
  reactionData: any,
  crudOperation: string) => async (dispatch: Dispatch) => {
  try {
    const response = await axios.post(`${URL}/api/v1/pet/add-reaction/${userId}?crudOperation=${crudOperation}`,reactionData);
    return response.data.data.data;
  } catch (err) {
    dispatch({
      type: ActionType.FAILURE,
    });
  }


  
};

/**
 * get reaction of pet of day
 * @param data
 * @returns
 */
 export const likeReactionByUser = ( pet_of_the_day_reactionId: number,userId:number) => async (dispatch: Dispatch) => {
  try {
    const response = await axios.get(`${URL}/api/v1/pet/userReaction/${userId}/${pet_of_the_day_reactionId}`);
    return response.data.data.data;
  } catch (err) {
    dispatch({
      type: ActionType.FAILURE,
    });
  }
  }


  /**
 * List for reaction of pet of day
 * @param post_id
 * @returns
 */
export const allReaction = (pet_of_the_day_id: number,offset:number) => async (
  dispatch: Dispatch
) => {
  try {
    const response = await axios.get(`${URL}/api/v1/pet/allreaction/${pet_of_the_day_id}?offset=${offset}`);
    return response.data.data.data;
  } catch (err) {
    dispatch({
      type: ActionType.FAILURE,
    });
  }
};