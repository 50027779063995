import ApiResponse from "../../interfaceModules/IApiResponse"
import * as  http from "./http";
import { endpoint } from "../../utils/endPoints";





export const  createReport= async (userId:number,id:number,data:object): Promise<ApiResponse> => {
   return http.post(`${endpoint.report.report}`.replace(':userId', `${userId}`).replace(':id',`${id}`),data)

  }

  export const  blockUser= async (userId:number,id:number): Promise<ApiResponse> => {
  
    return http.post(`${endpoint.report.block}`.replace(':userId', `${userId}`).replace(':id',`${id}`))

  }


  export const  getBlocklList= async (userId:number,id:number): Promise<ApiResponse> => {
  
    return http.get(`${endpoint.report.check_block}`.replace(':userId', `${userId}`).replace(':id',`${id}`))

  }

  export const  unblockUser= async (userId:number,id:number): Promise<ApiResponse> => {
  
    return http.post(`${endpoint.report.unblock_user}`.replace(':userId', `${userId}`).replace(':id',`${id}`))

  }

  export const  blockUserList= async (userId:number,id:number): Promise<ApiResponse> => {
  
    return http.get(`${endpoint.report.unblock_user}`.replace(':userId', `${userId}`).replace(':id',`${id}`))

  }

  export const  blockUserListbyid= async (userId:number): Promise<ApiResponse> => {
  
    return http.get(`${endpoint.report.block_lists}`.replace(':userId', `${userId}`))

  }

