import { useDispatch } from "react-redux";
import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";

import {
  Grid,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  SelectChangeEvent,
} from "@mui/material";
import { Box } from "@mui/system";
import { Button } from "@mui/material";
import { ChatBubbleOutline, Search } from "@mui/icons-material";

import {
  getAllUserOrders,
  getAllUserClientOrders,
  updateClientOrderStatus,
} from "../../redux/action-creators/orderAction";

import LeftSidebar from "../../components/sidebars/LeftSidebar";

import moment from "moment";

import { capitalize } from "../../utils/helper";

import { UserCommonStyles } from "../../styles/CommonStyles";

const Orders = () => {
  const dispatch = useDispatch();
  const { t: translation } = useTranslation();
  const commonStyle = UserCommonStyles();
  const userId: number = Number(localStorage.getItem("userId"));
  let userData: any = localStorage.getItem("userData");
  userData = userData ? JSON.parse(userData) : {};

  const [userHistory, setUserHistory] = useState([]);
  const [userClientHistory, setUserClientHistory] = useState([]);
  const [searchClientOrder, setSearchClientOrder] = useState("");
  const [searchMyOrder, setSearchMyOrder] = useState("");
  const [timeOutMyOrdersState, setTimeOutMyOrdersState] = React.useState(null);
  const [
    timeOutClientOrdersState,
    setTimeOutClientOrdersState,
  ] = React.useState(null);

  //For Order status
  const [clientOrders, setClientOrders] = useState({
    orderId: 0,
    showDropDown: false,
  });
  const [myOrders, setMyOrders] = useState({
    orderId: 0,
    showDropDown: false,
  });

  const orderStatusList = ["pending", "delivered", "cancelled"];

  interface Column {
    autoNumber: number;
    id: "name" | "code" | "population" | "size" | "density";
    label: string;
    minWidth?: number;
    align?: "right";
    format?: (value: number) => string;
  }

  const columns: readonly Column[] = [
    { autoNumber: 1, id: "name", label: "ID", minWidth: 170 },
    { autoNumber: 2, id: "code", label: "Product", minWidth: 100 },
    {
      autoNumber: 3,
      id: "population",
      label: "Order Date",
      minWidth: 170,
      align: "right",
      format: (value: number) => value.toLocaleString("en-US"),
    },
    {
      autoNumber: 4,
      id: "size",
      label: "From",
      minWidth: 170,
      align: "right",
      format: (value: number) => value.toLocaleString("en-US"),
    },
    {
      autoNumber: 5,
      id: "density",
      label: "Status",
      minWidth: 170,
      align: "right",
      format: (value: number) => value.toFixed(2),
    },
    {
      autoNumber: 6,
      id: "density",
      label: "Action",
      minWidth: 170,
      align: "right",
      format: (value: number) => value.toFixed(2),
    },
  ];

  /**
   * get all user orders by id
   * @param searchText
   */
  const getAllUserOrdersById = useCallback(
    async (searchText?: string) => {
      searchText = searchText ? searchText : searchMyOrder;
      const response: any = await dispatch(
        getAllUserOrders(userId, searchText)
      );
      if (response && !response.isError) {
        setUserHistory(response.data);
      }
    },
    [dispatch, searchMyOrder, userId]
  );

  /**
   * get all user clients orders by seller id
   * @param searchText
   */
  const getAllUserClientsOrdersBySellerId = useCallback(
    async (searchText?: string) => {
      if (userData.seller_id) {
        searchText = searchText ? searchText : searchClientOrder;
        const response: any = await dispatch(
          getAllUserClientOrders(userData.seller_id, searchText)
        );
        if (response && !response.isError) {
          setUserClientHistory(response.data);
        }
      }
    },
    [dispatch, searchClientOrder, userData.seller_id]
  );

  /**
   * client order status
   * @param orderId
   * @param e
   */
  const handleOrderClientStatusChange = async (
    orderId: number,
    e?: SelectChangeEvent<any>
  ) => {
    setClientOrders({
      orderId: 0,
      showDropDown: false,
    });

    let clientHistory = [...userClientHistory];
    const index = clientHistory.findIndex((x) => x.id === orderId);
    clientHistory[index].status = e.target.value;
    const response: any = await dispatch(
      updateClientOrderStatus({ status: e.target.value }, orderId)
    );

    if (response.success) {
      setUserClientHistory(clientHistory);
    }
  };

  /**
   * Change order status
   * @param orderId
   * @param e
   */
  const handleMyOrdersStatusChange = async (
    orderId: number,
    e?: SelectChangeEvent<any>
  ) => {
    setMyOrders({
      orderId: 0,
      showDropDown: false,
    });

    let userOrderHistory = [...userHistory];
    const index = userOrderHistory.findIndex((x) => x.id === orderId);
    userOrderHistory[index].status = e.target.value;
    const response: any = await dispatch(
      updateClientOrderStatus({ status: e.target.value }, orderId)
    );

    if (response.success) {
      setUserHistory(userOrderHistory);
    }
  };

  /**
   * For searching
   * @param event
   * @param from
   */
  const handleSearchChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    from: string
  ) => {
    const searchtext = event.target.value.trim();

    if (from === "clientOrders" && timeOutClientOrdersState) {
      clearInterval(timeOutClientOrdersState);
    }

    if (from === "myOrders" && timeOutMyOrdersState) {
      clearInterval(timeOutMyOrdersState);
    }

    if (from === "clientOrders") {
      //setSearchClientOrder(searchtext);
      let result = setTimeout(async () => {
        await getAllUserClientsOrdersBySellerId(searchtext);
      }, 800);
      setTimeOutClientOrdersState(result);
    }

    if (from === "myOrders") {
      //setSearchMyOrder(searchtext);
      let result = setTimeout(async () => {
        await getAllUserOrdersById(searchtext);
      }, 800);
      setTimeOutMyOrdersState(result);
    }
  };

  /**
   * updated order status
   * @param orderid
   * @param from
   */
  const updateOrderStatus = (orderid: number, from: string) => {
    if (from === "clientOrders") {
      setClientOrders({
        orderId: orderid,
        showDropDown: true,
      });
    } else {
      setMyOrders({
        orderId: orderid,
        showDropDown: true,
      });
    }
  };

  useEffect(() => {
    getAllUserClientsOrdersBySellerId();
    getAllUserOrdersById();
  }, [getAllUserClientsOrdersBySellerId, getAllUserOrdersById]);

  return (
    <Box>
      <Grid container>
        <Grid item md={3}>
          <LeftSidebar />
        </Grid>

        <Grid item md={9}>
          <Box className={`${commonStyle.mb20} ${commonStyle.p20}`}>
            <Typography
              variant="h6"
              className={`${commonStyle.grey} ${commonStyle.mb15} ${commonStyle.fontWeight600}`}
            >
              {translation("orders")}
            </Typography>

            <Box className={`${commonStyle.p20} ${commonStyle.card}`}>
              <Grid container className={commonStyle.mb15}>
                <Grid item md={6}>
                  <Typography variant="h6" className={commonStyle.grey}>
                    {translation("order_client")}
                  </Typography>
                </Grid>

                <Grid item md={6}>
                  <Grid container spacing={2}>
                    <Grid item md={7}>
                      <Box
                        className={`${commonStyle.headerSearch} ${commonStyle.w100}`}
                      >
                        <TextField
                          placeholder={translation("search")}
                          className={`${commonStyle.w100}`}
                          onChange={(e) =>
                            handleSearchChange(e, "clientOrders")
                          }
                        ></TextField>
                        <Search />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label={translation("sticky_table")}>
                  <TableHead>
                    <TableRow className={commonStyle.commonTableHead}>
                      {columns.map((column) => (
                        <TableCell
                          key={column.autoNumber}
                          style={{ minWidth: column.minWidth }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {userClientHistory && userClientHistory.length > 0
                      ? userClientHistory.map((order, index) => (
                          <TableRow hover tabIndex={-1} key={order.id}>
                            <TableCell>{order.order_number}</TableCell>
                            <TableCell>{order.name}</TableCell>
                            <TableCell>
                              {moment(order.created_ts).format("MM-DD-YYYY")}
                            </TableCell>
                            <TableCell>
                              {order.first_name
                                ? capitalize(order.first_name)
                                : ""}{" "}
                              {order.last_name
                                ? capitalize(order.last_name)
                                : ""}
                            </TableCell>
                            <TableCell
                              className={`${commonStyle.fontWeight500} ${commonStyle.skyBlue}`}
                            >
                              {clientOrders.showDropDown &&
                              clientOrders.orderId === order.id ? (
                                <Select
                                  className={commonStyle.w100}
                                  defaultValue={order.status}
                                  onChange={(e) =>
                                    handleOrderClientStatusChange(order.id, e)
                                  }
                                >
                                  {orderStatusList.map((orderStatus, index) => (
                                    <MenuItem
                                      key={index}
                                      value={orderStatus}
                                      defaultValue={order.status}
                                    >
                                      {orderStatus}
                                    </MenuItem>
                                  ))}
                                </Select>
                              ) : (
                                order.status
                              )}
                            </TableCell>

                            <TableCell>
                              <Button
                                onClick={() =>
                                  updateOrderStatus(order.id, "clientOrders")
                                }
                              >
                                <ChatBubbleOutline />
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))
                      : translation("no_orders_client")}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>

          <Box className={`${commonStyle.mb20} ${commonStyle.p20}`}>
            <Box className={`${commonStyle.p20} ${commonStyle.card}`}>
              <Grid container className={commonStyle.mb15}>
                <Grid item md={6}>
                  <Typography variant="h6" className={commonStyle.grey}>
                    {translation("order_history")}
                  </Typography>
                </Grid>

                <Grid item md={6}>
                  <Grid container spacing={2}>
                    <Grid item md={7}>
                      <Box
                        className={`${commonStyle.headerSearch} ${commonStyle.w100}`}
                      >
                        <TextField
                          placeholder={translation("search")}
                          className={`${commonStyle.w100}`}
                          onChange={(e) => handleSearchChange(e, "myOrders")}
                        ></TextField>
                        <Search />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label={translation("sticky_table")}>
                  <TableHead>
                    <TableRow className={commonStyle.commonTableHead}>
                      {columns.map((column) => (
                        <TableCell
                          key={column.autoNumber}
                          style={{ minWidth: column.minWidth }}
                        >
                          {column.id === "size" ? "Vendor" : column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {userHistory && userHistory.length > 0
                      ? userHistory.map((order, index) => (
                          <TableRow hover tabIndex={-1} key={order.id}>
                            <TableCell>{order.order_number}</TableCell>
                            <TableCell>{order.name}</TableCell>
                            <TableCell>
                              {moment(order.created_ts).format("MM-DD-YYYY")}
                            </TableCell>
                            <TableCell>
                              {capitalize(order.first_name)}{" "}
                              {capitalize(order.last_name)}
                            </TableCell>
                            <TableCell
                              className={`${commonStyle.fontWeight500} ${commonStyle.skyBlue}`}
                            >
                              {myOrders.showDropDown &&
                              myOrders.orderId === order.id ? (
                                <Select
                                  className={commonStyle.w100}
                                  defaultValue={order.status}
                                  onChange={(e) =>
                                    handleMyOrdersStatusChange(order.id, e)
                                  }
                                >
                                  {orderStatusList.map((orderStatus, index) => (
                                    <MenuItem
                                      key={index}
                                      value={orderStatus}
                                      defaultValue={order.status}
                                    >
                                      {orderStatus}
                                    </MenuItem>
                                  ))}
                                </Select>
                              ) : (
                                order.status
                              )}
                            </TableCell>
                            <TableCell>
                              <Button
                                onClick={() =>
                                  updateOrderStatus(order.id, "myOrders")
                                }
                              >
                                <ChatBubbleOutline />
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))
                      : translation("no_orders_purchased")}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Orders;
