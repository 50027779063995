import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useState } from "react";

import { Grid } from "@mui/material";
import { Box } from "@mui/system";

import { getProductDetailsById } from "../../redux/action-creators/productAction";

import LeftSidebar from "../../components/sidebars/LeftSidebar";
import ProductSidebar from "../../components/sidebars/ProductSidebar";

import { UserCommonStyles } from "../../styles/CommonStyles";
import { ProductStyles } from "./ProductsStyle";
import { Type } from "../../utils/constants";

const ProductDetail = () => {
  const { t: translation } = useTranslation();
  const commonStyle = UserCommonStyles();
  const styleClasses = ProductStyles();
  const dispatch = useDispatch();
  const [, setProductDetail] = useState([]);
  const [productImages, setProductImages] = useState([]);

  const { id }: { id: string } = useParams();
  /**
   * product details
   * @param id
   */
  const productDetails = useCallback(async (id: string) => {
    const response: any = await dispatch(getProductDetailsById(id));
    if (response && response.length) {
      let data = response[0]?.gallery_images;
      data = JSON.parse(data);
      setProductDetail(response[0]);
      setProductImages(data);
    }
  },[dispatch]);
  useEffect(() => {
    productDetails(id);
  }, [id, productDetails]);

  return (
    <Box>
      <Box className={commonStyle.windowColor}>
        <Grid container>
          <Grid item md={3}>
            <LeftSidebar />
          </Grid>

          <Grid item md={6}>
            <Box className={`${commonStyle.p20} ${commonStyle.mt20}`}>
              <Box
                className={`${commonStyle.bgWhite} ${styleClasses.combineBox}`}
              >
                <Box
                  className={`${commonStyle.p20} ${commonStyle.card} ${styleClasses.productDetailCard}`}
                >
                  {productImages && productImages.length > 0 && (
                    <img
                      src={productImages[0] ? productImages[0] : null}
                      alt={translation("imgalt_product")}
                      className={styleClasses.productCardImg}
                    />
                  )}
                </Box>

                <Grid container spacing={2} className={commonStyle.mt10}>
                  {productImages.length > 0 &&
                    productImages.slice(1).map((productImage, index) => (
                      <Grid item lg={4} key={index}>
                        <Box
                          className={`${commonStyle.flexCenter} ${commonStyle.card} ${styleClasses.productDetailCard}`}
                        >
                          <img
                            src={productImage}
                            alt={translation("imgalt_product")}
                          />
                        </Box>
                      </Grid>
                    ))}
                </Grid>
              </Box>
            </Box>
          </Grid>

          <Grid item md={3}>
            <ProductSidebar id={id} type={Type.product} />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ProductDetail;
