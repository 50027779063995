import { Link, RouteComponentProps } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useState, useRef, useEffect, MutableRefObject } from "react";
import GoogleLogins from "../../components/socialLogin/googleLogin";
import FacebookLogins from "../../components/socialLogin/facebookLogin";

import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { Apple, Facebook, Google } from "@mui/icons-material";

import Logo from "../../images/logo-petlover.png";
import Banner from "../../images/login-banner.png";

import { CommonButton } from "../../stories/Button";
import { UserAuthStyles } from "./UserAuth";
import { UserCommonStyles } from "../../styles/CommonStyles";
import { SignUpAction } from "../../redux/action-creators/index";

import { ISignUpState } from "../../interfaceModules/IUserInterface";
import { signUpValidationSchema } from "../../utils/validationSchema";
import { toast } from "../../utils/toastsMessage";

import { GooglePlacesDetail } from "../../redux/action-creators/profileAction";
import { isEmpty } from "../../utils/helper";
import { yupResolver } from "@hookform/resolvers/yup";

import { RoutingLinks, locationPrediction } from "../../utils/constants";

const SignUp = (props: RouteComponentProps) => {
  const { t: translation } = useTranslation();
  const commonStyle = UserCommonStyles();
  const styleClasses = UserAuthStyles();
  const dispatch = useDispatch();
  const [locationPredictions, setLocationPredictions] = useState({
    predictions: locationPrediction.predictions,
    location: locationPrediction.location,
    latitude: locationPrediction.latitude,
    longitude: locationPrediction.longitude,
  });
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  const [timeOutState, setTimeOutState] = useState(null);
  const [disable, setdisable] = useState(false);

  /**
   * Using useForm hook to handle change events, validations and form submission
   */
  const {
    handleSubmit,
    reset,
    register,
    formState: { errors },
  } = useForm<ISignUpState>({
    resolver: yupResolver(signUpValidationSchema(translation)),
  });
  console.log("errrorrrrrrrr", errors);
  //Updates the Textfield value in the state
  /* const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //Nothing to implement
  }; */

  /**
   * location search
   * @param event
   * @returns
   */
  const selectLocation = async (
    event?: Event | React.SyntheticEvent<Element, Event>
  ) => {
    const target = event?.target as HTMLInputElement;
    setLocationPredictions((prevState) => ({
      ...prevState,
      location: target.value,
    }));

    if (locationPredictions?.predictions?.length > 0) {
      setLocationPredictions((prevState) => ({
        ...prevState,
        predictions: [],
      }));
      return;
    }
    if (timeOutState) {
      clearInterval(timeOutState);
    }

    let result: any = setTimeout(async () => {
      let results: any = await googleAutocomplete(target.value);

      console.log("first autocomplete", results);

      if (results) {
        setLocationPredictions((prevState) => ({
          ...prevState,
          latitude: 0,
          longitude: 0,
          predictions: results?.predictions,
        }));
      }
    }, 800);
    setTimeOutState(result);
  };

  /**
   * Submit the form and show the pop-up as toast
   * @param data <ISignUpState>
   * @returns
   */

  function useOutsideAlerter(ref: MutableRefObject<any>) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event: { target: any }) {
        if (ref.current && !ref.current.contains(event.target)) {
          setLocationPredictions((prevState) => ({
            ...prevState,
            predictions: [],
          }));
        }
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  // Autocomplete search
  const googleAutocomplete = async (text: string) => {
    if (isEmpty(text)) {
      setLocationPredictions((prevState) => ({
        ...prevState,
        predictions: [],
      }));
      return null;
    }
    if (!text) {
      return translation("valid_text");
    }

    // for use in things like GatsbyJS where the html is generated first
    if (typeof window === "undefined") {
      return translation("valid_window");
    }

    try {
      let result;
      let autocompleteService = await new google.maps.places.AutocompleteService();
      result = await autocompleteService.getPlacePredictions(
        { input: text },
        result
      );
      return result;
    } catch (e) {
      return e;
    }
  };

  // Select place
  const onPlaceSelected = async (
    placeId: string,
    placeDescription: string,
    event: React.SyntheticEvent
  ) => {
    event.preventDefault();
    const response: any = await dispatch(GooglePlacesDetail(placeId));
    if (!response || !response?.result) {
      return;
    }
    setLocationPredictions({
      predictions: [],
      location: placeDescription,
      latitude: response.result?.geometry?.location?.lat,
      longitude: response.result?.geometry?.location?.lng,
    });
  };

  const onSubmit = async (data: ISignUpState) => {
    setdisable(true);
    const {
      firstname,
      lastname,
      email,
      phone_number,
      address,
      password,
      adult,
      acceptTerms,
    } = data;

    const isacceptedtnc = true;

    //dispatching signup action
    const response: any = await dispatch(
      SignUpAction({
        firstname,
        lastname,
        email,
        phone_number,
        address,
        password,
        adult,
        acceptTerms,
        isacceptedtnc,
        locationPredictions,
      })
    );
    if (response?.data?.isRegistered) {
      setdisable(false);

      reset({});
      props.history.push({
        pathname: RoutingLinks.verifyEmail,
        state: { email: email },
      });
      toast.success(translation(response.data.data.message));
    } else if (response?.data?.data?.code === "ER_DUP_ENTRY") {
      setdisable(false);

      toast.error(translation(response.data.data.error));
    } else {
      setdisable(false);

      toast.error(translation(response.data.message));
    }
  };
  return (
    <Box>
      <Grid container>
        <Grid item md={5}>
          <Box
            className={`${commonStyle.flexColumn} ${styleClasses.loginSide}`}
          >
            <img
              src={Logo}
              alt={translation("log_banner")}
              className={styleClasses.logoSize}
            />
            <img
              src={Banner}
              alt={translation("banner")}
              className={commonStyle.w100}
            />
          </Box>
        </Grid>

        <Grid item md={7}>
          <Box className={styleClasses.authForm}>
            <form
              onSubmit={handleSubmit(onSubmit)}
              noValidate
              className={styleClasses.formGroup}
            >
              <Box className={styleClasses.formSub}>
                <Typography variant="h5">{translation("signup")}</Typography>
                <Grid container spacing={2}>
                  <Grid item md={6}>
                    <Box className={commonStyle.commonInput}>
                      <TextField
                        {...register("firstname")}
                        name="firstname"
                        variant="outlined"
                        label={translation("fname")}
                        className={commonStyle.w100}
                      />
                      {errors && errors.firstname && (
                        <span className={commonStyle.errorMsg}>
                          {errors.firstname.message}
                        </span>
                      )}
                    </Box>
                  </Grid>

                  <Grid item md={6}>
                    <Box className={commonStyle.commonInput}>
                      <TextField
                        {...register("lastname")}
                        name="lastname"
                        variant="outlined"
                        label={translation("lname")}
                        className={commonStyle.w100}
                      />
                      {errors && errors.lastname && (
                        <span className={commonStyle.errorMsg}>
                          {errors.lastname.message}
                        </span>
                      )}
                    </Box>
                  </Grid>

                  <Grid item md={6}>
                    <Box className={commonStyle.commonInput}>
                      <TextField
                        {...register("address")}
                        variant="outlined"
                        autoComplete="off"
                        label={translation("address")}
                        className={commonStyle.w100}
                        onChange={selectLocation}
                        value={locationPredictions.location}
                      />
                      {locationPredictions.predictions.length !== 0 && (
                        <div
                          className={styleClasses.customSearchList}
                          ref={wrapperRef}
                        >
                          <ul className={commonStyle.searchCommonList}>
                            {locationPredictions.predictions.map(
                              (prediction) => (
                                <li
                                  key={prediction?.place_id}
                                  onClick={(e) =>
                                    onPlaceSelected(
                                      prediction?.place_id,
                                      prediction?.description,
                                      e
                                    )
                                  }
                                >
                                  {prediction?.description ||
                                    translation("not_found")}
                                </li>
                              )
                            )}
                          </ul>
                        </div>
                      )}

                      {!locationPredictions.location && (
                        <span className={commonStyle.errorMsg}>
                          {errors?.address?.message}
                        </span>
                      )}
                    </Box>
                  </Grid>

                  <Grid item md={6}>
                    <Box className={commonStyle.commonInput}>
                      <TextField
                        {...register("email")}
                        variant="outlined"
                        label={translation("email_address")}
                        className={commonStyle.w100}
                      />
                      {errors && errors.email && (
                        <span className={commonStyle.errorMsg}>
                          {errors.email.message}
                        </span>
                      )}
                    </Box>
                  </Grid>

                  <Grid item md={6}>
                    <Box className={commonStyle.commonInput}>
                      <TextField
                        {...register("phone_number")}
                        variant="outlined"
                        label={translation("phone_number")}
                        className={commonStyle.w100}
                        inputProps={{ maxLength: 10 }}
                      />
                      {errors && errors.phone_number && (
                        <span className={commonStyle.errorMsg}>
                          {errors.phone_number.message}
                        </span>
                      )}
                    </Box>
                  </Grid>

                  <Grid item md={6}>
                    <Box className={commonStyle.commonInput}>
                      <TextField
                        {...register("password")}
                        variant="outlined"
                        type="password"
                        label={translation("choose_password")}
                        className={commonStyle.w100}
                      />
                      {errors && errors.password && (
                        <span className={commonStyle.errorMsg}>
                          {errors.password.message}
                        </span>
                      )}
                    </Box>
                  </Grid>

                  <Grid item md={6}>
                    {/* <FormGroup> */}

                    <FormControlLabel
                      {...register("acceptTerms", { required: true })}
                      control={<Checkbox />}
                      label={
                        <Link
                          to="/terms-condition"
                          className={`${commonStyle.underlineText} ${commonStyle.black}`}
                          target="_blank"
                        >
                          {translation("accept_term")}
                        </Link>
                      }
                    />
                    {errors && errors.acceptTerms && (
                      <p
                        className={`${commonStyle.errorMsg} ${commonStyle.m0}`}
                      >
                        {errors.acceptTerms.message}
                      </p>
                    )}
                    <FormControlLabel
                      {...register("adult", { required: true })}
                      control={<Checkbox />}
                      label={translation("age_above")}
                    />
                    {errors && errors.adult && (
                      <p
                        className={`${commonStyle.errorMsg} ${commonStyle.m0}`}
                      >
                        {errors.adult.message}
                      </p>
                    )}
                  </Grid>
                </Grid>

                <Box className={`${commonStyle.orangeBtn} ${commonStyle.mt20}`}>
                  <CommonButton
                    label={translation("signup")}
                    onClick={handleSubmit(onSubmit)}
                    disabled={disable}
                  />
                </Box>

                <Box className={styleClasses.orText}>
                  <Typography>{translation("or_continue_with")}</Typography>
                </Box>

                <ul
                  className={`${styleClasses.socialLogin} ${commonStyle.removeListStyle}`}
                >
                  <li>
                    <IconButton
                      className={`${commonStyle.iconBtn} ${commonStyle.facebookBtn} ${commonStyle.socialBtnCustom} ${commonStyle.bgBlue}`}
                    >
                      <FacebookLogins />
                    </IconButton>
                  </li>

                  <li>
                    <IconButton
                      className={`${commonStyle.iconBtn} ${commonStyle.googleBtn} ${commonStyle.socialBtnCustom} ${commonStyle.bgRed}`}
                    >
                      <div className={commonStyle.googleLogin}>
                        <GoogleLogins />
                      </div>
                      <Google />
                    </IconButton>
                  </li>

                  {/* <li>
                    <IconButton
                      className={`${commonStyle.iconBtn} ${commonStyle.appleBtn} ${commonStyle.socialBtnCustom} ${commonStyle.bgBlack}`}
                    >
                      <Apple />
                    </IconButton>
                  </li> */}
                </ul>

                <Box className={styleClasses.authDescFooter}>
                  <Typography className={commonStyle.textCenter}>
                    {translation("signup_footer")} <a href="/terms-condition">{translation("accept_term")} </a>{translation("community_guidelines")} <a href="/privacy-policy">{translation("privacy_policy")}</a>
                  </Typography>
                  <Typography className={commonStyle.textCenter}>
                    {translation("already_have_account")}{" "}
                    <Link to="/">{translation("login")}</Link>
                  </Typography>
                </Box>
              </Box>
            </form>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SignUp;
