import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useEffect, useState, useCallback } from "react";

import { Avatar, Badge, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";

import { getAllUsersNotification, getAllUsersReadNotification } from "../../redux/action-creators/notification";
import { updateNotification } from "../../redux/action-creators/notification";

import LeftSidebar from "../../components/sidebars/LeftSidebar";
import RightSidebar from "../../components/sidebars/RightSidebar";

import {
  friendRequest,
  acceptFriend,
  addComment,
  taggedFriend,
  Like,
  RoutingLinks,
  approveAdd,
  rejectAdd,
  add_complete,
  ad_completeion24,
  Insufficient_amount,
  Seller_approve,
  new_order
} from "../../utils/constants";
import { capitalize, getTimeDiff } from "../../utils/helper";

import { INotificationlist } from "../../interfaceModules/IUserInterface";

import { ProfileStyle } from "./ProfileStyle";
import { UserCommonStyles } from "../../styles/CommonStyles";
import defaultUserprofile from "../../images/defaultUserprofile.png";
import minImage from "../../images/minImage.png";




const Notifications = () => {
  const { t: translation } = useTranslation();
  const commonStyle = UserCommonStyles();
  const styleClasses = ProfileStyle();
  const dispatch = useDispatch();

  const userId = Number(localStorage.getItem("userId"));
  const [userList, setuserList] = useState<INotificationlist[]>([]);
  const [loader, setLoader] = useState(true);

  /**
   * get all notification
   */
  const getAllNotification = useCallback(async () => {
    const result: any = await dispatch(getAllUsersNotification(userId));
    if (result && result.length > 0) {
      setLoader(false);
      setuserList(result);
    }
    setLoader(false);
  }, [dispatch, userId]);


  /**
   * to get all readed notification  
  */
  const getAllNotificationReads = useCallback(async () => {
    await dispatch(getAllUsersReadNotification(userId, true));
  }, [dispatch, userId]);


  /**
   * read all notification
   */
  const readAllNotification = useCallback(async () => {
    const result: any = await dispatch(updateNotification(userId));
    if (result) {
      getAllNotificationReads();
    }
  }, [dispatch, userId, getAllNotificationReads]);


  useEffect(() => {
    getAllNotification();
    readAllNotification();


  }, [getAllNotification, readAllNotification]);

  return (
    <Box>
      <Box className={commonStyle.windowColor}>
        <Grid container>
          <Grid item md={3}>
            <LeftSidebar />
          </Grid>

          <Grid item md={6}>
            <Box className={`${commonStyle.p20} ${commonStyle.mt20}`}>
              <Typography
                variant="h6"
                className={`${commonStyle.grey} ${commonStyle.mb15} ${commonStyle.fontWeight600}`}
              >
                {translation("notification")}
              </Typography>
              <Box className={`${commonStyle.p20} ${commonStyle.card}`}>
                <ul className={styleClasses.badgeCircular}>
                  {userList.length > 0
                    ? userList.map((item, index) => {
                      let notificationPath = "";
                      if (item.notificationtype === friendRequest ||
                        item.notificationtype === acceptFriend ||                                            
                        item.notificationtype === addComment) {
                        notificationPath = `${RoutingLinks.profiles}${item.id}`;
                      }                      

                      if (item.notificationtype === Like ||
                        item.notificationtype === taggedFriend ||  
                        item.notificationtype === addComment) {
                        notificationPath = `${RoutingLinks.postDetail}${JSON.parse(item.additional_information).post_id}`;
                      }

                      if (item.notificationtype === approveAdd ||
                        item.notificationtype === rejectAdd ||
                        item.notificationtype === add_complete || item.notificationtype === ad_completeion24) {
                        notificationPath = `${RoutingLinks.adlist}`;
                      }

                      if (item.notificationtype === Insufficient_amount) {
                        notificationPath = `${RoutingLinks.viewBalance}`;
                      }

                      if (item.notificationtype === Seller_approve) {
                        notificationPath = `${RoutingLinks.marketplace}`;
                      }
                      if(item.notificationtype===new_order)
                      {
                        notificationPath =`${RoutingLinks.orders}`
                      }

                      return (
                        <Link to={{ pathname: notificationPath }}  key={index}>
                          <li                            
                            className={`${commonStyle.flexCenter} ${commonStyle.justifySpaceBetween}`}
                          >
                            <Box className={commonStyle.wallUserInfo}>
                              <Badge
                                overlap="circular"
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "right",
                                }}
                                badgeContent={
                                  <img
                                    alt={translation("remy_sharp")}
                                    src={item.profile_image
                                      ? item.profile_image
                                      : defaultUserprofile}
                                    className={styleClasses.badgeImg}
                                  />
                                }
                              >
                                <Avatar
                                  alt={translation("travis_howard")}
                                  src={item.profile_image
                                    ? item.profile_image
                                    : minImage}
                                />
                              </Badge>
                              <Box className={commonStyle.ml20}>
                                <Typography variant="h2">
                                  {item.notificationtype === friendRequest ? (
                                    <>
                                      {translation("Friend_request_message")}
                                      <strong>
                                        {capitalize(item.first_name)}{" "}
                                        {capitalize(item.last_name)}
                                      </strong>
                                    </>
                                  ) : item.notificationtype === acceptFriend ? (
                                    <>
                                      <strong>
                                        {capitalize(item.first_name)}{" "}
                                        {capitalize(item.last_name)}{" "}
                                      </strong>
                                      {translation("accept_friend_request")}{" "}
                                    </>
                                  ) : 
                                  item.notificationtype === new_order ? (
                                  <>
                                    {translation("new_order_message")}{" "}
                                   <strong>
                                     {capitalize(item.first_name)}{" "}
                                     {capitalize(item.last_name)}
                                   </strong>
                                   </>
                               
                                  ) :
                                  item.notificationtype === addComment ? (
                                    <>
                                      <strong>
                                        {capitalize(item.first_name)}{" "}
                                        {capitalize(item.last_name)}
                                      </strong>
                                      {translation("comment_post")}{" "}
                                    </>
                                  ) : item.notificationtype === taggedFriend ? (
                                    <>
                                      {/* <Link
                                        className={`${commonStyle.anchorDecoration} ${commonStyle.black} ${commonStyle.fontWeight800}`}
                                        to={{ pathname: `${RoutingLinks.profiles}${item.id}` }}
                                      > */}
                                      <strong>
                                        {capitalize(item.first_name)}{" "}
                                        {capitalize(item.last_name)}
                                      </strong>
                                      {/* </Link>

                                      <Link
                                        to={{ pathname: `${RoutingLinks.postDetail}${JSON.parse(item.additional_information).id}` }}
                                      > */}
                                      {translation("tagged_post")}{" "}
                                      {/* </Link> */}

                                    </>
                                  ) : item.notificationtype === Like ? (
                                    <>
                                      {/* <Link
                                        className={`${commonStyle.anchorDecoration} ${commonStyle.black} ${commonStyle.fontWeight800}`}
                                        to={{ pathname: `${RoutingLinks.profiles}${item.id}` }}
                                      > */}
                                      <strong>
                                        {capitalize(item.first_name)}{" "}
                                        {capitalize(item.last_name)}
                                      </strong>
                                      {translation("like_post")}{" "}


                                    </>
                                  ) : item.notificationtype === approveAdd ? (
                                    <>

                                      {translation("add_approve")}{" "}


                                    </>
                                  ) :
                                    item.notificationtype === rejectAdd ? (
                                      <>
                                        {translation("add_reject")}{" "}

                                      </>
                                    ) : item.notificationtype === add_complete ?
                                      (
                                        <>
                                          {translation("add_complete")}{" "}
                                        </>
                                      )
                                      : item.notificationtype === ad_completeion24 ?
                                        (
                                          <>

                                            {translation("ad_completion24")}{" "}


                                          </>
                                        ) : item.notificationtype === Insufficient_amount ?
                                          (
                                            <>

                                              {translation("insufficient_amount")}{" "}

                                            </>) :
                                          item.notificationtype === Seller_approve ?
                                            (
                                              <>

                                                {translation("approve_seller")}{" "}


                                              </>) :
                                            null}
                                </Typography>
                                <Typography>
                                  {item?.created_ts
                                    ? getTimeDiff(item?.created_ts)
                                    : ""}{" "}
                                  {translation("ago")}
                                </Typography>
                              </Box>
                            </Box>

                            <Box
                              className={`${commonStyle.settingsBtn} ${commonStyle.ml10} ${commonStyle.mr10}`}
                            ></Box>
                          </li>
                        </Link>
                      )
                    })
                    : loader
                      ? translation("loading")
                      : translation("missing_record")}
                </ul>

                <Box
                  className={`${commonStyle.blueBtn} ${commonStyle.pt20} ${commonStyle.textCenter}`}
                ></Box>
              </Box>
            </Box>
          </Grid>

          <Grid item md={3}>
            <RightSidebar />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Notifications;
