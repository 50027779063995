import React, { useEffect, useState, useCallback } from "react";
import { Redirect } from "react-router";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  Button,
  Grid,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
  Select,
  MenuItem,
  Modal,
} from "@mui/material";
import {
  CheckCircleOutline,
  HighlightOff,
  ModeEditOutline,
} from "@mui/icons-material";
import { Box } from "@mui/system";

import { updatePassword, updateSetting } from "../../redux/action-creators/profileAction";
import { logoutUser, userDeleteAccount } from "../../redux/action-creators/index";

import LeftSidebar from "../../components/sidebars/LeftSidebar";

import { language, Privacy, empStr } from "../../utils/constants";
import SelectLanguage from '../../utils/lang';

import { ProfileStyle } from "./ProfileStyle";
import { UserCommonStyles } from "../../styles/CommonStyles";
import { blockUserListbyid, unblockUser } from "../../redux/action-creators/reportAction";
import ApiResponse from "../../interfaceModules/IApiResponse";
import { IBlockUserData } from "../../interfaceModules/ICommonInteface";
import { toast } from "../../utils/toastsMessage";
import ClipLoader from "react-spinners/ClipLoader";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#fff",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
  borderRadius: "10px",
};

const Settings = (props:any) => {
  const { t: translation } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const dispatch = useDispatch();
  const commonStyle = UserCommonStyles();
  const styleClasses = ProfileStyle();
  const userDataObject = useSelector((state: RootStateOrAny) => state.authReducer.authData);
  const userIdphone: number = Number(localStorage.getItem("userId"));
  const [userBlockData, setuserBlockData] = useState<IBlockUserData[]>([]);
  const [disable,setdisable]=useState(false)
  const [showDeleteModal, setDeleteModal] = useState(false)

  const [checked, setchecked] = useState(userDataObject.notification ? userDataObject.notification : 0);

  const getAllBlockUserList = useCallback(async (id: number) => {
    await blockUserListbyid(id).then((response: ApiResponse) => {
      if (response) {
        setuserBlockData(response.data.data.data)
      }

    })
  }, []);

  useEffect(() => {

    getAllBlockUserList(userIdphone)

  }, [getAllBlockUserList, userIdphone]);



  /**
   * common states
   */
  const [isEditSettings, setIsEditSettings] = React.useState({
    mobile: false,
    email: false,
    privacy: false,
    language: false,
    phone_number: false,
    notification: userDataObject.notification ? userDataObject.notification : 0,
  });

  /**
   * password error message states
   */
  const [errorPasswordMessage, setErrorPasswordMessage] = React.useState({
    oldPassword: [],
    newPassword: [],
    confirmNewPassword: [],
  });

  /**
   *  error message states
   */
  let [errorMessage, setErrorMessage] = React.useState({
    phone_number: empStr,
    email: empStr,
  });

  /**
   * updating state
   */
  const [updateProfileDetails, setupdateProfileDetails] = React.useState({
    email: userDataObject.email,
    phone_number: userDataObject.phone_number,
    language: userDataObject.language,
    privacy: userDataObject.privacy,
    oldPassword: empStr,
    newPassword: empStr,
    confirmNewPassword: empStr,
    successMessage: empStr,
    errormessage: empStr,
    success: undefined,
  });

  /**
   * previous state
   */
  const [previousProfileDetails, setPreviousProfileDetails] = React.useState({
    email: userDataObject.email,
    phone_number: userDataObject.phone_number,
    language: userDataObject.language,
    privacy: userDataObject.privacy,
    oldPassword: empStr,
    newPassword: empStr,
    confirmNewPassword: empStr,
  });

  /**
   * get value from input fields
   * @param event
   * @param name
   */
  const handleChangetextfield = (event, name: string) => {
    setupdateProfileDetails((prevState) => ({
      ...prevState,
      [name]: event.target.value,
    }));
    showError(event, name);
  };

  /**
   * edit button
   * @param name
   */
  const handleChange = (name: string) => {
    setIsEditSettings((prevState) => ({
      ...prevState,
      [name]: true,
    }));
  };

  /**
   * Update on right click
   * @param name
   */
  const updateDetails = (name: string) => {
    if (!errorMessage.phone_number) {
      setIsEditSettings((prevState) => ({
        ...prevState,
        [name]: false,
      }));

      setErrorMessage((prevState) => ({
        ...prevState,
        [name]: "",
      }));
      setupdateProfileDetails((prevState) => ({
        ...prevState,
        email: updateProfileDetails.email,
        phone_number: updateProfileDetails.phone_number,
        language: updateProfileDetails.language,
        privacy: updateProfileDetails.privacy,
      }));
      setPreviousProfileDetails((prevState) => ({
        ...prevState,
        email: updateProfileDetails.email,
        phone_number: updateProfileDetails.phone_number,
        language: updateProfileDetails.language,
        privacy: updateProfileDetails.privacy,
      }));
      save([name]);
    }
  };

  /**
   * notification
   */
  const updateDetailsNotification = () => {
    if (isEditSettings.notification) {
      setchecked(false);
      isEditSettings.notification = isEditSettings.notification - 1;
    } else {
      setchecked(true);
      isEditSettings.notification = isEditSettings.notification + 1;
    }
    save(isEditSettings.notification);
  };

  /**
   * cancel on cross icon
   * @param name
   */
  const onCancel = (name: string) => {
    setIsEditSettings((prevState) => ({
      ...prevState,
      [name]: false,
    }));
    setErrorMessage((prevState) => ({
      ...prevState,
      [name]: "",
    }));

    setupdateProfileDetails((prevState) => ({
      ...prevState,
      email: previousProfileDetails.email,
      phone_number: previousProfileDetails.phone_number,
      language: previousProfileDetails.language,
      privacy: previousProfileDetails.privacy,
    }));
  };

  /**
   * save data to database
   * @param data
   */
  const save = async (...data) => {
    userDataObject.phone_number = updateProfileDetails.phone_number;
    userDataObject.language = updateProfileDetails.language;
    userDataObject.privacy = updateProfileDetails.privacy;
    userDataObject.notification = isEditSettings.notification;
    localStorage.setItem("userData", JSON.stringify(userDataObject));
    const response: any = await dispatch(
      updateSetting(updateProfileDetails, userIdphone, isEditSettings.notification)
    );
    if (response && response?.isUpdated) {
      return toast.success(translation(response.message));
    }
    else {
      if(response){
        return toast.error(translation(response?.message));
      }
    }
  };

  /**
   * handlechange for password
   * @param event
   * @param name
   */
  const handlePassChange = (event, name: string) => {
    if (
      errorPasswordMessage &&
      errorPasswordMessage[name] &&
      errorPasswordMessage[name][0]
    ) {
      setErrorPasswordMessage((prevState) => ({
        ...prevState,
        [name]: undefined,
      }));
    }
    setupdateProfileDetails((prevState) => ({
      ...prevState,
      [name]: event.target.value,
    }));
  };

  /**
   * handlesubmit for password
   * @param event
   */
  const handlePassSubmit = async (event) => {
    event.preventDefault();
    setdisable(true)

    await setupdateProfileDetails((prevState) => ({
      ...prevState,
      successMessage: "",
      errormessage: "",
    }));
    if (validateUserInfo()) {
      const res: any = await dispatch(
        updatePassword(
          {
            email: updateProfileDetails.email,
            oldPassword: updateProfileDetails.oldPassword,
            newPassword: updateProfileDetails.newPassword,
            confirmNewPassword: updateProfileDetails.confirmNewPassword,
          },
          userIdphone
        )
      );

      if (res !== undefined) {
        if (res.success) {      
          setupdateProfileDetails((prevState) => ({
            ...prevState,
            oldPassword: "",
            newPassword: "",
            confirmNewPassword: "",
            errormessage: "",
            successMessage: translation(res.message),
            success: res.success,
          }));
          setdisable(false)
          return toast.success(translation(res.message));
          // handleLogout();
        } else {
          setupdateProfileDetails((prevState) => ({
            ...prevState,
            errormessage: translation(res.message),
            successMessage: "",
            success: translation(res.success),
          }));

          setdisable(false)
          return toast.success(translation(res.message));
        }
      } else {
        setupdateProfileDetails((prevState) => ({
          ...prevState,
          successMessage: translation("input_err_mess"),
          success: res.success,
        }));
        setdisable(false)
        return toast.error(translation("input_err_mess"));

      }
    }
  };

  /**
   * validation function for  phone number
   * @param event
   * @param name
   * @returns
   */

  const showError = (event, name: string) => {
    let email_checker = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6})*$/;
    let phone_checker = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;

    if (name === "phone_number") {
      if (event.target.value === 0) {
        setErrorMessage((prevState) => ({
          ...prevState,
          phone_number: translation("required_field", {
            is_required: translation("is_required"),
          }),
        }));
      } else if (!phone_checker.test(event.target.value)) {
        setErrorMessage((prevState) => ({
          ...prevState,
          phone_number: translation("phone_valid"),
        }));
      } else {
        setErrorMessage((prevState) => ({
          ...prevState,
          phone_number: "",
        }));
        return false;
      }
    } else if (name === "email") {
      if (event.target.value === 0) {
        setErrorMessage((prevState) => ({
          ...prevState,
          email: translation("required_field", {
            is_required: translation("is_required"),
          }),
        }));
      } else if (!email_checker.test(event.target.value)) {
        setErrorMessage((prevState) => ({
          ...prevState,
          email: translation("email_valid"),
        }));
      } else {
        setErrorMessage((prevState) => ({
          ...prevState,
          email: "",
        }));
        return false;
      }
    }
  };

  /**
   * validation function for passwords
   * @returns
   */
  const validateUserInfo = () => {
    let isFormValid = true;

    if (updateProfileDetails.oldPassword.trim().length === 0) {
      isFormValid = false;
      setdisable(false)
      errorPasswordMessage.oldPassword = [translation("old_pass_req")];
    }

    if (updateProfileDetails.newPassword.trim().length < 6) {
      isFormValid = false;
      setdisable(false)
      errorPasswordMessage.newPassword = [translation("err_passms1")];
    }

    if (updateProfileDetails.newPassword.trim().length === 0) {
      isFormValid = false;
      setdisable(false)
      errorPasswordMessage.newPassword = [translation("err_passms2")];
    }

    if (
      updateProfileDetails.newPassword.trim() !==
      updateProfileDetails.confirmNewPassword.trim()
    ) {
      isFormValid = false;
      setdisable(false)
      errorPasswordMessage.confirmNewPassword = [translation("err_passms3")];
    }

    if (updateProfileDetails.confirmNewPassword.trim().length === 0) {
      isFormValid = false;
      setdisable(false)
      errorPasswordMessage.confirmNewPassword = [translation("err_passms4")];
    }

    setupdateProfileDetails((prevState) => ({
      ...prevState,
    }));
    return isFormValid;
  };

  /**
   * logout
   */
  const handleLogout = async () => {
    await dispatch(logoutUser());
    props.history.push("/");
  };


  const unblockUserbyid = async (id: number) => {
    await unblockUser(userIdphone, id).then((response: ApiResponse) => {

      if (response) {

        getAllBlockUserList(userIdphone)

      }


    })

  }

  const deleteAccount=async ()=>{
    setdisable(true)    
    
    const result: any = await dispatch(
      userDeleteAccount(userDataObject.id)
    );
     
     if(result?.data?.success)
     {

      await dispatch(logoutUser());
      props.history.push("/");
      return toast.success(translation("account_deleted_successfully"));

     }

    console.log("deleteAccount",result.data.success)
  }

  return (

    <>
      <Box>

        <Box className={commonStyle.windowColor}>
          <Grid container>
            <Grid item md={3}>
              <LeftSidebar />
            </Grid>

            <Grid item md={9}>
              <Box className={`${commonStyle.p20} ${commonStyle.mt20}`}>
                <Typography
                  variant="h6"
                  className={`${commonStyle.grey} ${commonStyle.mb15} ${commonStyle.fontWeight600}`}
                >
                  {translation("settings")}
                </Typography>

                <Box className={`${commonStyle.card} ${commonStyle.p20}`}>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <strong>{translation("notification")}</strong>
                        </TableCell>

                        <TableCell colSpan={3}>
                          <Switch
                            checked={checked}
                            onChange={updateDetailsNotification}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        </TableCell>
                      </TableRow>

                     

                      <TableRow>
                        <TableCell>
                          <strong>{translation("mobile")}</strong>
                        </TableCell>
                        <TableCell>
                          {isEditSettings.phone_number ? (
                            <TextField
                              variant="standard"
                              onChange={(event) =>
                                handleChangetextfield(event, "phone_number")
                              }
                              defaultValue={updateProfileDetails.phone_number}
                              inputProps={{ maxLength: 10 }}
                            />
                          ) : (
                            updateProfileDetails.phone_number
                          )}
                        </TableCell>

                        <TableCell>&nbsp;</TableCell>

                        <TableCell>
                          {isEditSettings.phone_number ? (
                            <Box className={styleClasses.editCheckInline}>
                              <CheckCircleOutline
                                onClick={() => updateDetails("phone_number")}
                                className={`${commonStyle.mr10} ${commonStyle.skyBlue}`}
                              />
                              <HighlightOff
                                onClick={() => onCancel("phone_number")}
                                className={commonStyle.red}
                              />
                            </Box>
                          ) : (
                            <Typography
                              onClick={() => handleChange("phone_number")}
                              className={`${commonStyle.flexCenter} ${styleClasses.editLink} ${commonStyle.font14}`}
                            >
                              {translation("edit")}
                              <ModeEditOutline
                                className={styleClasses.editIconSettings}
                              ></ModeEditOutline>
                            </Typography>
                          )}
                        </TableCell>
                        <span className={commonStyle.errorMsg}>
                          {errorMessage.phone_number}
                        </span>
                      </TableRow>

                      <TableRow>
                        <TableCell>
                          <strong>{translation("email")}</strong>
                        </TableCell>
                        <TableCell colSpan={3}>{userDataObject.email}</TableCell>
                        {/* email section */}
                        {/* <TableCell>
                        {isEditSettings.email ? (
                          <TextField
                            variant="standard"
                            onChange={(event) =>
                              handleChangetextfield(event, "email")
                            }
                            defaultValue={updateProfileDetails.email}
                          />
                        ) : (
                          updateProfileDetails.email
                        )}
                      </TableCell>

                      <TableCell>
                        {isEditSettings.email ? (
                          <Box className={styleClasses.editCheckInline}>
                            <CheckCircleOutline


                              onClick={() => updateDetails("email")}
                              // onClick={(event) => showError(event, "email")}
                              className={`${commonStyle.mr10} ${commonStyle.skyBlue}`}
                            />
                            <HighlightOff
                              onClick={() => onCancel("email")}
                              className={commonStyle.red}
                            />
                          </Box>
                        ) : (
                          <Box onClick={() => handleChange("email")}>
                            <Box>
                              Edit{""}
                              <ModeEditOutline
                                className={styleClasses.editIconSettings}
                              ></ModeEditOutline>
                            </Box>
                          </Box>
                        )}
                      </TableCell>
                      <span className={commonStyle.errorMsg}>
                        {errorMessage.email}
                      </span> */}
                        {/* email section */}
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <strong>{translation("language")}</strong>
                        </TableCell>
                        <TableCell>
                          {isEditSettings.language ? (
                            //changes
                            <Select
                              onChange={(event) =>
                                handleChangetextfield(event, "language")
                              }
                              defaultValue={
                                updateProfileDetails.language && updateProfileDetails.language!=="null" 
                                  ? updateProfileDetails.language
                                  : ""
                              }
                              className={styleClasses.tableSelect}
                            >
                              {language.length > 0 &&
                                language?.map((lan, i) => {
                                  return (
                                    <MenuItem key={i} value={lan}>
                                      {translation(lan)}
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                          ) : (
                            translation(updateProfileDetails.language)
                          )}
                        </TableCell>
                        <TableCell>&nbsp;</TableCell>
                        <TableCell>
                          {isEditSettings.language ? (
                            <Box className={styleClasses.editCheckInline}>
                              <CheckCircleOutline
                                onClick={() => updateDetails("language")}
                                className={`${commonStyle.mr10} ${commonStyle.skyBlue}`}
                              />
                              <HighlightOff
                                onClick={() => onCancel("language")}
                                className={commonStyle.red}
                              />
                            </Box>
                          ) : (
                            <Typography
                              onClick={() => handleChange("language")}
                              className={`${commonStyle.flexCenter} ${styleClasses.editLink} ${commonStyle.font14}`}
                            >
                              {translation("edit")}
                              <ModeEditOutline
                                className={styleClasses.editIconSettings}
                              ></ModeEditOutline>
                            </Typography>
                          )}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>
                          <strong>{translation("acc_privacy")}</strong>
                        </TableCell>
                        <TableCell>{translation("privacy_ms")}</TableCell>

                        <TableCell>
                          {isEditSettings.privacy ? (
                            //changes
                            <Select
                              onChange={(event) =>
                                handleChangetextfield(event, "privacy")
                              }
                              defaultValue={
                                updateProfileDetails.privacy && updateProfileDetails.privacy!=="null" 
                                  ? updateProfileDetails.privacy
                                  : ""
                              }
                              className={styleClasses.tableSelect}
                            >
                              {Privacy.length > 0 &&
                                Privacy?.map((acc, j) => {
                                  return (
                                    <MenuItem key={j} value={acc}>
                                      {translation(acc)}
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                          ) : // <Select defaultValue='' className={styleClasses.tableSelect}>
                            //   </Select>                            
                            updateProfileDetails.privacy && updateProfileDetails.privacy!=="null"  ? (
                              translation(updateProfileDetails.privacy)
                            ) : (
                              ""
                            )}
                        </TableCell>

                        <TableCell>
                          {isEditSettings.privacy ? (
                            <Box className={styleClasses.editCheckInline}>
                              <CheckCircleOutline
                                onClick={() => updateDetails("privacy")}
                                className={`${commonStyle.mr10} ${commonStyle.skyBlue}`}
                              />
                              <HighlightOff
                                onClick={() => onCancel("privacy")}
                                className={commonStyle.red}
                              />
                            </Box>
                          ) : (
                            <Typography
                              onClick={() => handleChange("privacy")}
                              className={`${commonStyle.flexCenter} ${styleClasses.editLink} ${commonStyle.font14}`}
                            >
                              {translation("edit")}
                              <ModeEditOutline
                                className={styleClasses.editIconSettings}
                              ></ModeEditOutline>
                            </Typography>
                          )}
                        </TableCell>
                      </TableRow>

                      {!userDataObject.signUpBySocial && (
                        <TableRow>
                          <TableCell>
                            <strong>{translation("pass_change")}</strong>
                          </TableCell>
                          <TableCell colSpan={2}>*******************</TableCell>
                          <TableCell>
                            <Typography
                              className={`${commonStyle.flexCenter} ${styleClasses.editLink} ${commonStyle.font14}`}
                              onClick={handleOpen}
                            >
                              {translation("edit")}
                              <ModeEditOutline
                                className={styleClasses.editIconSettings}
                              />
                            </Typography>
                            {/* change password  */}
                            <Modal
                              open={open}
                              onClose={handleClose}
                              aria-labelledby="modal-modal-title"
                              aria-describedby="modal-modal-description"
                            >
                              <Box sx={style}>
                                <Typography
                                  id="modal-modal-title"
                                  variant="h6"
                                  component="h2"
                                  className={commonStyle.mb15}
                                >
                                  {translation("edit_pass")}
                                </Typography>
                                <Typography
                                  id="modal-modal-title"
                                  variant="h6"
                                  component="h2"
                                  className={commonStyle.mb15}
                                ></Typography>
                                {(() => {
                                  if (
                                    updateProfileDetails.successMessage !== "" &&
                                    updateProfileDetails.success
                                  ) {
                                    return (
                                      <div>
                                        {updateProfileDetails.successMessage}
                                        <Redirect to="/"></Redirect>
                                      </div>
                                    );
                                  } else if (
                                    updateProfileDetails.errormessage !== ""
                                  ) {
                                    return (
                                      <Typography
                                        className={`${commonStyle.mb10} ${commonStyle.red}`}
                                      >
                                        {updateProfileDetails.errormessage}
                                      </Typography>
                                    );
                                  }
                                })()}

                                <form>
                                  <Box className={commonStyle.commonInput}>
                                    <TextField
                                      type="password"
                                      variant="outlined"
                                      label={translation("old_pass")}
                                      className={commonStyle.w100}
                                      onChange={(event) =>
                                        handlePassChange(event, "oldPassword")
                                      }
                                    />
                                    <span className={commonStyle.errorMsg}>
                                      {errorPasswordMessage.oldPassword &&
                                        errorPasswordMessage.oldPassword[0]}
                                    </span>
                                  </Box>

                                  <Box className={commonStyle.commonInput}>
                                    <TextField
                                      type="password"
                                      variant="outlined"
                                      label={translation("new_pass")}
                                      className={commonStyle.w100}
                                      onChange={(event) =>
                                        handlePassChange(event, "newPassword")
                                      }
                                    />
                                    <span className={commonStyle.errorMsg}>
                                      {errorPasswordMessage.newPassword &&
                                        errorPasswordMessage.newPassword[0]}
                                    </span>
                                  </Box>
                                  <Box className={commonStyle.commonInput}>
                                    <TextField
                                      type="password"
                                      variant="outlined"
                                      label={translation("confirm_pass")}
                                      className={commonStyle.w100}
                                      onChange={(event) =>
                                        handlePassChange(
                                          event,
                                          "confirmNewPassword"
                                        )
                                      }
                                    />
                                    <span className={commonStyle.errorMsg}>
                                      {errorPasswordMessage.confirmNewPassword &&
                                        errorPasswordMessage.confirmNewPassword[0]}
                                    </span>
                                  </Box>

                                  <Box
                                    className={`${commonStyle.w100} ${commonStyle.orangeBtn}`}
                                  >
                                    <Button 
                                     disabled={disable}
                                    onClick={handlePassSubmit}
                                   >
                                      {translation("submit")}
                                    </Button>
                                  </Box>
                                </form>
                              </Box>
                            </Modal>
                            {/* change password  */}
                          </TableCell>
                        </TableRow>
                      )}

                      {userBlockData.length > 0 ?

                        <TableRow>
                          <TableCell>
                            <strong>{translation("Blocked Users")}</strong>

                            {userBlockData.map((item, index) => (
                              <li key={index}>{item.first_name + " " + item.last_name}
                                <TableCell colSpan={3}>
                                  <Box className={`${commonStyle.blueBtn}`}>
                                    <Button onClick={() => unblockUserbyid(item.profile_id)}>{translation("Unblock")}</Button>
                                  </Box>
                                </TableCell></li>
                            ))}
                          </TableCell>

                        </TableRow>
                        : null}
                         <TableRow>
                      <TableCell>
                          <strong>{translation("delete_account")}</strong>
                        </TableCell>
                        <TableCell colSpan={3}>
                          <Button
                          onClick={() => {
                            setDeleteModal(true)
                          }}
                          >
                           Continue
                          </Button>
                         
                        </TableCell>

                      </TableRow>
                    </TableBody>
                  </Table>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Modal
                                open={showDeleteModal}
                                onClose={()=>{setDeleteModal(false)}}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                                className={styleClasses.modalShadow}
                              >
                                <Box sx={style}>
                                  <Typography
                                    id="modal-modal-title"
                                    variant="h6"
                                    component="h2"
                                  >
                                    {translation("delete_account_user")}
                                  </Typography>
                                  <Box
                                    id="modal-modal-description"
                                    sx={{ mt: 2 }}
                                    className={commonStyle.flexCenter}
                                  >
                                    <Box
                                      className={`${commonStyle.blueBtn} ${commonStyle.pt20} ${commonStyle.mr10}`}
                                    >
                                      <Button
                                        disabled={disable}
                                         onClick={deleteAccount}
                                      >
                                        {" "}
                                        {translation("yes")}
                                        {disable ? <ClipLoader /> : null}
                                      </Button>
                                    </Box>

                                    <Box
                                      className={`${commonStyle.blueBtn} ${commonStyle.pt20}`}
                                    >
                                      <Button  onClick={() => {
                  setDeleteModal(false)
                }}>
                                        {translation("no")}
                                      </Button>
                                    </Box>
                                  </Box>
                                </Box>
                              </Modal>
      </Box>
      <SelectLanguage />
    </>
  );
};

export default Settings;
