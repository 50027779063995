import { MouseEvent, useEffect, useRef, useState, RefObject } from "react";
import { useTranslation } from "react-i18next";

import { Avatar, Badge, Typography } from "@mui/material";
import { Box } from "@mui/system";
import {
  SentimentSatisfiedOutlined,
  Article,
  PictureAsPdf,
} from "@mui/icons-material";

import { getEmoji } from "../../utils/helper";
import { NewLineRegex, Type } from "../../utils/constants";
import { IMessage, IMessageBoxProps } from "../../utils/interfaceModel";

import EmojiPopover from "./emojiPopover";

import { UserCommonStyles } from "../../styles/CommonStyles";
import { ProfileStyle } from "../../screens/profile/ProfileStyle";
import moment from "moment";

const MessageBox: React.FC<IMessageBoxProps> = (props) => {
  const inputRefs: RefObject<unknown> = useRef({});
  let currentDate:any = new Date();

  const divRef = useRef(null);
  const { t: translation } = useTranslation();
  const commonStyle = UserCommonStyles();
  const styleClasses = ProfileStyle();
  const {
    messages,
    userData,
    setOpenImageModal,
    handleReplyOnMessage,
    sendEmojiMessage,
  } = props;


  /**
   * Getting appropriate time for chat
   */
 const getDateTime = (timestamp: any): any => {
  const unixTimeStamp = timestamp / 10000000;
  const message = new Date(Math.ceil(unixTimeStamp * 1000));
  let date;
  const today = new Date();
  const diff = moment(today, "DD.MM.YYYY")
    .startOf("day")
    .diff(moment(message, "DD.MM.YYYY").startOf("day"), "days");
  if (diff === 0) {
    date = "Today";
  } else if (diff <= 7) {
    date = moment(message).format("dddd");
  } else {
    date = moment(message).format("MM/DD//YYYY");
  }
  const time = moment(message).format("hh:mm A");
  console.log("time",time)
   return time;
};


  /**
   * getting date from message timestamp
   */
   const getDate = (id: any):any => {
    const unixTimeStamp = id / 10000000;
    const message12 = unixTimeStamp * 1000;
    return moment(message12).format("L");
  };

  const getExactTime = (id: any) => {
    const date :any= getDate(id);
    const timeStamp = new Date().getTime();
    const yesterdayTimeStamp = timeStamp - 24 * 60 * 60 * 1000;
    currentDate = date;
    if (moment(yesterdayTimeStamp).format("L") === date) {
      return "Yesterday";
    } else if (moment(timeStamp).format("L") === date) {
      return "Today";
    } else {
      return date;
    }
  };

  /**
   * Auto scrolling to the last message
   */
  const [emojiMsg, setEmojiMsg] = useState<{
    message: IMessage | null;
    e?: EventTarget;
  }>({
    message: null,
    e: null,
  });
  useEffect(() => {
    divRef?.current?.scrollIntoView({ behavior: "smooth" });
  }, []);

  useEffect(() => {
    if (
      divRef.current &&
      (userData.id !== messages[messages.length - 1]?.entry?.id ||
        isInViewport(divRef.current))
    ) {
      divRef.current.scrollIntoView({ behavior: "smooth" });
    }

  
  }, [messages, userData.id]);

  /**
   *
   * @param ref sets Ref of every message
   * @param token
   */
  const setRef = (ref: React.Ref<unknown>, token: string) => {
    inputRefs.current[token] = ref;
  };

  /**
   * this function used to scroll user view port to quoted message when user clicks on quotes message
   * @param token
   */
  const focusQuoteMessage = (token?: string) => {
    if (token && inputRefs.current[token]) {
      inputRefs.current[token].scrollIntoView({
        inline: "center",
        block: "center",
        behavior: "smooth",
      });
    }
  };

  /**
   * this function checks that is element is available in view port or not
   * @param el
   * @returns
   */
  function isInViewport(el: Element) {
    const rect = el.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= window.innerHeight &&
      rect.right <= window.innerWidth
    );
  }
  return (
    <Box className={`${styleClasses.chatMainHeight} ${commonStyle.py15}`}>
      {messages.length
        ? messages.map((messageInfo, index: number) => {
            const data = messageInfo?.entry;
            const repliedMessage = data?.repliedMessage?.entry?.text;

            const QuotedBgColor =
              data?.id === userData.id && repliedMessage
                ? commonStyle.bgRed
                : commonStyle.bgSkyBlue;
            return (
             <>
             {(currentDate)!==(getDate(messageInfo?.timetoken)) && (
             <Box className={`${commonStyle.textCenter} ${commonStyle.m20}`}>
             <Badge className={styleClasses.timeSlotBadge}>{getExactTime(messageInfo?.timetoken)}</Badge>
             </Box>)}
              <Box
                className={`${
                  data?.id !== userData.id ? commonStyle.flexEnd : ""
                } ${commonStyle.mb15} ${commonStyle.dflex}`}
                key={index}
                ref={divRef}
              >
                {data?.id === userData.id ? (
                  <Avatar
                    src={userData.profile_image}
                    alt={translation("story_person")}
                    className={commonStyle.mr10}
                  />
                ) : null}
                <Box>
                <Box
                  className={`${commonStyle.flexCenter} ${styleClasses.justifyContentEnd}`}
                  ref={(e: React.Ref<unknown>) =>
                    setRef(e, messageInfo.timetoken)
                  }
                >
                  {data?.id !== userData.id && messageInfo?.reaction ? (
                    <span className={commonStyle.mr5}>
                      {getEmoji(messageInfo?.reaction)}
                    </span>
                  ) : null}
                  <Box className={`${QuotedBgColor} ${styleClasses.chatSide}`}>
                    {repliedMessage ? (
                      <Box
                        className={`${styleClasses.replyMessage} ${
                          data?.id !== userData.id
                            ? commonStyle.bgSkyBlue
                            : commonStyle.bgRed
                        }`}
                        onClick={() =>
                          focusQuoteMessage(data?.repliedMessage?.timetoken)
                        }
                      >
                        <Typography
                          dangerouslySetInnerHTML={{
                            __html: `${repliedMessage.replace(
                              NewLineRegex,
                              "<br />"
                            )}`,
                          }}
                        ></Typography>
                        {/* {messageInfo?.reply} */}
                      </Box>
                    ) : null}

 {data?.type==="text" &&(
  <>
    {data?.text === null ? (
                          <Typography
                            dangerouslySetInnerHTML={{
                              __html: `${data?.caption.replace(
                                NewLineRegex,
                                "<br />"
                              )}`,
                            }}
                          ></Typography>
                        ) : null}
  </>

 )}                   

{data?.type === "post"  && (
                      <>
                      
                        <Box
                          onClick={() =>
                            setOpenImageModal({
                              show: true,
                              images: JSON.parse(data?.text),
                            })
                          }
                          className={styleClasses.repeatBoxCount}
                        >
                          {JSON.parse(data?.text)
                            .slice(0, 4)
                            .map((image: string, i: number) => {
                              const moreThenFour =
                                JSON.parse(data?.text).length > 4 && i > 2;
                              return (
                                <Box
                                  className={`${commonStyle.m5} ${
                                    moreThenFour
                                      ? styleClasses.imageFileCount
                                      : ""
                                  }`}
                                >
                                  <img
                                    src={image}
                                    alt={`images-${i}`}
                                    key={i}
                                  />
                                  {moreThenFour ? (
                                    <p
                                      className={styleClasses.countNumberImage}
                                    >
                                      + {JSON.parse(data.text).length - 3}
                                    </p>
                                  ) : null}
                                </Box>
                              );
                            })}
                        </Box>
                      </>
                    )
                          }
                    {data?.type === Type.image ? (
                      <>
                        <Box
                          onClick={() =>
                            setOpenImageModal({
                              show: true,
                              images: JSON.parse(data?.text),
                            })
                          }
                          className={styleClasses.repeatBoxCount}
                        >
                          {JSON.parse(data?.text)
                            .slice(0, 4)
                            .map((image: string, i: number) => {
                              const moreThenFour =
                                JSON.parse(data?.text).length > 4 && i > 2;
                              return (
                                <Box
                                  className={`${commonStyle.m5} ${
                                    moreThenFour
                                      ? styleClasses.imageFileCount
                                      : ""
                                  }`}
                                >
                                  <img
                                    src={image}
                                    alt={`images-${i}`}
                                    key={i}
                                  />
                                  {moreThenFour ? (
                                    <p
                                      className={styleClasses.countNumberImage}
                                    >
                                      + {JSON.parse(data.text).length - 3}
                                    </p>
                                  ) : null}
                                </Box>
                              );
                            })}
                        </Box>
                        {data?.caption !== "" ? (
                          <Typography
                            dangerouslySetInnerHTML={{
                              __html: `${data?.caption.replace(
                                NewLineRegex,
                                "<br />"
                              )}`,
                            }}
                          ></Typography>
                        ) : null}
                      </>
                    ) : data?.type === Type.video ? (
                      <>
                        {JSON.parse(data?.text).map(
                          (video: string, i: number) => (
                            <video controls key={i}>
                              <source src={video} />
                            </video>
                          )
                        )}
                        {data?.caption !== "" ? (
                          <Typography
                            className={styleClasses.messageSpecial}
                            dangerouslySetInnerHTML={{
                              __html: `${data?.caption.replace(
                                NewLineRegex,
                                "<br />"
                              )}`,
                            }}
                          ></Typography>
                        ) : null}
                      </>
                    ) : data?.type === Type.audio ? (
                      <>
                        {JSON.parse(data?.text).map(
                          (audio: string, i: number) => (
                            <audio controls key={i}>
                              <source src={audio} />
                            </audio>
                          )
                        )}
                        {data?.caption !== "" ? (
                          <Typography
                            className={styleClasses.messageSpecial}
                            dangerouslySetInnerHTML={{
                              __html: `${data?.caption.replace(
                                NewLineRegex,
                                "<br />"
                              )}`,
                            }}
                          ></Typography>
                        ) : null}
                      </>
                    ) : data?.type === Type.otherdoc ? (
                      <>
                        {JSON.parse(data?.text).map(
                          (other_document: string, i: number) => (
                            <div
                              className={styleClasses.documentChatShow}
                              key={i}
                            >
                              <div className={styleClasses.documentName}>
                                <Article />
                                <Typography>
                                  {translation("document")}
                                </Typography>
                              </div>
                              <a href={other_document}>
                                {translation("download")}
                              </a>
                            </div>
                          )
                        )}
                        {data?.caption !== "" ? (
                          <Typography
                            dangerouslySetInnerHTML={{
                              __html: `${data?.caption.replace(
                                NewLineRegex,
                                "<br />"
                              )}`,
                            }}
                          ></Typography>
                        ) : null}
                      </>
                    ) : data?.type === Type.pdf ? (
                      <>
                        {JSON.parse(data?.text).map(
                          (pdf: string, i: number) => (
                            <div
                              className={styleClasses.documentChatShow}
                              key={i}
                            >
                              <div className={styleClasses.documentName}>
                                <PictureAsPdf />
                                <Typography>{translation("pdf")}</Typography>
                              </div>
                              <a href={pdf}>{translation("download")}</a>
                            </div>
                          )
                        )}
                        {data?.caption !== "" ? (
                          <Typography
                            dangerouslySetInnerHTML={{
                              __html: `${data?.caption.replace(
                                NewLineRegex,
                                "<br />"
                              )}`,
                            }}
                          ></Typography>
                        ) : null}
                      </>
                    ) : 
                    data?.type==="text"?(
                      <>
                        
                                              <Typography
                                                dangerouslySetInnerHTML={{
                                                  __html: `${data?.text.replace(
                                                    NewLineRegex,
                                                    "<br />"
                                                  )}`,
                                                }}
                                                
                                              ></Typography>
                                            
                      </>
                    
                     ):
                    (
                      <Typography
                        className={`${styleClasses.messageSpecial} ${
                          data?.id === userData.id ? "" : commonStyle.bgRed
                        }`}
                        dangerouslySetInnerHTML={{
                          __html: `${data?.text.replace(
                            NewLineRegex,
                            "<br />"
                          )}`,
                        }}
                      >
                        {/* {data?.text} */}
                      </Typography>
                    )}
                  </Box>
                  <Typography
                    className={`${commonStyle.flexCenter} ${styleClasses.chatReplyEmoji} ${commonStyle.ml10}`}
                  >
                    {data?.id === userData.id ? (
                      messageInfo?.reaction ? (
                        <span
                          onClick={(event) => {
                            setEmojiMsg({
                              message: messageInfo,
                              e: event.currentTarget,
                            });
                          }}
                        >
                          {getEmoji(messageInfo?.reaction)}
                        </span>
                      ) : (
                        <SentimentSatisfiedOutlined
                          onClick={(event: MouseEvent<SVGSVGElement>) => {
                            setEmojiMsg({
                              message: messageInfo,
                              e: event.currentTarget,
                            });
                          }}
                        />
                      )
                    ) : null}

                    {data.type === Type.text && data?.id === userData.id ? (
                      <span onClick={() => handleReplyOnMessage(messageInfo)}>
                        {translation("reply")}{" "}
                      </span>
                    ) : null}

                  </Typography>
                </Box>
             
                  <Typography className={styleClasses.timeTokenStyle}>{getDateTime(messageInfo?.timetoken)}</Typography>
            
                </Box>
                {console.log("datttta",data)}
              </Box>
              </>
            );
          })
          : null}

      {/** Emoji Reaction Popup Start */}
      {emojiMsg?.message ? (
        <EmojiPopover
          open={Boolean(emojiMsg)}
          anchorEl={emojiMsg.e}
          onClose={() =>
            setEmojiMsg({
              e: undefined,
              message: null,
            })
          }
          onClick={(replyMessage: string) => {
            sendEmojiMessage(
              emojiMsg?.message.timetoken,
              "reaction",
              replyMessage
            );
            setEmojiMsg({
              e: undefined,
              message: null,
            });
          }}
        />
      ) : null}
      {/** Emoji Reaction Popup End */}
    </Box>
  );
};

export default MessageBox;
