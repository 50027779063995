import  {
    useState,
    
  } from "react";
import { Grid, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";

import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";


import { CommonButton } from "../../stories/Button";
import { UserAuthStyles } from "./UserAuth";
import { UserCommonStyles } from "../../styles/CommonStyles";
import { verifyOtp, updateOtp } from "../../redux/action-creators/verifyEmail";
import { forgotPassword } from "../../redux/action-creators";

import Logo from "../../images/logo-petlover.png";
import Banner from "../../images/login-banner.png";

import { toast } from "../../utils/toastsMessage";
import { IverifyEmail } from "../../interfaceModules/IPetsInteface";
import {verifyEmailAddressValidationSchema} from "../../utils/validationSchema";
import { RoutingLinks } from "../../utils/constants";
import { IEmail } from "../../interfaceModules/IUserInterface";

const ForgotPassword = (props: any) => {
    const { t: translation } = useTranslation();
    const commonStyle = UserCommonStyles();
    const styleClasses = UserAuthStyles();
    const dispatch = useDispatch();
    const [disable,setdisable]=useState(false)

 
    /**
     * Using useForm hook to handle change events, validations and form submission
     */
    const {
        handleSubmit,
        register,

        formState: { errors },
    } = useForm<IEmail>({
        resolver: yupResolver(verifyEmailAddressValidationSchema(translation)),
    });
    console.log("dataaaaaaa",errors)

    /**
     * Submit the form and show the pop-up as toast
     * @param data <IverifyEmail>
     */
    const onSubmit = async (data:IEmail) => {
        setdisable(true)
        console.log("dataaaaaaaaa",data)
        const response: any = await dispatch(forgotPassword(data));
        if (response?.data?.success === false) {
            setdisable(false)

            toast.error(translation(response.data.message));
        } else {
            props.history.push({
                pathname: "/verify-email",
                state: {
                    email:data.email,
                  from: "/forgot-password",
                  nextScreen: "/reset-password",
                },
              })
              setdisable(false)

            toast.success(translation(response.data.message));
        }
    };

    return (
        <>
            <Box>
            <Grid container>


            <Grid item md={5}>
          <Box
            className={`${commonStyle.flexColumn} ${styleClasses.loginSide}`}
          >
            <img
              src={Logo}
              alt={translation("log_banner")}
              className={styleClasses.logoSize}
            />
            <img
              src={Banner}
              alt={translation("banner")}
              className={commonStyle.w100}
            />
          </Box>
        </Grid>

                <Grid item md={7}>
                    <Box>
                        <form
                            className={styleClasses.authForm}
                            onSubmit={handleSubmit(onSubmit)}
                            noValidate
                        >
                            <Box className={styleClasses.formGroup}>
                                <Typography variant="h5">Forgot Password</Typography>
                                <Box className={commonStyle.commonInput}>
                                    <TextField
                                        {...register("email")}
                                        variant="outlined"
                                        label={translation("email")}
                                        className={commonStyle.w100}

                                    />
                                    <span className={commonStyle.errorMsg}>
                                        {errors.email?.message}
                                    </span>
                                </Box>
                               
                              
                                <Box className={`${commonStyle.w100} ${commonStyle.orangeBtn}`}>
                                    <CommonButton
                                        label={translation("continue")}
                                        onClick={handleSubmit(onSubmit)}
                                        disabled={disable}
                                    />
                                </Box>

                                <Box className={styleClasses.orText}>
                                    <Typography>{translation("or_continue_with")}</Typography>
                                </Box>

                                <ul
                                    className={`${styleClasses.socialLogin} ${commonStyle.removeListStyle}`}
                                >





                                </ul>

                                <Box
                                    className={`${styleClasses.authDescFooter} ${commonStyle.textCenter}`}
                                >
                                    <Typography>
                                        {translation("not_have_an_account")}{" "}
                                        <Link to={RoutingLinks.signup}>
                                            {translation("signup")}
                                        </Link>
                                    </Typography>
                                </Box>
                            </Box>
                        </form>
                    </Box>
                </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default ForgotPassword;
