import axios from "../../services/axiosService";
import { Dispatch } from "redux";
import { ActionType } from "../action-types/index";
import { ICreatePostState } from "../../interfaceModules/IPetsInteface";

const URL = process.env.REACT_APP_API_URL;

/**
 * For List all the Posts
 * @param type
 * @param userId
 * @returns
 */
export const getAllPostLists = (type: string, userId: number,offset:number) => async (
  dispatch: Dispatch
) => {
  try {   
    const response = await axios.get(
      `${URL}/api/v1/post/${type}?userId=${userId}&offset=${offset}`
    );
    if (response) {
      dispatch({
        type: ActionType.COMMENTSLIST,
        payload: response,
      });
    }
    return response.data.data;
  } catch (err) {
    dispatch({
      type: ActionType.FAILURE,
    });
  }
};

/**
 * For List all the comments
 * @param type
 * @returns
 */
export const getAllCommentsLists = (type: string) => async (
  dispatch: Dispatch
) => {
  try {
    const response = await axios.get(`${URL}/api/v1/post/comments/${type}`);
    if (response) {
      dispatch({
        type: ActionType.COMMENTSLIST,
        payload: response,
      });
    }
    return response.data.data.data;
  } catch (err) {
    dispatch({
      type: ActionType.FAILURE,
    });
  }
};

/**
 * List for likes of all the posts
 * @param post_id
 * @returns
 */
export const likeReactionListsOnPosts = (post_id: number) => async (
  dispatch: Dispatch
) => {
  try {
    const response = await axios.get(`${URL}/api/v1/post/allLike/${post_id}`);
    return response.data.data.data;
  } catch (err) {
    dispatch({
      type: ActionType.FAILURE,
    });
  }
};

/**
 * Get Details of all child comments of post by commentId
 * @param type
 * @param commentId
 * @returns
 */
export const getAllChildCommentLists = (
  type: "tricks" | "chatRoom"  | "tails" | "post",
  commentId: number
) => async (dispatch: Dispatch) => {
  try {
    const response = await axios.get(
      `${URL}/api/v1/post/childComments/${type}/${commentId}`
    );
    return response.data.data.data;
  } catch (err) {
    dispatch({
      type: ActionType.FAILURE,
    });
  }
};

/**
 * Get Details of all Tagged Users of all the posts
 * @param type
 * @param userIds
 * @returns
 */
export const getTaggedUserDetails = (
  type: "tricks" | "chatRoom" | "tails" | "post",
  userIds: []
) => async (dispatch: Dispatch) => {
  try {
    const response = await axios.get(
      `${URL}/api/v1/post/taggedUsers/${type}/${userIds}`
    );
    return response.data.data.data;
  } catch (err) {
    dispatch({
      type: ActionType.FAILURE,
    });
  }
};

/**
 * For Create the New Post
 * @param data
 * @param type
 * @returns
 */
export const CreatePost = (data: ICreatePostState, type: string) => async (
  dispatch: Dispatch
) => {
  try {
    const response = await axios.post(
      `${URL}/api/v1/post/create/${type}`,
      data
    );
    if (response) {
      dispatch({
        type: ActionType.COMMENTSLIST,
        payload: response,
      });
    }
    return response;
  } catch (err) {
    dispatch({
      type: ActionType.FAILURE,
    });
  }
};

/**
 * All Posts of a user
 * @param profileId
 * @param userId
 * @returns
 */
export const postListsbyUserId = (profileId: number, userId: number) => async (
  dispatch: Dispatch
) => {
  try {
    const response = await axios.get(
      `${URL}/api/v1/post/all/${userId}/${profileId}`
    );
    return response.data.data.data;
  } catch (err) {
    dispatch({
      type: ActionType.FAILURE,
    });
  }
};

/**
 * All Posts of a user
 * @param postId
 * @returns
 */
export const deletePostById = (postId: number) => async (
  dispatch: Dispatch
) => {
  try {
    const response = await axios.post(
      `${URL}/api/v1/post/delete-post/${postId}`
    );
    return response.data;
  } catch (err) {
    dispatch({
      type: ActionType.FAILURE,
    });
  }
};

/**
 * Update post
 * @param postId
 * @param updatedPost
 * @returns
 */
export const updatePostById = (postId: number, updatedPost: any) => async (
  dispatch: Dispatch
) => {
  try {
    const response = await axios.post(
      `${URL}/api/v1/post/update/${postId}`,
      updatedPost
    );
    return response.data;
  } catch (err) {
    dispatch({
      type: ActionType.FAILURE,
    });
  }
};

/**
 * Comment on post
 * @param postType
 * @param userId
 * @param commentData
 * @returns
 */
export const addCommentOnThePost = (
  postType: string,
  userId: number,
  commentData: any
) => async (dispatch: Dispatch) => {
  try {
    const response = await axios.post(
      `${URL}/api/v1/post/comment/${postType}/${userId}`,
      commentData
    );
    return response.data;
  } catch (err) {
    dispatch({
      type: ActionType.FAILURE,
    });
  }
};

/**
 * Like on post/comment
 * @param postType
 * @param userId
 * @param reactionData
 * @param crudOperation
 * @returns
 */
export const addLikeOnThePost = (
  postType: string,
  userId: number,
  reactionData: any,
  crudOperation: string
) => async (dispatch: Dispatch) => {
  try {
    const response = await axios.post(
      `${URL}/api/v1/post/like/${postType}/${userId}?crudOperation=${crudOperation}`,
      reactionData
    );
    return response.data;
  } catch (err) {
    dispatch({
      type: ActionType.FAILURE,
    });
  }
};

  /**
 * List of all post by profileId
 * @param postId
 * @param userId
 * @returns
 */
   export const getPostData =
   (postId: number, userId: number) => async (dispatch: Dispatch) => {
     try {
       const response = await axios.get(
         `${URL}/api/v1/post/postDetails/${postId}/${userId}`
       );
       return response.data;
     } catch (err) {
       dispatch({
         type: ActionType.FAILURE,
       });
     }
   };
   
