import axios from "../../services/axiosService";
import { Dispatch } from "redux";
import { ActionType } from "../action-types/index";
import { Action } from "../actions/AuthAction";
import {
  ISignUpState,
  ILoginState,
  IEmail,
} from "../../interfaceModules/IUserInterface";
const URL = process.env.REACT_APP_API_URL;

/**
 * For SignUp the User
 * @param data
 * @returns
 */
export const SignUpAction = (data: ISignUpState) => async (
  dispatch: Dispatch<Action>
) => {
  try {
    const response = await axios
      .post(`${URL}/api/v1/users/signup`, data)
      .then((res) => {
        dispatch({
          type: ActionType.SIGNUP,
          payload: data,
        });
        return res;
      });
    return response;
  } catch (err) {
    dispatch({
      type: ActionType.FAILURE,
    });
  }
};

/**
 * For LogIn the User
 * @param data
 * @returns
 */
export const SignInAction = (data: ILoginState) => async (
  dispatch: Dispatch<Action>
) => {
  try {
    const response = await axios
      .post(`${URL}/api/v1/users/login`, data)
      .then((res) => {
        if (!res.data.hasOwnProperty("error")) {
          dispatch({
            type: ActionType.LOGIN,
            payload: res.data.user,
          });
          return res.data.user;
        }
        return res.data;
      });
    return response;
  } catch (err) {
    dispatch({
      type: ActionType.FAILURE,
    });
  }
};

/**
 * social logins
 * @param data
 * @returns
 */
export const socialLogin = (data: any) => async (
  dispatch: Dispatch<Action>
) => {
  try {
    const response=await axios
      .post(`${URL}/api/v1/users/sociallogin`, data)
      .then((res: any) => {
        if(res?.data?.error)
          {
            return res?.data
          }
          else
          {
            dispatch({
              type: ActionType.SOCIALLOGIN,
              payload: res,
            });
          }
          
        // dispatch({
        //   type: ActionType.SOCIALLOGIN,
        //   payload: res,
        // });
      });
      return response
  } catch (err) {
    dispatch({
      type: ActionType.FAILURE,
    });
  }
};

/**
 * social signup
 * @param data
 * @returns
 */
export const socialSignup = (data: ISignUpState) => async (
  dispatch: Dispatch<Action>
) => {
  try {
    const response = await axios
      .post(`${URL}/api/v1/users/socialSignUp`, data)
      .then((res: any) => {
        dispatch({
          type: ActionType.SOCIALLOGIN,
          payload: res,
        });
      });
    return response;
  } catch (err) {
    dispatch({
      type: ActionType.FAILURE,
    });
  }
};

/**
 * social login
 * @param data
 * @returns
 */
export const fsocialLogin = (data: ILoginState) => async (
  dispatch: Dispatch<Action>
) => {
  try {
    const response = await axios
      .post(`${URL}/api/v1/users/sociallogin`, data)
      .then((res: any) => {
        dispatch({
          type: ActionType.SOCIALLOGIN,
          payload: res,
        });

        return res.data;
      });
  } catch (err) {
    dispatch({
      type: ActionType.FAILURE,
    });
  }
};

/**
 * logout user
 * @returns
 */
export const logoutUser = () => async (dispatch: Dispatch) => {
  try {
    await dispatch({ type: ActionType.LOGOUTUSER });
    // if (window.gapi.auth2) {
    //   let auth2 = window.gapi.auth2.getAuthInstance();
    //   auth2.signOut().then(function () {});
    // }
  } catch (err) {
    dispatch({
      type: ActionType.FAILURE,
    });
  }
};

/**
 * logout user
 * @returns
 */
export const forgotPassword = (data: IEmail) => async (dispatch: Dispatch) => {
  console.log("dataaaaaaaaaaaaaaaaaa", data);
  try {
    const response = await axios
      .post(`${URL}/api/v1/users/forgot-password`, data)
      .then((res) => {
        dispatch({
          type: ActionType.SIGNUP,
          payload: data,
        });
        return res;
      });
    return response;
  } catch (err) {
    dispatch({
      type: ActionType.FAILURE,
    });
  }
};

/**
 * logout user
 * @returns
 */
export const resetPassword = (data: IEmail) => async (dispatch: Dispatch) => {
  console.log("dataaaaaaaaaaaaaaaaaa", data);
  try {
    const response = await axios
      .post(`${URL}/api/v1/users/reset-password`, data)
      .then((res) => {
        dispatch({
          type: ActionType.SIGNUP,
          payload: data,
        });
        return res;
      });
    return response;
  } catch (err) {
    dispatch({
      type: ActionType.FAILURE,
    });
  }
};


export const userDeleteAccount =
(id:any) => async (dispatch: Dispatch) => {
  try {
    const response = await axios
      .put(`${URL}/api/v1/users/account-delete/${id}`)
      .then((res) => {
  
        return res;
      });
    return response;
  } catch (err) {
    dispatch({
      type: ActionType.FAILURE,
    });
  }
};
