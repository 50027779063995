import { Link } from "react-router-dom";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState, useCallback } from "react";

import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Modal,
} from "@mui/material";
import { Box } from "@mui/system";
import { Button } from "@mui/material";

import {
  getAdListofUser,
  updateAdAction,
} from "../../redux/action-creators/adAction";

import LeftSidebar from "../../components/sidebars/LeftSidebar";

import { UserCommonStyles } from "../../styles/CommonStyles";
import { ProfileStyle } from "../profile/ProfileStyle";
import { RoutingLinks } from "../../utils/constants";
import ApiResponse from "../../interfaceModules/IApiResponse";
import {
  ChatBubbleOutline,
  HighlightOff,
  HighlightOffOutlined,
} from "@mui/icons-material";
import { HomeStyles } from "../../styles/HomeStyles";
import { isJsonString } from "../../utils/helper";
import moment from "moment";

const AdList: React.FC = (props: any) => {
  const commonStyle = UserCommonStyles();
  const { t: translation } = useTranslation();
  const [adAction, setAdAction] = useState({
    adId: 0,
    action: 0,
    currentStatus: 0,
  });
  const [adViewCommentId, setAdViewCommentId] = useState(0);
  const [adList, setAdList] = useState<any>([]);
  const styleClasses = ProfileStyle();
  const homeClasses = HomeStyles();
  const dispatch = useDispatch();
  const [modalopen, setOpen] = useState(false);
  const [loader, setLoader] = useState(true);

  const handleOpen = (id: number) => {
    setAdViewCommentId(id);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  const data = props?.location?.state;

  const userInfo = useSelector((state: RootStateOrAny) => {
    let userData = state.authReducer.authData;
    if (isJsonString(state.authReducer.authData)) {
      userData = JSON.parse(state.authReducer.authData);
    }
    return userData;
  });

  interface Column {
    autoNumber: number;
    id:
      | "companyname"
      | "url"
      | "email"
      | "startdate"
      | "phonenumber"
      | "address"
      | "status"
      | "package"
      | "adminComment"
      | "edit"
      | "play"
      | "view"
      | "click";
    label: string;
    minWidth?: number;
    align?: "right";
    format?: (value: number) => string;
  }
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "#fff",
    border: "2px solid #000",
    p: 4,
  };

  const columns: readonly Column[] = [
    { autoNumber: 1, id: "companyname", label: "Company Name", minWidth: 170 },
    { autoNumber: 2, id: "url", label: "URL", minWidth: 100 },
    {
      autoNumber: 3,
      id: "email",
      label: "Email",
      minWidth: 170,
      align: "right",
      format: (value: number) => value.toLocaleString("en-US"),
    },
    {
      autoNumber: 4,
      id: "startdate",
      label: "Start Date",
      minWidth: 170,
      align: "right",
      format: (value: number) => value.toLocaleString("en-US"),
    },
    {
      autoNumber: 5,
      id: "phonenumber",
      label: "Contact Info",
      minWidth: 170,
      align: "right",
      format: (value: number) => value.toLocaleString("en-US"),
    },
    {
      autoNumber: 6,
      id: "address",
      label: "Address",
      minWidth: 170,
      align: "right",
      format: (value: number) => value.toFixed(2),
    },
    {
      autoNumber: 7,
      id: "status",
      label: "Status",
      minWidth: 170,
      align: "right",
      format: (value: number) => value.toFixed(2),
    },
    {
      autoNumber: 8,
      id: "package",
      label: "Package",
      minWidth: 170,
      align: "right",
      format: (value: number) => value.toFixed(2),
    },
    {
      autoNumber: 9,
      id: "view",
      label: "TotalView",
      minWidth: 170,
      align: "right",
      format: (value: number) => value.toFixed(2),
    },
    {
      autoNumber: 10,
      id: "click",
      label: "TotalClick",
      minWidth: 170,
      align: "right",
      format: (value: number) => value.toFixed(2),
    },
    {
      autoNumber:11,
      id: "adminComment",
      label: "Admin Comment",
      minWidth: 170,
      align: "right",
      format: (value: number) => value.toFixed(2),
    },
    
    {
      autoNumber: 12,
      id: "edit",
      label: "Edit",
      minWidth: 170,
      align: "right",
      format: (value: number) => value.toFixed(2),
    },
   
    {
      autoNumber: 13,
      id: "play",
      label: "Action",
      minWidth: 170,
      align: "right",
      format: (value: number) => value.toFixed(2),
    },
   
  ];

  useEffect(() => {
    if (adList.length === 0) {
      getAdList();
    }
  }, [adList]);

  /**
   * fetch all ad packages list
   * @param id
   */
  const getAdList = useCallback(async () => {
    await getAdListofUser(userInfo.id).then((response: ApiResponse) => {
      const { data, error } = response;
      if (data?.data[0]?.length > 0 && data?.data[0]?.admin_comments != null) {
        for (let i = 0; i < data.data[0].length; i++) {
          let result = data.data[0][i]?.admin_comments;
          if (result !== "") {
            result = JSON.parse(result);
            data.data[0][i].admin_comments = result;
          }

          //
        }
      }
      setAdList(data?.data[0]);
    });
    setLoader(false);
  }, [dispatch]);

  const addPackage = (adId: number, company_name: string) => {
    props.history.push({
      pathname: "/adpackages",
      state: { id: adId, company_name: company_name },
    });
  };

  const [openAlert, setAlertOpen] = React.useState(false);

  const handleAlertOpen = (id: number, adStatus: number) => {
    setAdAction({
      adId: id,
      action: adStatus === 1 ? 2 : 1,
      currentStatus: adStatus,
    });
    setAlertOpen(true);
  };
  const handleAlertClose = () => setAlertOpen(false);

  const updateAdActionStatus = async () => {
    await updateAdAction(adAction.action, adAction.adId, userInfo.id).then(
      (response: ApiResponse) => {
        const { data } = response;
        if (data.isRegistered) {
          setAdList(data?.data.data);
        }
        handleAlertClose();
      }
    );
  };

  return (
    <Box className={commonStyle.windowColor}>
      <Grid container>
        <Grid item md={3}>
          <LeftSidebar />
        </Grid>

        <Grid item md={9}>
          <Box className={`${commonStyle.p20} ${commonStyle.mt20}`}>
            <Typography
              variant="h6"
              className={`${commonStyle.grey} ${commonStyle.mb15} ${commonStyle.fontWeight600}`}
            >
              {translation("adlist")}
            </Typography>

            <Box
              className={`${commonStyle.flexCenter} ${commonStyle.justifyEnd}`}
            >
              <Box
                className={`${commonStyle.mb10} ${commonStyle.textRight} ${commonStyle.mr5}`}
              >
                <Box className={commonStyle.orangeBtn}>
                  <Button onClick={() => props.history.push("/adcenter")}>
                    {translation("create_ad")}
                  </Button>
                </Box>
              </Box>

              <Box
                className={`${commonStyle.mb10} ${commonStyle.textRight} ${commonStyle.mr5}`}
              >
                <Box className={commonStyle.orangeBtn}>
                  <Button onClick={() => props.history.push("/cards")}>
                    {translation("add_bal")}
                  </Button>
                </Box>
              </Box>

              <Box className={`${commonStyle.mb10} ${commonStyle.textRight}`}>
                <Box className={commonStyle.orangeBtn}>
                  <Button onClick={() => props.history.push("/view-balance")}>
                    {translation("view_balance")}
                  </Button>
                </Box>
              </Box>
            </Box>
            <TableContainer sx={{ maxHeight: 440 }}>
              <Table stickyHeader aria-label={translation("sticky_table")}>
                <TableHead>
                  <TableRow className={commonStyle.commonTableHead}>
                    {columns.map((column) => (
                      <TableCell
                        key={column.autoNumber}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {adList && adList.length > 0
                    ? adList.map((ad, index) => {
                        return (
                          <TableRow hover tabIndex={-1} key={index}>
                            <TableCell>{ad.company_name}</TableCell>
                            <TableCell>{ad.company_website_url}</TableCell>
                            <TableCell>{ad.email}</TableCell>

                            <TableCell>
                              {ad.start_date
                                ? moment(ad.start_date.split("T")[0]).format(
                                    "MM-DD-YYYY"
                                  )
                                : null}
                            </TableCell>
                            <TableCell>{ad.phone_number}</TableCell>
                            <TableCell>{ad.address}</TableCell>
                            <TableCell>
                              {ad.is_active
                                ? "approved"
                                : "Pending for approval"}
                            </TableCell>
                            <TableCell>{ad.package}</TableCell>
                            <TableCell>{ad.view_count}</TableCell>
                            <TableCell>{ad.click_count}</TableCell>

                            {ad?.admin_comments?.length > 0 ? (
                              <TableCell>
                                <Box className={commonStyle.orangeBtn}>
                                  <Button onClick={() => handleOpen(ad.id)}>
                                    {translation("View Comments")}
                                  </Button>
                                </Box>
                              </TableCell>
                            ) : (
                              <TableCell>No Comment Found</TableCell>
                            )}

                            {ad?.admin_comments?.length > 0 &&
                              ad.id === adViewCommentId && (
                                <Modal
                                  open={modalopen}
                                  onClose={handleClose}
                                  aria-labelledby="child-modal-title"
                                  aria-describedby="child-modal-description"
                                >
                                  <Box className={commonStyle.commonModalStyle}>
                                    <Box
                                      className={`${homeClasses.modalHeader} ${commonStyle.px10}`}
                                    >
                                      <Typography
                                        id="modal-modal-title"
                                        variant="h6"
                                      >
                                        {translation("Admin Comments")}
                                      </Typography>
                                      <HighlightOff onClick={handleClose} />
                                    </Box>

                                    <Box className={`${commonStyle.p20}`}>
                                      <Table size="small">
                                        <TableHead>
                                          <TableRow>
                                            <TableCell>Date</TableCell>
                                            <TableCell>Comments</TableCell>
                                          </TableRow>
                                        </TableHead>

                                        <TableBody>
                                          {ad.id === adViewCommentId &&
                                            ad?.admin_comments?.length > 0 &&
                                            JSON.parse(ad?.admin_comments).map(
                                              (item: any, index: number) => {
                                                return (
                                                  <TableRow>
                                                    <TableCell key={index}>
                                                      {item?.date}
                                                    </TableCell>
                                                    <TableCell>
                                                      {item?.comment}
                                                    </TableCell>
                                                  </TableRow>
                                                );
                                              }
                                            )}
                                        </TableBody>
                                      </Table>
                                    </Box>
                                  </Box>
                                </Modal>
                              )}

                            <TableCell>
                              <Box className={commonStyle.orangeBtn}>
                                {ad.is_active ? (
                                  <Button disabled={ad.is_active}>
                                    {translation("edit")}
                                  </Button>
                                ) : (
                                  <Link
                                    to={{
                                      pathname: `${RoutingLinks.editAd}${ad.id}`,
                                    }}
                                    className={`${commonStyle.white} ${commonStyle.anchorDecoration}`}
                                  >
                                    <Button>{translation("edit")}</Button>
                                  </Link>
                                )}
                              </Box>
                            </TableCell>
                            <TableCell>
                              <Box className={commonStyle.orangeBtn}>
                                <Button
                                  onClick={() =>
                                    handleAlertOpen(ad.id, ad.status)
                                  }
                                  disabled={ad.status === 0 || ad.status === 3}
                                >
                                  {ad.status === 1 ? "Pause" : "Resume"}
                                </Button>
                              </Box>
                            </TableCell>
                          </TableRow>
                        );
                      })
                    : loader
                    ? translation("loading")
                    : translation("no_ad_avail")}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AdList;
