import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useEffect, useState, useCallback } from "react";

import { Box } from "@mui/system";
import { Avatar, Badge, Grid, Typography, Button } from "@mui/material";

import { getListOfFavourite } from "../../redux/action-creators/petPlayAction";
import { sendNotification } from "../../redux/action-creators/petPlayAction";
import { changeRequestType } from "../../redux/action-creators/requestAction";

import RightSidebar from "../../components/sidebars/RightSidebar";
import LeftSidebar from "../../components/sidebars/LeftSidebar";

import { toast } from "../../utils/toastsMessage";
import { RoutingLinks, Type } from "../../utils/constants";
import { capitalize } from "../../utils/helper";

import { IFavoriteList } from "../../interfaceModules/IUserInterface";

import { CommonButton } from "../../stories/Button";
import defaultUserprofile from "../../images/defaultUserprofile.png";

import { ProfileStyle } from "./ProfileStyle";
import { UserCommonStyles } from "../../styles/CommonStyles";

const Favourites = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loader, setloader] = useState(true);
  const { t: translation } = useTranslation();
  const commonStyle = UserCommonStyles();
  const styleClasses = ProfileStyle();
  const userId = Number(localStorage.getItem("userId"));
  const [favouriteList, setfavouriteList] = useState<IFavoriteList[]>([]);
  const [disable, setdisable] = useState(false);

  /**
   * To get the list of favourite
   */
  const getList = useCallback(async () => {
    let result: any = await dispatch(getListOfFavourite(userId));
    if (result && result.length > 0) {
      setfavouriteList(result);
      setdisable(false);
    } else {
      setloader(false);
    }
  }, [dispatch, userId]);

  useEffect(() => {
    getList();
  }, [getList]);

  /**
   * To send,accept friend request
   * @param id
   */
  const changeRequest = async (id: number) => {
    setdisable(true);
    const result: any = await dispatch(changeRequestType(id, userId));
    if ((result && result?.isRegistered) || result.affectedRows) {
      const data = { type: Type.friendrequest, id: userId };
      if (data.id !== id) {
        await dispatch(sendNotification(id, data));
        toast.success(translation("friend_request_sent"));
      }
      getList();
    } else {
      setdisable(false);
      toast.success(translation("error"));
    }
  };

  return (
    <Box>
      <Box className={commonStyle.windowColor}>
        <Grid container>
          <Grid item md={3}>
            <LeftSidebar />
          </Grid>

          <Grid item md={6}>
            <Box className={commonStyle.p20}>
              <Typography
                variant="h6"
                className={`${commonStyle.grey} ${commonStyle.mb15} ${commonStyle.fontWeight600}`}
              >
                {translation("your_favourites")}
              </Typography>
              <Box className={`${commonStyle.p20} ${commonStyle.card}`}>
                <ul className={styleClasses.badgeCircular}>
                  {favouriteList.length > 0
                    ? favouriteList.map((item, index) => (
                        <li
                          key={index}
                          className={`${commonStyle.flexCenter} ${commonStyle.justifySpaceBetween}`}
                        >
                          <Box className={commonStyle.wallUserInfo}>
                            <Badge
                              overlap="circular"
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              badgeContent={
                                <img
                                  alt={translation("image")}
                                  src={
                                    item.profile_image
                                      ? item.profile_image
                                      : defaultUserprofile
                                  }
                                  className={styleClasses.badgeImg}
                                />
                              }
                            >
                              <Avatar
                                alt={translation("image")}
                                src={
                                  item.profile_image
                                    ? item.profile_image
                                    : defaultUserprofile
                                }
                              />
                            </Badge>
                            <Box className={commonStyle.ml20}>
                              <Link
                                className={commonStyle.commonLink}
                                to={{
                                  pathname: `${RoutingLinks.profiles}${item?.id}`,
                                }}
                              >
                                <Typography variant="h2">
                                  {capitalize(item.first_name) +
                                    " " +
                                    capitalize(item.last_name)}
                                </Typography>
                              </Link>
                              <Typography>{item.type}</Typography>
                            </Box>
                          </Box>

                          <Box
                            className={`${commonStyle.blueBtn} ${commonStyle.ml10} ${commonStyle.mr10}`}
                          >
                            {item.request_type === "accepted" ? (
                              //

                              <CommonButton
                                label={translation("message")}
                                onClick={() =>
                                  history.push({
                                    pathname: RoutingLinks.chat,
                                    state: {
                                      id: item.id,
                                      first_name: item.first_name,
                                      last_name: item.last_name,
                                      profile_image: item.profile_image,
                                      channel: "",
                                    },
                                  })
                                }
                              />
                            ) : item.request_type === "pending" ? (
                              <CommonButton label={translation("pending")} />
                            ) : (
                              <Button
                                disabled={disable}
                                onClick={() => changeRequest(item.id)}
                              >
                                {translation("send_request")}
                              </Button>
                            )}
                          </Box>
                        </li>
                      ))
                    : loader
                    ? translation("loading")
                    : translation("no_fav_user")}
                </ul>
              </Box>
            </Box>
          </Grid>

          <Grid item md={3}>
            <RightSidebar />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Favourites;
