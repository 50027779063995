import axios from "../../services/axiosService";
import { Dispatch } from "redux";
import { ActionType } from "../action-types/index";
import { Action } from "../actions/AuthAction";
const URL = process.env.REACT_APP_API_URL;

/**
 * create seller profile
 * @param data
 * @returns
 */
export const createSeller = (data: object) => async (
  dispatch: Dispatch<Action>
) => {
  try {
    const response = await axios.post(`${URL}/api/v1/seller/add`, data);
    return response.data;
  } catch (err) {
    dispatch({
      type: ActionType.FAILURE,
    });
  }
};

/**
 * get details of seller
 * @param sellerId
 * @returns
 */
export const getSellerDetail = (sellerId: number) => async (
  dispatch: Dispatch<Action>
) => {
  try {
    const response = await axios.get(`${URL}/api/v1/seller/${sellerId}`);
    return response.data;
  } catch (err) {
    dispatch({
      type: ActionType.FAILURE,
    });
  }
};


/**
 * get product list by SellerId
 * @param SellerId
 * @returns
 */

 export const getProductListBySellerId = (sellerId: number,offset:number) => async (
  dispatch: Dispatch<Action>
) => {
  try {
    const response = await axios.get(`${URL}/api/v1/product/product-list/${sellerId}/?offset=${offset}`);
    console.log("RESPONSRREEEEEEEEEE",response)
    return response.data.data.data;
  } catch (err) {
    dispatch({
      type: ActionType.FAILURE,
    });
  }
};