import { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { Grid } from "@mui/material";
import { Box } from "@mui/system";

import { getdatabyId } from "../../redux/action-creators/adoptionAction";

import LeftSidebar from "../../components/sidebars/LeftSidebar";
import ProductSidebar from "../../components/sidebars/ProductSidebar";

import { ProductStyles } from "../products/ProductsStyle";
import { UserCommonStyles } from "../../styles/CommonStyles";

import { Type } from "../../utils/constants";

const AdoptionDetail = () => {
  const { t: translation } = useTranslation();
  const commonStyle = UserCommonStyles();
  const styleClasses = ProductStyles();
  const dispatch = useDispatch();
  const [Images, setImages] = useState([]);
  const type = Type.adoption;

  const { id }: { id: string } = useParams();
  /**
   * Function To get the details of Pets
   * @param id
   */
  const petDetails = useCallback(
    async (id: string) => {
      const response: any = await dispatch(getdatabyId(id, type));
      if (response && response.length) {
        let data = response[0]?.image;
        data = JSON.parse(data);
        setImages(data);
      }
    },
    [dispatch, type]
  );

  useEffect(() => {
    petDetails(id);
  }, [id, petDetails]);

  return (
    <Box>
      <Box className={commonStyle.windowColor}>
        <Grid container>
          <Grid item md={3}>
            <LeftSidebar />
          </Grid>

          <Grid item md={6}>
            <Box className={`${commonStyle.p20} ${commonStyle.mt20}`}>
              <Box
                className={`${commonStyle.bgWhite} ${styleClasses.combineBox}`}
              >
                <Box
                  className={`${commonStyle.p20} ${commonStyle.card} ${styleClasses.productDetailCard}`}
                >
                  <img
                    src={Images[0] ? Images[0] : null}
                    alt={translation("imgalt_product")}
                    className={styleClasses.productCardImg}
                  />
                </Box>

                <Grid container spacing={2} className={commonStyle.mt10}>
                  {Images.length > 0 &&
                    Images.slice(1).map((item, index) => (
                      <Grid key={index} item lg={4}>
                        <Box
                          className={`${commonStyle.flexCenter} ${commonStyle.card} ${styleClasses.productDetailCard}`}
                        >
                          <img src={item} alt={translation("imgalt_product")} />
                        </Box>
                      </Grid>
                    ))}
                </Grid>
              </Box>
            </Box>
          </Grid>

          <Grid item md={3}>
            <ProductSidebar id={id} type={type} />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default AdoptionDetail;
