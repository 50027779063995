import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Box } from "@mui/system";
import { Button, Grid, Typography } from "@mui/material";

import LeftSidebar from "../../components/sidebars/LeftSidebar";

import { RoutingLinks } from "../../utils/constants";

import { UserCommonStyles } from "../../styles/CommonStyles";
import { ProfileStyle } from "../profile/ProfileStyle";

const AddBalance: React.FC = (props:any) => {
  const commonStyle = UserCommonStyles();
  const { t: translation } = useTranslation();
  const styleClasses = ProfileStyle();
  let history = useHistory();

  const addetail  = props.location.state;

  

  const Buy = (cost:number)=>{
    const state = {
      cost : cost,
      adddetail : addetail,
    
    }
 
      props.history.push=({
        pathname: RoutingLinks.payment,
       state : {...state},
      });
    }


  return (
    <Box className={commonStyle.windowColor}>
      <Grid container>
        <Grid item md={3}>
          <LeftSidebar />
        </Grid>

        <Grid item md={9}>
          <Box className={`${commonStyle.p20} ${commonStyle.mt20}`}>
            <Typography
              variant="h6"
              className={`${commonStyle.grey} ${commonStyle.mb15} ${commonStyle.fontWeight600}`}
            >
             {translation("add_bal")}
            </Typography>
            <Box className={`${commonStyle.p20} ${commonStyle.card}`}>
              <Box
                className={`${commonStyle.flexCenter} ${commonStyle.justifySpaceBetween}`}
              >
                <Typography>
                  <strong>{translation("company_name")}</strong>
                  {addetail?.company_name}
                </Typography>
              </Box>

              <ul className={styleClasses.subscriptionListSlider}>
                <li>
                  <Box className={styleClasses.subscriptionCard}>
                    <Typography variant="h5">$100</Typography>
                    <Box
                      className={`${commonStyle.w100} ${commonStyle.orangeBtn} ${commonStyle.my10}`}
                    >
                      <Button onClick={()=>Buy(100)}>{translation("add")}</Button>
                    </Box>

                  </Box>
                </li>
                <li>
                  <Box className={styleClasses.subscriptionCard}>
                    <Typography variant="h5">$200</Typography>
                    <Box
                      className={`${commonStyle.w100} ${commonStyle.orangeBtn} ${commonStyle.my10}`}
                    >
                      <Button onClick={()=>Buy(200)}>{translation("add")}</Button>
                    </Box>
                  </Box>
                </li>
                
                <li>
                  <Box className={styleClasses.subscriptionCard}>
                    <Typography variant="h5">$300</Typography>
                    <Box
                      className={`${commonStyle.w100} ${commonStyle.orangeBtn} ${commonStyle.my10}`}
                    >
                      <Button onClick={()=>Buy(300)}>{translation("add")}</Button>
                    </Box>
                  </Box>
                </li>
              </ul>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AddBalance;
