import { Box, Grid } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import CommonCard from "../../components/commonCard/CommonCard";
import LeftSidebar from "../../components/sidebars/LeftSidebar";
import RightSidebar from "../../components/sidebars/RightSidebar";
import { getPostData } from "../../redux/action-creators/postAction";
import { UserCommonStyles } from "../../styles/CommonStyles";


const PostDetail = (props: any) => {
    let location = useLocation();
    const dispatch = useDispatch();
    const commonStyle = UserCommonStyles();
    const userId: number = Number(localStorage.getItem("userId"));
    const [postDetails, setPostDetails] = useState();
    const [postCommentsDetails, setPostCommentsDetails] = useState();
    const [postType, setPostType] = useState("");

    /**
    * Get the post details by postid
    */
    const getPostDetails = useCallback(async (postId: number) => {
        let result: any = await dispatch(
            getPostData(postId, userId)
        );
        if (result && result?.isFound) {
            setPostDetails(result.data.postData.data);
            setPostType(result.data.postData.data[0].type);
            setPostCommentsDetails(result.data.commentData.data);
        }
    }, [dispatch, userId]);

    /**
    * See the post details
    */
    useEffect(() => {
        let postId = location.pathname.split("/")[2];
        getPostDetails(Number(postId));
    }, [getPostDetails, location]);

    return (
        <Box>
            <Grid container className={commonStyle.windowColor}>
                {/* home part ------------ */}
                <Grid item md={3}>
                    <LeftSidebar />
                </Grid>
                <Grid item md={6}>
                <CommonCard
                    allPost={postDetails}
                    allPostComments={postCommentsDetails}
                    postType={postType}
                    props={props}
                />
                 </Grid>
                <Grid item md={3}>
                    <RightSidebar />
                </Grid>
                {/* home part ------------ */}
            </Grid>
        </Box>
    );
};

export default PostDetail;
