/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";

import { Search } from "@mui/icons-material";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Slider,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import OutlinedInput from "@mui/material/OutlinedInput";

import {
  getAllProductCategories,
  getAllProductCategoriesByCategoryId,
  getAllProductCategoriesBySubCategoryId,
  getAllProducts,
} from "../../redux/action-creators/productAction";
import { getSellerDetail } from "../../redux/action-creators/sellerAction";

import LeftSidebar from "../../components/sidebars/LeftSidebar";

import { toast } from "../../utils/toastsMessage";
import { capitalize, isJsonString } from "../../utils/helper";
import { RoutingLinks } from "../../utils/constants";

import { CommonButton } from "../../stories/Button";
import defaultUserprofile from "../../images/defaultUserprofile.png";

import { UserCommonStyles } from "../../styles/CommonStyles";
import { ProductStyles } from "./ProductsStyle";

const Products = () => {
  const { latitude, longitude } = useSelector(
    (state: RootStateOrAny) => state.authReducer.authData
  );

  const userInfo = useSelector((state: RootStateOrAny) => {
    let userData = state.authReducer.authData;
    if (isJsonString(state.authReducer.authData)) {
      userData = JSON.parse(state.authReducer.authData);
    }
    return userData;
  });
  const { t: translation } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const commonStyle = UserCommonStyles();
  const styleClasses = ProductStyles();

  const productCategoriesList = useSelector(
    (state: RootStateOrAny) => state.productReducer.productCategoryList
  );
  const [productCategories, setProductCategories] = useState([
    ...productCategoriesList,
  ]);

  const [productSubCategories, setProductSubCategories] = useState([]);
  const [productSubSubCategories, setProductSubSubCategories] = useState([]);
  const [productCategoryId, setProductCatgoryId] = useState({
    categoryId: 0,
    subCategoryId: 0,
    subProductCategoryId: 0,
  });

  const [productState, setProductsState] = useState({
    productsList: [],
    offset: 0,
    prevOffset: 0,
    hasMore: true,
  });

  const [sellerStatus, setSellerStatus] = useState(0);
  const [loader, setloader] = useState(true);
  const [searchCategory, setSearchCategory] = useState({ id: 0 });
  const [searchProduct, setSearchProduct] = useState({ product: "" });
  const [timeOutProductState] = React.useState(null);
  const [sellYoursLoader, setSellYoursLoader] = useState(false);
  const [distance, setdistance] = useState({ distance: "50" });
  const [productCallTimeOut, setProductCallTimeOut] = useState<any>();

  /**
   * get list of product categories
   */
  const getProductCategoriesList = useCallback(async () => {
    const response: any = await dispatch(getAllProductCategories());
    if (response && !response?.data?.isError) {
      setProductCategories(response.data);
    }
  }, [dispatch]);

  useEffect(() => {
    if (!(productCategoriesList.length > 0)) {
      getProductCategoriesList();
    }
  }, [getProductCategoriesList, productCategoriesList.length]);

  /**
   * get product list
   * @param searchCategoryText
   * @param searchText
   * @param distanceValue
   */
  const getProductsList = async (
    firstLoad: boolean,
    searchText?: string,
    searchCategoryId?: number,
    distanceValue?: string
  ) => {
    setloader(true);
    let offset = firstLoad ? 0 : productState.offset;
    searchCategoryId = searchCategoryId
      ? searchCategoryId
      : productCategoryId.categoryId;
    searchText = searchText !== undefined ? searchText : searchProduct.product;
    let distanceField = distanceValue ? distanceValue : distance.distance;

    if (productState.offset !== productState.prevOffset || firstLoad) {
      const response: any = await dispatch(
        getAllProducts(searchCategoryId, searchText, distanceField, {
          latitude,
          longitude,
          offset,
        })
      );

      setloader(false);
      if (response && !response?.data?.isError) {
        if (response.data.length > 0) {
          for (let i = 0; i < response.data.length; i++) {
            let data = response.data[i]?.gallery_images;
            data = JSON.parse(data);
            response.data[i].gallery_images = data[0];
          }

          setProductsState((prevState) => ({
            ...prevState,
            prevOffset: firstLoad ? 0 : prevState.offset,
            offset: firstLoad ? 10 : prevState.offset + 10,
            productsList: firstLoad
              ? response.data
              : prevState.productsList.concat(response.data),
            hasMore: true,
          }));
        } else {
          setloader(false);
          setProductsState((prevState) => ({
            ...prevState,
            productsList: firstLoad
              ? response.data
              : prevState.productsList.concat(response.data),
            hasMore: false,
          }));
        }
      }
    }
    setloader(false);
  };

  const commonProductDebounce = (searchtext, distance) => {
    let categoryId: any = "";
    if (productCategoryId.subProductCategoryId > 0)
      categoryId = productCategoryId.subProductCategoryId;
    else if (productCategoryId.subCategoryId > 0)
      categoryId = productCategoryId.subCategoryId;
    else if (productCategoryId.categoryId > 0)
      categoryId = productCategoryId.categoryId;

    setProductsState((prevState) => ({
      ...prevState,
      prevOffset: 0,
      offset: 0,
    }));

    if (productCallTimeOut) {
      clearTimeout(productCallTimeOut);
    }
    const timeoutValue = setTimeout(() => {
      getProductsList(true, searchtext, categoryId, distance);
    }, 1500);
    setProductCallTimeOut(timeoutValue);
  };
  /**
   * handle search change
   * @param event
   */
  const handleSearchChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const searchtext = event.target.value.trim();
    setSearchProduct({ product: searchtext });
    commonProductDebounce(searchtext, distance.distance);
  };

  /**
   * redirect page
   * @returns
   */
  const redirectPage = async () => {
    setSellYoursLoader(true);
    let status = await getSellerInfoById();

    if (status > 0) {
      history.push(RoutingLinks.addProduct);
    } else if (userInfo.seller_id !== 0) {
      setSellYoursLoader(false);
      return toast.success(translation("request_pending_for_approval"));
    } else {
      history.push("/create-seller");
    }
    setSellYoursLoader(false);
  };

  /**
   * get seller info by its id
   */
  const getSellerInfoById = async () => {
    const response: any = await dispatch(getSellerDetail(userInfo.seller_id));

    if (response)
      if (!response.isError && response.data.length > 0) {
        await setSellerStatus(response.data[0].is_active);
        return response.data[0].is_active;
      } else {
        return 0;
      }
  };

  /**
   * slider change on handle
   * @param event
   */
  const handleChangeSlider = (event: Event) => {
    let target = event.target as HTMLInputElement;
    const distanceValue = target.value;
    setdistance((prevState) => ({
      ...prevState,
      distance: distanceValue,
    }));
    commonProductDebounce(searchProduct.product, distanceValue);
  };

  useEffect(() => {
    if (!(productState.productsList.length > 0)) {
      getProductsList(true);
    }
  }, []);

  /**
   * get list of product categories
   */
  const selectCategory = useCallback(
    async (id: number, sp) => {
      getProductsList(true, sp, id, distance.distance);
      const response: any = await dispatch(
        getAllProductCategoriesByCategoryId(id)
      );
      if (!response.data.isError) {
        setProductSubCategories(response.data);
        setProductCatgoryId((prevState) => ({
          ...prevState,
          categoryId: id,
          subCategoryId: 0,
          subProductCategoryId: 0,
        }));
      }
    },
    [dispatch]
  );

  /**
   * get list of product categories
   */
  const selectSubCategory = useCallback(
    async (id: number, sp) => {
      getProductsList(true, sp, id, distance.distance);
      const response: any = await dispatch(
        getAllProductCategoriesBySubCategoryId(id)
      );
      if (!response.data.isError) {
        setProductSubSubCategories(response.data);
        setProductCatgoryId((prevState) => ({
          ...prevState,
          subCategoryId: id,
          subProductCategoryId: 0,
        }));
      }
    },
    [dispatch]
  );

  /**
   * set list of product category id
   */
  const selectSubSubProductCategory = (id: number, sp) => {
    setProductsState((prevState) => ({
      ...prevState,
      prevOffset: 0,
      offset: 0,
    }));
    getProductsList(true, sp, id, distance.distance);
    setProductCatgoryId((prevState) => ({
      ...prevState,
      subProductCategoryId: id,
    }));
  };

  return (
    <Box>
      <Box className={commonStyle.windowColor}>
        <Grid container>
          <Grid item md={3}>
            <LeftSidebar />
          </Grid>

          <Grid item md={9}>
            <Box className={commonStyle.p20}>
              <Box
                className={`${commonStyle.flexCenter} ${commonStyle.justifySpaceBetween} ${commonStyle.mb15} ${commonStyle.mt20}`}
              >
                <Typography
                  variant="h6"
                  className={`${commonStyle.grey} ${commonStyle.fontWeight600}`}
                >
                  {translation("top_products")}
                </Typography>

                <Box className={`${commonStyle.flexCenter}`}>
                  <Box className={commonStyle.commonSlider}>
                    <Typography>
                      {translation("distance_preference")}
                      <span>
                        {distance.distance} {translation("miles")}
                      </span>
                    </Typography>
                    <Slider
                      defaultValue={50}
                      aria-label={translation("default")}
                      valueLabelDisplay="auto"
                      // value={value2}
                      onChange={handleChangeSlider}
                    />
                  </Box>
                  <Box
                    className={`${commonStyle.orangeBtn} ${commonStyle.mr10}`}
                  >
                    <CommonButton
                      onClick={() => history.push(RoutingLinks.orders)}
                      label={translation("orders")}
                    />
                  </Box>

                  <Box className={`${commonStyle.orangeBtn} ${commonStyle.mr10}`}>
                    <CommonButton
                      label={translation("sell_yours")}
                      onClick={redirectPage}
                      disabled={sellYoursLoader}
                    />
                  </Box>

                  <Box className={`${commonStyle.orangeBtn}`}>
                    <CommonButton
                      label={translation("my_products")}
                      onClick={()=>{
                        history.push({
                          pathname:RoutingLinks.MyProduct + `${userInfo.seller_id}`,
                        })
                      }}
                      disabled={sellYoursLoader}
                    />
                  </Box>
                </Box>
              </Box>
              <Box className={`${commonStyle.p20} ${commonStyle.card}`}>
                <Grid container spacing={2}>
                  <Grid item md={6}>
                    <Box className={`${commonStyle.tagMain} ${commonStyle.m0}`}>
                      <Typography className={commonStyle.labelCommonInput}>
                        &nbsp;
                      </Typography>
                      <Box className={`${commonStyle.searchTag}`}>
                        <TextField
                          placeholder={translation("search")}
                          variant="standard"
                          onChange={(e) => handleSearchChange(e)}
                          defaultValue=""
                        />
                        <Search />
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item md={3}>
                    {/* <FormControl
                      fullWidth
                      className={styleClasses.selectProductBg}
                    > */}
                    <Box className={commonStyle.commonInput}>
                      <Typography className={commonStyle.labelCommonInput}>
                        Type of Pet
                      </Typography>
                      {/* <InputLabel id="demo-simple-select-label">
                        {translation("category")}
                      </InputLabel> */}
                      <Select
                        className={commonStyle.w100}
                        // labelId="demo-simple-select-label"
                        // id="demo-simple-select"
                        //  value={searchCategory.text}
                        // onChange={handleCategoryChange}
                        //input={<OutlinedInput />}
                        // variant="standard"
                        // renderValue={(selected) => {
                        //   if (selected.length === 0) {
                        //     return <>Type Of Pet</>;
                        //   }
                        //   return translation(selected);
                        // }}
                        placeholder={translation("category")}
                      >
                        {productCategories.length > 0 &&
                          productCategories?.map((category, index) => {
                            return (
                              <MenuItem
                                key={index}
                                value={category.name}
                                defaultValue={category.name}
                                onClick={() =>
                                  selectCategory(
                                    category.id,
                                    searchProduct.product
                                  )
                                }
                              >
                                {/* {translation(category)} */}
                                {category.name}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </Box>
                    {/* </FormControl> */}
                  </Grid>

                  <Grid item md={3}>
                    <Box className={commonStyle.commonInput}>
                      <Typography className={commonStyle.labelCommonInput}>
                        {translation("category")}
                      </Typography>
                      <Select
                        className={commonStyle.w100}
                        disabled={productSubCategories.length === 0}
                        value={productCategoryId.subCategoryId}
                      >
                        {productSubCategories.length > 0 &&
                          productSubCategories?.map(
                            (productCategory, index) => {
                              return (
                                <MenuItem
                                  key={index}
                                  value={productCategory.id}
                                  defaultValue={productCategory.name}
                                  onClick={() =>
                                    selectSubCategory(
                                      productCategory.id,
                                      searchProduct.product
                                    )
                                  }
                                >
                                  {productCategory.name}
                                </MenuItem>
                              );
                            }
                          )}
                      </Select>
                    </Box>
                  </Grid>

                  {productSubSubCategories.length > 0 && (
                    <Grid item md={12}>
                      <Box className={commonStyle.commonInput}>
                        <Typography className={commonStyle.labelCommonInput}>
                          Sub Category
                        </Typography>
                        <Select
                          className={commonStyle.w100}
                          disabled={productSubSubCategories.length === 0}
                          value={productCategoryId.subProductCategoryId}
                        >
                          {productSubSubCategories.length > 0 &&
                            productSubSubCategories?.map(
                              (productCategory, index) => {
                                return (
                                  <MenuItem
                                    key={index}
                                    value={productCategory.id}
                                    defaultValue={productCategory.name}
                                    onClick={() =>
                                      selectSubSubProductCategory(
                                        productCategory.id,
                                        searchProduct.product
                                      )
                                    }
                                  >
                                    {productCategory.name}
                                  </MenuItem>
                                );
                              }
                            )}
                        </Select>
                      </Box>
                    </Grid>
                  )}
                </Grid>

                <InfiniteScroll
                  dataLength={productState?.productsList?.length}
                  next={() => getProductsList(false)}
                  hasMore={productState?.hasMore}
                  loader={(() => {
                    if (loader) {
                      return <div className="loaing-area">Loading...</div>;
                    }
                  })()}
                  scrollThreshold="100px"
                >
                  <Box className={styleClasses.productCardBox}>
                    <Grid container spacing={2}>
                      {productState.productsList.length > 0
                        ? productState.productsList.map((product, index) => (
                            <Grid
                              item
                              lg={3}
                              className={commonStyle.textCenter}
                              key={index}
                            >
                              <Box className={styleClasses.productCard}>
                                <img
                                  src={
                                    product.gallery_images
                                      ? product.gallery_images
                                      : defaultUserprofile
                                  }
                                  alt={translation("imgalt_product")}
                                />
                              </Box>
                              <Typography>
                                {capitalize(product.name)}
                              </Typography>
                              <span>${product.price}</span>
                              <Box
                                className={`${commonStyle.blueBtn} ${commonStyle.mt10}`}
                              >
                                <CommonButton
                                  label={translation("view_details")}
                                  onClick={() =>
                                    history.push(
                                      `${RoutingLinks.productDetail}${product?.id}`
                                    )
                                  }
                                />
                              </Box>
                            </Grid>
                          ))
                        : loader
                        ? translation("loading")
                        : translation("missing_record")}
                    </Grid>
                  </Box>
                </InfiniteScroll>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Products;
