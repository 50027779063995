import { makeStyles } from "@mui/styles";

export const ProfileStyle = makeStyles((theme) => {
  return {
    profileShow: {
      width: "130px",
      minWidth: "130px",
      height: "130px",
      borderRadius: "100px",
      objectFit: "cover",
      objectPosition: "center",
    },
    userDescProfile: {
      "& h6": {
        fontWeight: 800,
        marginBottom: "10px",
      },
      "& p": {
        color: "#898989",
        fontSize: "14px",
        fontWeight: 300,
        WebkitLineClamp: 3,
        display: "-webkit-box",
        WebkitBoxOrient: "vertical",
        overflow: "hidden",
        textOverflow: "ellipsis",
        wordBreak: "break-all",
      },
    },

    mediaIconPopup: {
      listStyle: "none",
      padding: 0,
      margin: 0,
      "& li": {
        borderBottom: "1px solid #ccc",
        display: "flex",
        alignItems: "center",
        fontSize: "14px",
        padding: "4px 10px",
        cursor: "pointer",
        position: "relative",
        "& svg": {
          color: "#01B4BC",
          marginRight: "5px",
        },
        "& input": {
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          opacity: 0,
        },
      },
    },

    tabination: {
      margin: "20px 0",
    },

    tabsBorder: {
      borderBottom: "1px solid #EBEBEB",
      "& .Mui-selected": {
        color: "#000 !important",
        fontWeight: 600,
      },
    },

    tabImgGroup: {
      listStyle: "none",
      display: "flex",
      alignItems: "center",
      paddingLeft: 0,
      flexWrap: "nowrap",
      overflow: "auto",
      "& li": {
        paddingRight: "20px",
      },
    },

    tabImg: {
      width: "230px",
      height: "150px",
      borderRadius: "10px",
      objectFit: "cover",
      objectPosition: "center",
    },

    postDigit: {
      listStyle: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-evenly",
      textAlign: "center",
      border: "1px solid #EBEBEB",
      boxShadow: "0px 3px 0px #D3D8E066",
      borderRadius: "10px",
      margin: 0,
      padding: "7px",
      "& h6": {
        fontSize: "16px",
        fontWeight: 600,
        color: "#01B4BC",
      },
      "& p": {
        fontSize: "12px",
        fontWeight: 300,
        color: "#01B4BC",
      },
    },

    quottedMessage: {
      backgroundColor: "#F2F2F2",
      borderRadius: "30px 30px 0 0",
      fontSize: "14px",
      position: "absolute",
      top: "-83px",
      width: "100%",
      left: 0,
      borderBottom: "1px solid",
      "& p": {
        padding: "10px",
        "& span": {
          fontSize: "28px",
        },
      },
    },

    quottedClose: {
      position: "absolute",
      top: "10px",
      right: "15px",
      cursor: "pointer",
    },

    postReaction: {
      listStyle: "none",
      margin: 0,
      padding: 0,
      display: "flex",
      alignItems: "center",
      "& li": {
        marginRight: "10px",
        "& button": {
          color: "#D3D8E0",
          fontSize: "16px",
          fontWeight: 300,
          "& svg": {
            marginRight: "10px",
          },
          "&:hover": {
            color: "#01B4BC",
          },
        },
      },
    },

    postImgSub: {
      width: "200px",
      height: "200px",
      borderRadius: "5px",
      objectFit: "cover",
      objectPosition: "center",
    },

    reactionBox: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%",
    },

    friendCardImg: {
      color: "#454545",
      "& img": {
        height: "100px",
        width: "100px",
        objectFit: "cover",
        objectPosition: "center",
        marginRight: "15px",
      },
      "& h6": {
        fontSize: "16px",
      },
      "& p": {
        fontWeight: 300,
        fontSize: "14px",
      },
    },

    postPopup: {
      padding: 0,
      listStyle: "none",
      margin: 0,
      width: "200px",
      "& li": {
        padding: "12px",
        borderBottom: "1px solid #ccc",
        cursor: "pointer",
        "&:last-child": {
          border: "none",
        },
      },
    },

    popoverShadow: {
      "& .css-1ursewb-MuiPaper-root-MuiPopover-paper": {
        boxShadow: "0px 1px 8px #0000001a",
      },
    },

    badgeCircular: {
      listStyle: "none",
      margin: 0,
      padding: 0,
      "& a": {
        textDecoration: "none",
      },
      "& li": {
        borderBottom: "1px solid #ccc",
        padding: "15px 0",
      },
    },

    modalShadow: {
      "& .css-ay3ewf-MuiBackdrop-root-MuiModal-backdrop": {
        backgroundColor: "rgba(0,0,0,0.2)",
      },
    },

    badgeImg: {
      borderRadius: "100px",
      width: "18px",
      minWidth: "18px",
      height: "18px",
      border: "2px solid #fff",
    },

    petDayImg: {
      width: "100%",
      height: "350px",
      objectFit: "cover",
      objectPosition: "center",
    },

    //settings style -----------
    editLink: {
      textDecoration: "none",
      color: "#D95557",
      cursor: "pointer",
    },

    editIconSettings: {
      fontSize: "16px !important",
      marginLeft: "5px",
    },

    editCheckInline: {
      display: "flex",
      alignItems: "center",
      "& svg": {
        cursor: "pointer",
      },
    },

    //help sccordion style ------------
    helpAccordion: {
      backgroundColor: "#fff",
      borderRadius: "10px",
      boxShadow: "0px 1px 4px #0000001A",
    },

    accordionShadow: {
      boxShadow: "none",
    },

    //slider style --------------
    sliderStories: {
      width: "100% !important",
      "& h2, & p": {
        color: "#fff",
      },
    },

    outerStrom: {
      width: "50% !important",
      backgroundColor: "#01B4BC",
      boxShadow: "0px 1px 4px #0000001a",
      padding: "15px",
      margin: "0 auto",
    },

    ButtonSliderSet: {
      position: "absolute",
      top: "50%",
      left: 0,
      right: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: "70%",
      margin: "0 auto",

      "& button": {
        backgroundColor: "#01B4BC",
        color: "#fff",
        width: "50px",
        height: "50px",
        borderRadius: "100px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: "none",
        cursor: "pointer",
      },
    },

    sliderButtonDisable: {
      opacity: 0.5,
      cursor: "default !important",
    },

    sliderImg: {
      width: "100%",
      height: "550px",
      objectFit: "cover",
      objectPosition: "center",
    },

    sliderMsg: {
      padding: "30px",
      width: "70%",
      margin: "0 auto",
      "& ul": {
        listStyle: "none",
        margin: 0,
        padding: 0,
        "& li": {
          cursor: "pointer",
          fontSize: "30px",
          marginRight: "10px",
        },
      },
    },

    sliderInput: {
      backgroundColor: "#01B4BC",
      borderRadius: "20px",
      marginRight: "20px !important",
      "& div": {
        borderRadius: "20px",
        border: "none",
      },
      "& textarea": {
        color: "#fff",
      },
    },

    petPlaySlider: {
      width: "100%",
      height: "400px",
      objectFit: "cover",
      objectPosition: "center",
    },

    //chat screen style ----------------
    chatSide: {
      borderRadius: "20px",
      color: "#fff",
      maxWidth: "350px",
      position: "relative",
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "flex-start",
      overflow: "hidden",
      "& p": {
        fontSize: "12px",
        padding: "10px 15px",
        borderRadius: "10px",
        width: "100%",
      },
      "& img": {
        borderRadius: "20px",
        width: "150px",
        height: "150px",
        objectFit: "cover",
        objectPosition: "center",
      },

      "& video": {
        borderRadius: "10px",
        width: "100%",
        marginBottom: "10px",
      },
    },

    chatSendMsg: {
      position: "relative",
      width: "40px",
      height: "40px",
      "& input": {
        position: "absolute",
        width: "100%",
        height: "100%",
        borderRadius: "100px",
        overflow: "hidden",
        opacity: 0,
      },
    },

    messageSpecial: {
      width: "100%",
      backgroundColor: "#01B4BC",
    },

    showImageBox: {
      display: "flex",
      alignItems: "center",
      padding: 0,
      margin: 0,
      listStyle: "none",
      overflowX: "scroll",
      "& li": {
        borderRadius: "20px",
        width: "150px",
        minWidth: "150px",
        height: "150px",
        marginRight: "10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflow: "hidden",
        border: "1px solid #ccc",
        position: "relative",
        "& img": {
          width: "100%",
          height: "100%",
          objectFit: "cover",
          objectPosition: "center",
        },
        "& video": {
          width: "100%",
          height: "100%",
        },
        "& svg": {
          position: "absolute",
          top: "8px",
          right: "8px",
          color: "#fff",
          backgroundColor: "rgba(0,0,0,0.5)",
          cursor: "pointer",
          borderRadius: "100px",
          zIndex: 1,
        },
      },
    },

    repeatBoxCount: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-evenly",
      padding: "10px",
      width: "100%",
    },

    placeholderBoxMessage: {
      width: "100%",
      height: "430px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      opacity: 0.5,
    },

    imageFileCount: {
      position: "relative",
      overflow: "hidden",
      height: "150px",
      "&::after": {
        position: "absolute",
        content: `""`,
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0,0,0,0.5)",
        borderRadius: "20px",
      },
    },

    countNumberImage: {
      margin: 0,
      position: "absolute",
      top: 0,
      padding: "0 !important",
      zIndex: 1,
      fontSize: "20px !important",
      fontWeight: 600,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      height: "100%",
      cursor: "pointer",
    },

    lastFile: {
      position: "relative",

      "& p": {
        position: "absolute",
        zIndex: 1,
        color: "#fff",
        fontSize: "20px",
        fontWeight: 300,
      },
      "&::after": {
        position: "absolute",

        content: '" "',
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
      },
    },

    chatReplyEmoji: {
      fontSize: "12px !important",
      color: "#707070",
      cursor: "pointer",
      "& svg": {
        fontSize: "18px",
        marginRight: "10px",
      },
      "& .reaction-icon": {
        marginRight: "10px",
        fontSize: "14px",
      },
    },

    chatMsgSend: {
      backgroundColor: "#F2F2F2",
      borderRadius: "30px",
      padding: "5px",
      position: "relative",
      minWidth: "90%",
      // overflow: "hidden",
      "& input": {
        fontSize: "14px",
      },
      "& fieldset": {
        display: "none",
      },
      "& .emoji-picker-react": {
        position: "absolute",
        left: 0,
        bottom: "60px",
        width: "auto",
      },
    },

    tableSelect: {
      minWidth: "200px",
    },

    documentChatShow: {
      width: "150px",
      height: "150px",
      color: "#fff",
      textAlign: "center",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      flexFlow: "column",
      margin: "10px",
      "& a": {
        textDecoration: "none",
        color: "#fff",

        display: "inline-block",
        width: "100%",
        padding: "10px 0",
        borderTop: "1px solid #fff",
      },
    },

    documentName: {
      marginTop: "30px",
      "& svg": {
        fontSize: "35px",
      },
    },

    replyMessage: {
      width: "100%",
      borderRadius: "10px 10px 0 0",
      fontSize: "14px",
    },

    chatMainHeight: {
      height: "400px",
      overflow: "auto",
    },

    imageBannerSize: {
      height: "300px",
      objectFit: "cover",
      objectPosition: "center",
    },

    storySliderIndicator: {
      listStyle: "none",
      display: "flex",
      alignItems: "center",
      padding: 0,
      margin: 0,
      marginBottom: "10px",
      "& li": {
        backgroundColor: "#C5C5C5",
        width: "100%",
        height: "4px",
        marginRight: "8px",
        borderRadius: "10px",
      },
    },

    activeStoryIndicator: {
      backgroundColor: "#fff !important",
    },

    dateStoryShow: {
      display: "flex",
      alignItems: "center",
      fontSize: "14px",
      marginBottom: "5px",
      "& svg": {
        marginRight: "10px",
      },
    },

    subscriptionListSlider: {
      overflow: "auto",
      listStyle: "none",
      padding: 0,
      margin: "10px 0",
      display: "flex",
      alignItems: "center",
      flexWrap: "nowrap",
    },

    subscriptionCard: {
      border: "1px solid",
      borderRadius: "10px",
      padding: "10px",
      width: "300px",
      textAlign: "center",
      margin: "0 10px",
      minHeight: "200px",
    },

    threeFlexAlign: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-start",
    },

    timeTokenStyle: {
      fontSize: '10px !important',
      paddingLeft: '10px !important',
      paddingRight: '10px !important',
      marginTop: '4px !important',
      color: '#707070'
    },

    justifyContentEnd: {
      justifyContent: 'flex-end',
    },

    timeSlotBadge: {
      backgroundColor: '#D95557',
      borderRadius: '20px',
      color: '#fff',
      fontSize: '12px',
      padding: '4px 10px',
    },
  };
});
