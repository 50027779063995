import { useTranslation } from "react-i18next";

import { Modal } from "@mui/material";
import { HighlightOffOutlined } from "@mui/icons-material";
import { Box } from "@mui/system";

import { IImageModalProps } from "../../utils/interfaceModel";

import defaultUserprofile from "../../images/defaultUserprofile.png";

import { UserCommonStyles } from "../../styles/CommonStyles";

const BasicModal: React.FC<IImageModalProps> = (props: IImageModalProps) => {
  const commonStyle = UserCommonStyles();
  const { t: translation } = useTranslation();

  return (
    <Modal
      open={props?.open}
      onClose={props?.onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={commonStyle.commonModalStyle}>
        <Box
          className={`${commonStyle.p20} ${commonStyle.textRight} ${commonStyle.cursorA} ${commonStyle.commonHeaderPosition}`}
        >
          <HighlightOffOutlined onClick={props?.onClose} />
        </Box>
        {props?.images.map((image: string, i: number) => (
          <Box className={commonStyle.imagesListModal}>
            {image.split(".").pop() === "mov" ||
              image.split(".").pop() === "mp4" ||
              image.split(".").pop() === "mpeg" ? (
              <video controls src={image} />
            ) : (
              <img
                src={image || defaultUserprofile}
                alt={translation("images")}
              />
            )}
          </Box>
        ))}
      </Box>
    </Modal>
  );
};

export default BasicModal;
