import { ProfileStyle } from "../../screens/profile/ProfileStyle";
import { IStoryScrollBarProps } from "../../interfaceModules/IStoriesInterface";


const CommonStoryScrollBar: React.FC<IStoryScrollBarProps> = (
  props: IStoryScrollBarProps
) => {
  const { storyDetails, userStoryData } = props;
  const styleClasses = ProfileStyle();

  return (
    <ul className={styleClasses.storySliderIndicator}>
      {userStoryData &&
        userStoryData.length > 0 &&
        JSON.parse(userStoryData[storyDetails.activeSlideIndex].fileData).map(
          (index) => (
            <li
              className={index===storyDetails.activeInnerSlideIndex && styleClasses.activeStoryIndicator}
              key={index}
            ></li>
          )
        )}
    </ul>
  );
};

export default CommonStoryScrollBar;
