import { useEffect, useState, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { RouteComponentProps } from "react-router-dom";

import { Box } from "@mui/system";
import { Button, Grid, TextField, Typography } from "@mui/material";

import { createSeller } from "../../redux/action-creators/sellerAction";
import { GooglePlacesDetail } from "../../redux/action-creators/profileAction";

import LeftSidebar from "../../components/sidebars/LeftSidebar";
import RightSidebar from "../../components/sidebars/RightSidebar";

import { isEmpty } from "../../utils/helper";
import { toast } from "../../utils/toastsMessage";
import { locationPrediction, RoutingLinks } from "../../utils/constants";

import { ICreateSellerProfile } from "../../interfaceModules/IUserInterface";
import { createSellerProfileValidationSchema } from "../../utils/validationSchema";

import { UserCommonStyles } from "../../styles/CommonStyles";

import { yupResolver } from "@hookform/resolvers/yup";
import { ActionType } from "../../redux/action-types";

const CreateSeller = (props: RouteComponentProps) => {
  const { t: translation } = useTranslation();
  const commonStyle = UserCommonStyles();
  const dispatch = useDispatch();

  let userData = localStorage.getItem("userData");
  userData = userData ? JSON.parse(userData) : {};
  const [userInfo] = useState(JSON.parse(JSON.stringify(userData)));
  const [sellerInfo, setSellerInfo] = useState<ICreateSellerProfile>();
  const [enableSaveButton, setEnableSaveButton] = useState(false);
  const [timeOutState, setTimeOutState] = useState(null);
  const [locationPredictions, setLocationPredictions] = useState({
    predictions: locationPrediction.predictions,
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<ICreateSellerProfile>({
    resolver: yupResolver(createSellerProfileValidationSchema(translation)),
  });
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  /**
   * Updates the Textfield value in the state
   * @param event
   * @param name
   */
  const handleChange = (event: any, name: string) => {
    let target = event.target as HTMLInputElement;
    setSellerInfo({
      ...sellerInfo,
      [name]: target.value,
    });
  };
  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setLocationPredictions((prevState) => ({
            ...prevState,
            predictions: [],
          }));
        }
      }

      /**
       * Bind the event listener
       */
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        /**
         * Unbind the event listener on clean up
         */
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  /**
   * auto fill seller data
   */
  const autoFillSellerData = useCallback(async () => {
    if (userInfo) {
      setSellerInfo({
        userId: userInfo.id,
        firstname: userInfo.first_name,
        lastname: userInfo.last_name,
        email: userInfo.email,
        phone_number: userInfo.phone_number,
        location: userInfo.address,
        latitude: userInfo.latitude,
        longitude: userInfo.longitude,
        description: userInfo.description,
        image: userInfo.profile_image ? userInfo.profile_image : "",
        tax_id: null,
        imageUpdate: false,
        imageName: userInfo.profile_image
          ? userInfo.profile_image.substring(
              userInfo.profile_image.lastIndexOf("/") + 1
            )
          : "",
      });
    }
  }, [userInfo]);

  /**
   * Autocomplete in search
   * @param text
   * @returns
   */
  const googleAutocomplete = async (text: string) => {
    if (isEmpty(text)) {
      setLocationPredictions((prevState) => ({
        ...prevState,
        predictions: [],
      }));
      return null;
    }
    if (!text) {
      return translation("valid_text");
    }

    /**
     * for use in things like GatsbyJS where the html is generated first
     */
    if (typeof window === "undefined") {
      return translation("valid_window");
    }

    try {
      let result;
      let autocompleteService = await new google.maps.places.AutocompleteService();
      result = await autocompleteService.getPlacePredictions(
        { input: text },
        result
      );
      return result;
    } catch (e) {
      return e;
    }
  };

  /**
   * select location
   * @param event
   */
  const selectLocation = async (event: React.SyntheticEvent | Event) => {
    let target = event.target as HTMLInputElement;
    setSellerInfo((prevState) => ({
      ...prevState,
      location: target.value,
      latitude: 0,
      longitude: 0,
    }));
    if (timeOutState) {
      clearInterval(timeOutState);
    }

    let result = setTimeout(async () => {
      let results = await googleAutocomplete(target.value);
      if (results) {
        setLocationPredictions((prevState) => ({
          ...prevState,
          predictions: results?.predictions,
        }));
      }
    }, 800);

    setTimeOutState(result);
  };

  /**
   * on Place selected by placeId
   * @param placeId
   * @param placeDescription
   * @param event
   * @returns
   */
  const onPlaceSelected = async (
    placeId: string,
    placeDescription: string,
    event: React.SyntheticEvent
  ) => {
    event.preventDefault();
    const response: any = await dispatch(GooglePlacesDetail(placeId));
    if (!response || !response.result) {
      return;
    }
    setSellerInfo((prevState) => ({
      ...prevState,
      location: placeDescription,
      latitude: response.result?.geometry?.location?.lat,
      longitude: response.result?.geometry?.location?.lng,
    }));
    setLocationPredictions({
      predictions: [],
    });
  };

  /**
   * To submit data
   * @returns
   */
  const onSubmit = async () => {
    setEnableSaveButton(true);
    const response: any = await dispatch(createSeller({ sellerInfo }));
    if (response?.data?.isRegistered) {
      setEnableSaveButton(false);
      props.history.push(RoutingLinks.marketplace);
      let userDetails = userInfo;
      userDetails = {
        ...userDetails,
        seller_id: response.sellerId,
      };
      localStorage.setItem("userData", JSON.stringify(userDetails));
      await dispatch({
        type: ActionType.LOGIN,
        payload: JSON.stringify(userDetails),
      });
      return toast.success(translation(response.message));
    }
  };
  useEffect(() => {
    autoFillSellerData();
  }, [autoFillSellerData]);

  return (
    <Box>
      <Grid container>
        <Grid item md={3}>
          <LeftSidebar />
        </Grid>

        <Grid item md={6}>
          <Box className={commonStyle.p20}>
            <Typography
              variant="h6"
              className={`${commonStyle.grey} ${commonStyle.mb15} ${commonStyle.fontWeight600}`}
            >
              {translation("create_profile")}
            </Typography>
            <Box className={`${commonStyle.p20} ${commonStyle.card}`}>
              {sellerInfo && Object.keys(sellerInfo).length > 0 && (
                <form onSubmit={handleSubmit(onSubmit)} noValidate>
                  <Grid container spacing={2}>
                    <Grid item md={6}>
                      <Box className={commonStyle.commonInput}>
                        <Typography className={commonStyle.labelCommonInput}>
                          {translation("fname")}
                        </Typography>
                        <TextField
                          {...register("firstname")}
                          variant="outlined"
                          className={commonStyle.w100}
                          onChange={(e) => handleChange(e, "firstname")}
                          inputProps={{ readOnly: true }}
                          value={
                            sellerInfo.firstname ? sellerInfo.firstname : ""
                          }
                        />
                        {errors && errors.firstname && (
                          <span className={commonStyle.errorMsg}>
                            {errors?.firstname?.message}
                          </span>
                        )}
                      </Box>
                    </Grid>

                    <Grid item md={6}>
                      <Box className={commonStyle.commonInput}>
                        <Typography className={commonStyle.labelCommonInput}>
                          {translation("lname")}
                        </Typography>
                        <TextField
                          {...register("lastname")}
                          variant="outlined"
                          className={commonStyle.w100}
                          name="lastname"
                          onChange={(e) => handleChange(e, "lastname")}
                          inputProps={{ readOnly: true }}
                          value={sellerInfo.lastname ? sellerInfo.lastname : ""}
                        />
                        {errors && errors.lastname && (
                          <span className={commonStyle.errorMsg}>
                            {errors?.lastname?.message}
                          </span>
                        )}
                      </Box>
                    </Grid>

                    <Grid item md={12}>
                      <Box className={commonStyle.commonInput}>
                        <Typography className={commonStyle.labelCommonInput}>
                          {translation("location")}
                        </Typography>
                        <TextField
                          {...register("location")}
                          variant="outlined"
                          onChange={selectLocation}
                          autoComplete="off"
                          className={commonStyle.w100}
                          inputProps={{ readOnly: true }}
                          value={sellerInfo.location ? sellerInfo.location : ""}
                        />
                        {locationPredictions.predictions.length !== 0 && (
                          <div className="search-drop-list" ref={wrapperRef}>
                            <ul className={commonStyle.searchCommonList}>
                              {locationPredictions.predictions.map(
                                (prediction) => (
                                  <li
                                    key={prediction?.place_id}
                                    onClick={(e) =>
                                      onPlaceSelected(
                                        prediction?.place_id,
                                        prediction?.description,
                                        e
                                      )
                                    }
                                  >
                                    {prediction?.description ||
                                      translation("not_found")}
                                  </li>
                                )
                              )}
                            </ul>
                          </div>
                        )}

                        {errors && errors.location && (
                          <span className={commonStyle.errorMsg}>
                            {errors?.location?.message}
                          </span>
                        )}
                      </Box>
                    </Grid>

                    <Grid item md={6}>
                      <Box className={commonStyle.commonInput}>
                        <Typography className={commonStyle.labelCommonInput}>
                          {translation("email")}
                        </Typography>
                        <TextField
                          {...register("email")}
                          variant="outlined"
                          className={commonStyle.w100}
                          defaultValue={
                            sellerInfo.email ? sellerInfo.email : ""
                          }
                          inputProps={{ readOnly: true }}
                        />
                        {errors && errors.email && (
                          <span className={commonStyle.errorMsg}>
                            {errors?.email?.message}
                          </span>
                        )}
                      </Box>
                    </Grid>

                    <Grid item md={6}>
                      <Box className={commonStyle.commonInput}>
                        <Typography className={commonStyle.labelCommonInput}>
                          {translation("contact")}
                        </Typography>
                        <TextField
                          {...register("phone_number")}
                          variant="outlined"
                          className={commonStyle.w100}
                          name="phone_number"
                          onChange={(e) => handleChange(e, "phone_number")}
                          value={
                            sellerInfo.phone_number
                              ? sellerInfo.phone_number
                              : ""
                          }
                          inputProps={{ readOnly: true, maxLength: 10 }}
                        />
                        {errors && errors.phone_number && (
                          <span className={commonStyle.errorMsg}>
                            {errors?.phone_number?.message}
                          </span>
                        )}
                      </Box>
                    </Grid>

                    <Grid item md={6}>
                      <Box className={commonStyle.commonInput}>
                        <Typography className={commonStyle.labelCommonInput}>
                          {translation("tax_id")}
                        </Typography>
                        <TextField
                          {...register("tax_id")}
                          variant="outlined"
                          className={commonStyle.w100}
                          type="number"
                          onChange={(e) => handleChange(e, "tax_id")}
                          inputProps={{
                            min: 0,
                            maxLength: 12,
                          }}
                          value={sellerInfo.tax_id ? sellerInfo.tax_id : ""}
                        />
                        {errors && errors.tax_id && (
                          <span className={commonStyle.errorMsg}>
                            {errors?.tax_id?.message}
                          </span>
                        )}
                      </Box>
                    </Grid>

                    <Grid item md={6}>
                      <Box className={commonStyle.commonInput}>
                        <Typography className={commonStyle.labelCommonInput}>
                          {translation("profile_photo")}
                        </Typography>
                        <Box className={commonStyle.uploadBoxOutline}>
                          <Typography
                            className={`${commonStyle.flexCenter} ${commonStyle.h100} ${commonStyle.px15}`}
                          >
                            {sellerInfo.imageName}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>

                    <Grid item md={12}>
                      <Box className={commonStyle.commonInput}>
                        <Typography className={commonStyle.labelCommonInput}>
                          {translation("bio")}
                        </Typography>
                        <TextField
                          {...register("description")}
                          multiline
                          variant="outlined"
                          className={commonStyle.w100}
                          name="description"
                          inputProps={{ readOnly: true }}
                          onChange={(e) => handleChange(e, "description")}
                          rows={5}
                          value={
                            sellerInfo.description ? sellerInfo.description : ""
                          }
                        />
                        {errors && errors.description && (
                          <span className={commonStyle.errorMsg}>
                            {errors?.description?.message}
                          </span>
                        )}
                      </Box>
                    </Grid>
                  </Grid>

                  <Box className={commonStyle.flexCenter}>
                    <Box
                      className={`${commonStyle.mr10} ${commonStyle.blueBtn}`}
                    >
                      {/* <CommonButton label="Save & Continue" /> */}
                      <Button
                        disabled={enableSaveButton}
                        onClick={handleSubmit(onSubmit)}
                      >
                        {translation("save")}
                      </Button>
                    </Box>
                    <Box
                      className={`${commonStyle.mr10} ${commonStyle.greyBtn}`}
                    >
                      {/* <CommonButton label="Cancel" /> */}
                      <Button onClick={autoFillSellerData}>
                        {translation("cancel")}
                      </Button>
                    </Box>
                  </Box>
                </form>
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item md={3}>
          <RightSidebar />
        </Grid>
      </Grid>
    </Box>
  );
};

export default CreateSeller;
