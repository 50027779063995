import React, { useState, useEffect } from "react";
import ClipLoader from "react-spinners/ClipLoader";

import {
  Grid,
  Typography,
  FormControlLabel,
  Radio,
  Button,
  TextField,
  sliderUnstyledClasses,
  Modal,
} from "@mui/material";
import { Box } from "@mui/system";
import * as stripeJs from "@stripe/stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import LeftSidebar from "../../components/sidebars/LeftSidebar";
import { UserCommonStyles } from "../../styles/CommonStyles";
import { UserAuthStyles } from "../userAuth/UserAuth";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { ICardInterface } from "../../interfaceModules/ICardInterface";
import { useTranslation } from "react-i18next";

import {
  CardElement,
  Elements,
  useStripe,
  useElements,
  CardNumberElement,
} from "@stripe/react-stripe-js";

import { useDispatch, RootStateOrAny, useSelector } from "react-redux";

import {
  SaveCards,
  getSavedCardsList,
  makeStripePayment,
} from "../../redux/action-creators/stripe";
import { CardValidationSchema } from "../../utils/validationSchema";
import { toast } from "../../utils/toastsMessage";

import PaymentMethod from "./paymentMethod";
import { isJsonString } from "../../utils/helper";

// const stripePromise = loadStripe(
//     process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || ""
// );

if (!process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY) {
  console.error("**Stripe publishable key environment variable not set**");
  console.error(
    "**Add an environemnt variable REACT_APP_STRIPE_PUBLISHABLE_KEY**"
  );
  console.error("**Replace .env.example with .env and **");
}

const AddPaymentMethodModal = (props: any) => {
  const stripe = useStripe();
  const dispatch = useDispatch();

  const [modal, setModal] = useState(false);

  const userInfo = useSelector((state: RootStateOrAny) => {
    let userData = state.authReducer.authData;
    if (isJsonString(state.authReducer.authData)) {
      userData = JSON.parse(state.authReducer.authData);
    }
    return userData;
  });
  const commonStyle = UserCommonStyles();
  const styleClasses = UserAuthStyles();
  const { t: translation } = useTranslation();
  const elements = useElements();
  const [ref, setRef] = useState<any>({});
  const [stripePaymentResponse, setPaymentResponse] = useState(null);
  const [cardList, setCardLsit] = useState([]);
  const [disable, setdisable] = useState(false);
  const [loading, setloading] = useState(false);

  const [stripeCardDetails, setStripeCardDetails] = useState({
    isPaymentButtonEnable: false,
    paymentType: "",
    isCardDropdownEnable: false,
    savedCardList: [],
    selectedCardId: null,
    paymentTypeSelectErrorMsg: "",
    paymentResponse: null,
    incompletePaymentMessage: "",
    isPaymentInitialized: false,
    paymentDescription: "",
    cartSubtotal: 0,
    stripeResponse: {},
    handleClickKey: false,
    handleRestForm: false,
    isFormSubmitted: false,
    paypalDescriptionString: "",
    cartItems: [],
    chkboxnewcard: false,
    newCard: false,
  });

  const {
    handleSubmit,
    reset,
    register,
    control,
    formState: { errors },
  } = useForm<ICardInterface>({
    resolver: yupResolver(CardValidationSchema(translation)),
  });
  const getListofSaveCard = async () => {
    let result: any = await dispatch(getSavedCardsList(userInfo.id));
    if (result && result?.length > 0) {
      setCardLsit(result);
    }
  };

  useEffect(() => {
    getListofSaveCard();
  }, []);

  const handlCardDetailChange = (e: stripeJs.StripeCardElementChangeEvent) => {
    // if (e.empty === false) {
    //     if (stripePaymentResponse) {
    //         handleStripeResponse(null, {});
    //     } else {
    //         handleStripeResponse(false, {});
    //     }
    // }
  };

  const savenewcard = async (data: any) => {
    const res: any = await dispatch(
      SaveCards({ paymentMethodId: data, amount: props.amount }, userInfo.id)
    );
    if (res.isSuccess) {
      props.handleClose();
      setloading(false);
      props.success();

      return toast.success(translation(res.message));
    } else {
      setloading(false);

      reset({});
      props.handleClose();
      return toast.error(translation(res.message));
    }
  };
  const makePayment = async (id: any) => {
    const res: any = await dispatch(
      makeStripePayment(
        { paymentMethodId: id, amount: props.amount },
        userInfo.id
      )
    );
    if (res.isSuccess) {
      setloading(false);
      props.success();
      props.handleClose();

      return toast.success(translation(res.message));
    } else {
      setloading(false);
      props.handleClose();
      return toast.error(translation(res.message));
    }
  };

  //Save the new card details
  const handleSaveNewCard = () => {
    setStripeCardDetails((prevState: any) => ({
      ...prevState,
      chkboxnewcard: !stripeCardDetails.chkboxnewcard,
    }));
  };

  const cancel = async () => {
    reset();
    props.handleClose();
  };

  const onSubmit = async (data: ICardInterface) => {
    setdisable(true);
    setloading(true);
    // const handleSubmit = async (event) => {
    // Block native form submission.
    // event.preventDefault();

    // setSubscribing(true);

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);
    // const payload = await stripe.createToken(cardElement);
    // If a previous payment was attempted, get the lastest invoice
    // const latestInvoicePaymentIntentStatus = localStorage.getItem(
    //     "latestInvoicePaymentIntentStatus"
    // );
    // Use your card Element with other Stripe.js APIs
    // billingDetails[`address`][`city`] = billingDetails.city
    // billingDetails[`address`][`state`] = billingDetails.state
    // billingDetails[`address`][`postal_code`] = billingDetails.zip
    // billingDetails[`address`][`line1`] = billingDetails.line1

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
      billing_details: data,
    });

    if (error) {
      setdisable(false);
      setloading(false);

      return toast.error(translation("wrong_happen"));
    } else if (stripeCardDetails.chkboxnewcard) {
      savenewcard(paymentMethod.id);
    } else {
      makePayment(paymentMethod.id);
    }
  };

  return (
    <Box>
      <Grid item md={9}>
        <Modal
          open={props.toggleModal}
          onClose={props.handleclose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          // className={styleClasses.modalShadow}
        >
          <Box
            className={`${commonStyle.mb20} ${commonStyle.p20} ${commonStyle.card} ${styleClasses.cardCustomWidth}`}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <Typography
                variant="h6"
                className={`${commonStyle.grey} ${commonStyle.mb15} ${commonStyle.fontWeight600}`}
              >
                Card Details
              </Typography>

              <ul className={styleClasses.cardSaveList}></ul>

              <Grid container spacing={2}>
                <Grid item md={12}>
                  <Box className={commonStyle.commonInput}>
                    <TextField
                      {...register("name")}
                      variant="outlined"
                      label="Name"
                      className={commonStyle.w100}
                    />
                    {errors && errors.name && (
                      <span className={commonStyle.errorMsg}>
                        {errors?.name?.message}
                      </span>
                    )}
                  </Box>
                </Grid>

                <Grid item md={6}>
                  <Box className={commonStyle.commonInput}>
                    <TextField
                      {...register("email")}
                      variant="outlined"
                      label="Email"
                      className={commonStyle.w100}
                    />
                    {errors && errors.email && (
                      <span className={commonStyle.errorMsg}>
                        {errors?.email?.message}
                      </span>
                    )}
                  </Box>
                </Grid>

                <Grid item md={6}>
                  <Box className={commonStyle.commonInput}>
                    <TextField
                      {...register("phone")}
                      variant="outlined"
                      label="Phone"
                      inputProps={{ maxLength: 10 }}
                      className={commonStyle.w100}
                    />
                    {errors && errors.phone && (
                      <span className={commonStyle.errorMsg}>
                        {errors?.phone?.message}
                      </span>
                    )}
                  </Box>
                </Grid>

                <Grid item md={4}>
                  <Box className={commonStyle.commonInput}>
                    <TextField
                      {...register("address.city")}
                      variant="outlined"
                      label="City"
                      className={commonStyle.w100}
                    />
                    {errors && errors.address?.city && (
                      <span className={commonStyle.errorMsg}>
                        {errors.address?.city?.message}
                      </span>
                    )}
                  </Box>
                </Grid>

                <Grid item md={4}>
                  <Box className={commonStyle.commonInput}>
                    <TextField
                      {...register("address.state")}
                      variant="outlined"
                      label="State"
                      className={commonStyle.w100}
                    />
                    {errors && errors.address?.state && (
                      <span className={commonStyle.errorMsg}>
                        {errors.address?.state?.message}
                      </span>
                    )}
                  </Box>
                </Grid>

                <Grid item md={4}>
                  <Box className={commonStyle.commonInput}>
                    <TextField
                      {...register("address.postal_code")}
                      variant="outlined"
                      label="Zip"
                      inputProps={{ maxLength: 7 }}
                      className={commonStyle.w100}
                    />
                    {errors && errors.address?.postal_code && (
                      <span className={commonStyle.errorMsg}>
                        {errors.address?.postal_code?.message}
                      </span>
                    )}
                  </Box>
                </Grid>

                <Grid item md={12}>
                  <CardElement
                    // className={styleClasses.cardStripeElement}
                    onChange={(e) => {
                      // setError(e.error);
                      handlCardDetailChange(e);
                    }}
                    onReady={(e) => setRef(e)}
                    options={{
                      style: {
                        base: {
                          fontSize: "12px",
                          color: "#32325d",
                          fontFamily:
                            "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif",
                          "::placeholder": {
                            color: "#a0aec0",
                          },
                        },
                        invalid: {
                          color: "#9e2146",
                          // border: "1px solid",
                          backgroundColor: "#f5d4d3",
                        },
                      },
                    }}
                  />
                </Grid>
              </Grid>

              <FormControlLabel
                control={<Radio />}
                checked={stripeCardDetails.chkboxnewcard}
                onChange={handleSaveNewCard}
                label="Save card detail for faster payment"
              />

              <Box
                className={`${commonStyle.flexCenter} ${commonStyle.justifyCenter}`}
              >
                <Box
                  className={`${commonStyle.orangeOutlineBtn} ${commonStyle.textCenter} ${commonStyle.mr10}`}
                >
                  <Button onClick={() => cancel()}>Cancel</Button>
                </Box>
                <Box
                  className={`${commonStyle.orangeBtn} ${commonStyle.textCenter}`}
                >
                  <Button disabled={disable} onClick={handleSubmit(onSubmit)}>
                    Pay ${props.amount}
                    {loading ? <ClipLoader /> : null}
                  </Button>
                </Box>
              </Box>
            </form>
          </Box>
        </Modal>
      </Grid>
    </Box>
  );
};

export default AddPaymentMethodModal;
