import { Button } from "@mui/material";
import "./button.css";

interface ButtonProps {
  /**
   * Is this the principal call to action on the page?
   */
  primary?: boolean;
  /**
   * What background color to use
   */
  backgroundColor?: string;
  /**
   * How large should the button be?
   */
  size?: "small" | "medium" | "large";
  //Show button
  disabled?: boolean;

  /**
   * Button contents
   */
  label: string;
  /**
   * Optional click handler
   */
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

/**
 * Primary UI component for user interaction
 */
export const CommonButton = ({
  primary = false,
  size = "medium",
  backgroundColor,
  label,
  ...props
}: ButtonProps) => {
  const mode = primary
    ? "storybook-button--primary"
    : "storybook-button--secondary";
  return (
    <Button disableElevation size="large" variant="contained" {...props} disabled={props.disabled}>
      {label}
    </Button>
  );
};
