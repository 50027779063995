import React, { useState } from "react";
import classNames from "classnames";
import { Card, CardBody, Col, Row } from "reactstrap";
import {
  Grid,
  Typography,
  FormControlLabel,
  Radio,
  Button,
  TextField,
  sliderUnstyledClasses,
} from "@mui/material";
import Visa from "../../images/visa.png";
import Mastercard from "../../images/Mastercard.png";
import AMEX from "../../images/AMEX.jpg";
import { Box } from "@mui/system";
import { UserCommonStyles } from "../../styles/CommonStyles";
import { UserAuthStyles } from "../userAuth/UserAuth";
// import Discover from '../../images/discover.jpg';
// import Diners from '../../images/Diners.jpg';
// import JCB from '../../images/jcb.png';
// import VisaElectron from '../../images/visaelectron.png';

// import './PaymentMethod.scss';

const PaymentMethod = ({ selectedIndex, selectPayment, data }) => {
  const [checked, setCheck] = useState(false);
  const [disable, setdisable] = useState(false);

  const commonStyle = UserCommonStyles();
  const styleClasses = UserAuthStyles();

  const onSelect = () => {
    selectPayment(data.customer_payment_profile_id);
  };

  const handleChange = () => {
    setCheck(true);
    setdisable(true);
  };

  const getCardType = (param) => {
    let cardtypeImg = "";
    switch (param) {
      case "visa":
        cardtypeImg = Visa;
        break;
      case "Visa":
        cardtypeImg = Visa;
        break;
      case "mastercard":
        cardtypeImg = Mastercard;
        break;
      case "amex":
        cardtypeImg = AMEX;
        break;
      //    case 'discover':
      //    cardtypeImg = Discover;
      //    break;
      //   case 'diners':
      //     cardtypeImg = Diners;
      //     break;
      //   case 'Diners - Carte Blanche':
      //     cardtypeImg = Diners;
      //     break;
      //   case 'jcb':
      //     cardtypeImg = JCB;
      //     break;
      //   case 'Visa Electron':
      //     cardtypeImg = VisaElectron;
      //     break;
      default:
        return;
    }
    return cardtypeImg;
  };

  return (
    <div className="PaymentMethod mt-2">
      <Box onClick={onSelect}>
        <Box
          className={classNames(
            "PaymentMethod__card align-items-center text-black ",
            selectedIndex === data._id
              ? "PaymentMethod__selected PaymentMethod--checked"
              : ""
          )}
        >
          <Box
            className={`${commonStyle.flexCenter} ${commonStyle.justifySpaceBetween}`}
          >
            <Box>
              <img
                src={getCardType(data.brand_type)}
                alt="visa"
                className={commonStyle.widthCardSet}
              />
            </Box>

            <Box className={commonStyle.flexCenter}>
              <h6 className={commonStyle.mr5}>•••• {data.card_number}</h6>
              <h6 className="font-weight-normal mt-2"> {data.exp_date}</h6>
            </Box>
          </Box>

          <Box>
            <FormControlLabel
              control={<Radio />}
              checked={selectedIndex === data.customer_payment_profile_id}
              onChange={handleChange}
              label="Selected Card"
              disabled={disable}
            />
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default PaymentMethod;
