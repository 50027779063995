import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { SidebarStyles } from "./Sidebar";

import { RoutingLinks } from "../../utils/constants";

import StoreIcon from "../../images/store.png";
import PetIcon from "../../images/play-with-pet.png";
import AdoptIcon from "../../images/veterinary.png";
import MagicIcon from "../../images/magic-trick.png";
import LostIcon from "../../images/search.png";
import ChatRoomIcon from "../../images/chat-room-icon.png";
import TailsIcon from "../../images/baby-book.png";
import HelpIcon from "../../images/help-desk.png";
import AboutIcon from "../../images/about.png";
import AdIcon from "../../images/megaphone.png";
import TermsIcon from "../../images/terms-and-conditions.png";

import { UserCommonStyles } from "../../styles/CommonStyles";
import { Box } from "@mui/system";
import { RootStateOrAny, useSelector } from "react-redux";

import { useEffect, useState } from "react";
import { isJsonString } from "../../utils/helper";

const LeftSidebar = () => {
  const { t: translation } = useTranslation();
  const commonStyle = UserCommonStyles();
  const styleClasses = SidebarStyles();
  const userInfo = useSelector((state: RootStateOrAny) => {
    let userData = state.authReducer.authData;
    if (isJsonString(state.authReducer.authData)) {
      userData = JSON.parse(state.authReducer.authData);
    }
    return userData;
  });
  const [redirectProfile, setRedirectProfile] = useState(false);
  let redirectProfileLinking = `${RoutingLinks.editProfile}${userInfo.id}`;

  useEffect(() => {
    if (
      userInfo &&
      (userInfo?.breed?.length === 0 || userInfo?.address?.length === 0)
    ) {
      setRedirectProfile(true);
    }
  }, []);

  return (
    <Box className={styleClasses.SidebarMain}>
      <ul className={`${commonStyle.removeListStyle}`}>
        <li className={styleClasses.linkPoint}>
          <Link
            to={{
              pathname: redirectProfile
                ? redirectProfileLinking
                : RoutingLinks.petPlayDate,
            }}
            className={commonStyle.flexCenter}
          >
            <img src={PetIcon} alt={translation("linkicon")} />
            {translation("play_date")}
          </Link>
          {/* {toast.error("Please complete your profile first for the best user experience")}             */}
        </li>
        <li className={styleClasses.linkPoint}>
          <Link
            to={{
              pathname: redirectProfile
                ? redirectProfileLinking
                : RoutingLinks.marketplace,
            }}
            className={commonStyle.flexCenter}
          >
            <img src={StoreIcon} alt={translation("linkicon")} />
            {translation("market")}
          </Link>
        </li>
        <li className={styleClasses.linkPoint}>
          <Link
            to={{
              pathname: redirectProfile
                ? redirectProfileLinking
                : RoutingLinks.adoption,
            }}
            className={commonStyle.flexCenter}
          >
            <img src={AdoptIcon} alt={translation("linkicon")} />
            {translation("adoption")}
          </Link>
        </li>
        <li className={styleClasses.linkPoint}>
          <Link
            to={{
              pathname: redirectProfile
                ? redirectProfileLinking
                : RoutingLinks.tricks,
            }}
            className={commonStyle.flexCenter}
          >
            <img src={MagicIcon} alt={translation("linkicon")} />
            {translation("pet_tricks")}
          </Link>
        </li>
        <li className={styleClasses.linkPoint}>
          <Link
            to={{
              pathname: redirectProfile
                ? redirectProfileLinking
                : RoutingLinks.lostFound,
            }}
            className={commonStyle.flexCenter}
          >
            <img src={LostIcon} alt={translation("linkicon")} />
            {translation("lost_found")}
          </Link>
        </li>
        <li className={styleClasses.linkPoint}>
          <Link
            to={{
              pathname: redirectProfile
                ? redirectProfileLinking
                : RoutingLinks.chatRoom,
            }}
            className={commonStyle.flexCenter}
          >
            <img src={ChatRoomIcon} alt={translation("linkicon")} />
            {translation("chat_room")}
          </Link>
        </li>
        <li className={styleClasses.linkPoint}>
          <Link
            to={{
              pathname: redirectProfile
                ? redirectProfileLinking
                : RoutingLinks.tails,
            }}
            className={commonStyle.flexCenter}
          >
            <img src={TailsIcon} alt={translation("linkicon")} />
            {translation("tails")}
          </Link>
        </li>
        <li className={styleClasses.linkPoint}>
          <Link
            to={{
              pathname: redirectProfile
                ? redirectProfileLinking
                : RoutingLinks.helpSupport,
            }}
            className={commonStyle.flexCenter}
          >
            <img src={HelpIcon} alt={translation("linkicon")} />
            {translation("help")}
          </Link>
        </li>
        <li className={styleClasses.linkPoint}>
          <Link
            to={{
              pathname: redirectProfile
                ? redirectProfileLinking
                : RoutingLinks.aboutUs,
            }}
            className={commonStyle.flexCenter}
          >
            <img src={AboutIcon} alt={translation("linkicon")} />
            {translation("about")}
          </Link>
        </li>
        <li className={styleClasses.linkPoint}>
          <Link
            to={{
              pathname: redirectProfile ? redirectProfileLinking : "/adlist",
            }}
            className={commonStyle.flexCenter}
          >
            <img src={AdIcon} alt={translation("linkicon")} />
            {translation("ad")}
          </Link>
        </li>
        <li className={styleClasses.linkPoint}>
          <Link
            to={{
              pathname: redirectProfile
                ? redirectProfileLinking
                : "/terms-condition",
            }}
            className={commonStyle.flexCenter}
          >
            <img src={TermsIcon} alt={translation("linkicon")} />
            {translation("terms_conditons")}
          </Link>
        </li>
      </ul>
    </Box>
  );
};

export default LeftSidebar;
