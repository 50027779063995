import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { debounce } from "lodash";

import { useTranslation } from "react-i18next";
import {
  getAllChats,
  getChatChannel,
} from "../../redux/action-creators/chatActions";
import PubNub from "pubnub";
import { HomeStyles } from "../../styles/HomeStyles";
import { Avatar, Box, Modal, Typography,TextField } from "@mui/material";
import defaultUserprofile from "../../images/defaultUserprofile.png";
import minImage from "../../images/minImage.png";

import { UserCommonStyles } from "../../styles/CommonStyles";
import { toast } from "../../utils/toastsMessage";
import ShortcutIcon from "@mui/icons-material/Shortcut";
import { Search } from "@mui/icons-material";

import {
  ChatBubbleOutline,
  HighlightOff,
  HighlightOffOutlined,
} from "@mui/icons-material";
import {
    RoutingLinks,
    
  } from "../../utils/constants";
import { IReceiverdata } from "../../utils/interfaceModel";
import { getFriendsList, SearchFriendProfileById } from "../../redux/action-creators/profileAction";
import InfiniteScroll from "react-infinite-scroll-component";
import { isEmpty } from "../../utils/helper";

const ChatFriendList: React.FC<any> = (props: any) => {
  const authData = useSelector(
    (state: RootStateOrAny) => state.authReducer.authData
  );
  const [state, setState] = useState({
    userList: [],
    offset: 0,
    prevOffset: 0,
    hasMore: true,
  });
  const [channel, setChannel] = useState<string>("");
  const [open, setopen] = useState(false);
  const handleClose = () => setopen(false);
  const [disable, setdisable] = useState(false);
  const dispatch = useDispatch();
  const [allFriendListLoader, setAllFriendListLoader] = useState(false);
  const [searchItem, setSearchItem] =useState({
    searchText: "",
    showLoader: false,
  });
  const [timeOutState, setTimeOutState] = useState(null);


  const styleClasses = HomeStyles();
  const commonStyle = UserCommonStyles();
  const { t: translation } = useTranslation();

  useEffect(() => {
    setopen(true);
  }, []);
   /**
     * To get the list of Adoption
     * @param firstload
     */
    const getAllfriend = async (
        firstLoad?: boolean,
      ) => {
          console.log("hellooo",firstLoad)
        if (state.offset !== state.prevOffset || firstLoad) {
          setAllFriendListLoader(true);
          let offset = firstLoad ? 0 : state.offset;
          
  
          console.log("state.offset",state.offset)
  
          let result: any = await dispatch(
            getFriendsList(authData.id, offset)
          );
          console.log("result",result)
    
          if (result)
            if (result.length > 0) {
              console.log("hasmore",state.hasMore)
              setState((prevState) => ({
                ...prevState,
                prevOffset: firstLoad ? 0 : prevState.offset,
                offset: firstLoad ? 10 : prevState.offset + 10,
                userList: firstLoad ? result : prevState.userList.concat(result),
                hasMore: true,
              }));
            } else {
                console.log("false",console.log("hasmore",state.hasMore))
              setState((prevState) => ({
                ...prevState,
                userList: firstLoad ? result : prevState.userList.concat(result),
                hasMore: false,
              }));
            }
        }
        setAllFriendListLoader(false);
      };
  
    //          /**
    //  * get all friend list
    //  */
    // const getAllfriend = useCallback(async () => {
    //   let result: any = await dispatch(getFriendsList(authData.id));
    //   if (result) setfriendList(result);
    //   setAllFriendListLoader(false);
    // }, [dispatch,authData.id]);
          
    
      /**
       * Fetching all Friend List
       */
      useEffect(() => {
          getAllfriend(true);
      }, []);
  

  
   

      const onSend = async (user:IReceiverdata) => {
        setdisable(true);
      console.log("userssssssssssssss",user)
        props.sending.FriendList({
            ...user
        })
        console.log("props-sending",props)
          props.close();
        
      
      };
  

  /**
   * get all search result
   */
   const getSearchResult = debounce(async (searchTerm: string) => {
    setAllFriendListLoader(true);
    if (isEmpty(searchTerm)) {
      // setfriendList([]);
      setAllFriendListLoader(false);
      return null;
    }

    const response: any = await dispatch(
      SearchFriendProfileById(authData.id, searchTerm)
    );
    if (response)
      if (response.length > 0) {
        console.log("response",response[0])
        setState((prevState) => ({
          ...prevState,
          
          userList: response,
          hasMore: true,
        }));
        // setfriendList(response);
      } else {
        console.log("data res", response)
        // setfriendList([]);
            setState((prevState) => ({
          ...prevState,
          
          userList: response,
          hasMore: true,
        }));
      }
    setAllFriendListLoader(false);
  }, 1000);

 /**
   * handle changes on search
   * @param event
   */
  const handleSearchChange = async (
    event:any
  ) => {
    setAllFriendListLoader(true);
    let target = event.target as HTMLInputElement;
    event.preventDefault();
    const searchKeyword = target.value.trim();
    if (timeOutState) {
      clearInterval(timeOutState);
    }

    if (searchKeyword.length > 0) {
      setSearchItem({
        searchText: searchKeyword,
        showLoader: true,
      });
      let result: any = setTimeout(async () => {
        await getSearchResult(searchKeyword);
        
      }, 800);
      if (result) setTimeOutState(result);
    } else {
      setSearchItem((prevState) => ({
        ...prevState,
        showLoader: false,
      }));
    }

    if (searchKeyword.length === 0) {
      await getAllfriend(true);
    }
    setAllFriendListLoader(false);
  };

  return (
    <>
      <Modal
        open={props.showModal}
        onClose={props.showModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
        

        <Box className={`${commonStyle.commonModalStyle} ${commonStyle.p20}`} id="scroll">
        <Box className={`${commonStyle.cursorA} ${commonStyle.textRight}`}>
            <HighlightOff onClick={props.close} />
          </Box>
        <Box
                className={`${commonStyle.headerSearch} ${commonStyle.w100} ${commonStyle.mt10}`}
              >
                
                <TextField
                  onChange={(e) => handleSearchChange(e)}
                  placeholder={translation("search")}
                  className={commonStyle.w100}
                  // value={searchItem.searchText}
                ></TextField>
                <Search />
                </Box>
          
                      <ul className={styleClasses.modalDescTagAlt} >
                      <InfiniteScroll
                dataLength={state?.userList?.length}
                next={() => getAllfriend(false)}
                hasMore={state?.hasMore}
                scrollableTarget={"scroll"}
                loader={(() => {
                  if (allFriendListLoader) {
                    return <div className={commonStyle.textFlexCenter}>Loading...</div>;
             
                  }
                })()}
               
           

>    
          
            {state.userList.length > 0
              ? state.userList.map((user: IReceiverdata, i: number) => {
                return (
                  <li key={i} className={styleClasses.shareListDone}>
                    <Box
                      className={`${commonStyle.wallUserInfo} ${commonStyle.cursorA}`}
                    >
                      <Avatar
                        alt="story_person"
                        src={
                          user.profile_image
                            ? user.profile_image
                            : minImage
                        }
                      />
                      <Box className={commonStyle.ml10}>
                        <Typography>{user.first_name}</Typography>
                      </Box>
                    </Box>
                    <Box className={commonStyle.orangeBtn}>
                      <button
                         onClick={() =>
                            onSend(user)
                            }
                          
                      >
                        Message
                      </button>
                    </Box>
                  </li>
                );
              })
              : !allFriendListLoader ? (
                <Box className={commonStyle.textFlexCenter}>
                  {translation("no_friend")}
                </Box>) : (<Box className={commonStyle.textFlexCenter}>
                  {/* Loading... */}
                </Box>)
            }
                </InfiniteScroll>
          </ul>
      
        </Box>
        </>
      </Modal>
    </>
  );
};

export default ChatFriendList;
