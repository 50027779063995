export enum ActionType {
    FAILURE = "failure",
    LOGIN = "login",
    SIGNUP = "signup",
    UPDATEPROFILE = "update_profile",
    CREATEPOST = "create_post",
    POSTSLIST = "posts_list",
    COMMENTSLIST="comments_list",
    SOCIALLOGIN="socialLogin",
    ERROR="error",
    LOGOUTUSER="logout_user",
    PRODUCTCATEGORYLIST="product_category_list",
    Notification="Notification"
  }