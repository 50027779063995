import { Suspense } from "react";
import PrivateGuard from "./PrivateGuard";

import Header from "../components/header/Header";
import Friends from "../screens/profile/Friends";
import Home from "../screens/home/Home";
import EditProfile from "../screens/profile/EditProfile";
import Profile from "../screens/profile/Profile";
import Notifications from "../screens/profile/Notifications";
import Products from "../screens/products/Products";
import PetOfTheDay from "../screens/profile/PetOfTheDay";
import AddProduct from "../screens/products/AddProduct";
import Orders from "../screens/orders/Orders";
import AdoptionList from "../screens/adoption/AdoptionList";
import Favourites from "../screens/profile/Favourites";
import Settings from "../screens/profile/Settings";
import HelpCenter from "../screens/profile/HelpCenter";
import Stories from "../screens/profile/Stories";
import ProductDetail from "../screens/products/ProductDetail";
import Adoption from "../screens/adoption/Adoption";
import AdoptionDetail from "../screens/adoption/AdoptionDetail";
import PetLostFound from "../screens/adoption/PetLostFound";
import PetPlay from "../screens/profile/PetPlay";
import Chat from "../screens/chat/chat";
import PetLostFoundList from "../screens/adoption/PetsLostFoundList";
import PetsLostFoundDetail from "../screens/adoption/PetsLostFoundDetail";
import CreateSeller from "../screens/profile/CreateSeller";
import AdPackage from "../screens/adCenter/AdPackage";
import AdCenter from "../screens/adCenter/AdCenter";
import AdList from "../screens/adCenter/AdList";
import AddBalance from "../screens/adCenter/AddBalance";
import DashboardAdvertisment from "../screens/dashboardAdvertisment/DashboardAdvertisment";
import Cards from "../screens/cards/Cards";
import AboutUs from "../screens/profile/Aboutus";
import PaymentList from "../screens/adCenter/ViewBalance";
import PostDetail from "../screens/profile/PostDetail";
import MyProductList from "../screens/products/MyProductList";
import EditProduct from "../screens/products/EditProduct";

/**
 * Private routes are only visible for login users
 * */
function PrivateRoute(props: any) {
  return (
    <Suspense fallback={<div />}>
      <Header />
      {/* All routes mentions here */}
      <PrivateGuard
        path="/dashboard-advertisment"
        component={DashboardAdvertisment}
      />
      <PrivateGuard path="/cards" component={Cards} />
      <PrivateGuard path="/adpackages" component={AdPackage} />
      <PrivateGuard path="/home" component={Home} />
      <PrivateGuard path="/chat" component={Chat} exact />
      <PrivateGuard path="/pet-play" component={PetPlay} exact />
      <PrivateGuard path="/pet-lost-found" component={PetLostFound} exact />
      <PrivateGuard
        path="/adoption-detail/:id"
        component={AdoptionDetail}
        exact
      />
      <PrivateGuard
        path="/lost-found-detail/:id"
        component={PetsLostFoundDetail}
        exact
      />
      <PrivateGuard path="/adoption" component={Adoption} exact />
      <PrivateGuard
        path="/product-detail/:id"
        component={ProductDetail}
        exact
      />
      <PrivateGuard path="/stories" component={Stories} exact />
      <PrivateGuard path="/help-center" component={HelpCenter} exact />
      <PrivateGuard path="/Aboutus" component={AboutUs} exact />
      <PrivateGuard path="/settings" component={Settings} exact />
      <PrivateGuard path="/favourites" component={Favourites} exact />
      <PrivateGuard path="/adoption-list" component={AdoptionList} exact />
      <PrivateGuard path="/lost-found-list" component={PetLostFoundList} />
      <PrivateGuard path="/orders" component={Orders} exact />
      <PrivateGuard path="/add-product" component={AddProduct} exact />
      <PrivateGuard path="/create-seller" component={CreateSeller} exact />
      <PrivateGuard path="/pet-of-the-day/:id" component={PetOfTheDay} exact />
      <PrivateGuard path="/products" component={Products} exact />
      <PrivateGuard path="/post-detail/:post_id" component={PostDetail} exact />
      <PrivateGuard path="/notifications" component={Notifications} exact />
      <PrivateGuard path="/friends" component={Friends} exact />
      <PrivateGuard path="/edit-profile/:id" component={EditProfile} exact />
      <PrivateGuard path="/profile" component={Profile} exact />
      <PrivateGuard path="/profile/:id" component={Profile} exact />
      <PrivateGuard path="/friends/:id" component={Friends} exact />
      <PrivateGuard path="/my-product/:id" component={MyProductList} exact />
      <PrivateGuard path="/edit-product/:id" component={EditProduct} exact />

      <PrivateGuard path="/tricks" component={Home} exact />
      <PrivateGuard path="/chatRoom" component={Home} exact />
      <PrivateGuard path="/tails" component={Home} exact />
      <PrivateGuard path="/adcenter" component={AdCenter} exact />
      <PrivateGuard path="/adlist" component={AdList} exact />
      <PrivateGuard path="/edit-ad/:id" component={AdCenter} exact />
      <PrivateGuard path="/Addbalance" component={AddBalance} exact />
      <PrivateGuard path="/view-balance" component={PaymentList} exact />
    </Suspense>
  );
}

export default PrivateRoute;
