import { Box } from "@mui/system";
import {
  Avatar,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import { UserCommonStyles } from "../../styles/CommonStyles";
import { SidebarStyles } from "./Sidebar";
import defaultUserprofile from "../../images/defaultUserprofile.png";
import minImage from "../../images/minImage.png";

import { Add } from "@mui/icons-material";
import { useTranslation } from "react-i18next"
import { styled } from "@mui/material/styles";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getStoryListsbyUserId } from "../../redux/action-creators/storyAction";
import CreateStoryModal from "../commonModal/CreateStoryModal";
import { IAllUserStoryResponse, IStoriesSidebarProps } from "../../interfaceModules/IStoriesInterface";

const StoriesSidebar = (props: IStoriesSidebarProps) => {
  const { t: translation } = useTranslation();
  const Input = styled("input")({
    //  display: "none",
  });
  const commonStyle = UserCommonStyles();
  const styleClasses = SidebarStyles();
  const dispatch = useDispatch();
  const userInfo = useSelector((state: RootStateOrAny) => state.authReducer.authData);
  const [userStories, setUserStories] = useState<IAllUserStoryResponse[]>([]);
  const [openStoryModal, setOpenStoryModal] = useState(false);
  const handleOpenStory = () => setOpenStoryModal(true);
  const handleCloseStory = () => setOpenStoryModal(false);

  useEffect(() => {
    if (userStories.length === 0) {
      getStoryLists();
    }
  }, []);

  /**
 * get story list
 */
  const getStoryLists = async () => {
    let result: any = await dispatch(getStoryListsbyUserId(userInfo.id));
    if (result && result?.length > 0) {
      let myStory: any[] = [], otherStories: any[] = [];
      result.map((story: any) => {
        if (story.user_id === userInfo.id) {
          myStory = [story];
        } else {
          otherStories = [...otherStories, story];
        }
      });
      let allStories = myStory.concat(otherStories);
      setUserStories(allStories);
    }
  };

  /**
  * show story of users
  * @param userId
  * @param index
  */
  const showStoryOfUser = async (userId: number, index: number) => {
    let userStoryList = [...userStories];
    //userStoryList.splice(index,1);     
    props.showStory(userId, index, userStoryList);
  }

  return (
    <Box
      className={`${styleClasses.sidebarScroll} ${styleClasses.SidebarMain}`}
    >
      <Typography variant="h6">{translation("stories")}</Typography>
      <Box>
        <List className={styleClasses.rightList}>
          <ListItem className={styleClasses.requestHeader}>
            <Typography fontWeight="fontWeightMedium">{translation("your_story")}</Typography>
          </ListItem>
          <ListItem
            className={`${styleClasses.listAvatarSide} ${commonStyle.p0}`}
          >
            <Box className={`${commonStyle.flexCenter} ${commonStyle.cursorA}`}>
              <IconButton
                className={commonStyle.iconBtn}
                onClick={handleOpenStory}
              >
                <Add className={commonStyle.white} />
              </IconButton>
              {/* modal own story ----------- */}
              {openStoryModal ? (
                <CreateStoryModal
                  open={openStoryModal}
                  onClose={handleCloseStory}
                />
              ) : null}

              <ListItemText
                onClick={handleOpenStory}
                primary={translation("create_story")}
                secondary={translation("create_story_content")}
                className={styleClasses.avatarText}
              />
            </Box>
          </ListItem>
        </List>
        <List className={styleClasses.rightList}>
          <ListItem className={styleClasses.requestHeader}>
            <Typography fontWeight="fontWeightMedium">{translation("all_stories")}</Typography>
          </ListItem>

          {userStories.length > 0 && userStories.map((userStory, index) => (
            <ListItem className={`${styleClasses.listAvatarSide} ${commonStyle.p0} ${commonStyle.cursorA}`} key={index}>
              <ListItemAvatar>
                <Avatar className={styleClasses.requestAvatar}>
                  <img src={userStory.profile_image ? userStory.profile_image : minImage} alt={translation("req_avt")} onClick={() => showStoryOfUser(userStory.user_id, index)} />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={userStory.first_name}
                //secondary="13h"
                className={styleClasses.avatarText}
                onClick={() => showStoryOfUser(userStory.user_id, index)}
              />
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  );
};

export default StoriesSidebar;
