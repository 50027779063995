import axios from 'axios'

const baseUrl =  process.env.REACT_APP_API_URL




export const http = axios.create({
    baseURL: baseUrl,
    headers: { 'Content-Type': 'application/json' },
  })
 
  // http.interceptors.request.use((req) => {
  //   req.headers.authorization = `Bearer ${getAccessToken()}`
  
  //   return req

export function get<P>(url: string, params?: P): Promise<any> {
    return http({
      method: 'get',
      url,
      params,
    })
  }
  
  export function post<D, P>(url: string, data?: D, params?: P): any {
    return http({
      method: 'post',
      url,
      data,
      params,
    })
  }

  export function patch<D, P>(url: string, data: D, params?: P): any {
    return http({
      method: 'patch',
      url,
      data,
      params,
    })
  }