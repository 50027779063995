import Dropzone from "react-dropzone";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { RouteComponentProps } from "react-router-dom";
import React, { useEffect, useState, useRef, useMemo } from "react";

import {
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
  FormControl,
  Button,
} from "@mui/material";
import { Box } from "@mui/system";
import { HighlightOff } from "@mui/icons-material";

import imageCompression from "browser-image-compression";

import { createListofLostFound } from "../../redux/action-creators/lostFoundAction";
import { GooglePlacesDetail } from "../../redux/action-creators/profileAction";

import LeftSidebar from "../../components/sidebars/LeftSidebar";

import { isEmpty } from "../../utils/helper";
import { toast } from "../../utils/toastsMessage";
import { LostFoundValidationSchema } from "../../utils/validationSchema";
import {
  Categories,
  ImgTypeArray,
  RoutingLinks,
  locationPrediction,
} from "../../utils/constants";

import { ILostFound } from "../../interfaceModules/IPetsInteface";

import { CommonButton } from "../../stories/Button";
import { UserCommonStyles } from "../../styles/CommonStyles";

import { yupResolver } from "@hookform/resolvers/yup";

const PetsLostFoundList = (props: RouteComponentProps) => {
  const { t: translation } = useTranslation();
  const commonStyle = UserCommonStyles();
  const dispatch = useDispatch();
  const userId = localStorage.getItem("userId");

  const [imageError, setimageError] = useState("");
  const [files, setFile] = useState([]);
  const [disable, setDisable] = React.useState(false);
  const [loactionerr,setlocationerr]=useState(false)

  const [locationPredictions, setLocationPredictions] = useState({
    predictions: locationPrediction.predictions,
    location: locationPrediction.location,
    latitude: locationPrediction.latitude,
    longitude: locationPrediction.longitude,
  });
  const [timeOutState, setTimeOutState] = useState(null);
  const [checked, setChecked] = useState<string>();
  const [types, setType] = useState(); 

  const {
    handleSubmit,
    reset,
    register,    
    control,
    formState: { errors },
  } = useForm<ILostFound>({       
    resolver: yupResolver(LostFoundValidationSchema(translation)),
  });

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setLocationPredictions((prevState) => ({
            ...prevState,
            predictions: [],
          }));
        }
      }

      /**
       * Bind the event listener
       */
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        /**
         * Unbind the event listener on clean up
         */
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const handleTypeChange = (e) => {   
    setChecked(null);

    if (e.target.value) {
      setType(e.target.value);
    }
  };

  /**
   * To Remove selected images from Drag & Drop list
   * @param index
   */
  const removeImage = (index: number) => {
    const remove = [...files];
    remove.splice(index, 1);

    setFile(remove);
  };

  /**
   * To handle selcted files
   * @param file
   */
  const handleChange = async (file: string | any[]) => {
    if (file.length > 0) {
      for (let i = 0; i < file.length; i++) {
        if (file[i].type !== undefined) {
          const imgType = file[i].type.split("/");
          const MimeTypeArray = ImgTypeArray;
          if (imgType[1] !== undefined) {
            if (MimeTypeArray.includes(imgType[1])) {
              if (file && file[i]) {
                const img = file[i];
                const options = {
                  maxSizeMB: 1,
                  maxWidthOrHeight: 720,
                  useWebWorker: true,
                };
                try {
                  const compressImg = await imageCompression(img, options);
                  console.log("compressImg",compressImg)
                  readFile(compressImg);
                } catch (e) {
                  console.error("Error while compressing image : ", e);
                }
              }
              else
              {
                toast.error(translation("file_not_support_img"));
              }
            }
          }
        }
      }
    }
  };

  /**
   * To Read Selected file in base 64
   * @param file
   */
  const readFile = async (file: Blob) => {
    const fileReader = new FileReader();

    fileReader.onloadend = function () {
      if (files.length > 0) {
        setFile((prevState: string[]) => [...prevState, fileReader?.result]);
      } else {
        setFile((prevState: string[]) => [...prevState, fileReader?.result]);
      }
    };

    fileReader.readAsDataURL(file);
  };

  /**
   * Autocomplete search
   * @param text
   * @returns
   */
  const googleAutocomplete = async (text: string) => {
    if (isEmpty(text)) {
      setLocationPredictions((prevState) => ({
        ...prevState,
        predictions: [],
      }));
      return null;
    }
    if (!text) {
      return translation("valid_text");
    }

    /**
     *  for use in things like GatsbyJS where the html is generated first
     */
    if (typeof window === "undefined") {
      return translation("valid_window");
    }

    try {
      let result;
      let autocompleteService = await new google.maps.places.AutocompleteService();
      result = await autocompleteService.getPlacePredictions(
        { input: text },
        result
      );
      return result;
    } catch (e) {
      return e;
    }
  };

  /**
   * Select location
   * @param event
   */
  const selectLocation = async (event?: React.SyntheticEvent | Event) => {
    const target = event.target as HTMLInputElement;
    setLocationPredictions((prevState) => ({
      ...prevState,
      location: target.value,
    }));
    if (timeOutState) {
      clearInterval(timeOutState);
    }

    let result = setTimeout(async () => {
      let results = await googleAutocomplete(target.value);
      if (results) {
        setLocationPredictions((prevState) => ({
          ...prevState,
          latitude: 0,
          longitude: 0,
          predictions: results?.predictions,
        }));
      }
    }, 800);

    setTimeOutState(result);
  };

  /**
   * on Place selected by placeId
   * @param placeId
   * @param placeDescription
   * @param event
   * @returns
   */
  const onPlaceSelected = async (
    placeId: string,
    placeDescription: string,
    event: React.SyntheticEvent
  ) => {
    setlocationerr(false)

    event.preventDefault();
    const response: any = await dispatch(GooglePlacesDetail(placeId));

    if (!response || !response?.result) {
      return;
    }
    setLocationPredictions({
      predictions: [],
      location: placeDescription,
      latitude: response.result?.geometry?.location?.lat,
      longitude: response.result?.geometry?.location?.lng,
    });
  };
  const cancelProduct = () => {
    setLocationPredictions({
      predictions: [],
      location: "",
      latitude: 0,
      longitude: 0,
    });
    reset({     
      'breed':"",
      'color':"",
      'age':0,
      'description':"",
      'location':"",
      'name':"",
      'type':""
    });
    setFile([]);    
  };

  /**
   * To submit data
   * @param data
   * @returns
   */
  const onSubmit = async (data: ILostFound) => {
    setDisable(true);

    if (files.length === 0) {
      setimageError(translation("img_req"));
      setDisable(false);
    }

    if (locationPredictions.latitude === 0) {
      setlocationerr(true);
      setDisable(false);

    }
    if (files.length > 0 && locationPredictions.latitude!==0) {
      const response: any = await dispatch(
        createListofLostFound({
          data,
          locationPredictions,
          files,
          userId,
        })
      );
      if (response.isRegistered) {
        props.history.push(RoutingLinks.lostFound);       
        return toast.success(translation(response.message));
      } else {
        setDisable(false);
        return toast.error(translation(response.message));
      }
    }
  };

  return (
    <Box>
      <Box className={`${commonStyle.windowColor}`}>
        <Grid container>
          <Grid item md={3}>
            <LeftSidebar />
          </Grid>

          <Grid item md={9}>
            <Box className={`${commonStyle.p20} ${commonStyle.mt20}`}>
              <Typography
                variant="h6"
                className={`${commonStyle.grey} ${commonStyle.mb15} ${commonStyle.fontWeight600}`}
              >
                {translation("list_yours")}
              </Typography>
              <Box className={`${commonStyle.p20} ${commonStyle.card}`}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Grid container spacing={2}>
                    <Grid item md={12} className={commonStyle.flexCenter}>
                      <FormControl component="fieldset">
                        <Controller
                          {...register("status")}
                          rules={{ required: true }}
                          control={control}
                          defaultValue=""
                          name="status"
                          render={({ field }) => (
                            <RadioGroup
                              aria-label={translation("gender")}
                              row
                              {...field}
                            >
                              <FormControlLabel
                                value="Lost"
                                control={<Radio />}
                                onClick={handleTypeChange}
                                label={translation<string>("lost")}
                              />
                              <FormControlLabel
                                value="Found"
                                control={<Radio />}
                                onClick={handleTypeChange}
                                label={translation<string>("found")}
                              />
                            </RadioGroup>
                          )}
                        />
                      </FormControl>

                      {errors && errors.status && (
                        <span className={commonStyle.errorMsg}>
                          {errors?.status?.message}
                        </span>
                      )}
                    </Grid>
                    <Grid item md={4}>
                      <Box className={commonStyle.commonInput}>
                        <Typography className={commonStyle.labelCommonInput}>
                          {translation("pet_name")}
                        </Typography>
                        <TextField
                          {...register("name")}
                          variant="outlined"
                          className={commonStyle.w100}
                        />
                        {errors && errors.name && (
                          <span className={commonStyle.errorMsg}>
                            {errors?.name?.message}
                          </span>
                        )}
                      </Box>
                    </Grid>

                    <Grid item md={4}>
                      <Box className={commonStyle.commonInput}>
                        <Typography className={commonStyle.labelCommonInput}>
                          {translation("Type_of_Pet")}
                        </Typography>
                        <Controller
                          name="type"
                          control={control}
                          defaultValue=""
                          render={({ field }) => {

                            return <Select
                              value={field.value}
                              onChange={(e) => { field.onChange(e) }}
                              {...register("type")}
                              className={commonStyle.w100}


                            >
                              {Categories.length > 0 &&
                                Categories?.map((pet, index) => {
                                  return (
                                    <MenuItem key={index} value={pet}>
                                      {translation(pet)}
                                    </MenuItem>
                                  );
                                })}
                            </Select>

                          }}
                        />
                        {errors && errors.type && (
                          <span className={commonStyle.errorMsg}>
                            {errors?.type?.message}
                          </span>
                        )}
                      </Box>

                    </Grid>
                    {types === "Lost" ? (
                      <Grid item md={4}>
                        <Box className={commonStyle.commonInput}>
                          <Typography className={commonStyle.labelCommonInput}>
                            {translation("breed")}
                          </Typography>
                          <TextField
                            {...register("breed")}
                            variant="outlined"
                            className={commonStyle.w100}
                          />
                          {errors && errors.breed && (
                            <span className={commonStyle.errorMsg}>
                              {errors?.breed?.message}
                            </span>
                          )}
                        </Box>
                      </Grid>
                    ) : null}

                    <Grid item md={12}>
                      <Box className={commonStyle.commonInput}>
                        <Typography className={commonStyle.labelCommonInput}>
                          {translation("description")}
                        </Typography>
                        <TextField
                          {...register("description")}
                          multiline
                          variant="outlined"
                          className={commonStyle.w100}
                          rows={5}
                          placeholder={translation("write_something")}
                        />
                        {errors && errors.description && (
                          <span className={commonStyle.errorMsg}>
                            {errors?.description?.message}
                          </span>
                        )}
                      </Box>
                    </Grid>

                    <Grid item md={4}>
                      <Box className={commonStyle.commonInput}>
                        <Typography className={commonStyle.labelCommonInput}>
                          {translation("color")}
                        </Typography>
                        <TextField
                          {...register("color")}
                          variant="outlined"
                          className={commonStyle.w100}
                        />
                        {errors && errors.color && (
                          <span className={commonStyle.errorMsg}>
                            {errors?.color?.message}
                          </span>
                        )}
                      </Box>
                    </Grid>

                    {types === "Lost" ? (
                      <Grid item md={4}>
                        <Box className={commonStyle.commonInput}>
                          <Typography className={commonStyle.labelCommonInput}>
                            {translation("age")}
                          </Typography>
                          <TextField
                            {...register("age")}
                            variant="outlined"
                            type="number"
                            className={commonStyle.w100}
                            InputProps={{ inputProps: { min: 1 } }}
                          />
                          {errors && errors.age && (
                            <span className={commonStyle.errorMsg}>
                              {errors?.age?.message}
                            </span>
                          )}
                        </Box>
                      </Grid>
                    ) : null}

                    <Grid item md={12}>
                      <Box className={commonStyle.commonInput}>
                        <Typography className={commonStyle.labelCommonInput}>
                          {translation("location")}
                        </Typography>
                        <TextField
                          {...register("location")}
                          variant="outlined"
                          autoComplete="off"
                          className={commonStyle.w100}
                          onChange={selectLocation}
                          value={locationPredictions.location}
                        />

                        {locationPredictions.predictions.length !== 0 && (
                          <div className="search-drop-list" ref={wrapperRef}>
                            <ul className={commonStyle.searchCommonList}>
                              {locationPredictions.predictions.map(
                                (prediction) => (
                                  <li
                                    key={prediction?.place_id}
                                    onClick={(e) =>
                                      onPlaceSelected(
                                        prediction?.place_id,
                                        prediction?.description,
                                        e
                                      )
                                    }
                                  >
                                    {prediction?.description ||
                                      translation("not_found")}
                                  </li>
                                )
                              )}
                            </ul>
                          </div>
                        )}
                     {!locationPredictions.location?<span className={commonStyle.errorMsg}>{errors?.location?.message}</span>:loactionerr?<span className={commonStyle.errorMsg}>{"Please select a valid location"}</span>:""}

                        {/* {!locationPredictions.location && (
                          <span className={commonStyle.errorMsg}>
                            {errors?.location?.message}
                          </span>
                        )} */}
                      </Box>
                    </Grid>

                    <Grid item md={12}>
                      <Box className={commonStyle.commonInput}>
                        <Typography className={commonStyle.labelCommonInput}>
                          {translation("upload_image")}
                        </Typography>
                        <Box className={`${commonStyle.commonUploadBox}`}>
                          <Dropzone onDrop={(files) => handleChange(files)}>
                            {({ getRootProps, getInputProps }) => (
                              <div className="container">
                                <div
                                  {...getRootProps({ className: "dropzone" })}
                                >
                                  <p>{translation("drop_box_content")}</p>
                                  <input {...getInputProps()} />
                                </div>
                                {files.length > 0 && (
                                  <ul
                                    className={`${commonStyle.flexCenter} ${commonStyle.removeListStyle} ${commonStyle.dragList}`}
                                  >
                                    {files.length > 0 &&
                                      files.map((item, index) => (
                                        <li key={index}>
                                          <img
                                            src={item}
                                            alt={translation("altnull")}
                                            className={
                                              commonStyle.imageDragDrop
                                            }
                                          />
                                          <HighlightOff
                                            onClick={() => removeImage(index)}
                                          />
                                        </li>
                                      ))}
                                  </ul>
                                )}
                              </div>
                            )}
                          </Dropzone>
                        </Box>
                        {files.length === 0 && (
                          <span className={commonStyle.errorMsg}>
                            {imageError}
                          </span>
                        )}
                      </Box>
                    </Grid>
                  </Grid>

                  <Box className={commonStyle.flexCenter}>
                    <Box
                      className={`${commonStyle.mr10} ${commonStyle.blueBtn}`}
                    >
                      <Button
                        disabled={disable}
                        onClick={handleSubmit(onSubmit)}
                      >
                        {translation("upload")}
                      </Button>
                    </Box>
                    <Box
                      className={`${commonStyle.mr10} ${commonStyle.greyBtn}`}
                    >
                      <CommonButton
                        onClick={cancelProduct}
                        label={translation("cancel")}
                      />
                    </Box>
                  </Box>
                </form>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default PetsLostFoundList;
