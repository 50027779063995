import axios from "../../services/axiosService";
import { Dispatch } from "redux";
import { ActionType } from "../action-types/index";
import { Action } from "../actions/AuthAction";
const URL = process.env.REACT_APP_API_URL;
export const createListofLostFound = (data: any) => async (
  dispatch: Dispatch<Action>
) => {
  try {
    const response = await axios.post(`${URL}/api/v1/pet/add-lost-found`, data);
    return response.data.data;
  } catch (err) {
    dispatch({
      type: ActionType.FAILURE,
    });
  }
};

/**
 * list of lost and found pets
 * @param options
 * @param type
 * @param distance
 * @param id
 * @returns
 */
export const listLostFoundpets = (
  options: string,
  type: string,
  distance: string,
  id: string,
  offset:number
) => async (dispatch: Dispatch<Action>) => {
  try {
    const response = await axios.get(
      `${URL}/api/v1/pet/list-lost-found/${id}?searchText=${options}&type=${type}&distance=${distance}&offset=${offset}`
    );

    return response.data.data.data;
  } catch (err) {
    dispatch({
      type: ActionType.FAILURE,
    });
  }
};
