import React, { useEffect, useState, useRef, useCallback } from "react";
import imageCompression from "browser-image-compression";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import Calendar from "react-calendar";

import {
  Checkbox,
  Button,
  Grid,
  Input,
  MenuItem,
  Select,
  TextField,
  Typography,
  FormControlLabel,
  FormControl,
  Radio,
  RadioGroup,
  Modal,
} from "@mui/material";
import { Box } from "@mui/system";

import {
  getProfile,
  GooglePlacesDetail,
  updateProfile,
} from "../../redux/action-creators/profileAction";

import LeftSidebar from "../../components/sidebars/LeftSidebar";
import RightSidebar from "../../components/sidebars/RightSidebar";

import { isEmpty } from "../../utils/helper";
import { ImgTypeArray } from "../../utils/constants";
import { toast } from "../../utils/toastsMessage";
import { profileValidationSchema } from "../../utils/validationSchema";
import {
  RoutingLinks,
  locationPrediction,
  Categories,
  gender,
  Type,
} from "../../utils/constants";

import { IEditProfile } from "../../interfaceModules/IUserInterface";
import { IUserData } from "../../interfaceModules/ICommonInteface";

import moment from "moment";
import { CommonButton } from "../../stories/Button";
import { yupResolver } from "@hookform/resolvers/yup";

import { UserCommonStyles } from "../../styles/CommonStyles";
import { ProfileStyle } from "./ProfileStyle";

import "react-calendar/dist/Calendar.css";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#fff",
  border: "2px solid #000",
  p: 4,
};

const EditProfile = () => {
  const styleClasses = ProfileStyle();
  const [change_cover, setChange_cover] = useState(false);
  const [profie_change, setProfile_change] = useState(false);
  const [showModal,SetShowModal]=useState(false)


  const history = useHistory();
  const { t: translation } = useTranslation();
  const id = Number(useParams<{ id: string }>().id);
  const userId = Number(localStorage.getItem("userId"));

  const commonStyle = UserCommonStyles();
  const [profile_image, setprofile_image] = useState();
  const [coverImage, setCoverImage] = useState();

  const [profileFile, setprofileFile] = useState<string[]>();
  const [coverFile, setcoverFile] = useState<string[]>();
  const [userData, setUserData] = useState<IUserData[]>([]);
  const [boDDate, setBoDDate]: any = useState("");
  const [boDDateError, setBoDDateError] = useState("");
  const [locationerr, setlocationerr] = useState(false);

  const [locationPredictions, setLocationPredictions] = useState({
    predictions: locationPrediction.predictions,
    location: locationPrediction.location,
    latitude: locationPrediction.latitude,
    longitude: locationPrediction.longitude,
  });
  const [disable, setDisable] = React.useState(false);
  const [timeOutState, setTimeOutState] = useState(null);
  const [checked, setChecked] = useState<string>("");
  const [types, setType] = useState();

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const dispatch = useDispatch();
  const {
    handleSubmit,
    register,
    getValues,
    control,
    formState: { errors },
  } = useForm<IEditProfile>({
    resolver: yupResolver(profileValidationSchema(translation)),
  });
    console.log("VALUES",errors)
  /**
   * get user data
   * @param user
   */
  const getUserData = useCallback(
    async (user: number) => {
      let result: any = await dispatch(getProfile(user));
      if (result && result?.length > 0) {
        setUserData(result);

        if (result[0].dob) {
          // let date = moment(result[0].dob).format("yyyy-MM-DD");
          // setBoDDate(date);
          const localOffsetInHours = -(moment().utcOffset() / 60);
          setBoDDate(
            moment(result[0].dob)
              .clone()
              .add(localOffsetInHours, "hours")
              .toDate()
          );
        }
        setLocationPredictions((prevState) => ({
          ...prevState,
          location: result[0].address,
          latitude: result[0].latitude,
          longitude: result[0].longitude,
        }));
        if (result[0].surgical_procedure) {
          setChecked(result[0].surgical_procedure);
        }
        if (result[0].type) {
          setType(result[0].category);
        }

        setprofile_image(
          result[0].profile_image.substring(
            result[0].profile_image.lastIndexOf("/") + 1
          )
        );
        setCoverImage(
          result[0].cover_image.substring(
            result[0].cover_image.lastIndexOf("/") + 1
          )
        );
        setprofileFile((prevState) => ({
          ...prevState,
          profileImage: result[0].profile_image,
        }));
        setcoverFile((prevState) => ({
          ...prevState,
          coverImage: result[0].cover_image,
        }));
      }
    },
    [dispatch]
  );
  useEffect(() => {
    getUserData(id);
  }, [getUserData, id]);

  function useOutsideAlerter(ref: React.MutableRefObject<any>) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event: { target: any }) {
        if (ref.current && !ref.current.contains(event.target)) {
          setLocationPredictions((prevState) => ({
            ...prevState,
            predictions: [],
          }));
        }
      }

      /**
       * Bind the event listener
       */
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        /**
         * Unbind the event listener on clean up
         */
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  /**
   * Select the file which user selects
   * @param e
   */
  const fileSelectHandler = async (e: any) => {
    

    if (e.target.files[0] !== undefined)
      for (let i = 0; i < e.target.files.length; i++) {
        if (e.target.files[i].type !== undefined) {
          const imgType = e.target.files[i].type.split("/");
          const MimeTypeArray = ImgTypeArray;
          if (imgType[1] !== undefined) {
            if (MimeTypeArray.includes(imgType[1])) {
              if (e.target.name === "profile_image") {
                console.log("profile_Image",e.target.files[0].name.split(".")[0])
                setprofile_image(e.target.files[0].name.split(".")[0]);
              } else {
                setCoverImage(e.target.files[0].name.split(".")[0]);
              }
              if (e.target && e.target.files[i]) {
               
                  const img = e.target.files[i];
                  console.log("image",img)
                  const options = {
                    maxSizeMB: 1,
                    maxWidthOrHeight: 720,
                    useWebWorker: true,
                  };
                  try {
                    console.log("IMAGE",img)
                    const compressImg = await imageCompression(img, options);
                    console.log("compressImg",compressImg)
                    readFile(compressImg, e.target.name);
                  } catch (e) {
                    console.error("Error while compressing image : ", e);
                  }
                  // readFile(img, e.target.name);
           
              }
            }
            else
            {
              toast.error(translation("file_not_support_img"));
            }
          }
        }
      }
  };

  /**
   * Read the file data which user selects
   * @param file
   * @param name
   */
  const readFile = async (file: Blob, name: string) => {
    /**
     * Create an instance of FileReader
     */
    console.log("FILESS",file)
    const fileReader = new FileReader();
    if (name === "profile_image") {
      console.log("profile_image",fileReader.result)
      setProfile_change(true);
      fileReader.onloadend = (event) =>
        setprofileFile((prevState) => ({
          ...prevState,
          profileImage: fileReader.result,
        }));
    } else {
      setChange_cover(true);
      console.log("fileReader.result",fileReader.result)
      fileReader.onloadend = (event) =>
        setcoverFile((prevState) => ({
          ...prevState,
          coverImage: fileReader.result,
        }));
    }

    fileReader.readAsDataURL(file);

    /**
     * Start reading the file
     */
  };

  /**
   * google auto complete the location
   * @param text
   * @returns
   */
  const googleAutocomplete = async (text: string) => {
    if (isEmpty(text)) {
      setLocationPredictions((prevState) => ({
        ...prevState,
        predictions: [],
      }));
      return null;
    }
    if (!text) {
      return translation("valid_text");
    }

    /**
     * for use in things like GatsbyJS where the html is generated first
     */
    if (typeof window === "undefined") {
      return translation("valid_window");
    }

    try {
      let result;
      let autocompleteService = await new google.maps.places.AutocompleteService();
      result = await autocompleteService.getPlacePredictions(
        { input: text },
        result
      );
      return result;
    } catch (e) {
      return e;
    }
  };

  /**
   * select location
   * @param event
   */
  const selectLocation = async (event: React.SyntheticEvent | Event) => {
    const target = event.target as HTMLInputElement;
    setLocationPredictions((prevState) => ({
      ...prevState,
      location: target.value,
    }));
    if (timeOutState) {
      clearInterval(timeOutState);
    }

    let result = setTimeout(async () => {
      let results = await googleAutocomplete(target.value);
      if (results) {
        setLocationPredictions((prevState) => ({
          ...prevState,
          latitude: 0,
          longitude: 0,
          predictions: results?.predictions,
        }));
      }
    }, 800);

    setTimeOutState(result);
  };

  /**
   * on Place selected by placeId
   * @param placeId
   * @param placeDescription
   * @param event
   * @returns
   */
  const onPlaceSelected = async (
    placeId: string,
    placeDescription: string,
    event: React.SyntheticEvent
  ) => {
    setlocationerr(false);

    event.preventDefault();
    const response: any = await dispatch(GooglePlacesDetail(placeId));
    if (!response || !response.result) {
      return;
    }
    setLocationPredictions({
      predictions: [],
      location: placeDescription,
      latitude: response.result?.geometry?.location?.lat,
      longitude: response.result?.geometry?.location?.lng,
    });
  };
  const handleTypeChange = (e) => {
    setChecked(null);

    if (e.target.value) {
      setType(e.target.value);
    }
  };

  /**
   * cancel product
   */
  const cancelProduct = () => {
    if (
      userData[0]?.breed?.length === 0 ||
      userData[0]?.description?.length === 0
    ) {
      return toast.error(translation("fill_profile"));
    } else {
      history.push(RoutingLinks.profiles);
    }
  };

  const handleChangeSlider = (e) => {
    if (e.target.value === "") {
      setChecked("spayed");
    } else {
      setChecked("");
    }
  };

  const handleDateChange = (e, key) => {
    const value = moment(e).format("yyyy-MM-DD");
    setBoDDate(value);
  };

  const validateDateOfBirth = () => {
    let isFormValid = true;

    if (boDDate.length === 0) {
      isFormValid = false;
      setBoDDateError(translation("date_req"));
    }
    return isFormValid;
  };


  const showImageModal=()=>{
   
    
    if( 
      profile_image==="")
    {
      console.log("SHSHSHS")
     SetShowModal(true)
    }
    else
    {
      handleSubmit(onSubmit)();
  
      
   
    }
  }

  /**
   * submit data
   * @param data
   * @returns
   */
  const onSubmit = async (data: IEditProfile) => {
    console.log("dataaaaaa",data)
    setDisable(true);
    if (
      locationPredictions.location === "" ||
      locationPredictions.latitude === 0
    ) {
      console.log("locaton>>>>>>>>>>>>>>>>>>>>", locationPredictions);

      setlocationerr(true);
      setDisable(false);
    }

    const {
      breed,
      bio,
      email,
      type,
      phone_number,
      gender,
      surgical_procedure,
    } = data;

    const bodDate = moment(boDDate).format("yyyy-MM-DD");

    if (
      locationPredictions.location !== "" &&
      locationPredictions.latitude !== 0
    ) {
      if (validateDateOfBirth()) {
        const response: any = await dispatch(
          updateProfile(
            {
              breed,
              bio,
              boDDate: bodDate,
              email,
              type,
              phone_number,
              coverFile,
              profileFile,
              profile_image,
              coverImage,
              gender,
              change_cover,
              profie_change,
              locationPredictions,
              surgical_procedure,
            },
            userId
          )
        );

        if (response.isUpdated) {
          let result: any = await dispatch(getProfile(id));
          setUserData(result);

          if (result && result.length > 0) {
            localStorage.setItem("userData", JSON.stringify(result[0]));
          }
          setDisable(false);
          history.push(RoutingLinks.userProfile);
          return toast.success(translation(response.message));
        } else if (response.code === "ER_DUP_ENTRY") {
          setDisable(false);
          return toast.error(translation(response.message));
        } else {
          setDisable(false);
          return toast.error(translation(response.message));
        }
      }
      setDisable(false);
    }
  };
  const showImageError=(value:string)=>{
    SetShowModal(false);
    if(value==="yes")
    {
      handleSubmit(onSubmit)();
    }
  }


  return (
    <Box>
      <Grid container className={commonStyle.windowColor}>
        <Grid item md={3}>
          <LeftSidebar />
        </Grid>
        <Grid item md={6}>
          <Box className={`${commonStyle.p20} ${commonStyle.mt20}`}>
            <Typography
              variant="h6"
              className={`${commonStyle.grey} ${commonStyle.mb15} ${commonStyle.fontWeight600}`}
            >
              {translation("edit_profile")}
            </Typography>
            {userData.length > 0 &&
              userData.map((item, index) => (
                <Box className={`${commonStyle.p20} ${commonStyle.card}`}>
                  <form onSubmit={() => showImageModal()} noValidate>
                    <Box className={commonStyle.commonInput}>
                      <Typography className={commonStyle.labelCommonInput}>
                        {translation("profile_photo")}
                      </Typography>
                      <Box className={commonStyle.uploadBoxOutline}>
                        <Box className={commonStyle.uploadBtn}>
                          <Input
                            type="file"
                            inputProps={{
                              accept: "image/*",
                            }}
                            onChange={fileSelectHandler}
                            name="profile_image"
                          />
                          <Typography>{translation("browse")}</Typography>
                        </Box>
                        <Typography
                          className={`${commonStyle.flexCenter} ${commonStyle.h100} ${commonStyle.px15}`}
                        >
                          {profile_image}
                        </Typography>
                      </Box>
                      {/* <span className={commonStyle.errorMsg}>Please choose file</span> */}
                    </Box>

                    <Box className={commonStyle.commonInput}>
                      <Typography className={commonStyle.labelCommonInput}>
                        {translation("cover_photo")}
                      </Typography>
                      <Box className={commonStyle.uploadBoxOutline}>
                        <Box className={commonStyle.uploadBtn}>
                          <Input
                            type="file"
                            inputProps={{
                              accept: "image/*",
                            }}
                            onChange={fileSelectHandler}
                            name="cover_image"
                          />
                          <Typography>{translation("browse")}</Typography>
                        </Box>
                        <Typography
                          className={`${commonStyle.flexCenter} ${commonStyle.h100} ${commonStyle.px15}`}
                        >
                          {coverImage}
                        </Typography>
                      </Box>
                      {/* <span className={commonStyle.errorMsg}>Please choose file</span> */}
                    </Box>

                    <Grid container spacing={2}>
                      <Grid item md={6}>
                        <Box className={commonStyle.commonInput}>
                          <Typography className={commonStyle.labelCommonInput}>
                            {translation("Type_of_Pet")}
                          </Typography>
                          <Select
                            {...register("type")}
                            className={commonStyle.w100}
                            onChange={handleTypeChange}
                            defaultValue={item.category}
                            value={types}
                          >
                            {Categories.length > 0 &&
                              Categories?.map((pet, index) => {
                                return (
                                  <MenuItem
                                    key={index}
                                    value={translation(pet)}
                                  >
                                    {translation(pet)}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                          {errors && errors.type && (
                            <span className={commonStyle.errorMsg}>
                              {errors?.type?.message}
                            </span>
                          )}
                        </Box>
                      </Grid>
                      <Grid item md={6}>
                        <Box className={commonStyle.commonInput}>
                          <Typography className={commonStyle.labelCommonInput}>
                            {translation("gender")}
                          </Typography>
                          <Select
                            {...register("gender")}
                            className={commonStyle.w100}
                            defaultValue={item.gender}
                          >
                            {gender.length > 0 &&
                              gender?.map((genderType, index) => {
                                return (
                                  <MenuItem
                                    key={index}
                                    value={translation(genderType)}
                                    defaultValue={item.gender}
                                  >
                                    {translation(genderType)}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                          {errors && errors.gender && (
                            <span className={commonStyle.errorMsg}>
                              {errors?.gender?.message}
                            </span>
                          )}
                        </Box>
                      </Grid>

                      <Grid item md={6}>
                        <Box className={commonStyle.commonInput}>
                          <Typography className={commonStyle.labelCommonInput}>
                            {translation("breed")}
                          </Typography>
                          <TextField
                            {...register("breed")}
                            variant="outlined"
                            className={commonStyle.w100}
                            // value={item.breed}
                            defaultValue={item?.breed}
                          />
                          {errors && errors.breed && (
                            <span className={commonStyle.errorMsg}>
                              {errors?.breed?.message}
                            </span>
                          )}
                        </Box>
                      </Grid>

                      {types === Type.cat || types === Type.dog ? (
                        <Grid item md={6} className={commonStyle.flexCenter}>
                          <FormControl component="fieldset">
                            <FormControlLabel
                              {...register("surgical_procedure")}
                              value={checked}
                              control={<Checkbox />}
                              label={translation<string>("spayed")}
                              onChange={handleChangeSlider}
                              checked={checked === "spayed" ? true : false}
                            />
                          </FormControl>
                        </Grid>
                      ) : null}

                      <Grid item md={12}>
                        <Box className={commonStyle.commonInput}>
                          <Typography className={commonStyle.labelCommonInput}>
                            {translation("bio")}
                          </Typography>
                          <TextField
                            {...register("bio")}
                            multiline
                            variant="outlined"
                            className={commonStyle.w100}
                            rows={5}
                            defaultValue={item.description}
                          />
                          {errors && errors.bio && (
                            <span className={commonStyle.errorMsg}>
                              {errors?.bio?.message}
                            </span>
                          )}
                        </Box>
                      </Grid>
                      {console.log(
                        "locationPredictions>>>>>>>>>>>>>>>>>",
                        locationPredictions.location
                      )}
                      <Grid item md={12}>
                        <Box className={commonStyle.commonInput}>
                          <Typography className={commonStyle.labelCommonInput}>
                            {translation("location")}
                          </Typography>
                          <TextField
                            {...register("location")}
                            variant="outlined"
                            autoComplete="off"
                            className={commonStyle.w100}
                            onChange={selectLocation}
                            value={locationPredictions.location}
                          />

                          {locationPredictions.predictions.length !== 0 && (
                            <div className="search-drop-list" ref={wrapperRef}>
                              <ul className={commonStyle.searchCommonList}>
                                {locationPredictions.predictions.map(
                                  (prediction) => (
                                    <li
                                      key={prediction?.place_id}
                                      onClick={(e) =>
                                        onPlaceSelected(
                                          prediction?.place_id,
                                          prediction?.description,
                                          e
                                        )
                                      }
                                    >
                                      {prediction?.description ||
                                        translation("not_found")}
                                    </li>
                                  )
                                )}
                              </ul>
                            </div>
                          )}
                          {console.log("errrrrrrrrrrrrrrrrrr", locationerr)}

                          {!locationPredictions.location ? (
                            <span className={commonStyle.errorMsg}>
                              {"Please select a location"}
                            </span>
                          ) : locationerr ? (
                            <span className={commonStyle.errorMsg}>
                              {"Please select a valid location"}
                            </span>
                          ) : (
                            ""
                          )}

                          {/* {!locationPredictions.location && (
                            <span className={commonStyle.errorMsg}>
                              {errors?.location?.message}
                            </span>
                          )} */}
                        </Box>
                      </Grid>

                      <Grid item md={6}>
                        <Box className={commonStyle.commonInput}>
                          <Typography className={commonStyle.labelCommonInput}>
                            {translation("email")}
                          </Typography>
                          {/* <fieldset disabled> */}
                          <TextField
                            {...register("email")}
                            inputProps={{ readOnly: true }}
                            variant="outlined"
                            className={commonStyle.w100}
                            value={item.email}
                          />
                          {/* </fieldset> */}
                          {errors && errors.email && (
                            <span className={commonStyle.errorMsg}>
                              {errors?.email?.message}
                            </span>
                          )}
                        </Box>
                      </Grid>

                      <Grid item md={6}>
                        <Box className={commonStyle.commonInput}>
                          <Typography className={commonStyle.labelCommonInput}>
                            {translation("contact")}
                          </Typography>
                          <TextField
                            {...register("phone_number")}
                            variant="outlined"
                            className={commonStyle.w100}
                            defaultValue={item.phone_number}
                            inputProps={{ maxLength: 10 }}
                          />
                          {errors && errors.phone_number && (
                            <span className={commonStyle.errorMsg}>
                              {errors?.phone_number?.message}
                            </span>
                          )}
                        </Box>
                      </Grid>

                      <Grid item md={12}>
                        <Box className={commonStyle.commonInput}>
                          <Typography className={commonStyle.labelCommonInput}>
                            {translation("dob")}
                          </Typography>

                          <Calendar
                            style={{ height: 500 }}
                            onChange={(event) =>
                              handleDateChange(event, "date")
                            }
                            value={boDDate !== "" && moment(boDDate).toDate()}
                            maxDate={new Date()}
                          ></Calendar>

                          {boDDateError && boDDateError.length > 0 && (
                            <span className={commonStyle.errorMsg}>
                              {boDDateError}
                            </span>
                          )}
                        </Box>
                      </Grid>
                    </Grid>

                    <Box className={commonStyle.flexCenter}>
                      <Box
                        className={`${commonStyle.mr10} ${commonStyle.blueBtn}`}
                      >
                        <Button
                          disabled={disable}
                          onClick={() => showImageModal()}
                        >
                          {translation("save")}
                        </Button>
                      </Box>
                      <Box
                        className={`${commonStyle.mr10} ${commonStyle.greyBtn}`}
                      >
                        <Button onClick={cancelProduct}>
                          {translation("cancel")}
                        </Button>
                      </Box>
                    </Box>
                  </form>
                </Box>
              ))}
          </Box>
        </Grid>
        <Modal
            open={showModal}
            // onClose={props?.modalhandleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className={styleClasses.modalShadow}
          >
            <Box 
            sx={style}
            >
              <Typography id="modal-modal-title" variant="h6" component="h2">
              {translation("Profile_image_message")}
              </Typography>
              <Box
                id="modal-modal-description"
                sx={{ mt: 2 }}
                className={commonStyle.flexCenter}
              >
                <Box
                  className={`${commonStyle.blueBtn} ${commonStyle.pt20} ${commonStyle.mr10}`}
                >
                  
                    <Button
                      onClick={()=>{showImageError("yes")}}
                    >
                      {" "}
                      {translation("yes")}
                    </Button>
                 
                  
                   
                   
                 
                </Box>

                <Box className={`${commonStyle.blueBtn} ${commonStyle.pt20}`}>
                  <Button 
                onClick={()=>{showImageError("no")}}
                  >
                    {translation("no")}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Modal>
        <Grid item md={3}>
          <RightSidebar />
        </Grid>
      </Grid>
    </Box>
  );
};

export default EditProfile;
