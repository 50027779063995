import Picker from "emoji-picker-react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { IEmojiData } from "emoji-picker-react";
import { useParams, Link } from "react-router-dom";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, RootStateOrAny, useSelector } from "react-redux";

import { Box } from "@mui/system";
import {
  Avatar,
  Button,
  Grid,
  IconButton,
  Popover,
  Tooltip,
  Typography,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import {
  Comment,
  ImageOutlined,
  LocalOffer,
  MoreHoriz,
  SendOutlined,
  SentimentSatisfiedOutlined,
  SentimentVerySatisfiedOutlined,
  Share,
  ThumbUpAlt,
} from "@mui/icons-material";

import {
  getAllCommentsLists,
  addCommentOnThePost,
  addLikeOnThePost,
  deletePostById,
  likeReactionListsOnPosts,
  getTaggedUserDetails,
  getAllChildCommentLists,
  postListsbyUserId,
  updatePostById,
} from "../../redux/action-creators/postAction";
import { sendNotification } from "../../redux/action-creators/petPlayAction";

import { toast } from "../../utils/toastsMessage";
import {
  RoutingLinks,
  NewLineRegex,
  ImgTypeArray,
  showreplystate,
  editPostIdstate,
  commentOnPoststate,
} from "../../utils/constants";
import { getTimeDiff, capitalize, isJsonString } from "../../utils/helper";

import CommonPopover from "../commonModal/CommonPopover";
import CommonUserDetailModal from "../../components/commonModal/CommonUserDetailModal";
import BasicModal from "../commonModal/CommonModal";

import defaultUserprofile from "../../images/defaultUserprofile.png";
import minImage from "../../images/minImage.png";
import likelcon from "../../images/likeIcon.jpg";

import { UserCommonStyles } from "../../styles/CommonStyles";
import { HomeStyles } from "../../styles/HomeStyles";
import { addAdActionViewAndClick } from "../../redux/action-creators/adAction";
import SharePopover from "../commonModal/SharePopover";

const CommonCard: React.FC<any> = (props) => {
  const { allPost, allPostComments, postType } = props;
  const dispatch = useDispatch();
  let location = useLocation();
  const { id }: { id: string } = useParams();
  const { t: translation } = useTranslation();
  const commonStyle = UserCommonStyles();
  const styleClasses = HomeStyles();
  const handleOpen = () => setOpen(true);
  const Input = styled("input")({
    display: "none",
  });
  const [showReplyBar, setShowReplyBar] = useState({
    showBar: false,
    parent_id: showreplystate.parent_id,
    post_id: showreplystate.post_id,
  });
  const [disableDeletePostButton, setDisableDeletePostButton] = useState(false);
  const [disableEditPostButton, setDisableEditPostButton] = useState(false);
  const [editDescription, setEditDescription] = React.useState("");
  const [disable, setdisable] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const open = Boolean(anchorEl);
  const idEdit = open ? translation("simple_pop") : undefined;

  const [allComments, setAllComments] = useState(allPostComments);
  const [allPosts, setAllPosts] = useState(allPost);
  // const [adData, setAdData] = useState<any[]>([]);

  const userInfo = useSelector((state: RootStateOrAny) => {
    let userData = state.authReducer.authData;
    if (isJsonString(state.authReducer.authData)) {
      userData = JSON.parse(state.authReducer.authData);
    }
    return userData;
  });
  const [allChildCommentsbyId, setAllChildCommentsbyId] = useState([]);

  const [openTag, setTagOpen] = React.useState(false);
  const [taggedUserDetails, setTaggegUserDetails] = useState([]);

  const [reportPostId, setreportPostId] = useState<number>();

  const [
    anchorExpression,
    setAnchorExpression,
  ] = React.useState<HTMLButtonElement | null>(null);
  const openExpression = Boolean(anchorExpression);
  const [openLike, setLikeOpen] = React.useState(false);
  const [likedPeopleUserDetails, setLikedPeopleUserDetails] = useState([]);

  const [editPostId, setEditPostId] = useState({
    postId: editPostIdstate.postId,
    postIndex: editPostIdstate.postIndex,
    userReaction: editPostIdstate.userReaction,
    post_userId: editPostIdstate.post_userId,
  });
  const [modalopen, setOpen] = React.useState(false);
  const modalhandleClose = () => setOpen(false);

  const [openImageModal, setOpenImageModal] = useState<{
    show: boolean;
    images: string[];
  }>({
    show: false,
    images: [],
  });

  const [showAddComment, setShowAddComment] = useState(false);

  const [commentOnPost, setCommentOnPost] = useState({
    showEmojiPicker: false,
    post_id: commentOnPoststate.post_id,
    comment: commentOnPoststate.comment,
    file: null,
    parent_id: commentOnPoststate.parent_id,
    post_userId: commentOnPoststate.post_userId,
  });
  const userId: number = Number(localStorage.getItem("userId"));
  const [showModalShare, setShowShareModal] = useState(false);
  const [shareAblePost, setShareAblePost] = useState<any>();

  const emojiRef = useRef(null);
  useOutsideAlerter(emojiRef);

  useEffect(() => {
    // setAdData(allAdsData);
    setAllPosts(allPost);
    setAllComments(allPostComments);
  }, [allPost, allPostComments]);

  /**
   * To get the reported post id
   * @param id
   */
  const getReportedId = (id: number) => {
    const index = allPost.findIndex((post) => post?.id === id);
    allPost[index]["reportUserId"] = userId;
    handleClose();
  };
  /**
   * Handle click
   * @param event
   * @param postId
   */
  const handleClicks = (
    event: React.MouseEvent<HTMLButtonElement>,
    postId: number
  ) => {
    setAnchorEl(event.currentTarget);
    setreportPostId(postId);
  };
  /**
   *Alert if clicked on outside of element
   */
  function useOutsideAlerter(ref: React.MutableRefObject<any>) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setCommentOnPost((prevState) => ({
            ...prevState,
            showEmojiPicker: false,
          }));
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  /**
   * When user clicks on any
   */
  const handleLikeOpen = (post_id: number) => {
    setLikeOpen(true);
    getAllLikeReactionsOnPost(post_id);
  };

  /**
   * when like popup closes
   */
  const handleLikeClose = () => {
    setLikeOpen(false);
    setLikedPeopleUserDetails([]);
  };

  /**
   * handle tag open
   */
  const handleTagOpen = (userIds: []) => {
    getAllTaggedUserDetails(userIds);
    setTagOpen(true);
  };

  /**
   * handle tag close
   */
  const handleTagClose = () => {
    setTagOpen(false);
    setTaggegUserDetails([]);
  };

  /**
   * handle click of expression opens
   */
  const handleExpressionClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    postId: number,
    index: number,
    reaction: string,
    post_userId: number
  ) => {
    setAnchorExpression(event.currentTarget);
    setEditPostId({
      postId: postId,
      postIndex: index,
      userReaction: reaction ? reaction : "0",
      post_userId: post_userId,
    });
  };

  /**
   * handle click of expression closes
   */
  const handleExpressionClose = () => {
    setAnchorExpression(null);
    setEditPostId({
      postId: 0,
      postIndex: 0,
      userReaction: "0",
      post_userId: 0,
    });
  };

  /**
   * edit post
   */
  const setEditPost = () => {
    setAllPosts(
      allPosts.map((i: { id: number }) => ({
        ...i,
        isEdit: i.id === editPostId.postId,
      }))
    );
    handleClose();
  };

  /**
   * Set image for view Modal
   */
  const setImageForViewModal = (galleryImages) => {
    let allImages = [...galleryImages];
    //allImages.splice(0, 2);
    setOpenImageModal({ show: true, images: allImages });
  };

  /**
   * Retrieve all the posts
   */
  const getAllPosts = async (profileId: number) => {
    const response: any = await dispatch(postListsbyUserId(profileId, userId));
    if (response) {
      setAllPosts(
        response.map((i) => ({
          ...i,
          isEdit: false,
        }))
      );
    }
  };

  /**
   * To get all child comments with given comment id
   */
  const getAllChildComments = async (
    commentId: number,
    commentPostId: number,
    childComments: boolean
  ) => {
    if (childComments) {
      const response: any = await dispatch(
        getAllChildCommentLists(postType, commentId)
      );
      if (response && response.length > 0) {
        let data = [...response];
        setAllChildCommentsbyId(data);
        setShowReplyBar({
          showBar: true,
          parent_id: commentId,
          post_id: commentPostId,
        });
      }
    } else {
      setAllChildCommentsbyId([]);
      setShowReplyBar({
        showBar: true,
        parent_id: commentId,
        post_id: commentPostId,
      });
    }
  };

  /**
   * Like reactions on post
   */
  const getAllLikeReactionsOnPost = async (post_id: number) => {
    const response: any = await dispatch(likeReactionListsOnPosts(post_id));
    if (response && response?.length > 0) {
      setLikedPeopleUserDetails(response);
    }
  };

  /**
   * To get all comments
   */
  const getAllPostsComments = async (type: string) => {
    const response: any = await dispatch(getAllCommentsLists(type));
    if (response && response?.length > 0) {
      let data = [...response];
      setAllComments(data);
    }
  };

  /**
   * To get tagged person details
   */
  const getAllTaggedUserDetails = async (userIds: []) => {
    const response: any = await dispatch(
      getTaggedUserDetails(postType, userIds)
    );
    if (response && response?.length > 0) {
      let data = [...response];
      setTaggegUserDetails(data);
    }
  };

  /**
   * Select the file which user selects
   */
  const fileSelectHandler = (
    e?: React.ChangeEvent<HTMLInputElement>,
    postId?: number,
    comment_parentId?: number
  ) => {
    if (e.target.files[0] !== undefined)
      for (let i = 0; i < e.target.files.length; i++) {
        if (e.target.files[i].type !== undefined) {
          const imgType = e.target.files[i].type.split("/");
          const MimeTypeArray = ImgTypeArray;
          if (imgType[1] !== undefined) {
            if (MimeTypeArray.includes(imgType[1])) {
              if (e.target && e.target.files[i]) {
                const img = e.target.files[i];
                postId
                  ? readFile(img, postId, comment_parentId)
                  : readFile(img);
              }
            }
          }
        }
      }
  };

  /**
   * To delete existing Post
   */
  const deletePost = async () => {
    setDisableDeletePostButton(true);
    const response: any = await dispatch(deletePostById(editPostId.postId));
    if (response?.isSuccess) {
      modalhandleClose();
      handleClose();
      getAllPosts(userId);
      setDisableDeletePostButton(false);
      return toast.success(translation(response.message));
    }
  };

  /**
   * update description
   */
  const updateDescription = async () => {
    setDisableEditPostButton(true);
    let response: any = await dispatch(
      updatePostById(editPostId.postId, {
        description: editDescription,
        userId: userId,
      })
    );

    if (response?.isSuccess) {
      setAllPosts(
        allPosts.map((i) => ({
          ...i,
          isEdit: i.id === editPostId && false,
          description:
            i.id === editPostId.postId ? editDescription : i.description,
        }))
      );
      setDisableEditPostButton(false);
      return toast.success(translation(response.message));
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  /**
   * Read the file data which user selects
   */
  const readFile = async (
    file: Blob,
    postId?: number,
    comment_parentId?: number
  ) => {
    /**
     * Create an instance of FileReader
     */
    const fileReader = new FileReader();
    /**
     * Implement onLoad function
     */
    fileReader.onloadend = (event) =>
      setCommentOnPost((prevState) => ({
        ...prevState,
        post_id: postId,
        file: fileReader.result,
        parent_id: comment_parentId,
      }));

    /**
     * Start reading the file
     */
    fileReader.readAsDataURL(file);
  };

  /**
   * handle change on description
   */
  const handleChangeDescription = (event: React.ChangeEvent) => {
    let target = event.target as HTMLInputElement;
    setEditDescription(target.value);
  };

  /**
   * emoji click on comment
   */
  const onCommentEmojiClick = (event: any, emojiObject?: IEmojiData) => {
    let target = event.target as HTMLInputElement;

    let postComment = { ...commentOnPost };

    if (emojiObject) {
      postComment.comment = postComment.comment + emojiObject.emoji;
    } else {
      postComment.comment = target.value;
    }

    setCommentOnPost((prevState) => ({
      ...prevState,
      showEmojiPicker: false,
      comment: JSON.parse(JSON.stringify(postComment.comment)),
    }));
  };

  /**
   * add comment on post
   */
  const addCommentOnPost = async () => {
    setShowAddComment(true);
    const response: any = await dispatch(
      addCommentOnThePost(postType, userInfo.id, commentOnPost)
    );
    if (response?.data?.isRegistered) {
      const data = {
        type: "Add Comment",
        id: userInfo.id,
        post_id: commentOnPost.post_id,
      };
      if (commentOnPost.post_userId !== data.id) {
        await dispatch(sendNotification(commentOnPost.post_userId, data));
      }

      setShowAddComment(false);

      setCommentOnPost({
        showEmojiPicker: false,
        post_id: 0,
        comment: "",
        file: null,
        parent_id: 0,
        post_userId: 0,
      });
      setShowReplyBar({
        showBar: false,
        parent_id: 0,
        post_id: 0,
      });
      getAllPostsComments(postType);
    }
  };

  /**
   * handle click
   * @param event
   * @param postId
   */
  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    postId: number
  ) => {
    setAnchorEl(event.currentTarget);
    setEditPostId((prevState) => ({
      ...prevState,
      postId: postId,
    }));
  };

  /**
   * Add or Remove emoji reaction from post
   * @param emojiCode
   * @param type
   */
  const handleAddEmoji = async (emojiCode: string, type: string) => {
    handleExpressionClose();
    setdisable(true);
    let crudOperation = "insert";
    let allPostList = [...allPosts];

    if (String.fromCodePoint(Number(emojiCode)) === editPostId.userReaction) {
      crudOperation = "delete";
      allPostList[editPostId.postIndex].reactionsCount =
        allPostList[editPostId.postIndex].reactionsCount - 1;
      allPostList[editPostId.postIndex].userReaction = "0";
    } else if (editPostId.userReaction !== "0") {
      crudOperation = "update";
      allPostList[editPostId.postIndex].userReaction = String.fromCodePoint(
        Number(emojiCode)
      );
    } else {
      crudOperation = "insert";
      allPostList[editPostId.postIndex].reactionsCount =
        allPostList[editPostId.postIndex].reactionsCount + 1;
      allPostList[editPostId.postIndex].userReaction = String.fromCodePoint(
        Number(emojiCode)
      );
    }

    setAllPosts(allPostList);
    handleExpressionClose();
    await dispatch(
      addLikeOnThePost(
        type,
        userInfo.id,
        {
          post_id: editPostId.postId,
          reaction: String.fromCodePoint(Number(emojiCode)),
        },
        crudOperation
      )
    );
    setdisable(false);
    const data = { type: "Like", id: userInfo.id, post_id: editPostId.postId };
    if (editPostId.post_userId !== data.id) {
      await dispatch(sendNotification(editPostId.post_userId, data));
    }
  };

  /**
   * When user clicks on ad
   */
  const handleAdClick = async (ad_id: number) => {
    addAdActionViewAndClick({
      user_id: userId,
      ad_id: ad_id,
      type: "click",
    });
  };

  const onSharePost = async (post: any) => {
    setShowShareModal(true);
    setShareAblePost(post);
  };
  const onSharePostClose = () => {
    setShowShareModal(false);
  };

  return (
    <>
      {allPosts &&
        allPosts.length > 0 &&
        allPosts.map((post, index) => (
          <Box
            className={`${commonStyle.mb15} ${commonStyle.card}`}
            key={index}
          >
            {post.type === "ad" ? (
              <Box>
                <Box className={commonStyle.p20}>
                  <Box
                    className={`${commonStyle.flexCenter} ${commonStyle.justifySpaceBetween} ${commonStyle.mb10}`}
                  >
                    <Typography>{post.company_name}</Typography>
                    <Typography className={styleClasses.adPill}>Ad</Typography>
                  </Box>

                  <Box
                    className={`${styleClasses.adImgSet} ${styleClasses.wallPostImg}`}
                  >
                    <a
                      href={"http://" + post.company_website_url}
                      onClick={() => handleAdClick(post.id)}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={JSON.parse(post.image)[0]}
                        alt={translation("wall_img")}
                      />
                    </a>
                  </Box>
                </Box>
              </Box>
            ) : (
              <Box>
                <Grid container className={commonStyle.p20}>
                  <Grid item md={10}>
                    <Box className={commonStyle.wallUserInfo}>
                      <Avatar
                        alt={translation("story_person")}
                        src={
                          post?.profile_image
                            ? post.profile_image
                            : minImage
                        }
                      />
                      <Box className={commonStyle.ml10}>
                        <Typography variant="h2">
                          <Link
                            to={{
                              pathname: `${RoutingLinks.profiles}${
                                post?.userId ? post?.userId : userId
                              }`,
                            }}
                            className={`${commonStyle.anchorDecoration} ${commonStyle.black}`}
                          >
                            {capitalize(post.first_name)}{" "}
                            {capitalize(post.last_name)}{" "}
                          </Link>
                          {{ ...post }["tagFriends"] && "is with "}
                          {{ ...post }["tagFriends"] && (
                            <Button
                              variant="text"
                              className={styleClasses.tagBtn}
                              onClick={() => handleTagOpen(post.tagFriends)}
                            >
                              <LocalOffer />
                              {{ ...post }["tagFriends"] &&
                                { ...post }["tagFriends"].split(",")
                                  .length}{" "}
                            </Button>
                          )}
                          {{ ...post }["tagFriends"] && " others"}
                          {taggedUserDetails.length > 0 && (
                            <CommonUserDetailModal
                              name="tagged_friend"
                              userDetails={taggedUserDetails}
                              open={openTag}
                              onClose={handleTagClose}
                            />
                          )}
                        </Typography>
                        <Typography>
                          {post?.created_ts
                            ? getTimeDiff(post?.created_ts)
                            : ""}{" "}
                          {translation("ago")}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  {!id && location.pathname === RoutingLinks.userProfile ? (
                    <Grid item md={2} className={commonStyle.textRight}>
                      <Box className={commonStyle.iconBtn}>
                        <IconButton onClick={(e) => handleClick(e, post.id)}>
                          <MoreHoriz />
                        </IconButton>
                        <CommonPopover
                          idEdit={idEdit}
                          open={open}
                          anchorEl={anchorEl}
                          handleClose={handleClose}
                          setEditPost={setEditPost}
                          handleOpen={handleOpen}
                          modalopen={modalopen}
                          modalhandleClose={modalhandleClose}
                          deletePost={deletePost}
                          deletePostButtonDisable={disableDeletePostButton}
                        />
                      </Box>
                    </Grid>
                  ) : post.reportUserId !== userId &&
                    post.reported_id !== post.id &&
                    post?.userId !== userId ? (
                    <Grid item md={2} className={commonStyle.textRight}>
                      <Box className={commonStyle.iconBtn}>
                        <IconButton onClick={(e) => handleClicks(e, post.id)}>
                          <MoreHoriz />
                        </IconButton>
                        <CommonPopover
                          open={open}
                          anchorEl={anchorEl}
                          handleClose={handleClose}
                          handleOpen={handleOpen}
                          modalopen={modalopen}
                          modalhandleClose={modalhandleClose}
                          reportPostId={reportPostId}
                          getReportedId={getReportedId}
                        />
                      </Box>
                    </Grid>
                  ) : null}
                  <Box className={commonStyle.wallPostTopContent}>
                    {post.isEdit ? (
                      <TextField
                        variant="outlined"
                        multiline
                        rows={3}
                        onChange={handleChangeDescription}
                        defaultValue={post.description}
                        placeholder={translation("what_is_in_your_mind")}
                        className={`${commonStyle.w100} ${commonStyle.modalTextarea}`}
                      />
                    ) : (
                      <Typography
                        dangerouslySetInnerHTML={{
                          __html: `${post.description.replace(
                            NewLineRegex,
                            "<br />"
                          )}`,
                        }}
                      ></Typography>
                    )}
                  </Box>
                  <Box>
                    {post.isEdit && (
                      <Box
                        className={`${commonStyle.mt20} ${commonStyle.flexCenter}`}
                      >
                        <Box
                          className={`${commonStyle.mr10} ${commonStyle.blueBtn}`}
                        >
                          <Button
                            disabled={disableEditPostButton}
                            onClick={updateDescription}
                          >
                            {translation("save")}
                          </Button>
                        </Box>
                        <Box
                          className={`${commonStyle.mr10} ${commonStyle.greyBtn}`}
                        >
                          <Button
                            onClick={() => {
                              setAllPosts(
                                allPosts.map((i) => ({
                                  ...i,
                                  isEdit: i.id === editPostId && false,
                                }))
                              );
                            }}
                          >
                            {translation("cancel")}
                          </Button>
                        </Box>
                      </Box>
                    )}
                  </Box>
                </Grid>

                <Box>
                  <Grid container spacing={1}>
                    {post.gallery_images != null &&
                      post.gallery_images.length > 0 &&
                      JSON.parse(post.gallery_images).map(
                        (image: string, index: number) => (
                          <>
                            {JSON.parse(post.gallery_images).length > 1 &&
                            index === 0 ? (
                              <Grid
                                item
                                md={8}
                                className={commonStyle.dflex}
                                key={index}
                              >
                                <Box className={styleClasses.wallPostImg}>
                                  {image.split(".").pop() === "mov" ||
                                  image.split(".").pop() === "mp4" ||
                                  image.split(".").pop() === "mpeg" ? (
                                    <video controls src={image} />
                                  ) : (
                                    <img
                                      src={image}
                                      alt={translation("wall_img")}
                                    />
                                  )}
                                </Box>
                              </Grid>
                            ) : (
                              index === 0 &&
                              JSON.parse(post.gallery_images).length === 1 && (
                                <Grid
                                  item
                                  md={12}
                                  className={commonStyle.dflex}
                                  key={index}
                                >
                                  <Box className={styleClasses.wallPostImg}>
                                    {image.split(".").pop() === "mov" ||
                                    image.split(".").pop() === "mp4" ||
                                    image.split(".").pop() === "mpeg" ? (
                                      <video controls src={image} />
                                    ) : (
                                      <img
                                        src={image}
                                        alt={translation("wall_img")}
                                      />
                                    )}
                                  </Box>
                                </Grid>
                              )
                            )}

                            {/* Start For multiple photos */}
                            {JSON.parse(post.gallery_images).length > 2 &&
                            index === 1 ? (
                              <Grid item md={4}>
                                <Box
                                  className={`${commonStyle.pb8} ${styleClasses.wallPostImg} ${styleClasses.multipleWallPost}`}
                                >
                                  {image.split(".").pop() === "mov" ||
                                  image.split(".").pop() === "mp4" ||
                                  image.split(".").pop() === "mpeg" ? (
                                    <video controls src={image} />
                                  ) : (
                                    <img
                                      src={image}
                                      alt={translation("wall_img")}
                                    />
                                  )}
                                </Box>

                                <Box
                                  className={`${styleClasses.multipleWallPost} ${styleClasses.wallPostImg} ${styleClasses.multipleShowAfter}`}
                                  onClick={() =>
                                    setImageForViewModal(
                                      JSON.parse(post.gallery_images)
                                    )
                                  }
                                >
                                  {image.split(".").pop() === "mov" ||
                                  image.split(".").pop() === "mp4" ||
                                  image.split(".").pop() === "mpeg" ? (
                                    <video controls src={image} />
                                  ) : (
                                    <img
                                      src={image}
                                      alt={translation("wall_img")}
                                    />
                                  )}

                                  <Typography variant="h6">
                                    +{" "}
                                    {JSON.parse(post.gallery_images).length - 2}
                                  </Typography>
                                </Box>
                              </Grid>
                            ) : (
                              index === 1 &&
                              JSON.parse(post.gallery_images).length === 2 && (
                                <Grid item md={4}>
                                  <Box
                                    className={`${commonStyle.pb8} ${styleClasses.wallPostImg} ${styleClasses.multipleWallPost}`}
                                  >
                                    {image.split(".").pop() === "mov" ||
                                    image.split(".").pop() === "mp4" ||
                                    image.split(".").pop() === "mpeg" ? (
                                      <video controls src={image} />
                                    ) : (
                                      <img
                                        src={image}
                                        alt={translation("wall_img")}
                                      />
                                    )}
                                  </Box>
                                </Grid>
                              )
                            )}
                          </>
                        )
                      )}
                  </Grid>
                </Box>
                {/*End Logic of multiple photos */}

                <Box className={styleClasses.commentPost}>
                  {post.reactionsCount > 0 && (
                    <Box className={styleClasses.actionBtns}>
                      <Button variant="text" className={commonStyle.blue}>
                        <img src={likelcon} alt={translation("altlike")} />
                      </Button>
                      {/* <Button variant="text" className={commonStyle.red}>
                      <Favorite />
                    </Button> */}
                      <Button
                        variant="text"
                        onClick={() => handleLikeOpen(post.id)}
                      >
                        {post.reactionsCount} {translation("people")}
                      </Button>
                    </Box>
                  )}

                  {likedPeopleUserDetails.length > 0 && (
                    <CommonUserDetailModal
                      name="reaction_of_friends"
                      userDetails={likedPeopleUserDetails}
                      open={openLike}
                      onClose={handleLikeClose}
                    />
                  )}

                  <Box className={styleClasses.commentBtns}>
                    <Button
                      variant="text"
                      onClick={(e) =>
                        handleExpressionClick(
                          e,
                          post.id,
                          index,
                          post.userReaction,
                          post.userId
                        )
                      }
                    >
                      {post.userReaction !== "0" ? (
                        post.userReaction
                      ) : (
                        <ThumbUpAlt />
                      )}{" "}
                      {translation("like")}
                    </Button>
                    <Popover
                      open={openExpression}
                      anchorEl={anchorExpression}
                      onClose={handleExpressionClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      className={`${styleClasses.emojiShadow}`}
                    >
                      <ul
                        className={`${commonStyle.flexCenter} ${styleClasses.emojiList}`}
                      >
                        <li>
                          <Tooltip
                            title={translation("like")}
                            disableTouchListener={true}
                          >
                            <span
                              style={disable ? { pointerEvents: "none" } : {}}
                              onClick={() => handleAddEmoji("0x1F44D", "post")}
                            >
                              &#128077;
                            </span>
                          </Tooltip>
                        </li>
                        <li>
                          <Tooltip
                            title={translation("heart")}
                            disableTouchListener={true}
                          >
                            <span
                              style={disable ? { pointerEvents: "none" } : {}}
                              onClick={() => handleAddEmoji("0x1F497", "post")}
                            >
                              &#128151;
                            </span>
                          </Tooltip>
                        </li>
                        <li>
                          <Tooltip
                            title={translation("laugh")}
                            disableTouchListener={true}
                          >
                            <span
                              style={disable ? { pointerEvents: "none" } : {}}
                              onClick={() => handleAddEmoji("0x1F603", "post")}
                            >
                              &#128515;
                            </span>
                          </Tooltip>
                        </li>
                        <li>
                          <Tooltip
                            title={translation("angry")}
                            disableTouchListener={true}
                          >
                            <span
                              style={disable ? { pointerEvents: "none" } : {}}
                              onClick={() => handleAddEmoji("0x1F621", "post")}
                            >
                              &#128545;
                            </span>
                          </Tooltip>
                        </li>
                      </ul>
                    </Popover>
                    <Button variant="text">
                      <Comment />
                      {translation("comment")}
                    </Button>
                    <Button variant="text" onClick={() => onSharePost(post)}>
                      <Share /> {translation("share")}
                    </Button>
                  </Box>

                  {allComments &&
                    allComments.length > 0 &&
                    allComments.map(
                      (
                        comment: {
                          post_id: number;
                          userId: string;
                          profile_image: string;
                          parent_id: string;
                          first_name: string;
                          last_name: string;
                          comment:
                            | boolean
                            | React.ReactChild
                            | React.ReactFragment
                            | React.ReactPortal;
                          attchements: string;
                          id: number;
                          childCount: {};
                        },
                        index: React.Key
                      ) =>
                        comment.post_id === post.id && (
                          <Box
                            className={styleClasses.commentReply}
                            key={index}
                          >
                            <Grid container>
                              <Link
                                to={{
                                  pathname: `${RoutingLinks.profiles}${
                                    comment?.userId ? comment?.userId : userId
                                  }`,
                                }}
                                className={`${commonStyle.white} ${commonStyle.anchorDecoration}`}
                              >
                                <Grid item md={1}>
                                  <Avatar
                                    className={styleClasses.commentAvatar}
                                    sizes="lg"
                                  >
                                    <img
                                      src={
                                        comment.profile_image
                                          ? comment.profile_image
                                          : minImage
                                      }
                                      alt={translation("comment")}
                                    />
                                  </Avatar>
                                </Grid>
                              </Link>
                              <Grid item md={11}>
                                <Box className={commonStyle.ml10}>
                                  {!comment.parent_id && (
                                    <Typography variant="h6">
                                      <Link
                                        to={{
                                          pathname: `${RoutingLinks.profiles}${
                                            comment?.userId
                                              ? comment?.userId
                                              : userId
                                          }`,
                                        }}
                                        className={`${commonStyle.anchorDecoration} ${commonStyle.black}`}
                                      >
                                        {capitalize(comment.first_name) +
                                          " " +
                                          capitalize(comment.last_name)}
                                      </Link>
                                    </Typography>
                                  )}

                                  {comment.comment && !comment.parent_id && (
                                    <Typography
                                      dangerouslySetInnerHTML={{
                                        __html: `${comment.comment
                                          .toString()
                                          .replace(NewLineRegex, "<br />")}`,
                                      }}
                                    ></Typography>
                                    // <Typography>{comment.comment}</Typography>
                                  )}
                                  {comment.attchements &&
                                    !comment.parent_id && (
                                      <img
                                        src={comment.attchements}
                                        alt={translation("comment")}
                                        className={styleClasses.commentChildImg}
                                      />
                                    )}

                                  {/* comment child --------- */}
                                  {showReplyBar.showBar &&
                                    showReplyBar.parent_id === comment.id &&
                                    allChildCommentsbyId.length > 0 &&
                                    allChildCommentsbyId.map(
                                      (childComment, index) => (
                                        <>
                                          <Box
                                            className={`${commonStyle.wallUserInfo} ${commonStyle.my10} ${styleClasses.wallFlexStart}`}
                                            key={index}
                                          >
                                            <Avatar
                                              alt={translation("story_person")}
                                              src={
                                                childComment.profile_image
                                                  ? childComment.profile_image
                                                  : minImage
                                              }
                                            />
                                            <Box className={commonStyle.ml10}>
                                              <Link
                                                to={{
                                                  pathname: `${
                                                    RoutingLinks.profiles
                                                  }${
                                                    childComment?.userId
                                                      ? childComment?.userId
                                                      : userId
                                                  }`,
                                                }}
                                                className={`${commonStyle.white} ${commonStyle.anchorDecoration}`}
                                              >
                                                <Typography variant="h2">
                                                  {capitalize(
                                                    childComment.first_name
                                                  ) +
                                                    " " +
                                                    capitalize(
                                                      childComment.last_name
                                                    )}
                                                </Typography>
                                              </Link>

                                              {childComment.comment && (
                                                // <Typography
                                                //   className={`${commonStyle.black} ${commonStyle.fontWeight400}`}
                                                // >
                                                <Typography
                                                  className={`${commonStyle.black} ${commonStyle.fontWeight400}`}
                                                  dangerouslySetInnerHTML={{
                                                    __html: `${childComment.comment.replace(
                                                      NewLineRegex,
                                                      "<br />"
                                                    )}`,
                                                  }}
                                                ></Typography>
                                              )}

                                              {childComment.attchements && (
                                                <img
                                                  src={childComment.attchements}
                                                  alt={translation("comment")}
                                                  className={
                                                    styleClasses.commentChildImg
                                                  }
                                                />
                                              )}
                                            </Box>
                                          </Box>
                                        </>
                                      )
                                    )}
                                  {/*end comment child --------- */}

                                  <Box>
                                    {comment.childCount > 0 &&
                                      showReplyBar.parent_id !== comment.id && (
                                        <Button
                                          onClick={() =>
                                            getAllChildComments(
                                              comment.id,
                                              comment.post_id,
                                              true
                                            )
                                          }
                                        >
                                          {translation("view")}{" "}
                                          {comment.childCount}{" "}
                                          {translation("replies")}
                                        </Button>
                                      )}
                                    {comment.childCount === 0 &&
                                      showReplyBar.parent_id !== comment.id && (
                                        <Button
                                          onClick={() =>
                                            getAllChildComments(
                                              comment.id,
                                              comment.post_id,
                                              false
                                            )
                                          }
                                        >
                                          {translation("reply_comment")}
                                        </Button>
                                      )}
                                  </Box>
                                </Box>

                                {showReplyBar.showBar &&
                                  showReplyBar.parent_id === comment.id && (
                                    <Grid container>
                                      <Grid item md={12}>
                                        <Box
                                          className={`${commonStyle.flexCenter} ${commonStyle.mt10} ${commonStyle.mb10} ${styleClasses.commentReplyFollow}`}
                                        >
                                          <Avatar
                                            className={`${styleClasses.commentAvatar} ${commonStyle.mr10}`}
                                            sizes="lg"
                                          >
                                            <img
                                              src={
                                                userInfo.profile_image
                                                  ? userInfo.profile_image
                                                  : minImage
                                              }
                                              alt={translation("comment")}
                                            />
                                          </Avatar>
                                          <Box
                                            className={`${commonStyle.flexCenter} ${commonStyle.wallPostFormInner} ${commonStyle.w100}`}
                                          >
                                            <TextareaAutosize
                                              placeholder={translation(
                                                "reply_placeholder"
                                              )}
                                              value={
                                                commentOnPost.post_id ===
                                                  post.id &&
                                                commentOnPost.parent_id &&
                                                comment.id ===
                                                  commentOnPost.parent_id
                                                  ? commentOnPost.comment
                                                  : ""
                                              }
                                              onFocus={() =>
                                                commentOnPost.post_id !==
                                                  post.id ||
                                                commentOnPost.parent_id !==
                                                  comment.id
                                                  ? setCommentOnPost({
                                                      post_id: post.id,
                                                      comment: "",
                                                      file: null,
                                                      showEmojiPicker: false,
                                                      parent_id: comment.id,
                                                      post_userId: post.userId,
                                                    })
                                                  : ""
                                              }
                                              onChange={onCommentEmojiClick}
                                            />

                                            {commentOnPost.post_id ===
                                              post.id &&
                                              commentOnPost.parent_id &&
                                              comment.id ===
                                                commentOnPost.parent_id &&
                                              commentOnPost.file && (
                                                <img
                                                  src={commentOnPost.file}
                                                  alt={translation("file")}
                                                  className={
                                                    styleClasses.chooseImage
                                                  }
                                                />
                                              )}

                                            <Box
                                              className={
                                                commonStyle.inlineButtons
                                              }
                                            >
                                              <IconButton
                                                className={commonStyle.iconBtn}
                                              >
                                                <Box
                                                  className={`${commonStyle.flexCenter} ${styleClasses.emojiList}`}
                                                  onClick={() =>
                                                    setCommentOnPost(
                                                      (prevState) => ({
                                                        ...prevState,
                                                        post_id: post.id,
                                                        parent_id: comment.id,
                                                        showEmojiPicker: true,
                                                        post_userId:
                                                          post.userId,
                                                      })
                                                    )
                                                  }
                                                >
                                                  {commentOnPost.post_id ===
                                                    post.id &&
                                                    commentOnPost.parent_id &&
                                                    comment.id ===
                                                      commentOnPost.parent_id &&
                                                    commentOnPost.showEmojiPicker && (
                                                      <div ref={emojiRef}>
                                                        <Picker
                                                          onEmojiClick={
                                                            onCommentEmojiClick
                                                          }
                                                        />
                                                      </div>
                                                    )}
                                                  <SentimentVerySatisfiedOutlined
                                                    className={
                                                      commonStyle.white
                                                    }
                                                  />
                                                </Box>
                                              </IconButton>
                                              <IconButton
                                                className={commonStyle.iconBtn}
                                              >
                                                <Box
                                                  className={
                                                    styleClasses.customFileModal
                                                  }
                                                >
                                                  <Input
                                                    type="file"
                                                    accept="image/*"
                                                    onChange={(e) =>
                                                      fileSelectHandler(
                                                        e,
                                                        post.id,
                                                        comment.id
                                                      )
                                                    }
                                                  />
                                                  <ImageOutlined
                                                    className={
                                                      commonStyle.white
                                                    }
                                                  />
                                                </Box>
                                              </IconButton>
                                              <IconButton
                                                className={commonStyle.iconBtn}
                                                disabled={showAddComment}
                                              >
                                                <SendOutlined
                                                  onClick={addCommentOnPost}
                                                  className={commonStyle.white}
                                                />
                                              </IconButton>
                                            </Box>
                                          </Box>
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  )}
                              </Grid>
                            </Grid>
                          </Box>
                        )
                    )}

                  <Box>
                    <Grid container>
                      <Grid item md={1} className={commonStyle.flexCenter}>
                        <Avatar
                          alt={translation("story_person")}
                          src={
                            userInfo.profile_image
                              ? userInfo.profile_image
                              : minImage
                          }
                        />
                      </Grid>
                      <Grid
                        item
                        md={11}
                        className={`${commonStyle.w100} ${commonStyle.flexCenter}`}
                      >
                        <Box
                          className={`${commonStyle.flexCenter} ${commonStyle.wallPostFormInner} ${commonStyle.w100}`}
                        >
                          <TextareaAutosize
                            aria-label={translation("empty_text")}
                            placeholder={translation("add_comment")}
                            value={
                              commentOnPost.post_id === post.id &&
                              !commentOnPost.parent_id
                                ? commentOnPost.comment
                                : ""
                            }
                            onFocus={() =>
                              commentOnPost.post_id !== post.id ||
                              commentOnPost.parent_id
                                ? setCommentOnPost({
                                    post_id: post.id,
                                    comment: "",
                                    file: null,
                                    showEmojiPicker: false,
                                    parent_id: 0,
                                    post_userId: post.userId,
                                  })
                                : ""
                            }
                            onChange={onCommentEmojiClick}
                          />
                          {commentOnPost.post_id === post.id &&
                            !commentOnPost.parent_id &&
                            commentOnPost.file && (
                              <img
                                src={commentOnPost.file}
                                alt={translation("comment_file")}
                                className={styleClasses.chooseImage}
                              />
                            )}

                          <Box className={commonStyle.inlineButtons}>
                            <IconButton className={commonStyle.iconBtn}>
                              <Box
                                className={`${commonStyle.flexCenter} ${styleClasses.emojiList}`}
                              >
                                <SentimentSatisfiedOutlined
                                  className={commonStyle.white}
                                  onClick={() =>
                                    setCommentOnPost((prevState) => ({
                                      ...prevState,
                                      post_id: post.id,
                                      showEmojiPicker: true,
                                    }))
                                  }
                                />
                                {commentOnPost.post_id === post.id &&
                                  !commentOnPost.parent_id &&
                                  commentOnPost.showEmojiPicker && (
                                    <div
                                      ref={emojiRef}
                                      className={styleClasses.emojiPicker}
                                    >
                                      <Picker
                                        onEmojiClick={onCommentEmojiClick}
                                      />
                                    </div>
                                  )}
                              </Box>
                            </IconButton>
                            <IconButton className={commonStyle.iconBtn}>
                              <Box className={styleClasses.customFileModal}>
                                <Input
                                  type="file"
                                  accept="image/*"
                                  onChange={(e) =>
                                    fileSelectHandler(e, post.id, 0)
                                  }
                                />
                                <ImageOutlined className={commonStyle.white} />
                              </Box>
                            </IconButton>
                            <IconButton
                              className={commonStyle.iconBtn}
                              disabled={showAddComment}
                            >
                              <SendOutlined
                                onClick={addCommentOnPost}
                                className={commonStyle.white}
                              />
                            </IconButton>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        ))}

      {showModalShare ? (
        <SharePopover
          showModal={showModalShare}
          post={shareAblePost}
          close={onSharePostClose}
        />
      ) : null}

      {/** Image view modal start */}
      {openImageModal?.show ? (
        <BasicModal
          open={openImageModal?.show}
          onClose={() => setOpenImageModal({ show: false, images: [] })}
          images={openImageModal?.images}
        />
      ) : null}
      {/** Image view modal start */}
    </>
  );
};

export default CommonCard;
