import { Dispatch } from "redux";
import { ActionType } from "../action-types/index";
import axios from "../../services/axiosService";
const URL = process.env.REACT_APP_API_URL;

/**
 * fetch request type
 */
export const RequestType = (id: number, userId: number) => async (
  dispatch: Dispatch
) => {
  try {
    const response = await axios.get(`${URL}/api/v1/request/${id}/${userId}`);

    return response.data.data.data;
  } catch (error) {
    dispatch({
      type: ActionType.FAILURE,
    });
  }
};

/**
 * change request type
 * @param id
 * @param userId
 * @returns
 */
export const changeRequestType = (id: number, userId: number) => async (
  dispatch: Dispatch
) => {
  try {
    const response = await axios.post(
      `${URL}/api/v1/request/add/${id}/${userId}`
    );
    return response.data.data;
  } catch (error) {
    dispatch({
      type: ActionType.FAILURE,
    });
  }
};

/**
 * fetch friend request
 * @param userId
 * @returns
 */
export const fetchfriendRequest = (userId: number) => async (
  dispatch: Dispatch
) => {
  try {
    const response = await axios.get(`${URL}/api/v1/request/${userId}`);

    return response.data.data.data;
  } catch (error) {
    dispatch({
      type: ActionType.FAILURE,
    });
  }
};

/**
 * request approval
 * @param userId
 * @param id
 * @param request
 * @returns
 */
export const requestapproval = (
  userId: number,
  id: number,
  request: boolean
) => async (dispatch: Dispatch) => {
  try {
    const response = await axios.post(`${URL}/api/v1/request/${userId}/${id}`, {
      request,
    });

    return response;
  } catch (error) {
    dispatch({
      type: ActionType.FAILURE,
    });
  }
};

/**
 * remove friend by id
 * @param friendId
 * @returns
 */
 export const removeFriendbyId = (userId: number,profileId:number) => async (
  dispatch: Dispatch
) => {
  try {
    const response = await axios.delete(
      `${URL}/api/v1/request/remove/${userId}/${profileId}`
    );

    return response.data;
  } catch (error) {
    dispatch({
      type: ActionType.FAILURE,
    });
  }
};

/**
 * get friends of friend list
 * @param userId
 * @returns
 */
export const getFriendsOfFriendsList = (userId: number) => async (
  dispatch: Dispatch
) => {
  try {
    const response = await axios.get(
      `${URL}/api/v1/request/friendsOfFriends/${userId}`
    );
    return response.data.data.data;
  } catch (error) {
    dispatch({
      type: ActionType.FAILURE,
    });
  }
};
