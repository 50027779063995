import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import ClipLoader from "react-spinners/ClipLoader";



import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Modal,
} from "@mui/material";
import { Box } from "@mui/system";
import { Button } from "@mui/material";



import LeftSidebar from "../../components/sidebars/LeftSidebar";

import { UserCommonStyles } from "../../styles/CommonStyles";
import { ProfileStyle } from "../profile/ProfileStyle";


import { isJsonString } from "../../utils/helper";
import { getProductListBySellerId } from "../../redux/action-creators/sellerAction";
import { updateProductAction } from "../../redux/action-creators/productAction";


const MyProductList: React.FC = (props: any) => {
  const commonStyle = UserCommonStyles();
  const { t: translation } = useTranslation();
  const [Loader,setLoader]=useState(false)

  const [state, setState] = useState({
    userList: [],
    offset: 0,
    prevOffset: 0,
    hasMore: true,
  });
  const [disable,setDisable]=useState(false)

  
  const styleClasses = ProfileStyle();
  const dispatch = useDispatch();
  const [modalopen, setOpen] = useState(false);
  const [product,setProduct]=useState({
    productId: 0,
    action: 0,
  })

 
  const data = props?.location?.state;

  const userInfo = useSelector((state: RootStateOrAny) => {
    let userData = state.authReducer.authData;
    if (isJsonString(state.authReducer.authData)) {
      userData = JSON.parse(state.authReducer.authData);
    }
    return userData;
  });

  interface Column {
    autoNumber: number;
    id:
      | "ProductName"
      | "price"
      | "edit"
      |"action"
    label: string;
    minWidth?: number;
    align?: "right";
    format?: (value: number) => string;
  }
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "#fff",
    border: "2px solid #000",
    p: 4,
  };

  const columns: readonly Column[] = [
    { autoNumber: 1, id: "ProductName", label: "Product Name", minWidth: 170 },
    

    {
      autoNumber: 4,
      id: "price",
      label: "Price",
      minWidth: 170,
      align: "right",
      format: (value: number) => value.toLocaleString("en-US"),
    },



    
    {
      autoNumber: 12,
      id: "edit",
      label: "Edit",
      minWidth: 170,
      align: "right",
      format: (value: number) => value.toFixed(2),
    },
    {
      autoNumber: 13,
      id: "action",
      label: "Acton",
      minWidth: 170,
      align: "right",
      format: (value: number) => value.toFixed(2),
    },
   
  
   
  ];
 /**
     * To get the list of Adoption
     * @param firstload
     */
  const getAllProductList = async (
    firstLoad?: boolean,
  ) => {
    if (state.offset !== state.prevOffset || firstLoad) {
        setLoader(true);
      let offset = firstLoad ? 0 : state.offset;
      
      let result: any = await dispatch(
        getProductListBySellerId(userInfo.seller_id, offset)
      );

      if (result)
        if (result.length > 0) {
          setState((prevState) => ({
            ...prevState,
            prevOffset: firstLoad ? 0 : prevState.offset,
            offset: firstLoad ? 10 : prevState.offset + 10,
            userList: firstLoad ? result : prevState.userList.concat(result),
            hasMore: true,
          }));
        } else {
          setState((prevState) => ({
            ...prevState,
            userList: firstLoad ? result : prevState.userList.concat(result),
            hasMore: false,
          }));
        }
    }
    setLoader(false);
  };
    /**
       * Fetching all reactions List
       */
     useEffect(() => {
        getAllProductList(true);
    }, [props.id]); 



    const disbaleProduct=async ()=>{
      setDisable(true)
        
      const Response:any=await dispatch(
        updateProductAction(product.action,product.productId )
    
      );
    
      if (Response && Response?.data?.isUpdated) {
        const index = state.userList.findIndex((item:any) => item.id === product.productId);
        state.userList[index]["is_active"] = product.action;
        setOpen(false)
        setDisable(false)

    
      }
      else
      {
        setOpen(false)
        setDisable(false)

    
    
      }
    
     }

     const viewModal=(id:number,status:number)=>{
   

      setProduct({
        productId:id,
        action:status
      })
      setOpen(true)
    }
  

 

  return (
    <Box className={commonStyle.windowColor}>
      <Grid container>
        <Grid item md={3}>
          <LeftSidebar />
        </Grid>

        <Grid item md={9}>
          <Box className={`${commonStyle.p20} ${commonStyle.mt20}`}>
            <Typography
              variant="h6"
              className={`${commonStyle.grey} ${commonStyle.mb15} ${commonStyle.fontWeight600}`}
            >
              {translation("my_product")}
              {/* {translation("adlist")} */}
            </Typography>

          
            <TableContainer sx={{ maxHeight: 440 }}>
            <InfiniteScroll
                dataLength={state?.userList?.length}
                next={() => getAllProductList(false)}
                hasMore={state?.hasMore}
                loader={(() => {
                  if (Loader) {
                    return <div className="loaing-area"></div>;
                  }
                })()}
                scrollThreshold="100px" 
                >
              <Table stickyHeader aria-label={translation("sticky_table")}>
                <TableHead>
                  <TableRow className={commonStyle.commonTableHead}>
                    {columns.map((column) => (
                      <TableCell
                        key={column.autoNumber}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
               
                <TableBody>
                
                
                  {state.userList.length > 0
                    ? state.userList.map((product, index) => {
                        return (
                          <TableRow hover tabIndex={-1} key={index}>
                            <TableCell>{product.name}</TableCell>
                          
                            <TableCell>${product.price}</TableCell>
                            
                            <TableCell>
                              <Box className={commonStyle.orangeBtn}>

                                <Button
                                
                                 onClick={()=>{
                                  props.history.push({
                                    pathname:`/edit-product/${product.id}`,
                                    state: { product:product},
                                  })
                                }
                              }
                                >
                                  {translation("edit")}
                                </Button>

                             
                              </Box>
                            </TableCell>

                             <TableCell>
                           
                              <Box className={commonStyle.orangeBtn}>

                                <Button
                             
                                onClick={()=>{
                                  viewModal(product.id,product.is_active?0:1)
                                }}
                                >
                       {product.is_active?"Inactive":"Active"}                               
  </Button>

                             
                              </Box>
                            </TableCell>
                             </TableRow>
                        );
                      })
                    : Loader
                    ? translation("loading")
                    : translation("no_prod_avail")
                    }
                      
                </TableBody>
             
              </Table>
              </InfiniteScroll>
            </TableContainer>
          </Box>
        </Grid>
        <Modal
                                open={modalopen}
                                onClose={()=>{setOpen(false)}}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                                className={styleClasses.modalShadow}
                              >
                                <Box sx={style}>
                                  <Typography
                                    id="modal-modal-title"
                                    variant="h6"
                                    component="h2"
                                  >
                                    {translation("ad_ms1")}{" "}
               {product.action===1?"Active":"Disable"}{" this product "}
                                  </Typography>
                                  <Box
                                    id="modal-modal-description"
                                    sx={{ mt: 2 }}
                                    className={commonStyle.flexCenter}
                                  >
                                    <Box
                                      className={`${commonStyle.blueBtn} ${commonStyle.pt20} ${commonStyle.mr10}`}
                                    >
                                      <Button
                                        disabled={disable}
                                        onClick={()=>{disbaleProduct()}}
                                      >
                                        {" "}
                                        {translation("yes")}{disable ? <ClipLoader /> : null}
                                      </Button>
                                    </Box>

                                    <Box
                                      className={`${commonStyle.blueBtn} ${commonStyle.pt20}`}
                                    >
                                      <Button onClick={()=>{setOpen(false)}}>
                                        {translation("no")}
                                      </Button>
                                    </Box>
                                  </Box>
                                </Box>
                              </Modal>
      </Grid>
    </Box>
  );
};

export default MyProductList;
