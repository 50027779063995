import { makeStyles } from "@mui/styles";

export const HomeStyles = makeStyles((theme) => {
  return {
    //storyCard
    homeMain: {
      padding: "20px",
    },
    storyCard: {
      position: "relative",
      borderRadius: "5px",
      overflow: "hidden",
      minHeight: "200px",
      minWidth: "160px",
      padding: "10px",
      display: "flex",
      flexFlow: "column",
      justifyContent: "space-between",
    },
    uploadImgList: {
      listStyle: "none",
      padding: 0,
      display: "flex",
      alignItems: "center",
      width: "100%",
      margin: "10px 0",
      zIndex: 2,
      "& li": {
        width: "100%",
        paddingRight: "10px",
        "& img": {
          width: "100%",
          height: "240px",
          objectFit: "contain",
          objectPosition: "center",
          marginBottom: "5px",
        },
        "& video": {
          width: "100%",
          height: "320px",
        },

        "& div": {
          "& div": {
            "&::after": {
              borderBottom: "none",
            },
            "&::before": {
              borderBottom: "none",
            },
            "&:hover": {
              "&::after": {
                borderBottom: "none !important",
              },
              "&::before": {
                borderBottom: "none !important",
              },
            },
          },
        },

        "& input": {
          color: "#fff",
        },

        "& textarea": {
          color: "#000",
          padding: "20px",
        },
      },
    },

    paddingLike: {
      padding: '10px 20px !important',
    },

    storyBgImg: {
      position: "absolute",
      width: "100%",
      height: "100%",
      objectFit: "cover",
      top: "0",
      left: "0",
    },
    heightBoxUpload: {
      minHeight: "300px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexFlow: "column",
      color: "#fff",
      cursor: "pointer",
      padding: "10px",
      position: "relative",
      "& svg": {
        fontSize: "60px",
        marginBottom: "20px",
      },
    },
    inputScrollHidden: {
      position: "absolute",
      top: 0,
      width: "100%",
      height: "100%",
      opacity: 0,
    },
    storyPerson: {
      "& button": {
        width: "50px",
        height: "50px",
        border: "2px solid #ffff",
        backgroundColor: "#01b4bc",
        color: "#fff",
        "& img": {
          width: "50px",
          height: "50px",
          minWidth: "50px",
        },
      },
    },
    storyName: {
      position: "relative",
      "& h5": {
        color: "#fff",
        fontSize: "16px",
        fontWeight: 500,
      },
    },

    //postLinks
    postLinks: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      "& a": {
        display: "flex",
        alignItems: "center",
        color: "#262626",
        textDecoration: "none",
        fontWeight: 500,
        margin: "0px 10px",
        fontSize: "14px",
        "& img": {
          marginRight: "10px",
          width: "20px",
          minWidth: "20px",
        },
      },
    },

    adPill: {
      backgroundColor: "#FA5457",
      color: "#fff",
      textAlign: "center",
      borderRadius: "10px",
      padding: "2px 12px",
      fontSize: "12px !important",
    },

    adImgSet: {
      "& a": {
        width: "100%",
        "& img": {
          width: "100%",
          height: "400px",
          objectFit: "cover",
          objectPosition: "center",
          borderRadius: "10px",
          overflow: "hidden",
        },
      },
    },

    //postCard
    // postCard: {
    //   "& button": {
    //     width: "100%",
    //     backgroundColor: "#01b4bc",
    //     marginBottom: "15px",
    //     "&:hover": {
    //       backgroundColor: "#01b4bc",
    //     },
    //   },
    // },

    //wallPostCard

    wallPostImg: {
      boxSizing: "border-box",
      display: "flex",
      height: "400px",
      width: "100%",
      "& img": {
        width: "100%",
        height: "100%",
        objectFit: "contain",
        boxSizing: "border-box",
        objectPosition: "top",
      },
      "& video": {
        width: "100%",
      },
    },

    actionBtns: {
      padding: "5px 0",
      "& button": {
        fontSize: "16px",
        minWidth: "auto",
        color: "#01B4BC",
        textTransform: "capitalize",
        "& svg": {
          width: "20px",
        },
      },
    },

    tagBtn: {
      minWidth: "30px !important",
      padding: "0 !important",
    },

    chooseImage: {
      width: "80px",
      height: "80px",
      borderRadius: "10px",
      objectFit: "cover",
      objectPosition: "center",
    },

    commentBtns: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      borderTop: "1px solid #ECEDF1",
      padding: "5px 0 8px",
      "& button": {
        color: "#01B4BC",
        display: "flex",
        alignItems: "center",
      },
      "& svg": {
        marginRight: "5px",
      },
    },

    commentPost: {
      margin: "0 20px",
      padding: "0 0 12px",
    },

    //modal style -------------
    modalHeader: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      borderBottom: "1px solid #707070",
      paddingBottom: "3px",
      "& h6": {
        color: "#3E4347",
        fontWeight: 600,
      },
      " & svg": {
        cursor: "pointer",
        backgroundColor: "#3E4347",
        color: "#fff",
        borderRadius: "100px",
        padding: "2px",
        fontSize: "20px",
      },
    },

    addPostInput: {
      position: "relative",
      "& textarea": {
        paddingRight: "160px",
      },
    },

    addPostIcon: {
      position: "absolute",
      top: "12px",
      right: "8px",
      display: "flex",
      alignItems: "center",
      "& img": {
        width: "22px",
        margin: "0 10px",
        cursor: "pointer",
      },
    },

    modalImageList: {
      overflow: "auto",
      listStyle: "none",
      padding: 0,
      whiteSpace: "nowrap",
      margin: "20px 0",
      "& li": {
        display: "inline-block",
        borderRadius: "13px",
        position: "relative",
        width: "170px",
        height: "200px",
        marginRight: "10px",
        overflow: "hidden",
        "& img": {
          width: "100%",
          height: "100%",
          objectFit: "cover",
          objectPosition: "center",
        },
      },
    },

    closePostImg: {
      position: "absolute",
      top: "5px",
      right: "5px",
      backgroundColor: "rgba(0,0,0,0.3)",
      width: "34px",
      height: "34px",
      borderRadius: "100px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      "& svg": {
        fontSize: "26px",
        color: "#fff",
      },
    },

    modalDescTag: {
      maxHeight: "400px",
      overflow: "auto",
      listStyle: "none",
      padding: 0,
      "& a": {
        color: "#000",
      },
    },

    modalDescTagAlt: {
      listStyle: "none",
      padding: 0,
      "& a": {
        color: "#000",
      },
    },

    shareListDone: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: "20px",
    },

    customFileModal: {
      position: "relative",
      "& input": {
        position: "absolute",
        width: "30px",
        height: "22px",
        display: "inline-block",
        top: 0,
        left: "4px",
        opacity: 0,
      },
    },

    emojiList: {
      margin: 0,
      padding: "10px 0",
      listStyle: "none",
      "& li": {
        cursor: "pointer",
        margin: "0 5px",
      },
    },

    emojiShadow: {
      "& div": {
        boxShadow: "0px 1px 5px #9595951a",
      },
    },

    //comment reply styling -------------
    commentReply: {
      borderTop: "1px solid #ECEDF1",
      padding: "10px 0",
      "& h6": {
        fontSize: "16px",
      },
      "& p": {
        fontSize: "14px !important",
        fontWeight: 400,
      },
      "& span": {
        color: "#707070",
        fontSize: "12px",
        marginRight: "10px",
        cursor: "pointer",
      },
    },

    commentReplyFollow: {
      position: "relative",
    },

    commentReplyAlign: {
      position: "absolute",
      padding: 0,
      margin: 0,
      listStyle: "none",
      display: "flex",
      alignItems: "center",
      top: "8px",
      right: 0,
      "& li": {
        cursor: "pointer",
        marginRight: "5px",
        "& svg": {
          fontSize: "20px",
        },
      },
    },

    commentAvatar: {
      "& img": {
        width: "100%",
        height: "100%",
        objectFit: "cover",
        objectPosition: "center",
      },
    },

    replyCommentInput: {
      "& label": {
        fontSize: "14px",
      },
      "& div": {
        fontSize: "14px",
        paddingRight: "80px",
      },
    },

    commentChildImg: {
      width: "150px",
      height: "150px",
      objectFit: "cover",
      objectPosition: "center",
    },

    emojiPicker: {
      overflow: "visible",
      "& .emoji-picker-react": {
        width: "auto !important",
        position: "absolute !important",
        top: 0,
        right: 0,
        zIndex: 1,
      },
    },

    wallTextarea: {
      height: "50px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "0 20px !important",
      "& div": {
        position: "relative",
        "&::before": {
          display: "none",
        },
        "&::after": {
          display: "none",
        },
        "& textarea": {
          fontSize: "16px",
        },
      },
    },
    commentTextareaAlign: {
      top: "14px",
      color: "#fff",
    },

    wallFlexStart: {
      alignItems: "flex-start",
    },

    likeShowYou: {
      display: "flex",
      justifyContent: "space-between",
    },

    commentImgResize: {
      width: "120px",
      height: "120px",
      objectFit: "contain",
      objectPosition: "center",
    },

    multipleWallPost: {
      height: "200px",
    },

    multipleShowAfter: {
      position: "relative",
      cursor: "pointer",
      "&::after": {
        position: "absolute",
        content: '" "',
        backgroundColor: "rgba(0,0,0,0.5)",
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
      },
      "& h6": {
        position: "absolute",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
        color: "#fff",
        zIndex: 1,
        fontSize: "32px",
      },
    },
    mediaIconPopup: {
      listStyle: "none",
      padding: 0,
      margin: 0,
      "& li": {
        borderBottom: "1px solid #ccc",
        display: "flex",
        alignItems: "center",
        fontSize: "14px",
        padding: "4px 10px",
        cursor: "pointer",
        position: "relative",
        "& svg": {
          color: "#01B4BC",
          marginRight: "5px",
        },
        "& input": {
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          opacity: 0,
        },
      },
    },

    showImageBox: {
      display: "flex",
      alignItems: "center",
      padding: 0,
      margin: 0,
      listStyle: "none",
      overflowX: "scroll",
      "& li": {
        borderRadius: "20px",
        width: "150px",
        minWidth: "150px",
        height: "150px",
        marginRight: "10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflow: "hidden",
        border: "1px solid #ccc",
        position: "relative",
        "& img": {
          width: "100%",
          height: "100%",
          objectFit: "cover",
          objectPosition: "center",
        },
        "& video": {
          width: "100%",
          height: "100%",
        },
        "& svg": {
          position: "absolute",
          top: "8px",
          right: "8px",
          color: "#fff",
          backgroundColor: "rgba(0,0,0,0.5)",
          cursor: "pointer",
          borderRadius: "100px",
          zIndex: 1,
        },
      },
    },
  };
});
